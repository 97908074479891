import React, { useEffect, useState } from "react";
import { api } from "../../config/api";
import { Comment } from "../../models/communityModel";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { Spin } from "antd";

export default function MyComments() {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 5;
  const history = useHistory();

  useEffect(() => {
    fetchMyComments();
  }, [currentPage]);

  const fetchMyComments = async () => {
    try {
      const offset = (currentPage - 1) * limit;
      const response = await api.getMyComments(offset, limit);
      setComments(response.comments);
      setTotalCount(response.total);
      const totalPage = Math.ceil(response.total / limit);
      setTotalPages(totalPage < 1 ? 1 : totalPage);
    } catch (error) {
      console.error('댓글을 불러오는데 실패했습니다:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handlePrevClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  const handleNextClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.format("yyyy.MM.dd HH:mm");
  };

  const handleCommentClick = (comment: Comment) => {
    if (comment.category) {
      // 일반 댓글인 경우 자유게시판/postId 형식으로 이동
      history.push(`${pages.community}/${comment.category.id}/${comment.postId}`);
    } else {
      // AI 댓글인 경우 category 객체가 있고, 바로 postId로 이동
      history.push(`${pages.communityAi}/${comment.postId}`);
    }
  };

  if (loading) return (
    <div className="loading-container">
      <Spin size="large" />
    </div>
  );

  return (
    <section className="content">
      <h4 className="text-dark mb-4">나의 댓글</h4>
      <div className="round-box">
        {comments.length === 0 ? (
          <p className="text-center py-4">작성한 댓글이 없습니다.</p>
        ) : (
          <>
            <div className="list-style-one">
              {comments.map((comment) => (
                <div 
                  key={`comment-${comment.id}-${comment.postId}`}  // key를 더 고유하게 만듦
                  className="comment-item p-3 border-bottom cursor-pointer" 
                  onClick={() => handleCommentClick(comment)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="text-gray-500 mb-2">{comment.content}</div>
                  <div className="d-flex justify-content-between">
                    <small className="text-gray-400">게시글: {comment.postTitle}</small>
                    <small className="text-gray-400">{formatDate(comment.createdAt)}</small>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-8 col-lg-9 col-xl-5 mx-auto mt-5 mb-3">
              <div className="paging">
                <a
                  href="#"
                  id="prev"
                  onClick={handlePrevClick}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <span className="material-symbols-outlined">chevron_left</span>
                </a>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                  <a
                    href="#"
                    key={`pagination-${pageNum}`}  // pagination- 접두사 추가
                    className={currentPage === pageNum ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageClick(pageNum);
                    }}
                  >
                    {pageNum}
                  </a>
                ))}
                <a
                  href="#"
                  id="next"
                  onClick={handleNextClick}
                  className={currentPage === totalPages ? "disabled" : ""}
                >
                  <span className="material-symbols-outlined">chevron_right</span>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
