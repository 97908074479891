/* eslint-disable import/no-anonymous-default-export */
import { useState } from "react";
import {
  SafetyManagePlanModel,
  UsagePlanItem,
} from "../../../../models/beginConstDoc/SafetyManagePlanModel";
import styles from "./beginSafetyHealthInfoPage.module.scss";
import { BackTop, Button } from "antd";
import { PrinterOutlined, UpCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { Worklist } from "../../../../models/worklist/WorklistModel";
import _ from "lodash";

interface Props {
  model: SafetyManagePlanModel;
  worklist: Worklist;
  putWorkListAsync: (data: any) => void;
}

export default (props: Props) => {
  const worklist = props.worklist;
  const putWorkListAsync = async (data: any) => props.putWorkListAsync(data);

  const temp = props.model;
  const [useModel, setUseModel] = useState<SafetyManagePlanModel>(temp);

  const printedBtn = async () => {
    await saveData();
    const printBody = document.getElementById("printArea") as HTMLElement;
    document.body.innerHTML = printBody.innerHTML;
    window.print();
    window.location.reload();
  };

  // onBlur 시에 동작
  const putPlanHealthInfo = (e: any, itemIndex: number, usageIndex: number) => {
    let data = worklist;
    data = {
      ...worklist,
      ...data,
    };

    const newArr = useModel.usagePlanItems.map(
      (planItem: UsagePlanItem, i: any) => {
        const cloneItem = _.cloneDeep(planItem);
        const cloneUsageItems = _.cloneDeep(planItem.usageItems);

        if (itemIndex === i) {
          const subItem: any = cloneUsageItems?.[usageIndex];
          if (e.target.name === "productPrice") {
            //productPrice일 때 콤마값과 아닌 값 저장.
            subItem &&
              (subItem["commaProductPrice"] = convertCommaValue(
                e.target.value
              ));
            subItem &&
              (subItem["productPrice"] = +e.target.value.replaceAll(",", ""));
          } else {
            //각 input에 맞는 값이 입력
            subItem &&
              (subItem[e.target.name] = e.target.value.replaceAll(",", ""));
          }
          const calPrice =
            Number(subItem.productNum) * Number(subItem.productPrice);
          subItem && (subItem["calPrice"] = calPrice); //calPrice에 계산 값 적용
          cloneUsageItems && (cloneUsageItems[usageIndex] = subItem);

          const usageItemsLength =
            cloneUsageItems === undefined ? 0 : cloneUsageItems?.length;
          let sumCalPrice = 0;
          for (let i = 0; i < usageItemsLength; i++) {
            let calPrice =
              cloneUsageItems === undefined ? 0 : cloneUsageItems[i].calPrice;
            sumCalPrice = sumCalPrice + calPrice;
          }
          cloneItem && (cloneItem["totalPrice"] = sumCalPrice); //각 항목의 금액 합계 적용
          return { ...cloneItem, usageItems: cloneUsageItems };
        } else {
          return cloneItem;
        }
      }
    );

    let tempData: SafetyManagePlanModel = {
      usagePlanItems: newArr,
    };
    data.safetyManagePlan = tempData;
    setUseModel(tempData);
  };

  const saveData = async () => {
    const data = _.cloneDeep(worklist);
    data.safetyManagePlan = useModel;
    await putWorkListAsync(data);
  };

  const addItem = (itemIndex: number) => {
    let newArr = useModel?.usagePlanItems?.map(
      (item: UsagePlanItem, i: any) => {
        const cloneUsageItems = _.cloneDeep(item.usageItems);
        cloneUsageItems?.push({
          usageDate: "2021-01-01",
          usageItemPrice: 0,
          productName: "",
          productNum: 0,
          productPrice: 0,
          calPrice: 0,
          commaProductPrice: "",
        });
        if (itemIndex === i) {
          return { ...item, usageItems: cloneUsageItems };
        } else {
          return item;
        }
      }
    );
    setUseModel({
      usagePlanItems: newArr,
    });
  };

  const subItem = (itemIndex: number) => {
    let newArr = useModel?.usagePlanItems?.map(
      (item: UsagePlanItem, i: any) => {
        const cloneItem = _.cloneDeep(item);
        const cloneUsageItems = _.cloneDeep(item.usageItems);
        cloneUsageItems?.pop();
        if (itemIndex === i) {
          if (Number(cloneItem?.usageItems?.length) > 1) {
            const usageItemsLength =
              cloneUsageItems === undefined ? 0 : cloneUsageItems?.length;
            let sumCalPrice = 0;
            for (let i = 0; i < usageItemsLength; i++) {
              let calPrice =
                cloneUsageItems === undefined ? 0 : cloneUsageItems[i].calPrice;
              sumCalPrice = sumCalPrice + calPrice;
            }
            cloneItem && (cloneItem["totalPrice"] = sumCalPrice); //각 항목의 금액 합계 적용
            return { ...cloneItem, usageItems: cloneUsageItems };
          } else {
            return cloneItem;
          }
        } else {
          return cloneItem;
        }
      }
    );
    setUseModel({
      usagePlanItems: newArr,
    });
  };

  /**
   * 금액 자동 (,) 적용
   */
  const handlePrice = (e: any, itemIndex: number, usageIndex: number) => {
    const newArr = useModel.usagePlanItems.map(
      (planItem: UsagePlanItem, i: any) => {
        const cloneItem = _.cloneDeep(planItem);
        const cloneUsageItems = _.cloneDeep(planItem.usageItems);
        if (itemIndex === i) {
          const subItem: any = cloneUsageItems?.[usageIndex];
          subItem &&
            (subItem["commaProductPrice"] = convertCommaValue(e.target.value));
          cloneUsageItems && (cloneUsageItems[usageIndex] = subItem);
          return { ...cloneItem, usageItems: cloneUsageItems };
        } else {
          return cloneItem;
        }
      }
    );
    let tempData: SafetyManagePlanModel = {
      usagePlanItems: newArr,
    };
    setUseModel(tempData);
  };

  function convertCommaValue(value: any) {
    value = Number(value.replaceAll(",", ""));
    if (isNaN(value)) {
      return 0;
    } else {
      const formatValue = value.toLocaleString("ko-KR");
      return formatValue;
    }
  }

  return (
    <div>
      <div id="printArea">
        <div className={styles.beginSafetyContainer}>
          <div className="page-doc">
            <div className={styles.title}>항목별 사용계획</div>
            <div
              style={{
                height: "20px",
                lineHeight: "20px",
                textAlign: "right",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.beginDate).format("yyyy년　MM월")}
            </div>
            <div className={styles.table_header}>
              <div className={styles.item_name}>항목</div>
              <div className={styles.usage_date}>사용일자</div>
              <div className={styles.usage_plan_items}>사용계획</div>
              <div className={styles.usage_item_price}>금액</div>
            </div>
            <div>
              {useModel?.usagePlanItems?.map(
                (item: UsagePlanItem, itemIndex: any) => {
                  return (
                    <div
                      className={styles.table_header}
                      style={{ borderTop: "hidden" }}
                    >
                      <div className={styles.item_name}>
                        {/* <div className={styles.item_num}></div> */}
                        <div className={styles.item_num_name}>
                          {itemIndex + 1}.{" "}
                          <span
                            style={{ display: "inline-flex", width: "120px" }}
                          >
                            {item.itemName}
                          </span>
                        </div>
                      </div>
                      <div className={styles.usage_date}>
                        <div style={{ borderBottom: "1px solid" }}>소계</div>
                        {Array.from(
                          { length: Number(item.usageItems?.length) },
                          (_, usageIndex) => {
                            return (
                              <div className={styles.input_box}>
                                <input
                                  onBlur={(e: any) => {
                                    putPlanHealthInfo(e, itemIndex, usageIndex);
                                  }}
                                  className={styles.date_input}
                                  defaultValue={
                                    item?.usageItems?.[
                                      usageIndex
                                    ].usageDate.toString() === "2021-01-01"
                                      ? ""
                                      : item?.usageItems?.[
                                          usageIndex
                                        ].usageDate.toString()
                                  }
                                  placeholder="2021-01-01"
                                  name="usageDate"
                                  //defaultValue={item1}
                                />
                              </div>
                            );
                          }
                        )}
                        <div className={styles.btn_container}>
                          <button
                            className={styles.square_btn}
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              addItem(itemIndex);
                            }}
                          >
                            +
                          </button>
                          <button
                            className={styles.square_btn}
                            onClick={() => {
                              subItem(itemIndex);
                            }}
                          >
                            -
                          </button>
                        </div>
                      </div>
                      <div className={styles.usage_plan_items}>
                        <div style={{ borderBottom: "1px solid" }}>　</div>
                        {Array.from(
                          { length: Number(item.usageItems?.length) },
                          (_, usageIndex) => {
                            return (
                              <div style={{ display: "flex" }}>
                                <div
                                  className={styles.input_box}
                                  style={{ width: "40%" }}
                                >
                                  <input
                                    onBlur={(e: any) => {
                                      putPlanHealthInfo(
                                        e,
                                        itemIndex,
                                        usageIndex
                                      );
                                    }}
                                    className={styles.plan_item_name_input}
                                    defaultValue={
                                      item?.usageItems?.[usageIndex].productName
                                    }
                                    placeholder="품명"
                                    name="productName"
                                  />
                                </div>
                                <div style={{ width: "10%" }}></div>
                                <div
                                  className={styles.input_box}
                                  style={{ width: "15%" }}
                                >
                                  <input
                                    onBlur={(e: any) => {
                                      putPlanHealthInfo(
                                        e,
                                        itemIndex,
                                        usageIndex
                                      );
                                    }}
                                    className={styles.plan_item_num_input}
                                    defaultValue={
                                      item?.usageItems?.[usageIndex]
                                        .productNum === 0
                                        ? ""
                                        : item?.usageItems?.[usageIndex]
                                            .productNum
                                    }
                                    placeholder="수량"
                                    name="productNum"
                                  />
                                </div>
                                <div style={{ width: "5%" }}>
                                  {item?.usageItems?.[usageIndex].productNum ===
                                    0 ||
                                  item?.usageItems?.[
                                    usageIndex
                                  ].productNum.toString() === ""
                                    ? ""
                                    : "x"}
                                </div>
                                <div
                                  className={styles.input_box}
                                  style={{ width: "30%" }}
                                >
                                  <input
                                    onBlur={(e: any) => {
                                      putPlanHealthInfo(
                                        e,
                                        itemIndex,
                                        usageIndex
                                      );
                                    }}
                                    onChange={(e: any) => {
                                      handlePrice(e, itemIndex, usageIndex);
                                    }}
                                    className={styles.plan_item_price_input}
                                    //defaultValue={item?.usageItems?.[usageIndex].productPrice === 0 ? '' : Number(item?.usageItems?.[usageIndex].productPrice).toLocaleString()}
                                    value={
                                      item?.usageItems?.[usageIndex]
                                        .commaProductPrice === "0"
                                        ? ""
                                        : item?.usageItems?.[usageIndex]
                                            .commaProductPrice
                                    }
                                    placeholder="단가"
                                    name="productPrice"
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                      <div className={styles.usage_item_price}>
                        <div style={{ borderBottom: "1px solid" }}>
                          {item.totalPrice.toLocaleString() === "0"
                            ? "　"
                            : item.totalPrice.toLocaleString()}
                        </div>
                        {Array.from(
                          { length: Number(item.usageItems?.length) },
                          (_, usageIndex) => {
                            return (
                              <div style={{ height: "20px" }}>
                                {Number(
                                  item?.usageItems?.[usageIndex].calPrice
                                ) === 0 ||
                                item?.usageItems?.[
                                  usageIndex
                                ].calPrice.toString() === ""
                                  ? ""
                                  : Number(
                                      item?.usageItems?.[usageIndex].calPrice
                                    ).toLocaleString()}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
            <div className={styles.comment}>
              ※ 주 : 사용계획은 항목별 사용일자가 빠른 순서로 작성
            </div>
            <div className={styles.save_container}>
              <button className={styles.square_btn} onClick={saveData}>
                <span>저장</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Button
        className="circleBtn"
        style={{
          backgroundColor: "rgb(68, 132, 255)",
          float: "right",
          position: "fixed",
          right: "63px",
          bottom: "200px",
        }}
        onClick={printedBtn}
      >
        <div>
          <PrinterOutlined
            className="printer-icon"
            style={{ fontSize: "30px" }}
          />
        </div>
        <div style={{ color: "white" }}>
          현재 페이지<div style={{ color: "white" }}>출력</div>
        </div>
      </Button>
      <BackTop style={{ bottom: "150px" }}>
        <UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} />
      </BackTop>
    </div>
  );
};
