/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-anonymous-default-export */
import { useContext, useEffect, useRef, useState } from "react";
import { api } from "../../config/api";
import { Project } from "../../config/models";
import styles from "./costProjectsPage.module.scss";
import { SearchOutlined } from "@ant-design/icons";
import SelectProjectModal from "../../components/modal/selectProject/SelectProjectModal";
import _ from "lodash";
import { CircularProgress, Dialog } from "@material-ui/core";
import CreateProjectModal from "../../components/modal/createProject/CreateProject";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import * as crypto from "crypto-js";
import SimpleConfirm from "../../components/modal/confirm/SimpleConfirm";
import DupLoginContext from "../../contexts/DupLoginContext";
import { useLocation } from "react-router-dom";
import { FreeUseDay } from "../../constants/General";
import { Link } from "react-router-dom";
import WorkListModal from "../../components/modal/workListModal/WorkListModal";
import WorkListModifyModal from "../../components/modal/workListModifyModal/WorkListModifyModal";
import DeleteConfirm from "../../components/modal/deleteConfirm/DeleteConfirm";
import EditProjectInfo from "../../components/modal/editProjectInfo/EditProjectInfo";
import EditProject from "../../components/modal/editProject/EditProject";

export default (props: any) => {
  const pageSize = 8;
  const [workProjects, setWorkProjects] = useState<[]>([]);
  const [originProjects, setOriginProjects] = useState<Project[]>();
  const [originWorkLists, setoriginWorkLists] = useState<Project[]>();
  const [projects, setProjects] = useState<Project[]>();
  const [currentIndex, setCurrentIndex] = useState(1);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(pageSize);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchFilterValue, setSearchFilterValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [estimate, setEstimate] = useState(false);
  const [copy, setCopy] = useState(false);
  const [tempRow, setTempRow] = useState(false);
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false);
  const [editConfirmOpen, setEditConfirmOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(0);
  const [isModalType, setIsModalType] = useState(0);
  const [isWorkModalState, setIsWorkModalState] = useState(false);
  const [isWorkModalModifyState, setIsWorkModalModifyState] = useState(false);
  const [isWorkModalModifyItem, setIsWorkModalModifyItem] = useState({});

  const isExpiredUser = useRef(false);

  const alert = useContext(AlertContext);
  const dupLogin = useContext(DupLoginContext);
  const history = useHistory();
  const location = useLocation();
  const windowLocation = window.location.href;
  const paths = windowLocation.split("/");
  let lastpath = paths[paths.length - 1];
  const [type, setType] = useState(lastpath);

  const handleChange = (page: number) => {
    setCurrentIndex(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  };

  const showWorkListModal = (type: any) => {
    setIsWorkModalState(true);
    setIsModalType(type);
  };

  const setModifyState = (item?: any) => {
    setIsWorkModalState(false);
    setIsWorkModalModifyState(true);
    setIsWorkModalModifyItem(item);
  };
  const onWorkListSave = () => {};
  useEffect(() => {
    setLoading(true);
    const windowLocation = window.location.href;
    const paths = windowLocation.split("/");
    lastpath = paths[paths.length - 1];
    if (lastpath == "copy") {
      setCopy(true);
    } else {
      setCopy(false);
    }
    setType(lastpath);
    new Promise(async () => {
      const key = util.getCookie(Cookies.authKey);
      if (!key) {
        alert.showAlert(
          "로그인 후 이용해주세요.\n회원가입시 " +
            FreeUseDay +
            "일 무료기간 제공중입니다.",
          () => {
            history.push(pages.login);
          }
        );
        return;
      } else {
        const info = await dupLogin.checkDupLogin(api.getMyInfo);
        if (
          info?.data?.expiry_date?.toString() === "None" ||
          new Date(info?.data?.expiry_date).getTime() -
            new Date(new Date().format("yyyy-MM-dd")).getTime() <
            0
        ) {
          isExpiredUser.current = true;
        }
      }
      await getProjects();

      setLoading(false);
    });
  }, [location]);

  const onClose = async () => {
    setDialogOpen(false);
    await getProjects();
  };

  const search = (value: string, filter?: string) => {
    let ps: any;
    if (lastpath != "worklist") {
      ps = originProjects?.filter((element: any) => {
        if (filter != "") {
          if (filter == "normal") {
            return element.name.includes(value) && element.subTypes[0] == null;
          }
          return (
            element.name.includes(value) && element.subTypes.includes(filter)
          );
        }
        return element.name.includes(value);
      });
      setSearchValue(value);
      setProjects(ps);
      setCurrentIndex(1);
      setMinIndex(0);
      setMaxIndex(pageSize);
      setSelectedIndex(0);
    } else {
      ps =
        value === "" || !value
          ? _.cloneDeep(originWorkLists)
          : originWorkLists?.filter((element: any) => {
              return element.constructionName?.includes(value);
            });
      setSearchValue(value);
      setWorkProjects(ps);
    }
  };

  const createProject = () => {
    if (
      isExpiredUser.current &&
      (originProjects?.length == null || originProjects.length > 0)
    ) {
      alert.showAlert("이용권을 구매하셔야 프로젝트 추가 생성이 가능합니다.");
      return;
    }

    setSelectedProjectId(-1);
    setEditConfirmOpen(true);
    setTempRow(false);
    setCreateOpen(true);
    setEstimate(false);
    setCopy(false);
  };

  const createClose = async (
    title?: string,
    varCopy?: boolean,
    varEestimate?: boolean,
    tempRow?: boolean
  ) => {
    setCreateOpen(false);
    if (!title) return;
    setLoading(true);
    let isCopy: boolean = false;
    let isEstimate: boolean = false;
    if (type == "copy") {
      isCopy = true;
    } else if (type == "estimate") {
      isEstimate = true;
    }
    await api.createProject(title, tempRow ? true : false, isEstimate, isCopy);

    await getProjects();
    setLoading(false);
  };

  const createEstimateProject = () => {
    if (
      isExpiredUser.current &&
      (originProjects?.length == null || originProjects.length > 0)
    ) {
      alert.showAlert("이용권을 구매하셔야 프로젝트 추가 생성이 가능합니다.");
      return;
    }
    setCreateOpen(true);
    setEstimate(true);
    setTempRow(true);
    setCopy(false);
  };

  const createCopyProject = () => {
    if (
      isExpiredUser.current &&
      (originProjects?.length == null || originProjects.length > 0)
    ) {
      alert.showAlert("이용권을 구매하셔야 프로젝트 추가 생성이 가능합니다.");
      return;
    }
    setEstimate(false);
    setCopy(true);
    setTempRow(true);
    setCreateOpen(true);
  };

  const clickProject = (project: any) => {
    const type = project.type;
    const id = project.id;
    if (type === "estimate") {
      const url = `${process.env.REACT_APP_COST_URL}document/${window.btoa(
        id
      )}`;
      window.location.href = url;
    } else {
      history.push(pages.costprojects + "/detail/" + id + "?type=" + type);
      // setDialogOpen(true);
      // setSelectedIndex(project.id);
    }
  };

  const clickFile = () => {
    if (isExpiredUser.current) {
      alert.showAlert("이용권을 구매하셔야 프로젝트 추가 생성이 가능합니다.");
      return;
    }
    document.getElementById("ddusulFile")?.click();
  };

  const clickImportXmlFile = () => {
    if (isExpiredUser.current) {
      alert.showAlert("이용권을 구매하셔야 프로젝트 추가 생성이 가능합니다.");
      return;
    }
    document.getElementById("xmlFile")?.click();
  };

  const getProjects = async () => {
    if (lastpath == "worklist") {
      const workObj: any = await api.getWorklists({ offset: 0, limit: 40 });
      const workProjects = workObj?.data.worklist_list;
      setWorkProjects(workProjects);
      setoriginWorkLists(workProjects);
    } else {
      const windowLocation = window.location.href;
      const paths = windowLocation.split("/");
      const tt = paths[paths.length - 1];
      const projects = await api.getProjects(tt);
      if (projects?.response?.status !== 401) {
        setOriginProjects(projects);
        setProjects(projects);
      }
    }
  };

  const clickDelete = async (event: any, id: number) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedProjectId(id);
    setRemoveConfirmOpen(true);
  };
  const handleWorklistDelete = (event: any, id: number) => {
    alert.showConfirmAlert(
      "알림",
      "공사 목록을 삭제하시겠습니까?",
      "예",
      "아니오",
      async () => {
        await api.deleteWorklist(id);
        const index = workProjects?.findIndex(
          (element: any) => element.worklist_id === id
        );
        const cloneWorklists = _.cloneDeep(workProjects);
        cloneWorklists?.splice(index, 1);
        setWorkProjects(cloneWorklists);
      }
    );
  };

  const clickEdit = async (event: any, id: number) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedProjectId(id);
    const project = projects?.find((p) => p.id === id);
    alert.showPrompt(
      "프로젝트 이름을 입력해주세요.",
      project?.name,
      async (value) => {
        if (value != undefined && project) {
          project.name = value;
          await dupLogin.checkDupLogin(() => api.editProjectInfo(project));
          const i = projects?.findIndex((p: Project) => p.id === project.id);
          projects && i && (projects[i] = _.cloneDeep(project));
          setProjects(projects);
        }
      }
    );
  };

  const handleClickEditInfo = async (event: any, el: Project) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedProjectId(el.id);
    setEditConfirmOpen(true);
  };

  const toggleFavorite = async (event: any, project: Project) => {
    event.stopPropagation();
    event.preventDefault();
    const index = projects?.findIndex((p) => p.id === project.id);
    if (index !== undefined) {
      await dupLogin.checkDupLogin(() =>
        api.toggleFavoriteProject(project.id, project.isFavorite ? false : true)
      );
      project.isFavorite = project.isFavorite ? false : true;
      setProjects(_.cloneDeep(projects));
      alert.showAlert(
        project.isFavorite
          ? "즐겨찾기에 추가되었습니다."
          : "즐겨찾기에서 삭제되었습니다."
      );
    }
  };

  const onSaveWorkList = async (type: number, object: any) => {
    if (type == 1) {
      if (object.deputyId) {
        await api.putDeputy(object, object.deputyId);
        setIsWorkModalModifyState(false);
        setIsWorkModalState(true);
      } else {
        await api.postDeputy(object);
        setIsWorkModalModifyState(false);
        setIsWorkModalState(true);
      }
    } else {
      if (object.company_id) {
        await api.putWorkCompany(object, object.company_id);
        setIsWorkModalModifyState(false);
        setIsWorkModalState(true);
      } else {
        await api.postWorkCompany(object);
        setIsWorkModalModifyState(false);
        setIsWorkModalState(true);
      }
    }
  };
  const onClickRemoveCancel = () => {
    setRemoveConfirmOpen(false);
  };

  const onClickRemoveConfirm = async () => {
    setRemoveConfirmOpen(false);
    await dupLogin.checkDupLogin(() => api.deleteProject(selectedProjectId));
    await getProjects();
  };

  const changeFile = (event: any) => {
    const file = event.target.files[0];
    const fileReader: FileReader = new FileReader();
    fileReader.onloadend = async (x) => {
      try {
        setLoading(true);
        const str = fileReader.result as string;
        await loadFromDdusulFile(str);
        setLoading(false);
      } catch (error) {
        alert.showAlert(
          "오류가 발생하였습니다. 파일을 다시한번 확인해 주세요.",
          () => {
            history.push(pages.index);
          }
        );
      }
    };
    if (file instanceof Blob) {
      fileReader.readAsText(file);
    }
  };

  const handleLectureClick = () => {
    const typeLecturePair: any = {
      normal: "0/47",
      estimate: "0/34",
      copy: "0/41",
    };
    window.open(
      process.env.REACT_APP_URL + "lectureroom/" + typeLecturePair[type],
      "",
      "width=#, height=#"
    );
  };

  const handleDdusulLectureClick = () => {
    // type
    //normal -> 7/21
    //copy -> 7/41
    //es -> 7/34
    history.push("/lectureroom/7/35");
    // window.open(
    //   process.env.REACT_APP_URL + "lectureroom/7/35",
    //   "",
    //   "width=#, height=#"
    // );
  };

  const handleXmlFileChange = (event: any) => {
    const file = event.target.files[0];
    const fileReader: FileReader = new FileReader();

    fileReader.onloadend = async (x) => {
      try {
        setLoading(true);
        let str = fileReader.result as string;
        const res: any = str.match("비고>(.*?)</비고");
        const projectId = res[1];
        if (!projectId) throw "project is null";
        const xmlRes = await api.getXmlProject(projectId);
        const ddusulString = xmlRes.data.encrypted_code;
        await loadFromDdusulFile(ddusulString);
        setLoading(false);
      } catch (error) {
        alert.showAlert(
          "오류가 발생하였습니다. 파일을 다시한번 확인해 주세요."
        );

        setLoading(false);
      }
    };
    if (file instanceof Blob) {
      fileReader.readAsText(file);
    }
  };

  const loadFromDdusulFile = async (str: string) => {
    const key = str.substring(0, 5);
    const value = str.substring(5);
    const obj = JSON.parse(
      crypto.AES.decrypt(value, key).toString(crypto.enc.Utf8)
    );
    const estimate = obj.filter((element: any) => element.type === "estimate");
    const copy = obj.filter((element: any) => element.type === "copy");
    const normal = obj.filter((element: any) => element.type === "normal");
    const contract = obj.filter((element: any) => element.type === "contract");
    const change = obj.filter((element: any) => element.type === "change");
    const ready = obj.filter((element: any) => element.type === "ready");
    const complete = obj.filter((element: any) => element.type === "complete");
    await dupLogin.checkDupLogin(() =>
      api.uploadProject({
        copy,
        normal,
        contract,
        change,
        ready,
        complete,
        estimate,
      })
    );
    await getProjects();
  };

  const title = () => {
    if (lastpath === "normal") {
      return "나의 설계내역";
    } else if (lastpath === "estimate") {
      return "나의 견적서";
    } else if (lastpath === "worklist") {
      return "나의 계약서류";
    } else {
      return "나의 입찰내역";
    }
  };

  const elements = () => {
    if (lastpath === "worklist") {
      return workProjects?.map((el: any, index) => {
        return (
          <div
            className="board-card-list"
            onClick={() => {
              history.push(
                `/worklists/${el.worklist_id}/documents/companyinfo`
              );
            }}
          >
            <a>
              <div className="d-flex gap-5 align-items-center">
                <div className="date">
                  <span className="yy-mm">
                    {new Date(el.created).format("yy.MM")}
                  </span>
                  <div className="dd">{new Date(el.created).format("dd")}</div>
                </div>
                <div>
                  <div className="d-flex gap-2">
                    <div className="badge text-bg-primary-soft category mt-0">
                      일반
                    </div>
                    {el.contract ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        계약
                      </div>
                    ) : (
                      ""
                    )}
                    {el.change ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        변경
                      </div>
                    ) : (
                      ""
                    )}
                    {el.ready ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        기성
                      </div>
                    ) : (
                      ""
                    )}
                    {el.complete ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        준공
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="title">{el.constructionName || "-"}</div>
                </div>
              </div>
            </a>
          </div>
        );
      });
    } else {
      return projects?.map((el, index) => {
        return (
          <div
            className="board-card-list"
            onClick={() => {
              clickProject(el);
            }}
          >
            <a>
              <div className="d-flex gap-5 align-items-center">
                <div className="date">
                  <span className="yy-mm">
                    {new Date(el.created).format("yy.MM")}
                  </span>
                  <div className="dd">{new Date(el.created).format("dd")}</div>
                </div>
                <div>
                  <div className="d-flex gap-2">
                    <div className="badge text-bg-primary-soft category mt-0">
                      일반
                    </div>
                    {el.contract ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        계약
                      </div>
                    ) : (
                      ""
                    )}
                    {el.change ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        변경
                      </div>
                    ) : (
                      ""
                    )}
                    {el.ready ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        기성
                      </div>
                    ) : (
                      ""
                    )}
                    {el.complete ? (
                      <div className="badge text-bg-primary-soft category mt-0">
                        준공
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="title">{el.name}</div>
                </div>
                <div className="position-absolute end-0 d-flex gap-2 me-4">
                  <button
                    type="button"
                    className="btn btn-primary-soft"
                    data-bs-toggle="modal"
                    data-bs-target="#project-editModal"
                    onClick={(event) => handleClickEditInfo(event, el)}
                  >
                    <span className="material-symbols-outlined">edit</span>
                    수정
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger-soft"
                    data-bs-toggle="modal"
                    data-bs-target="#dialogs"
                    onClick={(event) => clickRemove(event, el)}
                  >
                    <span className="material-symbols-outlined">delete</span>
                    삭제
                  </button>
                  {type === "normal" && (
                    <button
                      type="button"
                      className={`btn btn-link p-0 ${styles.favoriteButton}`}
                      onClick={(event) => toggleFavorite(event, el)}
                    >
                      {
                        <svg
                          className={`${styles.starIcon} ${
                            el.isFavorite ? styles.filled : styles.outline
                          }`}
                          xmlns="http://www.w3.org/2000/svg"
                          width="36"
                          height="36"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
                        </svg>
                      }
                    </button>
                  )}
                </div>
              </div>
            </a>
          </div>
        );
      });
    }
  };

  const clickRemove = (event: any, project: Project) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedProjectId(project.id);
    setRemoveConfirmOpen(true);
  };

  const totalCount = () => {
    if (lastpath === "worklist") {
      return workProjects.length;
    } else {
      return projects?.length;
    }
  };

  const types = [
    { title: "전체", value: "" },
    { title: "일반", value: "normal" },
    { title: "계약", value: "contract" },
    { title: "변경", value: "change" },
    { title: "기성", value: "ready" },
    { title: "준공", value: "complete" },
  ];

  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">
              <div className="section-btn-right">
                <div className="d-flex align-items-center">
                  {title()}
                  {type !== "worklist" ? (
                    <a
                      className="btn text-gray-400"
                      onClick={handleLectureClick}
                    >
                      <span className="material-symbols-outlined">help</span>
                    </a>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex align-items-center gap-3">
                  {type === "normal" ? (
                    <>
                      <label
                        htmlFor="formFile"
                        className=" btn btn-light left-icon"
                        onClick={() => {
                          clickFile();
                        }}
                      >
                        <input
                          type="file"
                          onChange={changeFile}
                          style={{ width: 0, height: 0, position: "absolute" }}
                          id="ddusulFile"
                          accept=".ddusul"
                        />
                        <span className="material-symbols-outlined me-2">
                          folder_open
                        </span>
                        .ddusul 파일열기
                      </label>
                      <label
                        htmlFor="formFile"
                        className=" btn btn-light left-icon"
                        onClick={clickImportXmlFile}
                      >
                        <input
                          type="file"
                          onChange={handleXmlFileChange}
                          style={{ width: 0, height: 0, position: "absolute" }}
                          id="xmlFile"
                          accept=".xml"
                        />
                        <span className="material-symbols-outlined me-2">
                          folder_open
                        </span>
                        .xml 파일열기
                      </label>

                      <button
                        type="button"
                        className="btn btn-primary left-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#project-editModal"
                        onClick={createProject}
                      >
                        <span className="material-symbols-outlined me-2">
                          add
                        </span>
                        설계내역 만들기
                      </button>
                    </>
                  ) : type === "estimate" ? (
                    <>
                      <label
                        htmlFor="formFile"
                        className=" btn btn-light left-icon"
                        onClick={() => {
                          clickFile();
                        }}
                      >
                        <input
                          type="file"
                          onChange={changeFile}
                          style={{ width: 0, height: 0, position: "absolute" }}
                          id="ddusulFile"
                          accept=".ddusul"
                        />
                        <span className="material-symbols-outlined me-2">
                          folder_open
                        </span>
                        .ddusul 파일열기
                      </label>
                      <button
                        type="button"
                        className="btn btn-primary left-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#newprojectModal"
                        onClick={createEstimateProject}
                      >
                        <span className="material-symbols-outlined me-2">
                          add
                        </span>
                        견적서 만들기
                      </button>
                    </>
                  ) : type === "worklist" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-light left-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#AgentModal"
                        onClick={() => {
                          showWorkListModal("1");
                        }}
                      >
                        <span className="material-symbols-outlined">
                          person_pin_circle
                        </span>
                        현장대리인
                      </button>
                      <button
                        type="button"
                        className="btn btn-light left-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#AgentModal"
                        onClick={() => {
                          showWorkListModal("2");
                        }}
                      >
                        <span className="material-symbols-outlined">
                          apartment
                        </span>
                        업체정보
                      </button>
                      <Link
                        to={pages.newwork}
                        className="btn btn-primary left-icon"
                      >
                        <span className="material-symbols-outlined me-2">
                          add
                        </span>
                        계약서류 만들기
                      </Link>
                    </>
                  ) : (
                    <>
                      <label
                        htmlFor="formFile"
                        className=" btn btn-light left-icon"
                        onClick={() => {
                          clickFile();
                        }}
                      >
                        <input
                          type="file"
                          onChange={changeFile}
                          style={{ width: 0, height: 0, position: "absolute" }}
                          id="ddusulFile"
                          accept=".ddusul"
                        />
                        <span className="material-symbols-outlined me-2">
                          folder_open
                        </span>
                        .ddusul 파일열기
                      </label>
                      <button
                        type="button"
                        className="btn btn-primary left-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#newprojectModal"
                        onClick={createCopyProject}
                      >
                        <span className="material-symbols-outlined me-2">
                          add
                        </span>
                        입찰내역 만들기
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 pt-4">
              <div className="input-group mb-3" id="search-form">
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="검색어를 입력해주세요"
                  aria-label="Text input with dropdown button "
                  value={searchValue}
                  onChange={(e) => {
                    search(e.target.value, searchFilterValue);
                  }}
                />
              </div>
            </div>
            {!["worklist", "estimate"].includes(type) && (
              <div className="col-12 pt-5">
                <div className="category-wrap">
                  {types.map((type, index) => {
                    return (
                      <>
                        <input
                          type="radio"
                          className="btn-check"
                          name="category"
                          id={"option" + index}
                          checked={searchFilterValue == type.value}
                          value={type.value}
                          onChange={(e) => {
                            setSearchFilterValue(e.target.value);
                            search(searchValue, e.target.value);
                          }}
                        />
                        <label className="btn" htmlFor={"option" + index}>
                          {type.title}
                        </label>
                      </>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="col-12">
              <div className="mt-5">
                <div>
                  TOTAL
                  <span className="text-primary fw-bold ms-1">
                    {totalCount()}
                  </span>
                </div>
              </div>
              <div
                className="board-list-wrap aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {elements()}
              </div>
            </div>
          </div>
        </section>
      </article>
      <CreateProjectModal
        open={createOpen}
        onClose={createClose}
        estimate={estimate}
        copy={copy}
        tempRow={tempRow}
      />
      <WorkListModal
        type={isModalType}
        open={isWorkModalState}
        onClickCancel={() => {
          setIsWorkModalState(false);
        }}
        onClickWrite={(item?) => {
          setModifyState(item);
        }}
      />
      <DeleteConfirm
        open={removeConfirmOpen}
        onClickConfirm={onClickRemoveConfirm}
        onClickCancel={onClickRemoveCancel}
      />
      <EditProject
        isCreate={createOpen}
        open={editConfirmOpen}
        onClose={async (project) => {
          setEditConfirmOpen(false);
            setSelectedProjectId(0);
            if (createOpen) {
            setCreateOpen(false);
            setLoading(true);
            if (project) {
              if (!project?.name) {
                alert.showAlert("프로젝트 이름을 입력해주세요.");
                return;
              }
              await api.createProject(
                project.name,
                false,
                undefined,
                undefined,
                project.corporationType,
                project.memo
              );
              // 프로젝트 추가
              getProjects();
            }
            setSelectedProjectId(0);
          } else {
            if (project) {
              api.editProjectInfo(project);
              // 프로젝트 정보 수정
              const i = projects?.findIndex(
                (p: Project) => p.id === project.id
              );
              projects &&
                i !== undefined &&
                (projects[i] = _.cloneDeep(project));

              setProjects(projects);
            }
          }
        }}
        project={projects?.find((p) => p.id === selectedProjectId)}
      />
    </main>
  );
};
