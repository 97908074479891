import { Link, useLocation } from "react-router-dom";

export default () => {
  const location = useLocation();
  const linkLocation = location.pathname;
  return (
    <footer
      style={
        linkLocation.indexOf("video/course") > -1 ? { display: "none" } : {}
      }
    >
      <div id="footer">
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between align-items-center py-4">
              <a
                className="btn btn-circle-xlg bg-gray-200"
                target="_blank"
                href="https://www.youtube.com/channel/UCRELSdKly-04TI7mjv_acCw"
              >
                <img
                  src="/images/ddusul/icon/sns-youtube.png"
                  className="w-100"
                />
              </a>
              <div className="d-flex gap-3 ">
                <a className="btn btn-md btn-outline" href="/statementlist">
                  샘플내역서
                </a>
                <a className="btn btn-md btn-outline" href="/payment">
                  이용권
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="border-1"></div>
        <div className="container">
          <div className="row py-4">
            <div
              className="col-sm-9  d-flex justify-content-between"
              id="footer-menu-wrap"
            >
              <div className="footer-menu">
                <div className="SubTitle3 text-dark">서비스</div>
                <ul>
                  <li>
                    <Link to="/costprojects/normal">설계내역</Link>
                  </li>
                  <li>
                    <Link to="/costprojects/copy">입찰내역</Link>
                  </li>
                  <li>
                    <Link to="/costprojects/estimate">견적서</Link>
                  </li>
                  <li>
                    <Link to="/costprojects/worklist">계약서류</Link>
                  </li>
                  <li>
                    <Link to="/excel">엑셀 자동화</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-menu">
                <div className="SubTitle3 text-dark">강의실</div>
                <ul>
                  <li>
                    <Link to="/lectureroom/0">강의실</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-menu">
                <div className="SubTitle3 text-dark">고객센터</div>
                <ul>
                  <li>
                    <Link to="/data">자료실</Link>
                  </li>
                  <li>
                    <Link to="/noticelist">공지사항</Link>
                  </li>
                  <li>
                    <Link to="/inquiry">문의하기</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-menu">
                <div className="SubTitle3 text-dark">용역 의뢰</div>
                <ul>
                  <li>
                    <Link to="/contact">설계문의</Link>
                  </li>
                  <li>
                    <Link to="/requestapplication">의뢰신청</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-3 col-12">
              <div className="SubTitle3 text-dark">고객센터</div>
              <h2 className="mt-3">
                <a className="text-dark" href="tel:070-7537-2587">
                  070-7537-2587
                </a>
              </h2>
              <a
                className="btn btn-md btn-primary-soft w-100 btn-full"
                href="/inquiry"
              >
                문의하기
              </a>
              <div className="mt-3 body2">
                오전 10시 ~ 오후 6시 (주말, 공휴일 제외)
              </div>
              <a
                className="btn btn-md btn-dark w-100 btn-full mt-3"
                href="/lectureroom/0"
              >
                뚜껑설계 사용법
              </a>
            </div>
          </div>
        </div>
        <div className="border-1"></div>
        <div className="container">
          <div>
            <img src="/images/ddusul/logo-b.png" className="footer-logo" />
            <div className="col-12">
              <div className="footer-bottom-menu">
                <ul>
                  <li data-bs-toggle="modal" data-bs-target="#terms">
                    이용약관
                  </li>
                  <li data-bs-toggle="modal" data-bs-target="#privacy">
                    <strong>개인정보 처리방침</strong>
                  </li>
                  <li>환불 정책</li>
                  <li>사업자 정보 확인</li>
                  <li>제휴/협력 문의</li>
                  <li>단체/기업 교육 문의</li>
                </ul>
              </div>
            </div>
            <div className="col-12 copyright mb-4">
              <p>
                주식회사 뚜껑 (대표이사: 양경석) / 제주특별자치도 서귀포 신서로
                102번길 30. 1층 (주)뚜껑사업자등록번호: 686-88-02080 /
                통신판매업신고: 제 2021-제주대천-56호팩스번호 : 0504-434-5924 /
                문의 이메일 : ddk@ddukeong.com
              </p>
              <p>© 2021 DDUKEONG Corp.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
