export default () => {
  return (
    <div
      className="modal fade"
      id="BusinessNumbermodal"
      aria-labelledby="BusinessNumberLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="BusinessNumberLabel">
              사업자번호 확인
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">올바르지 않은 사업자번호입니다.</div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary btn-lg w-100"
              data-bs-dismiss="modal"
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
