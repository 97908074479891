import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Typography, Tag, Descriptions, Progress, Button } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import styles from "./ExternalProjectDetail.module.scss";
import { ProjectModel } from "../../models/service/ServiceInfo";
import { useEffect } from "react";
import { serviceApi } from "../../config/serviceApi";

const { Title, Text } = Typography;

export const ExternalProjectDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [project, setProject] = useState<ProjectModel>();
  useEffect(() => {
    async function fetch() {
      const res = await serviceApi.getExternalProject(+id);
      setProject(res.data);
    }
    fetch();
  }, []);

  if (!project) {
    return <div>요청을 찾을 수 없습니다.</div>;
  }

  const getStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            검토중
          </Tag>
        );
      case "in_progress":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            진행중
          </Tag>
        );
      case "completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            완료
          </Tag>
        );
      default:
        return null;
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("ko-KR", {
      style: "currency",
      currency: "KRW",
    }).format(amount);
  };

  const progressPercentage = (project.currentCost / project.budget) * 100;

  const handleBack = () => {
    history.push("/service-status");
  };

  return (
    <div className={styles.container}>
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={handleBack}
        className={styles.backButton}
      >
        목록으로 돌아가기
      </Button>
      <Card className={styles.detailCard}>
        <Title level={2}>{project.title}</Title>
        <div className={styles.statusContainer}>
          {getStatusTag(project.status)}
          <Text type="secondary">신청일: {project.date}</Text>
        </div>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="서비스 종류">
            {project.service}
          </Descriptions.Item>
          <Descriptions.Item label="의뢰인">{project.client}</Descriptions.Item>
          <Descriptions.Item label="담당 전문가">
            {project.expert}
          </Descriptions.Item>
          <Descriptions.Item label="프로젝트 설명">
            {project.description}
          </Descriptions.Item>
          <Descriptions.Item label="총 예산">
            {formatCurrency(project.budget)}
          </Descriptions.Item>
          <Descriptions.Item label="현재 사용 비용">
            {formatCurrency(project.currentCost)}
          </Descriptions.Item>
        </Descriptions>
        <div className={styles.progressContainer}>
          <Text strong>진행률</Text>
          <Progress percent={Math.round(progressPercentage)} status="active" />
        </div>
      </Card>
    </div>
  );
};
