import { Checkbox, Input, Pagination, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./noticeList.scss";
import { api } from "../../config/api";
import { Link, useHistory } from "react-router-dom";
import { util } from "../../config/util";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { Notice } from "../../config/models";
import CategorySelector from "../../components/selector/categorySelector/CategorySelector";
import { noticeCategoryData } from "../../constants/NoticeData";
import { SearchOutlined } from "@ant-design/icons";

function NoticeList() {
  //공지사항 목록 서버에서 불러오기
  const [current, setCurrent] = useState<number>(1);
  const noticeSize: number = 3;
  const [minIndex, setMinIndex] = useState<number>(0);
  const [maxIndex, setMaxIndex] = useState<number>(3);
  const [selectedNoticeCategoryIndex, setSelectedNoticeCategoryIndex] =
    useState(0);
  const [notices, setNotices] = useState<Notice[]>([]);
  const [filteredNotices, setFilteredNotices] = useState<Notice[]>([]);

  useEffect(() => {
    if (notices.length === 0) {
      api
        .getNotices({ offset: 0, limit: notices.length })
        .then((result) => {
          const notices = result.data.notices;
          const n = notices.map((notice: any) => new Notice(notice));
          setNotices(n);
          setFilteredNotices(n);
        })
        .catch((error) => {
          console.error("에러발생 : ", error);
        });
    }
  });

  ///////////////////////////////////////////////////////////////////
  //검색 필터 구현
  //빈 값 체크 함스
  const isEmpty = function (value: any): boolean {
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (value != null && typeof value === "object" && !Object.keys(value).length)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setValue = (value: any) => {
    setFilterInput(value.target.value);
  };

  const { Search } = Input;

  const [filterInput, setFilterInput] = useState<string>("");
  const filterNotice = () => {
    if (isEmpty(filterInput)) return filteredNotices;
    if (!isEmpty(filterInput))
      return filteredNotices.filter((notice: Notice) =>
        notice.title.includes(filterInput)
      );
  };

  //pagenation
  const handleChange = (page: number) => {
    setCurrent(page);
    setMinIndex((page - 1) * noticeSize);
    setMaxIndex(page * noticeSize);
  };

  const handleCategoryClick = (index: number) => {
    setSelectedNoticeCategoryIndex(index);
    handleChange(1);
    if (index === 0) {
      setFilteredNotices(notices);
    } else {
      setFilteredNotices(
        notices.filter((notice) => {
          return notice.tag === noticeCategoryData[index].title;
        })
      );
    }
  };

  const pageList = () => {
    const totalPage: number = filterNotice()?.length!;
    const lastPage =
      totalPage % noticeSize > 0
        ? totalPage / noticeSize + 1
        : totalPage / noticeSize;
    let pages = [];
    for (let i = 1; i <= lastPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">공지사항</div>
            <div className="col-12 pt-4">
              <form>
                <div className="input-group mb-3" id="search-form">
                  <button className="btn btn-md dropdown-toggle" type="button">
                    전체
                  </button>
                  {/* <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        제목
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        내용
                      </a>
                    </li>
                  </ul> */}
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="검색어를 입력해주세요"
                    aria-label="Text input with dropdown button "
                    value={filterInput}
                    onChange={(value) => {
                      handleChange(1);
                      setValue(value);
                    }}
                  />
                </div>
              </form>
            </div>

            <div className="col-12 pt-5">
              <div className="category-wrap">
                {noticeCategoryData.map((category, index) => {
                  const idIndex = index + 1;
                  return (
                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="category"
                        id={"option" + idIndex}
                        checked={selectedNoticeCategoryIndex === index}
                        onClick={() => {
                          handleCategoryClick(index);
                        }}
                      />
                      <label className="btn" htmlFor={"option" + idIndex}>
                        {category.title}
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-12">
              <div className="mt-5">
                <div>
                  TOTAL
                  <span className="text-primary fw-bold ms-1">
                    {filterNotice()?.length}
                  </span>
                </div>
              </div>
              <div
                className="board-wrap aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {filterNotice()?.map(
                  (notice: Notice, index: number) =>
                    index >= minIndex &&
                    index < maxIndex && (
                      <div className="board-card">
                        <a href={`/notice/${notice.id}`}>
                          <div className="date">
                            <span className="yy-mm">
                              {notice.date.format("yyyy.MM")}
                            </span>
                            <div className="dd">{notice.date.format("dd")}</div>
                          </div>
                          <div className="badge text-bg-secondary category">
                            {notice?.tag}
                          </div>
                          <div className="title">{notice.title}</div>
                        </a>
                      </div>
                    )
                )}
              </div>
            </div>

            <div className="col-md-5 mx-auto">
              <div className="paging">
                <a
                  id="prev"
                  onClick={() => {
                    if (current > 1) handleChange(current - 1);
                  }}
                >
                  <span className="material-symbols-outlined">
                    chevron_left
                  </span>
                </a>
                {pageList().map((page) => {
                  return (
                    <a
                      className={current === page ? "active" : ""}
                      onClick={() => {
                        handleChange(page);
                      }}
                    >
                      {page}
                    </a>
                  );
                })}

                <a
                  id="next"
                  onClick={() => {
                    if (current < pageList().length) handleChange(current + 1);
                  }}
                >
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
}

export default NoticeList;
