import './index.css';

import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function IntegrityAgreement(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <p className="doc-title" style={{ fontSize: "38px", textAlign: "center", height: "100px", marginTop: "50px" }}>청렴계약이행을 위한 입찰특별유의서</p>

          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제1조(목 적) 이 청렴계약이행을 위한 입찰특별유의서는 {worklist?.contractRegion} 청렴계약제
            시행을 위하여 {worklist?.contractRegion}에서 집행하는 공사․용역․물품구매 등에 대한 입찰에 있어
            입찰에 참가하고자 하는 자가 입찰유의서 외에 특별히 유의할 사항을 정함을 목적으로 한다.</p>
          <p style={{ height: "30px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제2조(입찰참가업체의 청렴계약이행서약서 제출) ①입찰에 참여하는 업체는 발주기관과 입찰참여업체가
            상호신뢰를 바탕으로 입찰, 계약, 계약이행과정에서의 담합 등 불공정행위나 관계공무원에게
            금품․향응등 뇌물이나 부당한 이익을 제공하지 않고, 이를 위반시 입찰참가자격제한,
            계약해지 등을 감수하겠다는『{worklist?.contractRegion} 청렴계약이행 서약서』〔별지1〕를 업체대표가
            서명하여 입찰등록시 제출한 것으로 간주하며, 낙찰자로 선정된 업체만
            별도 업체대표가 계약체결시 계약상대자는 청렴계약이행 서약내용을 그대로 {worklist?.contractRegion} 청렴계약이행특수조건으로 약정하는데 동의하고 준수하여야 한다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}> ②입찰에서 낙찰자로 선정된 자가 계약체결시 청렴계약이행 서약서 내용을 {worklist?.contractRegion} 청렴계약이행 특수조건에 명기
            하여 계약하는 것을 거부하여 낙찰 통지를 받은후 10일이내에 계약이 체결되지 아니하는 경우에는 낙찰취소 조치를 받게 된다.</p>
          <p style={{ height: "30px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제3조(발주부서 청렴계약 이행서약서 교부) 청렴계약 대상 공사․용역․물품구매
            등의 입찰에 낙찰된 자는 계약체결시 발주부서에서 교부하는 '청렴계약이행서약서'에
            업체대표자가 서명하여 발주부서에 제출하면 {worklist?.contractRegion}는 발주기관의 장 및
            담당공무원이 입찰, 계약 및 계약이행과정에서 입찰참가업체로부터 어떠한 명목의
            금품이나 향응을 요구하거나 받지 않으며 위반시에는 관계법에 따라 징계 등 문책을
            감수하겠다고 함께 서명한『{worklist?.contractRegion} 청렴계약이행서약서』〔별지 1〕를 교부한다.</p>
          <p style={{ height: "30px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제4조(부정당업자의 입찰참가자격제한) ①입찰에 참가하는 자가 입찰가격이나
            특정인의 낙찰을 위하여 담합 등 불공정 행위를 하였을때에는 다음 각호의 1에서
            정하는 바에 의하여 {worklist?.contractRegion}에서 시행하는 입찰에 참가제한을 받게 된다.</p>
        </div>

        <div className="page-doc">
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>1. 경쟁입찰에 있어서 특정인의 낙찰을 위하여 담합을 주도한 자는 {worklist?.contractRegion}가
            발주하는 입찰에 1년이상 2년이하의 범위내에서 입찰참가 자격을 제한한다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>2. 경쟁입찰에 있어서 입찰가격을 서로 상의하여 미리 입찰가격을 협정하거나
            특정인의 낙찰을 위하여 담합한 자는 {worklist?.contractRegion}가 발주하는 입찰에 6개월이상
            1년미만의 범위내에서 입찰참가자격을 제한한다.</p>
          <p style={{ height: "30px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>② 입찰담합 등 불공정행위를 한 경우에는 제1항과 병행하여 독점규제 및
            공정거래에관한법령에 따라 공정거래위원회에 고발 등 조치를 하는데 일체
            이의를 제기하지 않는다.</p>
          <p style={{ height: "80px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px", height: "100px" }}>③ 입찰․계약체결 및 계약이행 과정에서 관계공무원에게
            직․간접적으로 금품, 향응 등의 뇌물이나 부당한 이익을 제공하지 않으며
            이를 위반하였을 때에는 다음 각호의 1에서 정하는 바에 의하여 {worklist?.contractRegion}에서
            시행하는 입찰에 참가제한을 받게 된다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>1. 계약체결 또는 그 이행과 관련하여 관계공무원에게 뇌물을 제공함으로써
            그 계약을 체결하였거나, 시공중 편의를 받아 시공을 부실하게 한자와 입찰
            또는 낙찰자 결정과 관련하여 관계공무원에게 뇌물을 제공한자는 {worklist?.contractRegion}가
            발주하는 입찰에 6개월이상 1년미만의 범위내에서 입찰참가자격을 제한한다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>2. 뇌물을 주어서 계약조건이 낙찰자에게 유리하게 되도록 계약을 체결하였거나
            그 이행을 부실하게 할 목적을 관계공무원에게 뇌물을 제공한자(제1호에 해당하는
            자를 제외한 자)는 {worklist?.contractRegion}가 발주하는 입찰에 1월이상 6개월미만의 범위내에서
            입찰 참가자격을 제한한다.</p>
          <p style={{ height: "80px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>④ 제1항 내지 제2항의 입찰참가자격제한의 {worklist?.contractRegion} 처분과 관련하여 입찰에
            참가하는 업체는 {worklist?.contractRegion}를 상대로 손해배상을 청구하거나 배제하는 입찰에
            관하여 민․형사상 이의를 제기하지 않는다.</p>
        </div>

        <div className="page-doc">
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제5조(계약해지 등) ①입찰, 계약체결 및 계약이행과 관련하여 관계공무원에게
            뇌물을 제공한 자는 다음 각호의 1에서 정하는 바에 의하여 당해 계약에 대한
            조치를 받는다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>1. 계약체결 전의 경우에는 적격낙찰자 결정대상에서 제외하거나 낙찰자가 결정된 경우에는 그 결정을 취소한다.</p>
          <div style={{ height: "25px" }}>　</div>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>2. 계약체결 이후부터 공사착공 전의 경우에는 당해 계약을 해제한다.</p>
          <div style={{ height: "25px" }}>　</div>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>3. 공사착공 이후에는 계약의 전부 또는 일부를 해지한다. 다만, 공사성격, 진도(進度), 규모, 공사기간 등을 감안하여 부득이한 경우에는 그러하지 아니하다.</p>
          <div style={{ height: "25px" }}>　</div>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>4. 계약상대자는 제1호 내지 제3호의 규정에 의하여 계약의 해지 또는 해제에 대하여 민․형사상 일체 이의를 제기하지 않는다.</p>
          <p style={{ height: "50px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제6조(기타사항) ① 입찰에 참가하는 업체의 임․직원(하도급업체 포함)과 대리인이
            관계공무원에게 뇌물을 제공하거나 담합 등 불공정행위를 하지 않도록 하는
            업체윤리강령과 내부비리 제보자에 대하여도 일체의 불이익 처분을 하지 않는
            사규를 제정하도록 적극 노력하여야 한다.</p>
          <p style={{ height: "50px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>② 본건 입찰, 계약체결, 계약이행, 준공과 관련하여 {worklist?.contractRegion}에서
            시행하는 청렴계약 옴부즈만이 요구하는 자료제출, 서류열람,
            현장확인 등 활동에 적극 협력하여야 한다.</p>
          <p style={{ height: "25px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>③ 본건 관련 하도급계약체결 및 이행에 있어서 하도급자로부터 금품을
            수수하거나 부당 또는 불공정한 행위를 하지 아니한다.</p>
        </div>

        <div className="page-doc">
          <p className="title" style={{ fontSize: "40px", textAlign: "center", height: "48px" }}>청 렴 계 약 이 행 특 수 조 건</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제1조(목 적) 이 청렴계약이행특수조건은 {worklist?.contractRegion} 계약담당공무원과
            계약상대자가 체결하는 공사․용역․물품구매 등의 도급계약에 있어
            계약일반조건 외에 청렴계약을 위한 내용을 특별히 규정함을 목적으로 한다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제2조(청렴계약이행 준수의무) ①공사․용역․물품구매 등의 입찰에 청렴계약
            이행서약서를 제출하고 참가하여 낙찰된 계약상대자는 계약체결 및 이행과
            관련하여 어떠한 명분으로도 관계공무원에게 직․간접적으로 금품․향응 등의
            뇌물이나 부당한 이익을 제공하여서는 아니된다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제3조(부정당업자의 입찰참가자격제한) ① 입찰에 참가하는 자가 입찰가격이나
            특정인의 낙찰을 위하여 담합 등 불공정 행위를 하였을때에는 다음 각호의
            1에서 정하는 바에 의하여 {worklist?.contractRegion}에서 시행하는 입찰에 참가제한을 받게 된다.</p>

          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>1. 경쟁입찰에 있어서 특정인의 낙찰을 위하여 담합을 주도한 자는 {worklist?.contractRegion}에서
            발주하는 입찰에 1년이상 2년이하의 범위내에서 입찰참가자격을 제한한다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>2. 경쟁입찰에 있어서 입찰가격을 서로 상의하여 미리 입찰가격을 협정하거나
            특정인의 낙찰을 위하여 담합한 경우에는 {worklist?.contractRegion}가 발주하는 입찰에 6개월이상
            1년미만의 범위내에서 입찰참가 자격을 제한한다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>② 입찰담합 등 불공정행위를 한 경우에는 제1항과 병행하여 독점규제 및
            공정거래에관한법령에 따라 공정거래위원회에 고발 등 조치를 하는데 일체
            이의를 제기하지 않는다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>③ 입찰․계약체결 및 계약이행 과정에서 관계공무원에게 직․간접적으로 금품,
            향응 등의 뇌물이나 부당한 이익을 제공하지 않으며 이를 위반하였을 때에는
            다음 각호의 1에 해당하는 기간동안 {worklist?.contractRegion}에서 시행하는 입찰에 참가제한을
            받게 된다.</p>

          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>1. 계약체결 또는 그 이행과 관련하여 관계공무원에게 뇌물을 제공함으로써
            그 계약을 체결하였거나, 시공 중 편의를 받아 시공을 부실하게 한자와
            입찰 또는 낙찰자 결정과 관련하여 관계공무원에게 뇌물을 제공한자는 {worklist?.contractRegion}가 발주하는 입찰에 6개월이상 1년미만의 범위내
            에서 입찰참가자격을 제한한다.</p>
        </div>

        <div className="page-doc">
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>2. 뇌물을 주어서 계약조건이 낙찰자에게 유리하게 되도록 계약을 체결하였거나
            그 이행을 부실하게 할 목적을 관계공무원에게 뇌물을 제공한자(제1호에
            해당하는 자를 제외한 자)는 {worklist?.contractRegion}가 발주하는 입찰에 1월이상 6개월미만의
            범위내에서 입찰 참가자격을 제한한다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>④ 제1항 내지 제2항의 규정에 의하여 입찰참가자격을 제한하는 {worklist?.contractRegion}
            처분을 받은 자는 {worklist?.contractRegion}를 상대로 손해배상을 청구하거나 배제하는
            입찰에 관하여 민․형사상 이의를 제기하지 않는다. </p>
          <p style={{ height: "30px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제4조(계약해지 등) ①입찰, 계약체결 및 계약이행과 관련하여 관계공무원에게
            뇌물을 제공한 자는 다음 각호의 1에서 정하는 바에 의하여 당해 계약에 대한
            조치를 받는다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>1. 계약체결 전의 경우에는 적격낙찰자 결정대상에서 제외 또는 결정통보를 취소한다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>2. 계약체결 이후 공사착공 전의 경우에는 당해 계약을 해제한다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>3. 공사착공 이후에는 발주처에서 전체 또는 일부 계약해지 한다. 다만, 공사성격, 진도(進度), 규모, 공사기간 등을 감안하여 발주처에서 그러하지 아니할 수 있다.</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>4. 계약상대자는 제1호 내지 제3호의 처리에 대하여 민․형사상 일체 이의를 제기하지 않는다.</p>
          <p style={{ height: "30px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>제5조(기타사항)① 업체의 임․직원(하도급업체 포함)과 대리인이 관계공무원에게
            뇌물을 제공하거나 담합 등 불공정행위를 하지 않도록 하는 업체윤리강령과
            내부비리 제보자에 대하여도 일체의 불이익 처분을 하지 않는 사규를 제정하도록
            적극 노력한다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>② 본건 입찰, 계약체결, 계약이행, 준공과 관련하여 {worklist?.contractRegion}에서 시행하는
            청렴계약 옴부즈만이 요구하는 자료제출, 서류열람, 현장확인 등 활동에
            적극 협력하여야 한다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>③ 본건 관련 하도급계약체결 및 이행에 있어서 하도급자로부터 금품을 수수하거나
            부당 또는 불공정한 행위를 하지 아니한다.</p>
        </div>

        <div className="page-doc">
          <p className="title" style={{ fontSize: "40px", textAlign: "center", height: "100px" }}>{worklist?.contractRegion} 청렴계약이행서약서</p>
          <p className="subTitle">󰏚 공사(용역, 물품) 계약명 : <u style={{ wordBreak: "keep-all" }}>{worklist?.constructionName}</u></p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>당사는 부패없는 투명한 기업경영과 공정한 행정이 지역사회발전과 국가
            경쟁력에 중요한 관건이 됨을 깊이 인식하며, 국제적으로도 OECD 뇌물방지
            협약이 발효되고 부패기업 및 국가에 대한 제재가 강화되고 있는 시점에서
            청렴계약제 시행 취지에 적극 호응하여 {worklist?.contractRegion}에서 시행하는 공사(용역,
            물품) 입찰에 참여함에 있어 당사 및 하도급업체의 임직원과 대리인은 {worklist?.contractRegion} 청렴계약옴부즈만설치운영규칙과 청렴계약 이행을 위한 입찰특별유의서
            및 특수조건이 정하는 내용을 성실히 준수할 것을 다짐하며 다음과 같이 서약합니다.</p>
          <p style={{ height: "50px" }}>　</p>
          <p style={{ textAlign: "center" }}>다　　　　　　　음</p>
          <p style={{ height: "50px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>1. 이번 입찰에 참여함에 있어 입찰가격이나 특정인의 낙찰을 위하여 담합등
            불공정 행위를 하였을 때에는 청렴계약 이행을 위한 입찰특별유의서 및
            특수조건에서 정하는 기간동안 입찰참가제한을 감수하겠습니다. </p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>2. 입찰․계약체결 및 계약이행과정에서 관계공무원에게 직․간접적으로 금품,
            향응 등의 뇌물이나 부당한 이익을 제공하지 않겠으며, 이를 위반시에는
            청렴계약 이행을 위한 입찰특별유의서 및 특수조건에서 정하는 기간동안
            입찰참가자격이 제한됨을 감수하겠습니다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>3. 또한 입찰담합등 불공정 행위를 한 사실이 드러날 경우 독점규제 및
            공정거래에 관한 법률이 정하는바에 따라 공정거래에 관한법률이 정하는
            바에따라 공정거래위원회에 우리시가 고발하여 과징금등을 부과하도록
            하는데 어떠한 이의도 제기하지 않겠습니다.</p>
        </div>

        <div className="page-doc">

          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>4. 입찰, 계약체결 및 계약이행과 관련하여 관계공무원에게 뇌물을 제공한
            사실이 드러날 경우에는 계약체결 이전의 경우에는 낙찰자결정 취소, 공사
            착공전에는 계약취소, 공사착공 이후에는 발주처에서 전체 또는 일부계약을
            해지하여도 감수하고 민․형사상 이의를 제기하지 않겠습니다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>5. 회사 임․직원이 관계 공무원에게 뇌물을 제공하거나 담합 등 불공정 행위를
            하지 않도록 하는 회사윤리 강령과 내부비리 제보자에 대해서도 일체의
            불이익처분을 하지 않는 사규를 제정토록 노력하겠습니다. </p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>6. 본건 입찰, 계약체결, 계약이행, 준공과 관련하여 {worklist?.contractRegion}에서 시행하는
            청렴계약 옴부즈만이 요구하는 자료제출, 서류 열람, 현장확인 등 활동에
            적극 협조하겠습니다.</p>
          <p style={{ height: "20px" }}>　</p>
          <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>위 청렴계약이행서약은 상호신뢰를 바탕으로 한 약속으로서 반드시 지킬
            것이며, 낙찰자로 결정될 시 본 서약내용을 그대로 계약특수조건으로
            계약하여 이행하고, 입찰참가자격제한, 계약해지등 {worklist?.contractRegion}의 조치와
            관련하여 당사가 {worklist?.contractRegion}를 상대로 손해배상을 청구하거나 당사를
            배제하는 입찰에 관하여 민․형사상 이의를 제기하지 않을 것을 서약합니다.</p>
          <p style={{ height: "60px" }}>　</p>
          <p style={{ textAlign: "center", fontSize: "20px" }}>{moment(worklist?.contractDate).format('yyyy년　MM월　DD일')}</p>
          <p style={{ height: "40px" }}>　</p>
          <p style={{ textAlign: "right", letterSpacing: "2px", paddingRight: "130px" }}>(서 약 자)</p>
          <p style={{ textAlign: "right" }}>회 사 명 : <span style={{width: "200px", display: "inline-flex"}}>{worklist?.companyName}</span></p>
          <p style={{ textAlign: "right" }}>대 표 자 : <span style={{width: "200px", display: "inline-flex", letterSpacing: "5px"}}>{worklist?.representativeName}</span></p>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default IntegrityAgreement;