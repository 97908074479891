import React, { useState } from 'react'
import { CommunityFormDetailModel } from '../../models/communityboard/communityFormDetailModel';
import styles from './UserBoardForm.module.scss'


interface Props{
  onClickSubmit: (e: any)=>void;
  getTitle:(e:any)=>void;
  getContent:(e:any)=>void;
  getCategory:(e:any)=>void;
}


const UserBoardFormPresenter = (props:Props) => {
//  console.log(props)
  return (
  <>
    <div className={styles.request_wrapper}>
      <div className={styles.center_wrap}>
        <div className={styles.request_title}>커뮤니티</div>
        <div className={styles.subtitle}>
      뚜껑설계 커뮤니티에 글을 남겨주세요.
        </div>
          <form onSubmit={props.onClickSubmit}
            className={styles.form_wrap}    
            noValidate={false}
          >
            <div className={styles.form_group}>
              <div className={styles.group_title}>
                <div className={styles.text}>글내용</div>
                <div className={styles.line}></div>
              </div>
              <div className={styles.form_item}>
              <div className={styles.require}>*</div>
                <div className={styles.form_name}>카테고리</div>
                <div className={styles.radio_wrapper}>
                  <label className={styles.check_box}>
                    <input type="radio" name="inquiry_type" value="고민상담" required 
                    onClick={props.getCategory}/>
                    고민상담
                  </label>
                  <label className={styles.check_box}>
                    <input type="radio" name="inquiry_type" value="자료공유" required 
                    onClick={props.getCategory}/>
                    자료공유
                  </label>
                  <label className={styles.check_box}>
                    <input type="radio" name="inquiry_type" value="구인구직" required 
                    onClick={props.getCategory}/>
                    구인구직
                  </label>
                  <label className={styles.check_box}>
                    <input type="radio" name="inquiry_type" value="기타" required 
                    onClick={props.getCategory}/>
                    기타
                  </label>
                </div>
              </div>
              <div className={styles.form_item}>
                <div className={styles.require}>*</div>
                <div className={styles.form_name}>제목</div>
                <input
                  required
                  className={styles.full_width_input}
                  name="title"
                  onChange={props.getTitle}
                />
              </div>        
              <div className={styles.form_item}>
                <div className={styles.require}>*</div>
                <div className={styles.form_name}>글내용</div>
                <textarea required className={styles.content} 
                name="content"
                onChange={props.getContent}
                 />
              </div>
              <div className={styles.form_item}>
                <div className={styles.require}></div>
                <div className={styles.form_name}>첨부파일</div>
                <label htmlFor="selectFile" className={styles.select_file}>
                  파일선택
                  <input
                    maxLength={100}
                    id="selectFile"
                    style={{ display: "none" }}
                    type="file"
                    accept="*.*"
                    name="files"
                    multiple
                    // onChange={handleChangeFiles}
                  />
                </label>
                <textarea
                  // ref={textareaRef}
                  className={styles.input_attach_files}
                  placeholder={`파일첨부 (최대 5개), 총 20MB`}
                  disabled
                />
              </div>           

              <div className={styles.divider}></div>
           
            <button className={styles.summit} type="submit">글쓰기</button>
              </div>
        
          </form>
        </div>      
    </div>
 
  </>

  )
}

export default UserBoardFormPresenter

function setFormContent(value: string): void {
  throw new Error('Function not implemented.');
}
