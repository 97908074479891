import styles from "./Video.module.scss";
import {
  AiFillPlayCircle,
  AiOutlineFastBackward,
  AiOutlineFastForward,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { pages, videoPages } from "../../../../constants/PagePaths";
import { videoApi } from "../../../../config/videoApi";
import { util } from "../../../../config/util";
import AlertContext from "../../../../contexts/AlertContext";
import {
  CurriculumsModel,
  LectureDataModel,
  Videos,
} from "../../models/LectureDataModel";
import LecturePlayer from "../lecturePlayer/LecturePlayer";
import ContentCard from "./components/ContentCard";

// jQuery에 Bootstrap 메서드를 확장하는 타입 선언
declare global {
  interface JQuery {
    modal(action?: string): JQuery;
  }
}

const Video = () => {
  const { videoId } = useParams<{
    videoId: string;
  }>();
  const history = useHistory();
  const alertContext = useContext(AlertContext);
  const [curriculum, setCurriculum] = useState<LectureDataModel>();
  const [description, setDescription] = useState();
  const [selectedTitle, setSelectedTitle] = useState();
  const [videoUrl, setVideoUrl] = useState("");
  const [vimeoId, setVimeoId] = useState();
  const [myPurchase, setMyPurchase] = useState<any>([]);
  const myPurchases = myPurchase?.map((a: { id: any }) => a.id);
  const [courseId, setCourseId] = useState();
  const videoIds = useRef<Videos[]>([]);
  const [state, setState] = useState(0);

  const inputStarRef = useRef<any>(null);
  const inputReviewRef = useRef<any>(null);

  useEffect(() => {
    new Promise(async () => {
      const videoRes = await videoApi.getCourseInfoAsync(videoId);
      const videoData = videoRes.data;
      setCourseId(videoData.courseId);
      if (videoData.vimeoId) {
        setVideoUrl(videoData.videoUrl);
        setVimeoId(videoData.vimeoId);
        setDescription(videoData.description);
        setSelectedTitle(videoData.title);
        if (util.isLogin()) {
          const purchaseData = await (
            await videoApi.getMyCourseAsync()
          ).data.user_video_purchases;
          setMyPurchase(purchaseData);
        }
        if (!curriculum) {
          const courseId = videoData.courseId;
          const res = await videoApi.getCourseCurriculumAsync(courseId);
          setCurriculum(res.data);
          videoIds.current = res.data.curriculums
            .map((a: any) => a.videos)
            .reduce((a: any, b: any) => a.concat(b));
        }
      } else {
        // 로그인 여부 확인

        if (!util.isLogin()) {
          await alertContext.showAlert("로그인 후 시청 가능합니다.");
          history.push(pages.login);
          return;
        } else if (videoRes.status === 204) {
          await alertContext.showAlert("잘못된 링크입니다.");
          history.replace(`${videoPages.index}`);
        } else {
          await alertContext.showAlert("결제 후 시청 가능합니다.");
          history.push(`${videoPages.payment}/${videoData.courseId}`);
        }
      }
    });
  }, [history.location]);

  useEffect(() => {
    var $star_rating: any = $(".star-rating .fa");

    var SetRatingStar = function () {
      return $star_rating.each(function () {
        if (
          parseInt($star_rating.siblings("input.rating-value").val()) >=
          parseInt($(this).data("rating"))
        ) {
          return $(this).removeClass("fa-star-o").addClass("fa-star");
        } else {
          return $(this).removeClass("fa-star").addClass("fa-star-o");
        }
      });
    };

    $star_rating.on("click", function (e) {
      if (!e.target.parentNode.classList.value.includes("disabled")) {
        $star_rating.siblings("input.rating-value").val($(this).data("rating"));
        return SetRatingStar();
      }
    });

    SetRatingStar();
  }, [state]);

  const refresh = async () => {
    setState(state + 1);
    if (util.isLogin()) {
      const purchaseData = await (
        await videoApi.getMyCourseAsync()
      ).data.user_video_purchases;
      setMyPurchase(purchaseData);
    }
  };

  const writeReview = async () => {
    if (inputStarRef.current && inputReviewRef.current) {
      if (inputReviewRef.current.value === "") {
        $("#reviewModal").modal("hide");
        alertContext.showAlert("내용을 입력해주세요.", () => {
          $("#reviewModal").modal("show");
        });
        return;
      }
      const rating = inputStarRef.current.value;
      // selectedVideoId;
      const review = inputReviewRef.current.value;
      const res = await videoApi.postReview(
        courseId!,
        review,
        parseInt(rating)
      );
      if (res.status === 200) {
        $("#reviewModal").modal("hide");
        alertContext.showAlert("리뷰가 등록되었습니다.");
      } else {
        $("#reviewModal").modal("hide");
        alertContext.showAlert("리뷰 등록에 실패하였습니다.");
      }
      inputReviewRef.current.value = "";
      inputStarRef.current.value = 5;
      refresh();
    }
  };

  const videoClick = async (item: any) => {
    myPurchases?.indexOf(courseId) !== -1 || item.is_free
      ? history.push(`${videoPages.course}/${item.id}`)
      : alertContext.videoConfirmAlert(
          "알림",
          "구매 후 이용 가능합니다. 구매하시겠습니까?",
          "아니오",
          "예",
          async () => {
            history.push(`${videoPages.payment}/${courseId}`);
          }
        );
  };

  const titleList = curriculum?.curriculums?.map((item, index) => {
    const indexOfVideoId = item.videos.map((a) => a.id).indexOf(+videoId);
    return (
      <ContentCard
        index={index + 1}
        title={item.title}
        videos={item.videos}
        videoId={videoId}
        videoClick={videoClick}
      />
    );
  });

  const prev = () => {
    const currentIndex = videoIds.current.findIndex(
      (a: any) => a.id === +videoId
    );
    if (currentIndex === 0) {
      alertContext.showAlert("첫번째 강의입니다.");
      return;
    }
    const prevVideo = videoIds.current[currentIndex - 1];
    if (
      myPurchases?.indexOf(courseId) === -1 &&
      prevVideo !== undefined &&
      !prevVideo?.is_free
    ) {
      alertContext.videoConfirmAlert(
        "알림",
        "구매 후 이용 가능합니다. 구매하시겠습니까?",
        "아니오",
        "예",
        async () => {
          history.push(`${videoPages.payment}/${courseId}`);
        }
      );
      return;
    }
    history.push(`${videoPages.course}/${prevVideo?.id}`);
  };

  const next = () => {
    const currentIndex = videoIds.current.findIndex(
      (a: any) => a.id === +videoId
    );
    if (currentIndex + 1 === videoIds.current.length) {
      alertContext.showAlert("마지막 강의입니다.");
      return;
    }

    const nextVideo = videoIds.current?.[currentIndex + 1];
    if (
      myPurchases?.indexOf(courseId) === -1 &&
      nextVideo !== undefined &&
      !nextVideo?.is_free
    ) {
      alertContext.videoConfirmAlert(
        "알림",
        "구매 후 이용 가능합니다. 구매하시겠습니까?",
        "아니오",
        "예",
        async () => {
          history.push(`${videoPages.payment}/${courseId}`);
        }
      );
      return;
    }
    history.push(`${videoPages.course}/${nextVideo.id}`);
  };

  return (
    <main className="video-view-wrap">
      <header className="bg-dark sticky-top shadow-sm d-flex justify-content-between align-items-center">
        <div className="px-3">
          <div className="header-wrap d-flex align-items-center mb-0 col-sm-12 col-12 SubTitle2">
            <Link
              to={videoPages.curriculum + "/" + courseId}
              className="text-gray-400 "
            >
              <span className="material-symbols-outlined">arrow_back</span>
            </Link>
            <p className="text-gray-400 text-truncate mb-0 ms-2">
              {curriculum?.title}
            </p>
          </div>
        </div>
        {myPurchases?.indexOf(courseId) !== -1 &&
          !myPurchase.find((el: any) => el.id === courseId)?.review && (
            <div>
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#reviewModal"
              >
                리뷰쓰기
              </button>
            </div>
          )}
      </header>

      <section>
        <div className="video-player-section">
          <div className="video-player-wrap">
            <LecturePlayer url={videoUrl} vimeoId={vimeoId} />
          </div>
        </div>
        <div className="player-control-wrap">
          <div className="control-left">
            <button
              type="button"
              className="btn text-gray-400"
              onClick={() => {
                prev();
              }}
            >
              <span className="material-symbols-outlined">chevron_left</span>
              이전
            </button>
            <button
              type="button"
              className="btn text-gray-400"
              onClick={() => {
                next();
              }}
            >
              다음
              <span className="material-symbols-outlined">chevron_right</span>
            </button>
          </div>
          <div className="control-right">
            {/* <button type="button" className="btn text-gray-400">
              <span className="material-symbols-outlined">fullscreen</span>
              넓은화면
            </button> */}
          </div>
        </div>
      </section>
      <aside className="side-wrap">
        <ul className="nav nav-tabs" id="EduTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="edu-list-tab"
              data-bs-toggle="tab"
              data-bs-target="#edu-list-tab-pane"
              type="button"
              role="tab"
              aria-controls="edu-list-tab-pane"
              aria-selected="true"
            >
              강의목차
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="edu-data-tab"
              data-bs-toggle="tab"
              data-bs-target="#edu-data-tab-pane"
              type="button"
              role="tab"
              aria-controls="edu-data-tab-pane"
              aria-selected="false"
              tabIndex={-1}
            >
              강의자료
            </button>
          </li>
        </ul>
        <div className="tab-content" id="EduTabContent">
          <div
            className="tab-pane fade show active px-4 py-4"
            id="edu-list-tab-pane"
            role="tabpanel"
            aria-labelledby="edu-list-tab"
            tabIndex={0}
          >
            <div className="edu-list-wrap">{titleList}</div>
          </div>
          <div
            className="tab-pane fade"
            id="edu-data-tab-pane"
            role="tabpanel"
            aria-labelledby="edu-data-tab"
            tabIndex={0}
          >
            <div className="px-4 py-4">
              <a
                href={description}
                target="_blank"
                className="btn btn-lg right-icon btn-primary text-gray-100"
              >
                강의자료 보기
                <span className="material-symbols-outlined">chevron_right</span>
              </a>
            </div>
          </div>
        </div>
      </aside>
      <div
        className="modal fade"
        id="reviewModal"
        tabIndex={-1}
        aria-labelledby="reviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="reviewModalLabel">
                강의는 어떠셨나요?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  inputReviewRef.current.value = "";
                  inputStarRef.current.value = 5;
                }}
              ></button>
            </div>
            <div className="modal-body">
              <label className="fw-bold" style={{ color: "black" }}>
                평점
              </label>
              <div className="star-rating mb-4" style={{ color: "black" }}>
                <span className="fa fa-star" data-rating="1"></span>
                <span className="fa fa-star" data-rating="2"></span>
                <span className="fa fa-star" data-rating="3"></span>
                <span className="fa fa-star" data-rating="4"></span>
                <span className="fa fa-star" data-rating="5"></span>
                <input
                  type="hidden"
                  name="whatever1"
                  className="rating-value"
                  value="5"
                  ref={inputStarRef}
                />
              </div>
              <label className="fw-bold" style={{ color: "black" }}>
                내용
              </label>
              <textarea
                className="form-control"
                aria-label="With textarea"
                ref={inputReviewRef}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                // data-bs-dismiss="modal"
                onClick={() => {
                  writeReview();
                }}
              >
                작성완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Video;
