import { useContext, useEffect, useRef, useState } from "react";
import { api } from "../../../config/api";
import AlertContext from "../../../contexts/AlertContext";
import DupLoginContext from "../../../contexts/DupLoginContext";
import CouponBoxModel from "../../../models/coupon/CouponBoxModel";

export default () => {
  const dlContext = useContext(DupLoginContext);
  const alert = useContext(AlertContext);
  const [coupons, setCoupons] = useState<CouponBoxModel[]>([]);
  const [errorMessage, setCouponErrorMessage] = useState("");
  const couponInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    new Promise(async () => {
      try {
        const payHistory = await api.getPaymentHistoryAsync(0, 5);
        const coupons = await getCoupons(0, 5);
        setCoupons(coupons);
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  const getCoupons = async (offset?: number, limit?: number) => {
    const couponsRes = await api.getCouponHistoryAsync(offset, limit);
    const tempCoupons = couponsRes.data.coupons.map(
      (coupon: any): CouponBoxModel => {
        return {
          name: coupon.name,
          expiryDate: coupon.expiry_date,
          key: coupon.key,
        };
      }
    );
    return tempCoupons;
  };

  const handleClickCouponAdd = async (key: string) => {
    const res = await dlContext.checkDupLogin(() =>
      api.getCouponUsableAsync(key || "")
    );
    if (res.data.can_use) {
      const registCouponRes = await api.postRegistCouponAsync(key || "");
      if (registCouponRes.status === 200 && registCouponRes.data.data === 1) {
        alert.showAlert("등록되었습니다.");
      } else {
        setCouponErrorMessage(registCouponRes.data.message);
      }
    } else {
      setCouponErrorMessage(res.data.message);
    }
  };

  return (
    <section className="content">
      <h3>쿠폰</h3>
      <form className="needs-validation" >
        <div className="input-group has-validation mb-3">
          <input
            type="text"
            className="form-control"
            id="validationCustomUsername"
            placeholder="쿠폰코드를 입력해주세요."
            aria-describedby="inputGroupPrepend"
            ref={couponInput}
          />
          <button
            className="btn btn-primary left-icon"
            type="button"
            id="coupon-submit"
            onClick={() => {
              handleClickCouponAdd(couponInput.current?.value || "");
            }}
          >
            <span className="material-symbols-outlined me-2">add</span>
            쿠폰 등록
          </button>
          <div className="invalid-feedback">
            올바른 쿠폰코드를 입력해주세요.
          </div>
        </div>
      </form>

      {coupons?.length === 0 ? (
        <div
          className="section-empty aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src="images/ddusul/3d-coupon.png" />
          <p>
            사용가능한 쿠폰이 없습니다.
            <br />
            쿠폰을 등록해주세요.
          </p>
        </div>
      ) : (
        coupons.map((coupon, index) => (
          <div className="coupon-list-item list-disabled bg-white">
            <div className="price-wrap">
              <h4>
                <span>30,000</span>원
              </h4>
              <span className="badge">D-day</span>
            </div>
            <div className="flex-2">
              <div className="title-lg">쿠폰명</div>
              <div className="caption">
                <span></span>
                까지
              </div>
              <div>
                <button
                  className="btn text-gray-400 px-0 right-icon"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#coupon-notice02"
                  aria-expanded="false"
                  aria-controls="coupon-notice02"
                >
                  유의사항
                  <span className="material-symbols-outlined ms-2">
                    manage_search
                  </span>
                </button>
                <div className="collapse" id="coupon-notice02">
                  <div className="text">유의사항 컨텐츠 영역입니다.</div>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </section>
  );
};
