import React, { useState } from "react";
import { List, Card, Tag, Typography, Tooltip, Button } from "antd";
import {
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import styles from "./ExternalProjectList.module.scss";
import { ExternalProjectModel } from "../models/service/ServiceInfo";
import { useEffect } from "react";
import { serviceApi } from "../config/serviceApi";
import { pages } from "../constants/PagePaths";

const { Title, Text } = Typography;

interface ServiceRequestListProps {
  showAll?: boolean;
}

export const ExternalProjectList: React.FC<ServiceRequestListProps> = ({
  showAll = false,
}) => {
  const [externalProjects, setExternalProjects] = useState<
    ExternalProjectModel[]
  >([]);

  useEffect(() => {
    async function fetch() {
      const res = await serviceApi.getExternalProjects();
      setExternalProjects(res.data);
    }
    fetch();
  }, []);

  const getStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            검토중
          </Tag>
        );
      case "in_progress":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            진행중
          </Tag>
        );
      case "completed":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            완료
          </Tag>
        );
      default:
        return null;
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("ko-KR", {
      style: "currency",
      currency: "KRW",
    }).format(amount);
  };

  const displayRequests = showAll
    ? externalProjects
    : externalProjects.slice(0, 3);

  return (
    <div className={styles.container}>
      <Title level={2}>최근 용역 현황</Title>
      <List
        grid={{ gutter: 16, column: 1 }}
        dataSource={displayRequests}
        renderItem={(item) => (
          <List.Item>
            <Link to={`${pages.externalProject}/${item.id}`}>
              <Card
                title={item.title}
                extra={getStatusTag(item.status)}
                className={styles.requestCard}
              >
                <div className={styles.cardContent}>
                  <div className={styles.serviceInfo}>
                    <Text strong>{item.service}</Text>
                    <Text type="secondary">신청일: {item.date}</Text>
                  </div>
                  <div className={styles.budgetInfo}>
                    <Tooltip title="총 예산">
                      <Text strong>{formatCurrency(item.budget)}</Text>
                    </Tooltip>
                    <Tooltip title="현재 사용 비용">
                      <Text type="secondary">
                        {formatCurrency(item.currentCost)}
                      </Text>
                    </Tooltip>
                    <div className={styles.progressBar}>
                      <div
                        className={styles.progress}
                        style={{
                          width: `${(item.currentCost / item.budget) * 100}%`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </Card>
            </Link>
          </List.Item>
        )}
      />
      {!showAll && (
        <div className={styles.viewAllContainer}>
          <Link to="/service-status">
            <Button
              type="primary"
              size="large"
              className={styles.viewAllButton}
            >
              전체 용역 현황 보기 <RightOutlined />
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};
