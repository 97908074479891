/* eslint-disable import/no-anonymous-default-export */
import CompletionSafetyHealthPresenter from "./CompletionSafetyHealthInfoPresenter";
import { SafetyManagementStateItemData } from "../../../../constants/SafetyManagementItemData";
import { Worklist } from "../../../../models/worklist/WorklistModel";
interface Props {
  worklist: Worklist;
  putWorkListAsync: (data: any) => void;
}
export default (props: Props) => {
  const worklist = props.worklist;
  const putWorkListAsync = props.putWorkListAsync;
  if (!worklist?.safetyManageState) {
    return <div>로딩중...</div>;
  }
  const useData = !worklist
    ? SafetyManagementStateItemData
    : worklist.safetyManageState;
  return (
    <div>
      <CompletionSafetyHealthPresenter
        worklist={worklist}
        model={useData}
        putWorkListAsync={putWorkListAsync}
      />
    </div>
  );
};
