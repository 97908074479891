import styles from "./Curriculum.module.scss";
import { useContext, useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import AlertContext from "../../../../contexts/AlertContext";
import { videoPages } from "../../../../constants/PagePaths";
import { useHistory, useParams } from "react-router-dom";
import { CurriculumsModel, Videos } from "../../models/LectureDataModel";
import { videoApi } from "../../../../config/videoApi";
import { util } from "../../../../config/util";
interface Props {
  isPurchase: boolean | undefined;
  paymentUrl: string;
  hour?: number;
  loginAlert: () => void;
}

const Curriculum = (props: Props) => {
  const alert = useContext(AlertContext);
  const isLogin = util.isLogin();
  const { curriculumId } = useParams<{
    curriculumId: string;
  }>();
  const history = useHistory();
  const isPurchase = props.isPurchase as boolean;
  const loginAlert = props.loginAlert;
  const [curriculumData, setCurriculumData] = useState<CurriculumsModel[]>();

  //클릭한 summary 인덱스 저장소
  const [clickSummary, setClickSummary] = useState<number>();

  //열림상태인 summary(소제목)들
  const [openedSummary, setOpenedSummary] = useState<number[]>();

  //열림상태 summary(소제목)들의 초기값
  const [defaultSummary, setDefaultSummary] = useState<any>();

  //커리큘럼 모두접기,모두열기 버튼
  const [detailsOpen, setDetailsOpen] = useState<boolean>(true);
  const paymentUrl = props.paymentUrl;

  //summary(소제목)클릭시 해당 index를 열림상태 summaryOpen에 넣거나 빼서 소제목 우측 버튼 색/모양 변경
  const changeOpenedSummary = (index: number) => {
    let addInx = [index];
    let deleteInx = openedSummary?.filter((i) => i !== [...addInx][0]);
    openedSummary?.indexOf(index) !== -1 && openedSummary?.indexOf(index) === -1
      ? setOpenedSummary((currentSummary) => {
          return currentSummary?.concat([...addInx]);
        })
      : setOpenedSummary((currentSummary) => {
          return (currentSummary = deleteInx);
        });
  };

  const allSummaryOpen = () => {
    let copy = [...defaultSummary];
    detailsOpen === true ? setOpenedSummary([]) : setOpenedSummary(copy);
    setDetailsOpen((currentOpen) => {
      return !currentOpen;
    });
    setClickSummary(-1);
  };

  const noneTagClick = () => {
    alert.videoShowAlert("구매 후 이용해주세요", () => {
      history.push(paymentUrl);
    });
  };

  const freeVideo = async (item: any) => {
    !isLogin
      ? loginAlert()
      : !item?.is_free && !isPurchase
      ? noneTagClick()
      : history.push(`${videoPages.course}/${item.id}`);
  };

  useEffect(() => {
    new Promise(async () => {
      const res = await videoApi.getCourseCurriculumAsync(curriculumId);
      const data = res?.data?.curriculums;
      const newDataInx = data?.map((a: any, i: number) => i);
      setCurriculumData(data);
      setOpenedSummary(newDataInx);
      setDefaultSummary(newDataInx);
    });
  }, []);

  useEffect(() => {
    openedSummary?.length === 0 && setDetailsOpen(false);
  }, [openedSummary, detailsOpen]);

  const titleList = curriculumData?.map(
    (item: CurriculumsModel, index: number) => {
      return (
        <details
          className={styles.video_list_folding}
          key={index}
          open={detailsOpen === true ? true : false}
        >
          <summary
            className={`${
              clickSummary === index || defaultSummary?.indexOf(index) !== -1
                ? styles.active
                : ""
            }`}
            onClick={() => {
              setClickSummary(index);
              changeOpenedSummary(index);
            }}
          >
            {`${index + 1}. ${item.title}`}
            {openedSummary?.indexOf(index) !== -1 ? (
              <BiCaretUp className={styles.white} />
            ) : (
              <BiCaretDown className={styles.white} />
            )}
          </summary>
          <div className={styles.video_list_item}>
            {item.videos.map((item: Videos, i: number) => {
              return (
                <li
                  key={i}
                  className={`${clickSummary === index ? styles.active : ""}`}
                  onClick={() => {
                    freeVideo(item);
                  }}
                >
                  <AiFillPlayCircle className={styles.ai_video} />
                  {`${i + 1}. ${item.title}`}

                  {/* 수강권 구입 하였으면 강의듣기 버튼, 아니면 무료체험 해당 강의에 무료체험 버튼 출력  */}
                  {isPurchase === true ? (
                    <div className={styles.free_button}>강의듣기</div>
                  ) : item.is_free === true ? (
                    <div className={styles.free_button}>무료체험</div>
                  ) : null}
                </li>
              );
            })}
          </div>
        </details>
      );
    }
  );

  return (
    <section className="bg-dark py-120">
      <div className="container page">
        <div className="text-dark text-center mb-7 mt-4">
          <h4 className="fw-normal text-primary-300">
            총 {props.hour}시간의 강의로 실무 실력을 향상시키세요!
          </h4>
          <h1 className="text-white">강의 커리큘럼</h1>
        </div>
        <div
          className="curriculum-list-wrap aos-init"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {curriculumData?.map((item: any, index: number) => {
            return (
              <div className="curriculum-list">
                <div className="flex-2">
                  <h3>{`${index + 1}. ${item.title}`}</h3>
                </div>
                <div className="flex-2">
                  <ul className="text-white">
                    {item.videos.map((item: any, i: number) => {
                      return (
                        <li
                          onClick={() => {
                            freeVideo(item);
                          }}
                          className="pointer"
                        >
                          {`${item.title}`}

                          {item.is_free === true ? (
                            <span className={""} style={{ marginRight: 36 }}>
                              무료체험
                            </span>
                          ) : null}
                          <span className="material-symbols-outlined">
                            play_circle
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Curriculum;
