/**
 * 아래의 파일들은 https://docs.iamport.kr/tech/imp?lang=ko#param를 참조하여 선언되었음.
 **/
type ValueOf<T> = T[keyof T];

type PayMethodExplain = {[key in keyof typeof IamportPayMethod]: string}

export const payMethodMapper: PayMethodExplain = {
  card: "신용카드",
  cultureland: "컬쳐랜드",
  default: "신용카드",
  happymoney: "해피머니",
  kakaopay: "카카오페이",
  kpay: "K페이",
  lpay:"L페이",
  payco: "페이코",
  phone: "핸드폰결제",
  samsung: "삼성페이",
  smartculture: "스마트컬쳐",
  ssgpay: "신세계페이",
  tosspay: "토스페이",
  trans: "실시간 계좌이체",
  vbank: "무통장결제",
}


export enum IamportPayMethod {
  default = "",
  /** 카드 결제 **/
  card="card",
  /** 실시간 계좌이체 **/
  trans = "trans",
  /** 무통장 결제 **/
  vbank = "vbank",
  /** 핸드폰 소액결제 **/
  phone = "phone",
  /** 삼성페이 **/
  samsung = "samsung",
  /** 미사용 **/
  kpay = "kpay",
  /** 카카오페이 **/
  kakaopay = "kakaopay",
  /** 미사용 **/
  payco = "payco",
  /** 미사용 **/
  lpay = "lpay",
  /** 미사용 **/
  ssgpay = "ssgpay",
  /** 토스 페이 **/
  tosspay = "tosspay",
  /** 미사용 **/
  cultureland = "cultureland",
  /** 미사용 **/
  smartculture = "smartculture",
  /** 미사용 **/
  happymoney = "happymoney",
}
type ResponseStatus = "ready" | "paid" | "failed" | "cancelled";

export interface IamportPaymentRequestModel {
  /** PG사 선택. 현재는 이니시스만 설정. **/
  pg?: "html5_inicis" | "html5_inicis.INIBillTst";
  /** 거래 방식. KG이니시스 관련 방식만 선언되어있음. **/
  pay_method: IamportPayMethod;
  /** 가맹점에서 생성/관리하는 고유 주문번호 **/
  merchant_uid: string;
  /** 결제할 금액	 **/
  amount: number;
  /** 주문명 **/
  name?: string;
  /** 주문자명 **/
  buyer_name?: string;
  /** 주문자 연락처	 **/
  buyer_tel: string;
  /** 주문자 Email	 **/
  buyer_email?: string;
  /** 주문자 주소	 **/
  buyer_addr?: string;
  /** 주문자 우편번호	 **/
  buyer_postcode?: string;
  escrow?: boolean;
  /** 가맹점 임의 지정 데이터	 **/
  custom_data?: any;
  /** amount 중 면세공급가액	 **/
  tax_free?: number;
  /** 화폐단위 **/
  currency?: string;
  /** 결제창의 언어 설정	 **/
  language?: string;
  /** Notification URL	 **/
  notice_url?: string | string[];
  /** 결제화면과 관련한 옵션 설정	 **/
  display?: {
    /** 할부개월수 선택 UI제어옵션	 **/
    card_quota: number[];
  };
  /**
   * 결제상품이 컨텐츠인지 여부.
   * 휴대폰소액결제시 필수.
   * 반드시 실물/컨텐츠를 정확히 구분해주어야 함
   **/
  digital?: boolean;
  /** 가상계좌 입금기한	 **/
  vbank_due?: string;
  /** 모바일 결제 후 이동될 주소	 **/
  m_redirect_url?: string;
  /** 모바일 앱 결제도중 앱복귀를 위한 URL scheme	 **/
  app_scheme?: string;
  /** 계약된 사업자등록번호 10자리(기호를 포함하면 안됨)	 **/
  biz_num?: string;
}

export interface ImpCallbackResponseModel {
  /**
   * 결제처리가 성공적이었는지 여부.
   * 실제 결제승인이 이뤄졌거나, 가상계좌 발급이 성공된 경우, True
   **/
  success: boolean;
  /** 결제처리에 실패한 경우 단축메세지. 현재 코드체계는 없음 **/
  error_code: string;
  /** 결제처리에 실패한 경우 상세메세지	 **/
  error_msg: string;
  /** 아임포트 거래 고유 번호	 **/
  imp_uid: string;
  /**
   * 가맹점에서 생성/관리하는 고유 주문번호.
   * 아임포트에서 부여하는 거래건 당 고유한 번호(success:false일 때,
   * 사전 validation에 실패한 경우라면 imp_uid는 null일 수 있음)
   **/
  merchant_uid: string;
  /** 결제수단 **/
  pay_method: string;
  /** 결제금액 **/
  paid_amount: number;
  /** 결제상태 **/
  status: ResponseStatus;
  /** 주문명 **/
  name: string;
  /** 결제승인/시도된 PG사	 **/
  pg_provider: string;
  /** PG사 거래고유번호	 **/
  pg_tid: string;
  /** 주문자 이름	 **/
  buyer_name: string;
  /** 주문자 Email	 **/
  buyer_email: string;
  /** 주문자 연락처	 **/
  buyer_tel: string;
  /** 주문자 주소	 **/
  buyer_addr: string;
  /** 주문자 우편번호	 **/
  buyer_postcode: string;
  /** 가맹점 임의 지정 데이터	 **/
  custom_data: string;
  /** 결제승인시각 **/
  paid_at: number;
  /** PG사에서 발행되는 거래 매출전표 URL	 **/
  receipt_url: string;
  /** 카드사 승인번호.신용카드결제에 한하여 제공**/
  apply_num?: string;
  /**
   * 가상계좌 입금계좌번호.
   * PG사로부터 전달된 정보 그대로 제공하므로
   * 숫자 외 dash(-)또는 기타 기호가 포함되어 있을 수 있음
   * **/
  vbank_num?: string;
  /** 가상계좌 은행명	 **/
  vbank_name?: string;
  /** 가상계좌 예금주	 **/
  vbank_holder?: string;
  /**
   * 가상계좌 입금기한.
   * 계약된 사업자명으로 항상 일정함.
   * 단, 일부 PG사의 경우 null반환하므로 자체 처리 필요
   * **/
  vbank_date?: number;
  /** 모바일에서 리다이렉션 됐을 때 넘어오는 성공 변수 */
  imp_success?: boolean;
}

export interface ImpCertificationRequestModel {
  /** 가맹점에서 생성/관리하는 고유 주문번호 **/
  merchant_uid?: string;
  /** 허용하는 최소 만 나이	 **/
  min_age?: number;
  /** 본인인증 할 이름	 **/
  name?: string;
  /** 본인인증 할 전화번호	 **/
  phone?: string;
  /** 본인인증 통신사 지정	 **/
  carrier?: string;
  /** 서비스 도메인URL 또는 명칭	 **/
  company?: string;
  /** 새 창(window)에 본인인증 화면 생성 여부	 **/
  popup?: boolean;
}

export interface ImpCertificationResponseModel {
  error_code: string;
  error_msg: string;
  imp_uid: string;
  merchant_uid: string;
  pg_provider: string;
  pg_type: string;
  success: boolean;
  name: string;
  gender: string;
  birth: string;
  unique_key: string;
  unique_in_site: string;
}