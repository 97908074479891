import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Button, Typography, Descriptions, message, Modal } from "antd";
import { serviceApi } from "../../config/serviceApi";
import { ServiceRequestModel } from "../../models/service/ServiceRequestModel";
import { api } from "../../config/api";
import { AxiosError } from "axios";
import { getServiceStatus } from "../service/ServiceUtil";

const { Title, Paragraph } = Typography;

interface ServiceRequest {
  id: string;
  service: string;
  client: string;
  date: string;
  status: string;
  description: string;
  expectedCompletionDate: string;
  matchedExperts?: {
    id: string;
    name: string;
    rating: number;
    experience: string;
    completedProjects: number;
    specialties: string[];
  }[];
  consultationCompleted?: boolean;
}

const ExpertMatchingPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [request, setRequest] = useState<ServiceRequestModel | null>(null);
  const [isMatchingComplete, setIsMatchingComplete] = useState(false);

  useEffect(() => {
    loadRequest();
    window.addEventListener("serviceApplicationUpdated", loadRequest);
    return () => {
      window.removeEventListener("serviceApplicationUpdated", loadRequest);
    };
  }, [id]);

  const loadRequest = async () => {
    const res = await serviceApi.getServiceRequest(id);
    const myInfo = await api.getMyInfo();
    if (res.status == 200) {
      setRequest(res.data);
      setIsMatchingComplete(
        res.data.matching_experts.filter(
          (experts) => experts.user.email === myInfo.data.email
        ).length > 0
      );
    } else {
      message.error("요청을 찾을 수 없습니다.");
      history.push("/dashboard");
    }
  };

  const handleMatchingParticipation = async () => {
    if (request) {
      try {
        const res = await serviceApi.joinService(request.id);
        if (res.status == 200) {
          message.success("매칭 참여가 완료되었습니다.", () => {
            window.location.reload();
          });
        }
      } catch (e: any) {
        message.error(e.response?.data?.detail || "에러가 발생했습니다.");
      }
    }
  };

  // const handleCreateProject = () => {
  //   //서비스 내용을 그대로 옮겨서 프로젝트로 만든다.
  //   if (request && request.consultationCompleted) {
  //     const newProject = {
  //       id: Date.now().toString(),
  //       title: request.service,
  //       status: "진행 중",
  //       tasks: [],
  //       client: "",
  //       expert: "김전문",
  //       description: request.description,
  //       startDate: new Date().toISOString().split("T")[0],
  //       endDate: request.expectedCompletionDate,
  //       budget: 0,
  //       progress: 0,
  //     };

  //     const projects = JSON.parse(localStorage.getItem("projects") || "[]");
  //     projects.push(newProject);
  //     localStorage.setItem("projects", JSON.stringify(projects));

  //     const storedApplications = localStorage.getItem("serviceApplications");
  //     if (storedApplications) {
  //       const applications = JSON.parse(storedApplications);
  //       const updatedApplications = applications.map((app: any) =>
  //         app.id === id
  //           ? { ...app, status: "프로젝트 생성 완료", projectId: newProject.id }
  //           : app
  //       );
  //       localStorage.setItem(
  //         "serviceApplications",
  //         JSON.stringify(updatedApplications)
  //       );
  //     }

  //     setRequest({ ...request, status: 5 });
  //     setIsMatchingComplete(true);

  //     message.success("프로젝트가 생성되었습니다.");
  //     history.push(`/projects/${newProject.id}`);
  //   } else {
  //     message.error("아직 의뢰자의 전화 상담이 완료되지 않았습니다.");
  //   }
  // };

  if (!request) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        padding: "40px",
        backgroundColor: "#f0f2f5",
        minHeight: "100vh",
      }}
    >
      <Card
        title={<Title level={2}>서비스 요청 상세</Title>}
        style={{
          maxWidth: 800,
          margin: "0 auto",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        }}
      >
        <Descriptions bordered>
          <Descriptions.Item label="서비스" span={3}>
            {request.service.title}
          </Descriptions.Item>
          <Descriptions.Item label="의뢰인">
            {request.client.username}
          </Descriptions.Item>
          <Descriptions.Item label="신청일">
            {new Date(request.created_at).toISOString().split("T")[0]}
          </Descriptions.Item>
          <Descriptions.Item label="완료 희망일">
            {request.desired_completion_date}
          </Descriptions.Item>
          <Descriptions.Item label="상태">
            {getServiceStatus(request.status).title}
          </Descriptions.Item>
          <Descriptions.Item label="설명" span={3}>
            {request.description}
          </Descriptions.Item>
        </Descriptions>

        {(!isMatchingComplete && request.status === "matching") ||
        request.status === "pending" ? (
          <Button
            type="primary"
            onClick={handleMatchingParticipation}
            style={{ marginTop: "20px" }}
          >
            매칭 참여하기
          </Button>
        ) : (
          // ) : request.status === 5 && request.selectedExpert ? (
          //   <Button
          //     type="primary"
          //     onClick={handleCreateProject}
          //     style={{ marginTop: "20px" }}
          //     disabled={false}
          //   >
          //     프로젝트 생성하기
          //   </Button>
          <Paragraph style={{ marginTop: "20px" }}>
            이미 참여하였거나 프로젝트가 생성되었습니다.
          </Paragraph>
        )}

        <Button
          onClick={() => history.push("/dashboard")}
          style={{ marginTop: 20 }}
        >
          대시보드로 돌아가기
        </Button>
      </Card>
    </div>
  );
};

export default ExpertMatchingPage;
