/* eslint-disable import/no-anonymous-default-export */
import React from "react";

import styles from "./lectureRoom.module.scss";
import CategorySelector from "../../components/selector/categorySelector/CategorySelector";
import { LectureItem } from "../../models/LectureModel";
import LectureRoomStudy from "./LectureRoomStudy";
import { CategoryItem } from "../../models/CategoryItem";

interface Props {
  /** 전체 카테고리 리스트 */
  categories?: CategoryItem[];
  /** 선택된 카테고리의 강의 목록 */
  lectures?: LectureItem[];
  /** 선택된 강의 */
  selectedLecture?: LectureItem;
  /** 선택된 카테고리의 인덱스 */
  selectedCategoryIndex: number;
  /** 단일 영상이 아닌 재생목록일 때 넘겨주는 데이터. **/
  playlist: string;
  /** 카테고리 선택 콜백 함수 */
  onClickCategory: (index: number) => void;
  /** 재생목록의 다른 강의를 눌렀을 때의 콜백 **/
  onClickOtherLecture: (index: number) => void;
}

export default (props: Props) => {
  return (
    <div className={styles.lecture_wrapper}>
      <div className={styles.project_title}>강의실</div>
      <CategorySelector
        categories={props.categories}
        onClickCategory={props.onClickCategory}
        selectedCategoryIndex={props.selectedCategoryIndex}
      />
      <LectureRoomStudy
        selectedLecture={props.selectedLecture}
        lectures={props.lectures}
        playlist={props.playlist}
        onClickOtherLecture={props.onClickOtherLecture}
      />
    </div>
  );
};
