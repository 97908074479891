import { Details } from "@material-ui/icons";
import { useState } from "react";
import { util } from "../../../config/util";
import {
  PaymentProductDetailModelV2,
  PaymentProductModelV2,
} from "../../../models/payment/PaymentProductModelV2";
import styles from "../PaymentPresenter.module.scss";

interface Props {
  product: PaymentProductModelV2;
  index: number;
  handleGroupedProductClick: (
    period: number,
    detailId: number,
    amount: number,
    productId: number
  ) => void;
  deleteProduct: (
    period: number,
    detailId: number,
    amount: number,
    productId: number
  ) => void;
  //   selectProduct: (detail: PaymentProductDetailModelV2) => void;
}

export default (props: Props) => {
  const [selectedPeriod, setSelectedPeriod] = useState(30);
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div id={"pay-option" + (props.index + 1)} className="payment-box">
      <input
        type="checkbox"
        className={"btn-check"}
        id={"btn-check" + props.product.id + (props.index + 1)}
        autoComplete="off"
        onChange={(e) => {
          setIsSelected(e.target.checked);
          const details = props.product.details;
          const selectedDetail = details.filter(
            (detail: PaymentProductDetailModelV2) =>
              detail.period == selectedPeriod
          )[0];

          if (!e.target.checked) {
            props.deleteProduct(
              selectedPeriod,
              selectedDetail.id,
              selectedDetail.discounted_price,
              props.product.id
            );
          } else {
            props.handleGroupedProductClick(
              selectedPeriod,
              selectedDetail.id,
              selectedDetail.discounted_price,
              props.product.id
            );
          }
        }}
      />
      <label
        className="btnbox "
        htmlFor={"btn-check" + props.product.id + (props.index + 1)}
      >
        <div className="payment-item">
          <div className="pay-check">
            <span className="material-symbols-outlined">task_alt</span>
          </div>
          <div className="payment-info">
            <div className="title-wrap">
              <div className="subtitle">{props.product.subtitle}</div>
              <div className="title">{props.product.name}</div>
              <div className="content">
                <ul>
                  {props.product.descriptions.map((description: any) => {
                    return <li>{description}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div className="payment-option-wrap">
              <div className="payment-option">
                {props.product.details.map(
                  (detail: PaymentProductDetailModelV2, index: number) => {
                    return (
                      <>
                        <input
                          type="radio"
                          className="btn-check"
                          name={"options" + detail.name}
                          id={"option" + detail.name + (index + 1)}
                          value={detail.id}
                          autoComplete="off"
                          checked={selectedPeriod === detail.period}
                          onChange={() => {
                            if (isSelected) {
                              props.handleGroupedProductClick(
                                detail.period,
                                detail.id,
                                detail.discounted_price,
                                props.product.id
                              );
                            }
                            setSelectedPeriod(detail.period);
                          }}
                        />
                        <label
                          className="btn btn-outline position-relative"
                          htmlFor={"option" + detail.name + (index + 1)}
                        >
                          {detail.period == 30
                            ? "1개월"
                            : detail.period == 90
                            ? "3개월"
                            : detail.period == 180
                            ? "6개월"
                            : "1년"}
                          <div
                            className="discount-label position-absolute right-0 translate-middle badge rounded-pill bg-primary"
                            style={{ top: "-5px" }}
                          >
                            {detail.origin_price &&
                              `${Math.floor(
                                (1 -
                                  detail.discounted_price /
                                    detail.origin_price) *
                                  100
                              )}%할인`}
                          </div>
                        </label>
                      </>
                    );
                  }
                )}
              </div>
              <div className="payment-content">
                {props.product.details.map((detail: any, index: number) => {
                  return (
                    <div
                      className={
                        index == 0
                          ? "payment-price basic"
                          : index == 1
                          ? "payment-price month"
                          : "payment-price year"
                      }
                      style={
                        selectedPeriod == detail.period
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="price">
                          <span>{util.addComma(detail.discounted_price)}</span>
                          원
                          <span className={styles.origin_price}>
                            {detail.origin_price &&
                              `(${detail.origin_price?.toLocaleString()}원)`}
                          </span>
                        </div>
                      </div>
                      <div className="ticket">
                        <span>{detail.period}</span>일 이용권
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};
