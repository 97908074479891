import { useContext, useState, useEffect } from "react";
import styles from "./deleteConfirm.module.scss";
import AlertContext from "../../../contexts/AlertContext";

interface Props {
  onClickConfirm: (args: any) => void;
  onClickCancel: () => void;
  open: boolean;
  reviewTitle: string;
  reviewContent?: string;
  reviewRating?: number;
}

export default (props: Props) => {
  const [rating, setRating] = useState(props.reviewRating || 0);
  const [review, setReview] = useState(props.reviewContent || "");
  const [isReadOnly, setIsReadOnly] = useState(false);
  
  const alertContext = useContext(AlertContext);
  
  useEffect(() => {
    setIsReadOnly(!!props.reviewRating);
    setRating(props.reviewRating || 0);
    setReview(props.reviewContent || "");
  }, [props.reviewRating, props.reviewContent]);

  const writeReview = async () => {
    if (review.length < 1) {
      alertContext.showAlert("리뷰 내용을 입력해주세요.");
      return;
    }
    if (rating > 5 || rating < 1) {
      alertContext.showAlert("리뷰 점수를 확인해주세요.");
      return;
    }
    props.onClickConfirm({review, rating});
    setReview("");
    setRating(0);
  };

  const handleStarClick = (value: number) => {
    if (!isReadOnly) {
      setRating(value);
    }
  };

  const handleReviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!isReadOnly) {
      setReview(e.target.value);
    }
  };

  const handleClose = () => {
    props.onClickCancel();
    if (!isReadOnly) {
      setReview("");
      setRating(0);
    }
  };

  return (
    <div
      className="modal fade"
      id="reviewModal"
      tabIndex={-1}
      aria-labelledby="reviewModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="reviewDetailModalLabel">
              {props.reviewTitle}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <label className="fw-bold">평점</label>
            <div className={`star-rating mb-4 ${isReadOnly ? 'opacity-50' : ''}`}>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={`fa ${star <= rating ? "fa-star" : "fa-star-o"}`}
                  data-rating={star}
                  onClick={() => handleStarClick(star)}
                  style={{ cursor: isReadOnly ? "default" : "pointer" }}
                ></span>
              ))}
            </div>
            <label className="fw-bold">내용</label>
            <textarea
              className="form-control"
              aria-label="With textarea"
              value={review}
              onChange={handleReviewChange}
              readOnly={isReadOnly}
              style={{ backgroundColor: isReadOnly ? '#e9ecef' : 'white' }}
            ></textarea>
          </div>
          {!isReadOnly && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={writeReview}
                data-bs-dismiss="modal"
              >
                작성완료
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};