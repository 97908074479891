import styled from "styled-components";

interface Props {
  completed: string;
}

const ProgressBar = (props:Props) => {
    const { completed } = props;

    const ContainerBox = styled.div`
    height: auto;
    width: 100%;
    /* border:1px solid black; */
    background-color: "#81817b"; 
    border-radius: 5px;
    /* margin: 50px; */
    display: flex;
  `
    const FillerStyles = styled.div`
    height: '100%';
    width: 100%;
    /* border: 1px solid blue; */
    background-color: whitesmoke;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  `

    const LabelStyles = styled.span<{bg: string, width: string}>`
    width: ${props => props.width};
    /* padding: 5px; */
    background-color: ${props => props.bg};
    /* border: 1px solid red; */
    border-radius: 50px;
    color: ${props => props.color};
    font-size: 20px;
    font-Weight: 'bold';
    /* text-align: right; */
    `
  
    return (
    <ContainerBox>      {
        completed === "request"
        ?<FillerStyles >
          <>
            <LabelStyles bg="var(--color-primary)" color ="#e9ecef" width="33.33%">
            상담중
            </LabelStyles>
            <LabelStyles bg="var(--color-gray3)" color ="var(--color-gray6)" width="33.33%">
            진행중
            </LabelStyles>
            <LabelStyles bg="var(--color-gray3)" color ="var(--color-gray6)" width="33.33%">
            납품완료
            </LabelStyles>
          </>
        </FillerStyles>
        :completed === "progress"
        ?<FillerStyles >
          <>
            <LabelStyles bg="var(--color-gray3)" color ="var(--color-gray6)" width="33.33%">
            상담중
            </LabelStyles>
            <LabelStyles bg="var(--color-primary)" color ="#e9ecef" width="33.33%">
            진행중
            </LabelStyles>
            <LabelStyles bg="var(--color-gray3)" color ="var(--color-gray6)" width="33.33%">
            납품완료
            </LabelStyles>
          </>
        </FillerStyles>
         :<FillerStyles >
           <>
            <LabelStyles bg="var(--color-gray3)" color ="var(--color-gray6)" width="33.33%">
            상담중
            </LabelStyles>
            <LabelStyles bg="var(--color-gray3)" color ="var(--color-gray6)" width="33.33%">
            진행중
            </LabelStyles>
            <LabelStyles bg="var(--color-primary)" color ="#e9ecef" width="33.33%">
            납품완료
            </LabelStyles>
          </>
        </FillerStyles>
      }
    </ContainerBox> 

    )
  };
  

  
    
  

  

 
  export default ProgressBar;