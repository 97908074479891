import React, { useState } from "react";
import {
  Card,
  Avatar,
  Typography,
  Tag,
  Rate,
  List,
  Button,
  Modal,
  Tabs,
  Divider,
} from "antd";
import {
  UserOutlined,
  MessageOutlined,
  LikeOutlined,
  StarOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import styles from "./ExpertDetail.module.scss";
import { useParams } from "react-router-dom";
import ChatComponent from "../../components/ChatComponent";
import { useEffect } from "react";
import { serviceApi } from "../../config/serviceApi";
import { ExpertModel } from "../../models/service/ExpertModel";

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

interface ExpertDetailProps {
  expert: {
    id: number;
    name: string;
    avatar: string;
    specialties: string[];
    experience: number;
    rating: number;
    description: string;
    projects: { title: string; description: string; date: string }[];
    reviews: {
      author: string;
      content: string;
      rating: number;
      date: string;
    }[];
    certifications: string[];
    education: string[];
  };
}

const ExpertDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  // const expertDummy: ExpertModel = {
  //   id: 1,
  //   nickname: "김전문",
  //   thumbnail:
  //     "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
  //   specialty: "도면 용역",
  //   // specialties: ["도면 용역", "실시설계 용역"],
  //   experience: "10년",
  //   rating: 4.8,
  //   description: "10년 경력의 도면 및 실시설계 전문가입니다.",
  //   projects: [
  //     {
  //       title: "서울 지하철 7호선 설계",
  //       description: "지하철 7호선 연장 구간 설계 프로젝트",
  //       date: "2022-01-01",
  //     },
  //     {
  //       title: "부산 해운대 아파트 단지 설계",
  //       description: "300세대 규모 아파트 단지 설계",
  //       date: "2021-06-15",
  //     },
  //   ],
  //   reviews: [
  //     {
  //       author: "홍길동",
  //       content: "매우 만족스러운 작업이었습니다.",
  //       rating: 5,
  //       date: "2023-01-15",
  //     },
  //     {
  //       author: "김철수",
  //       content: "전문성이 돋보이는 설계였습니다.",
  //       rating: 4.5,
  //       date: "2022-11-20",
  //     },
  //   ],
  //   certifications: [
  //     { name: "건축사", issued_by: "건축사", issued_date: "건축사" },
  //     {
  //       name: "구조기술사",
  //       issued_by: "구조기술사",
  //       issued_date: "구조기술사",
  //     },
  //   ],
  //   education: [
  //     {
  //       school: "서울대학교 건축학과",
  //       major: "서울대학교 건축학과",
  //       degree: "서울대학교 건축학과",
  //       graduated_year: 1999,
  //     },
  //   ],
  //   user: { id: 1, email: "string", name: "string" },
  // };
  const [expert, setExpert] = useState<ExpertModel | null>(null);
  const [isMessagingVisible, setIsMessagingVisible] = useState(false);

  useEffect(() => {
    async function fetch() {
      const res = await serviceApi.getExpert(+id);
      if (res.status == 200) {
        setExpert(res.data);
      } else {
        // 찾을 수 없음.
      }
    }
    fetch();
  }, []);

  return (
    <div className={styles.expertDetailContainer}>
      <Card className={styles.expertCard}>
        <div className={styles.expertHeader}>
          <Avatar size={120} src={expert?.thumbnail} icon={<UserOutlined />} />
          <div className={styles.expertInfo}>
            <Title level={2}>{expert?.nickname}</Title>
            <Rate disabled value={expert?.rating} />
            <Text type="secondary">경력 {expert?.experience}</Text>
            <div className={styles.expertSpecialties}>
              {expert?.specialty != null
                ? JSON.parse(expert?.specialty!).map(
                    (s: string, index: number) => (
                      <Tag key={index} color="blue">
                        {s}
                      </Tag>
                    )
                  )
                : ""}
              {/* <Tag color="blue">{expert?.specialty}</Tag> */}
            </div>
          </div>
        </div>
        <Divider />
        <Paragraph className={styles.expertDescription}>
          {expert?.description}
        </Paragraph>
        <Button
          type="primary"
          icon={<MessageOutlined />}
          size="large"
          className={styles.contactButton}
          onClick={() => setIsMessagingVisible(true)}
        >
          전문가에게 연락하기
        </Button>
      </Card>

      <Tabs defaultActiveKey="1" className={styles.detailTabs}>
        <TabPane
          tab={
            <span>
              <ProjectOutlined />
              프로젝트
            </span>
          }
          key="1"
        >
          <List
            itemLayout="vertical"
            dataSource={expert?.projects}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.title}
                  description={item.description}
                />
                {item.date}
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <StarOutlined />
              리뷰
            </span>
          }
          key="2"
        >
          <List
            itemLayout="vertical"
            dataSource={expert?.reviews}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.author}
                  description={<Rate disabled defaultValue={item.rating} />}
                />
                {item.content}
                <div className={styles.reviewDate}>{item.date}</div>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <LikeOutlined />
              자격 및 교육
            </span>
          }
          key="3"
        >
          <Title level={4}>자격증</Title>
          <List
            dataSource={expert?.certifications}
            renderItem={(item) => <List.Item>{item.name}</List.Item>}
          />
          <Title level={4}>학력</Title>
          <List
            dataSource={expert?.education}
            renderItem={(item) => <List.Item>{item.school}</List.Item>}
          />
        </TabPane>
      </Tabs>

      <Modal
        title="전문가와 대화하기"
        visible={isMessagingVisible}
        onCancel={() => setIsMessagingVisible(false)}
        footer={null}
        width={700}
      >
        <ChatComponent
          currentUser="현재 사용자 ID" // 실제 사용자 ID로 대체해야 합니다
          expertId={expert?.id.toString() ?? "0"}
        />
      </Modal>
    </div>
  );
};

export default ExpertDetail;
