/* eslint-disable import/no-anonymous-default-export */
import { Dialog } from "@material-ui/core";
import { useState } from "react";
import styles from "./deleteConfirm.module.scss";

interface Props {
  onClickConfirm: () => void;
  onClickCancel: () => void;
  open: boolean;
}

export default (props: Props) => {
  const [disabled, setDisabled] = useState(true);

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter" && !disabled) {
      props.onClickConfirm();
    }
  };

  const handleInputChange = (e: any) => {
    setDisabled(e?.target?.value !== "삭제합니다");
  };

  return (
    <div
      className="modal fade dialogs"
      id="dialogs"
      tabIndex={-1}
      aria-labelledby="dialogsLabel"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-6" id="dialogsLabel">
              알림
            </h1>
          </div>
          <div className="modal-body">
            삭제하시겠습니까? 복구할 수 없습니다. 일반 프로젝트 삭제시 다른
            프로젝트들도 삭제됩니다
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn text-danger"
              data-bs-dismiss="modal"
              onClick={() => {
                props.onClickConfirm();
              }}
            >
              삭제
            </button>
            <button
              type="button"
              className="btn text-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                props.onClickCancel();
              }}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
