import { useContext, useRef } from "react";
import { util } from "../../config/util";
import AlertContext from "../../contexts/AlertContext";
import CouponBoxModel from "../../models/coupon/CouponBoxModel";
import CouponPaymentModel from "../../models/coupon/CouponPaymentModel";
import { IamportPayMethod } from "../../models/payment/iamport/ImpModels";
import {
  PaymentModelV2,
  PaymentPackageModelV2,
  PaymentProductModelV2,
  PaymentSelectedProductModelV2,
} from "../../models/payment/PaymentProductModelV2";
import ProductRemoveModal from "./components/ProductRemoveModal";

interface Props {
  selectedData?: PaymentModelV2;
  selectedProduct: PaymentSelectedProductModelV2[];
  selectedPackages: PaymentSelectedProductModelV2[];
  totalPrice: number;
  deleteProduct: (period: number, detailId: number, amount: number, productId: number) => void;
  deletePackage: (period: number, detailId: number, amount: number, packageId: number) => void;
  handlePaymentClick: () => void;
  handleClickPayMethod: (payMethod: IamportPayMethod) => void;
  coupons?: CouponBoxModel[];
  selectedCoupon?: CouponPaymentModel;
  selectedType: string;
}

export default (props: Props) => {
  const checkRef = useRef<HTMLInputElement>(null);
  const alertContext = useContext(AlertContext);

  const getProduct = (productId: number): PaymentProductModelV2 => {
    const products = props.selectedData?.products;
    const productById = products?.filter(
      (product) => product.id === productId
    )[0];
    return productById!;
  };

  const getPackage = (productId: number): PaymentPackageModelV2 => {
    const products = props.selectedData?.packages;
    const productById = products?.filter(
      (product) => product.id === productId
    )[0];
    return productById!;
  };

  return (
    <main id="wrap">
      <article>
        <section className="bg-gray-50">
          <div className="container content">
            <h5 className="marketing-step">STEP 02</h5>
            <h3
              className="marketing-title pb-3 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              결제하기
            </h3>
            <div
              className="paypage-wrap aos-init aos-animate"
              data-aos="fade-up"
            >
              <div className="payinfo-left">
                <div className="paypage-box">
                  <div className="SubTitle2">이용권 정보</div>
                  <div className="product-list">
                    <ul>
                      {props.selectedType === "product" &&
                        props.selectedProduct.map((product, index) => {
                          return (
                            <li>
                              <div className="product-list-detail">
                                <div className="item">
                                  <div className="thum">
                                    {getProduct(product.productId)?.name}
                                  </div>
                                  <div>
                                    <div className="sub">
                                      <div className="d-flex align-items-center mb-2">
                                        <span className="material-symbols-outlined">
                                          info
                                        </span>
                                        <div className="text-primary-400 ms-2">
                                          {getProduct(product.productId)?.subtitle}
                                        </div>
                                      </div>
                                      <ul>
                                        {getProduct(
                                          product.productId
                                        )?.descriptions.map((description) => {
                                          return <li>{description}</li>;
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-2">
                                  <div className="date">
                                    {product.period === 30
                                      ? "1개월"
                                      : product.period === 90
                                      ? "3개월"
                                      : product.period === 180
                                      ? "6개월"
                                      : "1년"}
                                  </div>
                                  <div className="price">
                                    <span>{util.addComma(product.amount)}</span>
                                    원
                                  </div>
                                </div>
                                <div className="flex-1 text-end delete">
                                  {props.selectedProduct?.length > 1 && (<button
                                    type="button"
                                    className="btn btn-lg text-danger"
                                    // data-bs-toggle="modal"
                                    // data-bs-target={"#DeleteModal"}
                                    onClick={() => {
                                      props.deleteProduct(
                                        product.period,
                                        product.id,
                                        product.amount,
                                        product.productId
                                      );
                                    }}
                                  >
                                    <span className="material-symbols-outlined">
                                      close
                                    </span>
                                  </button>)}
                                </div>
                              </div>
                            </li>
                          );
                        })}

                      {props.selectedType === "package" &&
                        props.selectedPackages.map((product, index) => {
                          return (
                            <li>
                              <div className="product-list-detail">
                                <div className="item">
                                  <div className="thum">
                                    {getPackage(product.productId)?.name}
                                  </div>
                                  <div>
                                    <div className="sub">
                                      <div className="d-flex align-items-center mb-2">
                                        <span className="material-symbols-outlined">
                                          info
                                        </span>
                                        <div className="text-primary-400 ms-2">
                                          {getPackage(product.productId)?.subtitle}
                                        </div>
                                      </div>
                                      <ul>
                                        {getPackage(product.productId)?.products.map(
                                          (description) => {
                                            return <li>{description}</li>;
                                          }
                                        )}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-2">
                                  <div className="date">
                                    {product.period === 30
                                      ? "1개월"
                                      : product.period === 90
                                      ? "3개월"
                                      : product.period === 180
                                      ? "6개월"
                                      : "1년"}
                                  </div>
                                  <div className="price">
                                    <span>{util.addComma(product.amount)}</span>
                                    원
                                  </div>
                                </div>
                                <div className="flex-1 text-end delete">
                                  {props.selectedPackages?.length > 1 && (<button
                                    type="button"
                                    className="btn btn-lg text-danger"
                                    // data-bs-toggle="modal"
                                    // data-bs-target={"#DeleteModal"}
                                    onClick={() => {
                                      props.deletePackage(
                                        product.period,
                                        product.id,
                                        product.amount,
                                        product.productId
                                      );
                                    }}
                                  >
                                    <span className="material-symbols-outlined">
                                      close
                                    </span>
                                  </button>)}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
{/* 
                <div className="paypage-box" style={{ display: "none" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="SubTitle2">쿠폰선택</div>
                    <div className="body2">
                      <span>{props.coupons?.length}</span>장 보유
                    </div>
                  </div>
                  {props.selectedCoupon != null ? (
                    <>{props.selectedCoupon.name + "적용 중 입니다."}</>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-outline btn-lg left-icon w-100 mt-4"
                      data-bs-toggle="modal"
                      data-bs-target="#CouponModal"
                    >
                      <span className="material-symbols-outlined me-2 text-primary">
                        confirmation_number
                      </span>
                      사용 가능한 쿠폰이{" "}
                      <span className="ms-1">{props.coupons?.length}</span>장
                      있습니다.
                    </button>
                  )}
                </div> */}
                <div className="paypage-box">
                  <div className="SubTitle2">결제수단 선택</div>

                  <div className="pay-card pay-list-basic">
                    <div className="card-item-wrap">
                      <input
                        type="radio"
                        className="btn-check"
                        name="options-pay"
                        id="btn-check-pay"
                        autoComplete="off"
                        onClick={() => {
                          props.handleClickPayMethod(IamportPayMethod.card);
                        }}
                      />
                      <label className="btnbox-primary" htmlFor="btn-check-pay">
                        <div className="pay-card-item">
                          <div className="pay-title">
                            <span className="material-symbols-outlined me-2">
                              credit_card
                            </span>
                            카드 &amp; 간편결제
                          </div>
                        </div>
                      </label>
                    </div>

                    <div className="card-item-wrap">
                      <input
                        type="radio"
                        className="btn-check"
                        name="options-pay"
                        id="btn-check-pay2"
                        autoComplete="off"
                        onClick={() => {
                          props.handleClickPayMethod(IamportPayMethod.vbank);
                        }}
                      />
                      <label className="btnbox-primary" htmlFor="btn-check-pay2">
                        <div className="pay-card-item">
                          <div className="pay-title">
                            <span className="material-symbols-outlined me-2">
                              savings
                            </span>
                            무통장 입금
                          </div>
                        </div>
                      </label>
                    </div>

                    <div className="card-item-wrap">
                      <input
                        type="radio"
                        className="btn-check"
                        name="options-pay"
                        id="btn-check-pay3"
                        autoComplete="off"
                        onClick={() => {
                          props.handleClickPayMethod(IamportPayMethod.trans);
                        }}
                      />
                      <label className="btnbox-primary" htmlFor="btn-check-pay3">
                        <div className="pay-card-item">
                          <div className="pay-title">
                            <span className="material-symbols-outlined me-2">
                              account_balance
                            </span>
                            실시간 계좌이체
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="paypage-box">
                  <div className="SubTitle2">동의영역</div>
                  <div className="accordion mt-4" id="accordion-underline">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          주의사항
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">내용</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          개인정보 제3자 제공
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">내용</div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          개인정보 처리 위탁 안내
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">내용</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <aside>
                <div className="SubTitle2">결제금액</div>
                <div className="SubTitle1 text-dark  mb-4">
                  <span>{util.addComma(props.totalPrice)}</span>원
                </div>
                <div className="d-flex flex-column justify-content-between mb-4">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>주문금액</div>
                    <div>
                      <span className="fw-bold text-dark">
                        {util.addComma(props.totalPrice)}
                      </span>
                      원
                    </div>
                  </div>
                  {/* <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>할인금액</div>
                    <div>
                      <span className="fw-bold text-primary">-1,000,000</span>원
                    </div>
                  </div> */}
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="agree-check"
                    ref={checkRef}
                  />
                  <label className="form-check-label body2" htmlFor="agree-check">
                    안내사항을 확인했으며 결제에 동의합니다.
                  </label>
                </div>
                <button
                  type="button"
                  className="btn btn-lg btn-primary w-100"
                  onClick={() => {
                    if (!checkRef.current?.checked) {
                      alertContext.showAlert(
                        "안내사항 약관 동의를 확인해주세요."
                      );
                      return;
                    }
                    props.handlePaymentClick();
                  }}
                >
                  <span>{util.addComma(props.totalPrice)}</span>원 결제하기
                </button>
              </aside>
            </div>
          </div>
        </section>
      </article>
      <ProductRemoveModal />
    </main>
  );
};
