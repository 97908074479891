export default class DateCalculationService {
  private _isYun = (year: number) => {
    if (year % 4 === 0) {
      if (year % 100 === 0) {
        return year % 400 === 0;
      }
      return true;
    }
    return false;
  };

  public getMonthFirstAndLast = (date: Date) => {
    const queryStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    // const queryEndDate = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), nextMonth.getDate() - 1);
    return {
      start: queryStartDate,
      end: nextMonth,
    };
  };

  public _getMonthDayCount = (date: Date) => {
    const dayCountPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (this._isYun(date.getFullYear())) dayCountPerMonth[1] = 29;
    return dayCountPerMonth[date.getMonth()];
  };

  public getWeekFirstAndLast = (date: Date) => {
    return {
      start: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - date.getDay()
      ),
      end: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + (6 - date.getDay())
      ),
    };
  };

  public addMonth = (date: Date, month: number) => {
    let addMonthFirstDate = new Date(
      date.getFullYear(),
      date.getMonth() + month,
      1
    );
    let addMonthLastDate = new Date(
      addMonthFirstDate.getFullYear(),
      addMonthFirstDate.getMonth() + 1,
      0
    );
    let result = addMonthFirstDate;
    if (date.getDate() > addMonthLastDate.getDate()) {
      result.setDate(addMonthLastDate.getDate());
    } else {
      result.setDate(date.getDate());
    }
    return result;
  };

  public getTableData = (date: Date) => {
    const today = new Date();
    let isPast = false;
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    const returnArr = [];
    if (
      date.getMonth() === 1 &&
      date.getDay() === 0 &&
      !this._isYun(date.getFullYear())
    ) {
      //윤년이 아닌 년중에 일요일로 시작하며 2월인 월은 한달이 4주.
      for (let i = 0; i < 6; i++) {
        const weekArr = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0) {
            const pushDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() - (7 - j)
            );

            isPast = today.getTime() > pushDate.getTime();
            weekArr.push({
              date: pushDate,
              monthDifferent: -1,
              isPast: isPast,
            });
          } else if (i < 5) {
            const pushDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() + ((i - 1) * 7 + j)
            );

            isPast = today.getTime() > pushDate.getTime();
            weekArr.push({
              date: pushDate,
              monthDifferent: 0,
              isPast: isPast,
            });
          } else {
            const pushDate = new Date(
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate() + j
            );

            isPast = today.getTime() > pushDate.getTime();
            weekArr.push({
              date: pushDate,
              monthDifferent: 1,
              isPast: isPast,
            });
          }
        }
        returnArr.push(weekArr);
      }
      return returnArr;
    }
    const firstDay = date.getDay();
    const dayCount = this._getMonthDayCount(date);
    let count = -firstDay;
    for (let i = 0; i < 6; i++) {
      //6주
      const weekArr = [];
      for (let j = 0; j < 7; j++) {
        //일주일 7일
        count++;

        // 오늘 날짜보다 이전이면 isPast = true

        if (i === 0) {
          if (j < firstDay) {
            //firstDay는 월의 첫일의 요일. j가 firstDay보다 작다면 이전달
            const unit = count - 1;
            const pushDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() + unit
            );
            // 오늘이 pushdate의 timestamp보다 작으면 isPast = true
            isPast = today.getTime() > pushDate.getTime();

            weekArr.push({
              date: pushDate,
              monthDifferent: -1,
              isPast,
            });
          } else {
            const pushDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              count
            );
            isPast = today.getTime() > pushDate.getTime();
            weekArr.push({
              date: pushDate,
              monthDifferent: 0,
              isPast,
            });
          }
        } else {
          if (count > dayCount) {
            let unit = count - dayCount;
            const pushDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              dayCount + unit
            );

            isPast = today.getTime() > pushDate.getTime();
            weekArr.push({
              date: pushDate,
              monthDifferent: 1,
              isPast,
            });
          } else {
            const pushDate = new Date(
              date.getFullYear(),
              date.getMonth(),
              count
            );

            isPast = today.getTime() > pushDate.getTime();
            weekArr.push({
              date: pushDate,
              monthDifferent: 0,
              isPast,
            });
          }
        }
      }
      returnArr.push(weekArr);
    }
    return returnArr;
  };
}
