import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function StandardContract(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table border="1">
            <tr>
              <td colspan="5" rowspan="2" style={{ textAlign: "center", fontSize: "35px", fontWeight: "bold" }}>공사도급표준계약서</td>
              <td style={{ textAlign: "center", width: "120px" }}>
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td style={{ textAlign: "right" }}>　제　　　　호　</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>공</span>
                  <span>고</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td style={{ textAlign: "right" }}>　제　　　　호　</td>
            </tr>

            <tr>
              <td style={{ textAlign: "center", width: "40px" }} rowspan="8"><p>계<p>약<p>자</p></p></p></td>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>발</span>
                  <span>주</span>
                  <span>처</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4">{worklist?.orderOrganization}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" rowspan="4">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>대</span>
                  <span>상</span>
                  <span>자</span>
                </span>
              </td>
              <td style={{ borderBottom: "hidden", borderRight: "hidden", paddingLeft: "5px", width: "110px", height: "25px" }}>
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>상호</span>
                  <span>또는</span>
                  <span>법인</span>
                </span>
                :</td>
              <td style={{ borderBottom: "hidden" }} colspan="3">{worklist?.companyName}</td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden", borderRight: "hidden", paddingLeft: "5px", height: "25px" }}>
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>법</span>
                  <span>인</span>
                  <span>등</span>
                  <span>록</span>
                  <span>번</span>
                  <span>호</span>
                </span>
                :</td>
              <td style={{ borderBottom: "hidden" }} colspan="3">{worklist?.corporateNum}</td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden", borderRight: "hidden", paddingLeft: "5px" }} height="25px">
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>주</span>
                  <span>소</span>
                </span>
                :</td>
              <td style={{ borderBottom: "hidden", wordBreak: "break-all", width: "380px" }} colspan="3">{worklist?.businessAddress}</td>
            </tr>
            <tr>
              <td style={{ borderRight: "hidden", paddingLeft: "5px" }} height="25px">
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>대</span>
                  <span>표</span>
                  <span>자</span>
                </span>
                :</td>
              <td style={{ borderRight: "hidden" }}>{worklist?.representativeName}</td>
              <td style={{ borderRight: "hidden" }} height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>전</span>
                  <span>화</span>
                  <span>번</span>
                  <span>호</span>
                </span>
                :</td>
              <td>{worklist?.phoneNum}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} rowspan="3" colspan="2">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>연</span>
                  <span>대</span>
                  <span>보</span>
                  <span>증</span>
                  <span>인</span>
                </span>
              </td>
              <td style={{ borderBottom: "hidden", borderRight: "hidden", paddingLeft: "5px", width: "110px", height: "25px" }}>
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>상호</span>
                  <span>또는</span>
                  <span>법인</span>
                </span>
                :</td>
              <td style={{ borderBottom: "hidden", borderRight: "hidden" }}></td>
              <td style={{ borderBottom: "hidden", borderRight: "hidden", height: "25px" }}>
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>법</span>
                  <span>인</span>
                  <span>등</span>
                  <span>록</span>
                  <span>번</span>
                  <span>호</span>
                </span>
                :</td>
              <td style={{ borderBottom: "hidden" }}></td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden", borderRight: "hidden", paddingLeft: "5px" }} height="25px">
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>주</span>
                  <span>소</span>
                </span>
                :</td>
              <td style={{ borderBottom: "hidden", borderRight: "hidden" }}></td>
              <td style={{ borderRight: "hidden", borderBottom: "hidden" }} height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>전</span>
                  <span>화</span>
                  <span>번</span>
                  <span>호</span>
                </span>
                :</td>
              <td style={{ borderBottom: "hidden" }}></td>
            </tr>
            <tr>
              <td style={{ borderRight: "hidden", paddingLeft: "5px" }} height="25px">
                <span style={{ width: "60px", display: "inline-flex", justifyContent: "space-between", marginRight: "5px" }}>
                  <span>대</span>
                  <span>표</span>
                  <span>자</span>
                </span>
                :</td>
              <td colspan="3"></td>
            </tr>

            <tr>
              <td style={{ textAlign: "center" }} rowspan="12"><p>계<p>약<p>내<p>용</p></p></p></p></td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>공</span>
                  <span>사</span>
                  <span>명</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px", wordBreak: "break-all", wordWrap:"break-word" }} colspan="4">{worklist?.constructionName}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>금</span>
                  <span>액</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>총</span>
                  <span>공</span>
                  <span>사</span>
                  <span>부</span>
                  <span>기</span>
                  <span>금</span>
                  <span>액</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4"></td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>보</span>
                  <span>증</span>
                  <span>금</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4">
                {`일금 ${props.krContractGuaranteePrice}정 (￦${props.commaContractGuaranteePrice})`}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>현</span>
                  <span>장</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px", wordBreak: "keep-all" }} colspan="4">{worklist?.constructionAddress}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>지</span>
                  <span>체</span>
                  <span>상</span>
                  <span>금</span>
                  <span>율</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4">{worklist?.compensationOfDeferment}%</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>물</span>
                  <span>가</span>
                  <span>변</span>
                  <span>동</span>
                  <span>계</span>
                  <span>약</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4"></td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>금</span>
                  <span>액</span>
                  <span>조</span>
                  <span>정</span>
                  <span>방</span>
                  <span>법</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4"></td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>착</span>
                  <span>공</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4">
                {
                  moment(worklist?.beginDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.beginDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>준</span>
                  <span>공</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4">
                {
                  moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.completionDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">
                <span style={{ width: "90px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>기</span>
                  <span>타</span>
                  <span>사</span>
                  <span>항</span>
                </span>
              </td>
              <td style={{ paddingLeft: "5px" }} colspan="4"></td>
            </tr>

            <tr>
              <td style={{ paddingLeft: "5px" }} colspan="7" height="25px">하자담보책임 (복합공종의 경우 공종별 구분 기재)</td>
            </tr>

            <tr>
              <td style={{ textAlign: "center" }} colspan="2" height="25px">공　　　종</td>
              <td style={{ textAlign: "center" }} colspan="2">공종별 계약금액</td>
              <td style={{ textAlign: "center" }} colspan="2">하자보수보증금율(%) 및 금액</td>
              <td style={{ textAlign: "center" }}>하자담보책임기간</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" className="nonLine" height="25px">{worklist?.typeOfWork}</td>
              <td style={{ textAlign: "center" }} colspan="2" rowspan="2">￦{props.commaContractPrice}</td>
              <td style={{ textAlign: "center", borderRight: "hidden" }} rowspan="2">({worklist?.contractRepairRate})% 금</td>
              <td style={{ textAlign: "center" }} rowspan="2">{props.commaRepairPrice} -  원정</td>
              <td style={{ textAlign: "center" }} rowspan="2">{worklist?.contractRepairTerm}</td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} colspan="2" className="nonLine" height="25px">공사</td>
            </tr>

            <tr>
              <td style={{ lineHeight: "30px", paddingLeft: "5px", paddingRight: "5px", textIndent: "5px" }} colspan="7">계약담당공무원과 계약대상자는 상호 대등한 입장에서 붙임의 계약문서에 의하여 위의 공
                사에 대한 도급계약을 체결하고 신의에 따라 성실히 계약상의 의무를 이행할 것을 확약하며,
                연대보증인은 계약자와 연대하여 계약상의 의무를 이행할 것을 확약한다. 이계약의 증거로
                서 계약서를 작성하여 당사자가 기명날인한 후 각각 1통씩 보관한다.
              </td>
            </tr>

            <tr>
              <td style={{ paddingLeft: "5px" }} colspan="2" className="nonLine" height="25px">붙임서류</td>
              <td colspan="5" className="nonLine">1. 공사입찰유의서 1부</td>
            </tr>
            <tr>
              <td colspan="2" className="nonLine" height="25px"></td>
              <td colspan="5" className="nonLine">2. 공사계약일반조건 1부</td>
            </tr>
            <tr>
              <td colspan="2" className="nonLine" height="25px"></td>
              <td colspan="5" className="nonLine">3. 공사계약특수조건 1부</td>
            </tr>
            <tr>
              <td colspan="2" className="nonLine" height="25px"></td>
              <td colspan="5" className="nonLine">4. 설계서 1부</td>
            </tr>
            <tr>
              <td className="nonLine" colspan="2" height="25px"></td>
              <td className="nonLine" colspan="5">5. 산출내역서 1부</td>
            </tr>

            <tr>
              <td className="nonLine" colspan="5" height="25px"></td>
              <td className="nonLine" colspan="2">
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>

            <tr>
              <td colspan="5" className="nonLine" height="25px" style={{ textAlign: "right" }}>
                <span style={{ width: "97.17px", display: "inline-flex", justifyContent: "space-between", marginRight: "25px" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>대</span>
                  <span>상</span>
                  <span>자</span>
                </span>
                <span>{worklist?.companyName}</span>
              </td>
              <td className="nonLine" style={{ letterSpacing: "10px", textAlign: "center" }}>{worklist?.representativeName}</td>
              <td className="nonLine">(인)</td>
            </tr>

            <tr>
              <td colspan="5" className="nonLine" height="25px" style={{ textAlign: "right" }}>
                <span style={{ width: "97.17px", display: "inline-flex", justifyContent: "space-between", marginRight: "25px" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>담</span>
                  <span>당</span>
                  <span>공</span>
                  <span>무</span>
                  <span>원</span>
                </span>
                <span style={{visibility: "hidden"}}>{worklist?.companyName}</span>
              </td>
              <td className="nonLine"></td>
              <td className="nonLine">(인)</td>
            </tr>

          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default StandardContract;