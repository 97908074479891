import { useEffect, useRef, useState } from "react";

interface appProps {
  onClose: (
    title?: string,
    estimate?: boolean,
    tempRow?: boolean,
    copy?: boolean
  ) => void;
  estimate: boolean;
  copy?: boolean;
  open: boolean;
  tempRow: boolean;
  isMoveWarning?: boolean;
  defaultValue?: string;
}

export default (props: appProps) => {
  console.log(props);
  const [title, setTitle] = useState(props.defaultValue);

  useEffect(() => {
    setTitle(props.defaultValue);
  }, [props]);

  return (
    <div
      className="modal fade"
      id="project"
      aria-labelledby="projectLabel"
      style={{ display: "none" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="projectLabel">
              프로젝트에서 열기
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                프로젝트 이름을 입력해 주세요.
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleFormControlInput1"
                value={title}
                placeholder="프로젝트 이름을 입력해 주세요."
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <p>확인을 누르면 설계내역 프로젝트로 바로 이동합니다.</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-light btn-md flex-1"
              data-bs-dismiss="modal"
            >
              취소
            </button>
            <button
              type="button"
              className="btn btn-primary btn-md flex-1"
              onClick={() => {
                props.onClose(title, props.copy, props.estimate, props.tempRow);
              }}
            >
              확인(Enter)
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
