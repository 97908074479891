import React, { useState, useEffect } from "react";
import { Typography, Spin, Radio, message } from "antd";
import { Link } from "react-router-dom";
import styles from "./Dashboard.module.scss";
import { ClientDashboard } from "./ClientDashboard";
import { ExpertDashboard } from "./ExpertDashboard";
import { serviceApi } from "../../config/serviceApi";

const { Title } = Typography;

// 더미 사용자 데이터
const dummyUserData = {
  isExpert: true, // 이 값을 false로 변경하면 전문가가 닌 상태가 됩니다.
};

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState<"expert" | "client">("client");
  const [isExpert, setIsExpert] = useState(false);

  useEffect(() => {
    loadData();

    const handleServiceApplicationUpdate = () => {
      loadData();
    };

    window.addEventListener(
      "serviceApplicationUpdated",
      handleServiceApplicationUpdate
    );

    return () => {
      window.removeEventListener(
        "serviceApplicationUpdated",
        handleServiceApplicationUpdate
      );
    };
  }, []);

  const loadData = async () => {
    const res = await serviceApi.checkExpert();
    setIsExpert(res.data.is_expert);
    setLoading(false);
  };

  if (loading) {
    return <Spin size="large" />;
  }

  const handleViewModeChange = (mode: "expert" | "client") => {
    if (mode === "expert" && !isExpert) {
      message.warning("전문가로 등록되어 있지 않습니다.");
      return;
    }
    setViewMode(mode);
  };

  return (
    <div className={styles.dashboardContainer}>
      <Title level={2}>대시보드</Title>
      <Radio.Group
        onChange={(e) => handleViewModeChange(e.target.value)}
        value={viewMode}
        style={{ marginBottom: "20px" }}
      >
        <Radio.Button value="client">의뢰자 뷰</Radio.Button>
        <Radio.Button value="expert" disabled={!isExpert}>
          전문가 뷰
        </Radio.Button>
      </Radio.Group>
      {!isExpert && (
        <div style={{ marginBottom: "20px", color: "#faad14" }}>
          전문가로 등록되어 있지 않습니다. 전문가 등록을 원하시면{" "}
          <Link to="/expert-registration">여기</Link>를 클릭하세요.
        </div>
      )}
      {viewMode === "expert" ? <ExpertDashboard /> : <ClientDashboard />}
    </div>
  );
};

export default Dashboard;
