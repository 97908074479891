/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../config/api";
import "./myPage.scss";
import AlertContext from "../../contexts/AlertContext";
import { pages } from "../../constants/PagePaths";

interface Props {}

interface BoardModel {
  created_at: string;
  id: number;
  is_admin_visible: boolean;
  request_data: any;
  status: string;
  user_id: number;
}

export default () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1); // 총 페이지 수
  const [totalCount, setTotalCount] = useState<number>(0); //

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const alert = useContext(AlertContext);
  const history = useHistory();
  const [boards, setBoards] = useState<BoardModel[]>([]);
  const pageSize = 10;

  useEffect(() => {
    new Promise(async () => {
      const page = (currentPage - 1) * pageSize;
      const res = await api.getMyServiceRequestDoc(pageSize, page);
      console.log(res);
      setBoards(res.data.data);
      setTotalCount(res.data.total_count);
      let totalPage = res.data.total_count / pageSize;
      setTotalPages(totalPage < 1 ? 1 : Math.ceil(totalPage));
    });
  }, [currentPage]);

  const goDetail = (board: BoardModel) => {
    history.push({
      pathname: pages.mypage + "/contact/" + board.id,
      state: { board: board },
    });
  };

  function getSecondKeyValue(data: any) {
    const keys = Object.keys(data.request_data);
    const secondKey = keys[0];
    const secondValue = data.request_data[secondKey];

    return { key: secondKey, value: secondValue };
  }

  return (
    <section className="content" id="qna_list">
      <h3>용역 게시판</h3>
      <div className="round-box">
        <table className="table table-striped" id="qna_board">
          <thead>
            <tr>
              <th scope="col">No</th>
              <th scope="col">제목</th>
              <th scope="col">상태</th>
              <th scope="col">작성일</th>
            </tr>
          </thead>
          <tbody>
            {boards.length > 0
              ? boards.map((board, index) => {
                  const num = (currentPage - 1) * pageSize;
                  const rownum = totalCount - num - index;
                  return (
                    <tr>
                      <td>{rownum}</td>
                      <td className="overflow">
                        <a onClick={() => goDetail(board)}>
                          {getSecondKeyValue(board).key}
                        </a>
                      </td>
                      <td>
                        <span
                          className={
                            board.status == "처리 완료"
                              ? "badge text-bg-primary"
                              : "badge text-bg-primary-soft"
                          }
                        >
                          {board.status}
                        </span>
                      </td>
                      <td className="date">{board.created_at}</td>
                    </tr>
                  );
                })
              : ""}
          </tbody>
        </table>
        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2 ms-auto mt-5">
          <Link
            to={pages.contact}
            className="btn btn-lg w-100 btn-primary-soft"
          >
            <span className="material-symbols-outlined me-2">edit</span>
            의뢰하기
          </Link>
        </div>

        <div className="col-md-8 col-lg-9 col-xl-5 mx-auto mt-5 mb-3">
          <div className="paging">
            <a
              href="#"
              id="prev"
              onClick={handlePrevClick}
              className={currentPage === 1 ? "disabled" : ""}
            >
              <span className="material-symbols-outlined">chevron_left</span>
            </a>
            {[...Array(totalPages)].map((_, index) => (
              <a
                href="#"
                key={index + 1}
                className={currentPage === index + 1 ? "active" : ""}
                onClick={() => handlePageClick(index + 1)}
              >
                {index + 1}
              </a>
            ))}
            <a
              href="#"
              id="next"
              onClick={handleNextClick}
              className={currentPage === totalPages ? "disabled" : ""}
            >
              <span className="material-symbols-outlined">chevron_right</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
