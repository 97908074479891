import React, { createContext, useState, useEffect, useContext } from "react";
import { Project, Task } from "../types/project";

interface ProjectContextType {
  projects: Project[];
  updateProjectProgress: (projectId: number, newProgress: number) => void;
  addTask: (projectId: number, task: Task) => void;
  approveTask: (projectId: number, taskId: number) => void;
  updateTask: (
    projectId: number,
    taskId: number,
    updatedTask: Partial<Task>
  ) => void;
  loading: boolean;
  refreshProjects: () => void;
}

const ProjectContext = createContext<ProjectContextType | undefined>(undefined);

export const ProjectProvider: React.FC = ({ children }) => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);

  const loadProjects = () => {
    const storedProjects = localStorage.getItem("projects");
    if (storedProjects) {
      setProjects(JSON.parse(storedProjects));
    } else {
      const dummyProjects: Project[] = [
        {
          id: 1,
          title: "서울 아파트 설계 프로젝트",
          description: "서울 강남구 신축 아파트 단지 설계 프로젝트입니다.",
          status: "진행 중",
          startDate: "2023-06-01",
          endDate: "2023-12-31",
          budget: 500000000,
          serviceCost: 250000000,
          progress: 0,
          client: "서울 주택 개발(주)",
          manager: "김프로",
          tasks: [],
          updates: [],
          attachments: [],
        },
      ];
      setProjects(dummyProjects);
      localStorage.setItem("projects", JSON.stringify(dummyProjects));
    }
    setLoading(false);
    console.log("Projects loaded:", projects); // 디버깅 로그
  };

  useEffect(() => {
    loadProjects();
  }, []);

  const updateProjectProgress = (projectId: number, newProgress: number) => {
    setProjects((prevProjects) => {
      const newProjects = prevProjects.map((project) =>
        project.id === projectId
          ? { ...project, progress: newProgress }
          : project
      );
      localStorage.setItem("projects", JSON.stringify(newProjects));
      return newProjects;
    });
  };

  const addTask = (projectId: number, task: Task) => {
    setProjects((prevProjects) => {
      const newProjects = prevProjects.map((project) =>
        project.id === projectId
          ? { ...project, tasks: [...project.tasks, task] }
          : project
      );
      localStorage.setItem("projects", JSON.stringify(newProjects));
      return newProjects;
    });
  };

  const approveTask = (projectId: number, taskId: number) => {
    setProjects((prevProjects) => {
      const newProjects = prevProjects.map((project) =>
        project.id === projectId
          ? {
              ...project,
              tasks: project.tasks.map((task) =>
                task.id === taskId ? { ...task, status: "approved" } : task
              ),
            }
          : project
      );
      localStorage.setItem("projects", JSON.stringify(newProjects));
      return newProjects;
    });
  };

  const updateTask = (
    projectId: number,
    taskId: number,
    updatedTask: Partial<Task>
  ) => {
    setProjects((prevProjects) => {
      const newProjects = prevProjects.map((project) =>
        project.id === projectId
          ? {
              ...project,
              tasks: project.tasks.map((task) =>
                task.id === taskId ? { ...task, ...updatedTask } : task
              ),
            }
          : project
      );
      localStorage.setItem("projects", JSON.stringify(newProjects));
      return newProjects;
    });
  };

  const refreshProjects = () => {
    loadProjects();
  };

  return (
    <ProjectContext.Provider
      value={{
        projects,
        updateProjectProgress,
        addTask,
        approveTask,
        updateTask, // 새로 추가된 함수
        loading,
        refreshProjects,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjects = () => {
  const context = useContext(ProjectContext);
  if (context === undefined) {
    throw new Error("useProjects must be used within a ProjectProvider");
  }
  return context;
};
