import React,{ useState } from 'react'
import UserBoardDetailPresenter from './UserBoardDetailPresenter'
// 만들어준 interface와 더미데이터는 모두 container에서 받아서 return   
import {UserBoardDetailDummyData} from '../../constants/UserBoardDummyData'
import {UserBoardDetailModel} from '../../models/userboard/UserBoardModel'
import { useHistory } from "react-router-dom";


const UserBoardDetailContainer = () => {
  const [boardDetail,setBoardDetail] = useState<UserBoardDetailModel>(UserBoardDetailDummyData)
  const history = useHistory();

  const handleToList = ()=>{
    history.goBack()
  }
  return (
    <div>
      <UserBoardDetailPresenter 
      boardDetail={boardDetail}
      onClickList={handleToList}
      />
    </div>
   
  )
}

export default UserBoardDetailContainer