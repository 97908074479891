//userBoard 에 관련된 interface

export enum UserBoardCategory {
  "전체" = "전체",
  "고민상담" = "고민상담",
  "자료공유" = "자료공유",
  "구인구직" = "구인구직",
  "기타" = "기타",
}

export interface UserBoardDetailModel extends UserBoardListItem {
  content: string;
}

export interface UserBoardListItem {
  id: number;
  title: string;
  category: UserBoardCategory;
  // keywords?: string[];
  created: Date;
  // recommendCount?: number;
  viewCount: number;
}

