/* eslint-disable import/no-anonymous-default-export */
import { SearchOutlined } from "@ant-design/icons";
import React, { InputHTMLAttributes, useRef } from "react";
import styled from "styled-components";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  onSearch: (value: string) => void;
}

export default (props: Props) => {
  const searchInput = useRef<HTMLInputElement>(null);
  return (
    <SearchInputContainer
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          props.onSearch(searchInput?.current?.value || "");
        }
      }}
    >
      <Input {...props} ref={searchInput} placeholder={props.placeholder}/>
      <Icon onClick={() => props.onSearch(searchInput?.current?.value || "")}>
        <SearchOutlined />
      </Icon>
    </SearchInputContainer>
  );
};

const SearchInputContainer = styled.div`
  width: 323px;
  display: flex;
  height: 39px;
`;

const Input = styled.input`
  width: 277px;
  height: 100%;
  border-radius: 13px 0 0 13px !important;
  background-color: white;
  outline: none;
  border: 1px solid lightgrey;
  padding-left: 15px;
`;

const Icon = styled.div`
  width: 46px;
  height: 100%;
  cursor: pointer;
  background-color: var(--color-primary);
  border-radius: 0 13px 13px 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  path {
    color: white;
  }
`;
