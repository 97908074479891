/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { pages } from "../../constants/PagePaths";
import { passwordRegex } from "../../constants/RegexData";
import AlertContext from "../../contexts/AlertContext";
import DupLoginContext from "../../contexts/DupLoginContext";
import MyPageHeader from "../myPage/MyPageHeader";
import ChangePasswordPresenter from "./ChangePasswordPresenter";

interface Props {}

export default (props: Props) => {
  const [id, setId] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [isCertified, setIsCertified] = useState(false);

  const alert = useContext(AlertContext);
  const history = useHistory();
  const dupLogin = useContext(DupLoginContext);

  const pwInputRef = useRef<HTMLInputElement>(null);
  const changePwInputRef1 = useRef<HTMLInputElement>(null);
  const changePwInputRef2 = useRef<HTMLInputElement>(null);

  useEffect(() => {
    new Promise(async () => {
      const res = await dupLogin.checkDupLogin(api.getMyInfo);
      if (res?.status === 200) {
        setId(res.data.email);
        const remainDays = Math.ceil(
          (new Date(res.data.expiry_date).getTime() - new Date().getTime()) /
            (1000 * 3600 * 24)
        );
        if (remainDays > 0) {
          setExpiryDate(res.data.expiry_date.toString());
        }
      }
    });
  }, []);

  const handleCertifyClick = async (pw?: string): Promise<boolean> => {
    try {
      const res = await dupLogin.checkDupLogin(() =>
        api.checkPasswordAsync(pw || "")
      );
      if (res?.data?.success) {
        setIsCertified(true);
        return true;
      } else {
        if (res?.response?.status === 401) {
        } else {
          alert.showAlert("아이디/비밀번호가 맞지 않습니다.");
        }
      }
    } catch (error: any) {
      if (error.hasOwnProperty("response")) {
        const status = error.response?.status;
        if (status === 400) {
          alert.showAlert("아이디/비밀번호가 맞지 않습니다.");
        } else {
          alert.showAlert("에러가 발생하였습니다.");
        }
      }
      return false;
    }
    return false;
  };

  const handleChangePasswordClick = async (pw1: string, pw2: string) => {
    if (!passwordRegex.test(pw1)) {
      alert.showAlert("비밀번호 양식을 맞춰주세요.");
      return;
    }

    if (pw1 != "" && pw1 === pw2) {
      const res = await dupLogin.checkDupLogin(() =>
        api.changePasswordAsync(pw1)
      );
      if (res?.status === 200) {
        alert.showAlert("변경이 완료되었습니다.", () => {
          history.push(pages.mypage);
        });
      } else {
        if (res?.status === 400) {
          alert.showAlert(res.data.new_password2.find(Boolean));
        }
      }
    } else {
      alert.showAlert("변경할 비밀번호가 일치하지 않습니다.");
    }
  };

  return (
    <section className="content">
      <h3>비밀번호 변경</h3>
      <div className="round-box">
        <div className="infoBox-highlight mb-4 mt-3">
          <span className="material-symbols-outlined">info</span>
          <div className="fs-sm">
            <strong>{id}</strong>의 비밀번호를 변경합니다.
          </div>
        </div>
        <form className="row col-md-6 mx-auto g-3 needs-validation">
          <div className="col-md-12">
            <label htmlFor="Inputname" className="form-label">
              현재 비밀번호
            </label>
            <input
              type="password"
              className="form-control"
              id="Inputname"
              placeholder="사용중인 비밀번호를 입력해주세요"
              required
              ref={pwInputRef}
            />
            <div className="invalid-feedback">
              사용중인 비밀번호를 입력해주세요.
            </div>
          </div>
          <div className="col-md-12">
            <label htmlFor="Inputname" className="form-label">
              변경할 비밀번호
            </label>
            <input
              type="password"
              className="form-control"
              id="Inputname"
              placeholder="영문/숫자/특수문자 조합으로 8~15자리"
              required
              ref={changePwInputRef1}
            />
            <div className="invalid-feedback">
              변경할 비밀번호를 입력해주세요.
            </div>
          </div>
          <div className="col-md-12">
            <label htmlFor="Inputname" className="form-label">
              변경할 비밀번호 확인
            </label>
            <input
              type="password"
              className="form-control"
              id="Inputname"
              placeholder="변경할 비밀번호를 다시 한번 입력해주세요"
              required
              ref={changePwInputRef2}
            />
            <div className="invalid-feedback">
              변경할 비밀번호를 다시 한번 입력해주세요.
            </div>
          </div>
          <div className="col-12 col-md-6 mx-auto pb-3">
            <button
              className="btn btn-xlg btn-primary w-100"
              type="button"
              onClick={async () => {
                const isOk: boolean = await handleCertifyClick(
                  pwInputRef.current?.value || ""
                );
                if (isOk) {
                  handleChangePasswordClick(
                    changePwInputRef1.current?.value || "",
                    changePwInputRef2.current?.value || ""
                  );
                }
              }}
            >
              비밀번호 변경
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};
