import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, Card, Space, Button, List, Divider, message, Modal, Input, Spin } from 'antd';
import { LikeOutlined, LikeFilled, MessageOutlined, ArrowLeftOutlined, FlagOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './PostDetail.module.scss';
import CommentForm from './CommentForm';
import { api } from '../../config/api';
import PostForm from './PostForm';
import { Post } from '../../models/communityModel';
import { pages } from '../../constants/PagePaths';

const { Title, Text } = Typography;

const PostDetail: React.FC = () => {
  const { id, category } = useParams<{ id: string; category: string }>();
  const history = useHistory();
  const [post, setPost] = useState<Post | null>(null);
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<number | null>(null);
  const [editingContent, setEditingContent] = useState('');

  const handleBack = useCallback(() => {
    history.push(`${pages.community}/${category}`);
  }, [history, category]);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await api.getCommunityPostDetail(parseInt(id));
        setPost(response);
        setIsAuthor(response.isAuthor);
      } catch (error) {
        console.error('게시글을 불러오는 데 실패했습니다:', error);
        message.error('게시글을 찾을 수 없습니다.');
        handleBack();
      }
    };

    fetchPost();
  }, [id, handleBack]);

  if (!post) {
    return (
      <div className={styles.loadingContainer}>
        <Spin size="large" tip="게시글을 불러오는 중..." />
      </div>
    );
  }

  const handleLike = async () => {
    try {
      await api.toggleCommunityPostLike(post.id);
      setPost(prevPost => {
        if (!prevPost) return null;
        const newLikeCount = prevPost.isLiked ? prevPost.likes - 1 : prevPost.likes + 1;
        return {
          ...prevPost,
          likes: newLikeCount,
          isLiked: !prevPost.isLiked
        };
      });
    } catch (error) {
      console.error('좋아요 처리 중 오류 발생:', error);
      if ((error as any).response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error('좋아요 처리에 실패했습니다.');
      }
    }
  };

  const handleAddComment = async (content: string) => {
    setIsSubmittingComment(true);
    try {
      const newComment = await api.createCommunityComment(post.id, { content });
      setPost(prevPost => prevPost ? {...prevPost, comments: [...prevPost.comments, newComment]} : null);
      message.success('댓글이 등록되었습니다!');
    } catch (error) {
      console.error('댓글 등록 중 오류 발생:', error);
      if ((error as any).response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error('댓글 등록에 실패했습니다.');
      }
    } finally {
      setIsSubmittingComment(false);
    }
  };

  const handleReport = () => {
    if (isAuthor) {
      message.error('자신의 게시글은 신고할 수 없습니다.');
      return;
    }
    setIsReportModalVisible(true);
  };

  const handleReportSubmit = async () => {
    if (isAuthor) {
      message.error('자신의 게시글은 신고할 수 없습니다.');
      return;
    }

    if (!reportReason.trim()) {
      message.error('신고 사유를 입력해주세요.');
      return;
    }

    try {
      await api.reportCommunityPost(post.id, { reason: reportReason });
      message.success('게시글이 신고되었습니다.');
      setIsReportModalVisible(false);
      setReportReason('');
    } catch (error: any) {
      console.error('게시글 신고 중 오류 발생:', error);
      if (error.response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error(error.response?.data?.detail || '신고 처리 중 오류가 발생했습니다.');
      }
    }
  };

  const handleDelete = () => {
    if (!isAuthor) {
      message.error('자신이 작성한 게시글만 삭제할 수 있습니다.');
      return;
    }

    Modal.confirm({
      title: '게시글 삭제',
      content: '정말로 이 게시글을 삭제하시겠습니까?',
      onOk: async () => {
        try {
          await api.deleteCommunityPost(post.id);
          message.success('게시글이 삭제되었습니다.');
          history.replace(`${pages.community}/${category}`);
        } catch (error) {
          console.error('게시글 삭제 중 오류 발생:', error);
          message.error('게시글 삭제에 실패했습니다.');
        }
      },
    });
  };

  const handleEdit = () => {
    if (!isAuthor) {
      message.error('자신이 작성한 게시글만 수정할 수 있습니다.');
      return;
    }
    setIsEditModalVisible(true);
  };

  const handleEditSubmit = async (updatedPost: Post) => {
    try {
      const response = await api.updateCommunityPost(post.id, updatedPost);
      setPost(response);
      setIsEditModalVisible(false);
      message.success('게시글이 수정되었습니다.');
    } catch (error) {
      console.error('게시글 수정 중 오류 발생:', error);
      message.error('게시글 수정에 실패했습니다.');
    }
  };

  const handleDownload = (url: string, filename: string) => {
    // 실제 환경에서는 서버로부터 파일을 다운로드 받아야 합니다.
    // 여기서 간단히 새 탭에서 파일을 열도록 합니다.
    window.open(url, '_blank');
    message.success(`${filename} 다운로드를 시작합니다.`);
  };

  const getFileName = (url: string) => {
    // URL에서 파일 이름 추출
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  const handleEditComment = async (commentId: number, content: string) => {
    try {
      const updatedComment = await api.updateCommunityComment(post.id, commentId, content);
      setPost(prevPost => {
        if (!prevPost) return null;
        return {
          ...prevPost,
          comments: prevPost.comments.map(comment =>
            comment.id === commentId ? { ...updatedComment, isAuthor: true } : comment
          )
        };
      });
      setEditingCommentId(null);
      message.success('댓글이 수정되었습니다.');
    } catch (error) {
      console.error('댓글 수정 중 오류 발생:', error);
      if ((error as any).response?.status === 401) {
        message.error('로그인이 필요합니다.');
      } else {
        message.error('댓글 수정에 실패했습니다.');
      }
    }
  };

  const handleDeleteComment = async (commentId: number) => {
    Modal.confirm({
      title: '댓글 삭제',
      content: '정말로 이 댓글을 삭제하시겠습니까?',
      onOk: async () => {
        try {
          await api.deleteCommunityComment(post.id, commentId);
          setPost(prevPost => {
            if (!prevPost) return null;
            return {
              ...prevPost,
              comments: prevPost.comments.filter(comment => comment.id !== commentId)
            };
          });
          message.success('댓글이 삭제되었습니다.');
        } catch (error) {
          console.error('댓글 삭제 중 오류 발생:', error);
          if ((error as any).response?.status === 401) {
            message.error('로그인이 필요합니다.');
          } else {
            message.error('댓글 삭제에 실패했습니다.');
          }
        }
      }
    });
  };

  return (
    <div className={styles.postDetailContainer}>
      <Button 
        icon={<ArrowLeftOutlined />} 
        onClick={handleBack} 
        className={styles.backButton}
      >
        목록으로 돌아가기
      </Button>
      <Card className={styles.postDetailCard}>
        <Title level={2}>{post.title}</Title>
        <div className={styles.postHeader}>
          <Text strong>{post.author}</Text>
          <Text type="secondary">{new Date(post.createdAt).format("yyyy-MM-dd HH:mm")}</Text>
        </div>
        <div 
          className={styles.postContent}
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
        {post.files && post.files.length > 0 && (
          <div className={styles.attachments}>
            <Text strong>첨부 파일:</Text>
            <List
              itemLayout="horizontal"
              dataSource={post.files}
              renderItem={(attachment, index) => (
                <List.Item>
                  <a 
                    onClick={() => handleDownload(attachment.fileUrl, attachment.fileName)}
                    className={styles.attachmentLink}
                  >
                    {attachment.fileName}
                  </a>
                </List.Item>
              )}
            />
          </div>
        )}
        <div className={styles.postFooter}>
          <Space>
            <Button 
              icon={post.isLiked ? <LikeFilled className={styles.likeIcon} /> : <LikeOutlined className={styles.likeIcon} />}
              onClick={handleLike}
              className={`${styles.iconButton} ${post.isLiked ? styles.liked : ''}`}
            >
              좋아요 {post.likes}
            </Button>
            {!isAuthor && (
              <Button 
                icon={<FlagOutlined />} 
                onClick={handleReport}
                className={styles.iconButton}
              >
                신고
              </Button>
            )}
            {isAuthor && (
              <>
                <Button 
                  icon={<EditOutlined />} 
                  onClick={handleEdit}
                  className={styles.iconButton}
                >
                  수정
                </Button>
                <Button 
                  icon={<DeleteOutlined />} 
                  onClick={handleDelete}
                  className={styles.iconButton}
                  danger
                >
                  삭제
                </Button>
              </>
            )}
          </Space>
        </div>
      </Card>

      <Divider orientation="left">댓글</Divider>
      
      <List
        itemLayout="horizontal"
        dataSource={post.comments}
        renderItem={comment => (
          <List.Item
            actions={comment.isAuthor ? [
              <Space key="comment-actions">
                {editingCommentId === comment.id ? (
                  <>
                    <Button 
                      size="small" 
                      type="primary"
                      onClick={() => handleEditComment(comment.id, editingContent)}
                    >
                      저장
                    </Button>
                    <Button 
                      size="small"
                      onClick={() => setEditingCommentId(null)}
                    >
                      취소
                    </Button>
                  </>
                ) : (
                  <>
                    <Button 
                      size="small"
                      onClick={() => {
                        setEditingCommentId(comment.id);
                        setEditingContent(comment.content);
                      }}
                    >
                      수정
                    </Button>
                    <Button 
                      size="small" 
                      danger
                      onClick={() => handleDeleteComment(comment.id)}
                    >
                      삭제
                    </Button>
                  </>
                )}
              </Space>
            ] : []}
          >
            <List.Item.Meta
              title={<Text strong>{comment.author}</Text>}
              description={
                <>
                  <div className={styles.commentContent}>
                    {editingCommentId === comment.id ? (
                      <Input.TextArea
                        value={editingContent}
                        onChange={(e) => setEditingContent(e.target.value)}
                        autoSize
                      />
                    ) : (
                      comment.content
                    )}
                  </div>
                  <Text type="secondary">
                    {new Date(comment.createdAt).format("yyyy-MM-dd HH:mm")}
                  </Text>
                </>
              }
            />
          </List.Item>
        )}
      />

      <Divider orientation="left">댓글 작성</Divider>
      <CommentForm onSubmit={handleAddComment} isSubmitting={isSubmittingComment} />
      
      <Modal
        title="게시글 신고"
        visible={isReportModalVisible}
        onOk={handleReportSubmit}
        onCancel={() => setIsReportModalVisible(false)}
        zIndex={1060} // 이 줄을 추가합니. 값은 헤더의 z-index보다 높아야 합니다.
      >
        <Input.TextArea
          rows={4}
          placeholder="신고 사유를 입력해주세요"
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
        />
      </Modal>
      
      <Modal
        title="게시글 수정"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
        width={800}
        zIndex={1060}
      >
        <PostForm 
          onSubmit={handleEditSubmit} 
          initialValues={post}
          isEditing={true}
          category={parseInt(category)}
        />
      </Modal>
    </div>
  );
};

export default PostDetail;
