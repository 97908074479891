/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { pages, videoPages } from "../../constants/PagePaths";
import "./myPage.scss";
import { videoApi } from "../../config/videoApi";
import { api } from "../../config/api";
import AlertContext from "../../contexts/AlertContext";

interface Props {}

export default () => {
  const alertContext = useContext(AlertContext);
  const [isLogin, setIsLogin] = useState(true);
  const [myPurchase, setMyPurchase] = useState([]);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const inputStarRef = useRef<any>(null);
  const inputReviewRef = useRef<any>(null);
  const history = useHistory();
  const alreadyWriteReviewRef = useRef<any>(null);
  const [alreadyWriteReviewRating, setAlreadyWriteReviewRating] = useState(0);
  const [state, setState] = useState(0);

  useEffect(() => {
    new Promise(async () => {
      if (isLogin) {
        const myDataRes = await videoApi.getMyCourseAsync();
        const purchaseData = await (
          await videoApi.getMyCourseAsync()
        ).data.user_video_purchases;
        // console.log(myDataRes);
        // console.log(purchaseData);

        setMyPurchase(purchaseData);
      }
    });
    var $star_rating: any = $(".star-rating .fa");

    var SetRatingStar = function () {
      return $star_rating.each(function () {
        if (
          parseInt($star_rating.siblings("input.rating-value").val()) >=
          parseInt($(this).data("rating"))
        ) {
          return $(this).removeClass("fa-star-o").addClass("fa-star");
        } else {
          return $(this).removeClass("fa-star").addClass("fa-star-o");
        }
      });
    };

    $star_rating.on("click", function (e) {
      if (!e.target.parentNode.classList.value.includes("disabled")) {
        $star_rating.siblings("input.rating-value").val($(this).data("rating"));
        return SetRatingStar();
      }
    });

    // SetRatingStar();
  }, [state]);

  const refresh = () => {
    setState(state + 1);
  };

  const writeReview = async () => {
    if (inputStarRef.current && inputReviewRef.current) {
      const rating = inputStarRef.current.value;
      // selectedVideoId;
      const review = inputReviewRef.current.value;
      if (review.length < 1) {
        alertContext.showAlert("리뷰 내용을 입력해주세요.");
        return;
      }
      if (rating > 5 || rating < 1) {
        alertContext.showAlert("리뷰 점수를 확인해주세요.");
        return;
      }
      const res = await videoApi.postReview(
        selectedVideoId!,
        review,
        parseInt(rating)
      );
      if (res.data.code === 1) {
        alertContext.showAlert("리뷰가 등록되었습니다.", () => {
          $("#reviewModal").modal("hide");
        });
      } else {
        alertContext.showAlert("등록에 실패하였습니다.", () => {
          inputReviewRef.current.value = "";
          inputStarRef.current.value = 1;
          $("#reviewModal").modal("hide");
          // window.location.reload();
        });
      }
      refresh();
    }
  };

  // 현재 날짜를 yyyy-mm-dd 형식으로 가져오는 함수
  function getCurrentDateString() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // 월은 0부터 시작하므로 1을 더합니다.
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  // 날짜 문자열을 비교하는 함수
  function isExpired(dateString: string) {
    const today = new Date(getCurrentDateString());
    const inputDate = new Date(dateString);

    return inputDate < today;
  }

  return (
    <>
      <section className="content">
        <h3>나의 강의장</h3>
        <div
          className="video-list aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {myPurchase.map((course: any) => {
            return (
              <div
                className={
                  isExpired(course.expire_date)
                    ? "video-list-item list-disabled bg-white"
                    : "video-list-item bg-white"
                }
                key={course.id}
              >
                <a>
                  <div
                    className="img-wrap"
                    onClick={() => {
                      history.push(`${videoPages.curriculum}/${course.id}`);
                    }}
                  >
                    <img src={course.card_image} />
                  </div>
                  <div className="flex-2">
                    <div className="title-lg">{course.title}</div>
                    <div className="caption">{course.description}</div>
                    <div className="border-1"></div>
                    <div className="list-style-sm">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <div className="title">시작일</div>
                          <div className="body">{course.started_at}</div>
                        </li>
                        <li className="list-group-item">
                          <div className="title">종료일</div>
                          <div className="body">
                            <div className="body text-danger">
                              {course.expire_date}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {isExpired(course.expire_date) ? (
                      <button
                        type="button"
                        className="btn btn-md btn-primary-soft w-100"
                        onClick={() => {
                          history.push(
                            videoPages.course + "/" + course.last_viewed_video
                          );
                        }}
                      >
                        종료된 강의
                      </button>
                    ) : course.last_viewed_video ? (
                      <button
                        type="button"
                        className="btn btn-md btn-primary-soft w-100"
                        onClick={() => {
                          history.push(
                            videoPages.course + "/" + course.last_viewed_video
                          );
                        }}
                      >
                        이어서 듣기
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-md btn-primary w-100"
                        onClick={() => {
                          history.push(videoPages.curriculum + "/" + course.id);
                        }}
                      >
                        시작하기
                      </button>
                    )}
                  </div>
                </a>
                <div className="review-btn-wrap">
                  {course.review ? (
                    <button
                      type="button"
                      className="btn btn-md btn-outline review-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#reviewDetailModal"
                      onClick={() => {
                        alreadyWriteReviewRef.current.value =
                          course.review.review;
                        setAlreadyWriteReviewRating(course.review.rating);
                      }}
                    >
                      내가 쓴 리뷰 확인하기
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-md btn-outline review-btn"
                      data-bs-toggle="modal"
                      data-bs-target="#reviewModal"
                      onClick={() => {
                        setSelectedVideoId(course.id);
                      }}
                    >
                      리뷰쓰기
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {myPurchase.length == 0 ? (
          <div
            className="section-empty aos-init"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <img src="/images/ddusul/3d-danger.png" />
            <p>내용이 없습니다.</p>
          </div>
        ) : (
          ""
        )}
      </section>
      <div
        className="modal fade"
        id="reviewDetailModal"
        tabindex="-1"
        aria-labelledby="reviewDetailModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="reviewDetailModalLabel">
                강의는 어떠셨나요?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label className="fw-bold">평점</label>
              <div className="star-rating mb-4 disabled">
                {Array.from({ length: 5 }).map((_, index) => (
                  <span
                    className={
                      index + 1 > alreadyWriteReviewRating
                        ? "fa fa-star-o"
                        : "fa fa-star"
                    }
                    data-rating={index + 1}
                  ></span>
                ))}
                <input
                  type="hidden"
                  name="whatever1"
                  className="rating-value"
                  readOnly
                  value={alreadyWriteReviewRating}
                />
              </div>
              <label className="fw-bold">내용</label>
              <textarea
                className="form-control"
                aria-label="With textarea"
                ref={alreadyWriteReviewRef}
                readOnly
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="reviewModal"
        tabIndex={-1}
        aria-labelledby="reviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="reviewModalLabel">
                강의는 어떠셨나요?
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label className="fw-bold">평점</label>
              <div className="star-rating mb-4">
                <span className="fa fa-star" data-rating="1"></span>
                <span className="fa fa-star" data-rating="2"></span>
                <span className="fa fa-star" data-rating="3"></span>
                <span className="fa fa-star" data-rating="4"></span>
                <span className="fa fa-star" data-rating="5"></span>
                <input
                  type="hidden"
                  name="whatever1"
                  className="rating-value"
                  value="5"
                  ref={inputStarRef}
                />
              </div>
              <label className="fw-bold">내용</label>
              <textarea
                className="form-control"
                aria-label="With textarea"
                ref={inputReviewRef}
              ></textarea>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  writeReview();
                }}
              >
                작성완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
