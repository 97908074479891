import { Videos } from "../../../models/LectureDataModel";
import Video from "../Video";

export default (props: {
  index: number;
  title: string;
  videos: Videos[];
  videoId: string;
  videoClick: (video: any) => void;
}) => {
  const target = `collapse${props.index}`;
  const isInVideo =
    props.videos.filter((e) => e.id == parseInt(props.videoId)).length > 0;

  return (
    <div className="accordion edu-list-collapse" id="curriculum-one">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={
              isInVideo ? "accordion-button" : "accordion-button collapsed"
            }
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={"#" + target}
            aria-expanded="false"
            aria-controls={target}
          >
            <div className="d-flex gap-3">
              <span className="circle-number">{props.index}</span>
              <div className="title_">
                <span className="fw-bold">{props.title}</span>
                <div className="view-num">
                  <span className="active">{props.videos.length}</span>
                  <span>개 강의</span>
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id={target}
          className={
            isInVideo
              ? "accordion-collapse collapse show"
              : "accordion-collapse collapse"
          }
          aria-labelledby="headingOne"
          data-bs-parent="#curriculum-one"
        >
          <div className="accordion-body">
            <ul className="edu-cr">
              {props.videos.map((video, index) => {
                return (
                  <li>
                    <a
                      className="btn"
                      onClick={() => {
                        props.videoClick(video);
                      }}
                    >
                      <div className="title" style={{ fontSize: 14, textAlign: "left" }}>
                        <div className="check-circle">
                          <span className="material-symbols-outlined">
                            {video.id + "" == props.videoId
                              ? "check_circle"
                              : "play_circle"}
                          </span>
                        </div>
                        <span>{video.title}</span>
                      </div>
                      <span className="time">
                        {video.is_free ? "무료강의" : ""}
                      </span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
