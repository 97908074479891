export default (props: { handleOk3: any; company?: any }) => {
  return (
    <form onSubmit={props.handleOk3}>
      <div
        className="modal fade"
        id="addCompany"
        aria-labelledby={props.company ? "editCompanyLabel" : "addCompanyLabel"}
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addCompanyLabel">
                업체정보 {props.company ? "수정" : "추가"}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-content">
                <div className="input-item">
                  <div style={{ flex: 2 }}>업체명</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="업체명을 입력해주세요"
                      aria-label="project-name"
                      aria-describedby="basic-addon1"
                      name="companyName"
                      defaultValue={props.company?.companyName}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>대표자명</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="대표자명을 입력해주세요"
                      aria-label="company-name"
                      aria-describedby="basic-addon1"
                      name="representativeName"
                      defaultValue={props.company?.representativeName}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>사업자등록번호</div>
                  <div className="contents">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="사업자등록번호를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="corporateRegistNum"
                      defaultValue={props.company?.corporateRegistNum}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>법인번호</div>
                  <div className="contents">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="법인번호를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="corporateNum"
                      defaultValue={props.company?.corporateNum}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>업체주소</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="업체주소를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="businessAddress"
                      defaultValue={props.company?.businessAddress}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>업종</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="업종을 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="typeOfBusiness"
                      defaultValue={props.company?.typeOfBusiness}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>전화번호</div>
                  <div className="contents">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="전화번호를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="phoneNum"
                      defaultValue={props.company?.phoneNum}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>팩스번호</div>
                  <div className="contents">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="팩스번호를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="faxNum"
                      defaultValue={props.company?.faxNum}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>작성자</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="은행명을 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="writer"
                      defaultValue={props.company?.writer}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>은행명</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="은행명을 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="bankName"
                      defaultValue={props.company?.bankName}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>계좌번호</div>
                  <div className="contents">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="계좌번호를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="accountNum"
                      defaultValue={props.company?.accountNum}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>예금주</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="예금주를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="accountHolder"
                      defaultValue={props.company?.accountHolder}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>예금종류</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="예금종류를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="typeOfDeposit"
                      defaultValue={props.company?.typeOfDeposit}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div style={{ flex: 2 }}>점포명</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="점포명을 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="storeName"
                      defaultValue={props.company?.storeName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer pt-4">
              <div className="modal-footer-wrap">
                <button
                  type="button"
                  className="btn btn-light btn-lg flex-1"
                  data-bs-dismiss="modal"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg flex-2"
                  data-bs-dismiss="modal"
                >
                  <span className="material-symbols-outlined me-2">add</span>
                  {props.company ? "수정하기" : "추가하기"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
