import React, { useEffect, useState } from "react";
import {
  Steps,
  Card,
  Button,
  message,
  Typography,
  Descriptions,
  Modal,
  List,
  Rate,
} from "antd";
import { useParams, useHistory } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { serviceApi } from "../../config/serviceApi";
import { getServiceStatus, steps } from "./ServiceUtil";
import {
  Client,
  ServiceRequestModel,
} from "../../models/service/ServiceRequestModel";
import { ExpertModel } from "../../models/service/ExpertModel";

const { Step } = Steps;
const { Title, Paragraph } = Typography;

const ServiceApplicationStatus: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [application, setApplication] = useState<ServiceRequestModel | null>(
    null
  );
  const history = useHistory();

  const loadApplication = async () => {
    try {
      const res = await serviceApi.getServiceRequest(id);
      setApplication(res.data);
    } catch (e) {
      console.log(e);
      message.error("데이터를 찾을 수 없습니다.");
    }
  };

  useEffect(() => {
    loadApplication();

    const handleServiceApplicationUpdate = (event: CustomEvent) => {
      if (event.detail && event.detail.id === id) {
        setApplication(event.detail);
      } else {
        loadApplication();
      }
    };

    window.addEventListener(
      "serviceApplicationUpdated",
      handleServiceApplicationUpdate as EventListener
    );

    return () => {
      window.removeEventListener(
        "serviceApplicationUpdated",
        handleServiceApplicationUpdate as EventListener
      );
    };
  }, [id]);

  const handleSelectExpert = async (expert: ExpertModel) => {
    if (application) {
      const res = await serviceApi.selectExpert(+id, expert.id);
      if (res.status == 200) {
        message.success(`${expert.nickname} 전문가가 선택되었습니다.`);
      }
    }
  };

  const handleConsultationComplete = async () => {
    if (application) {
      const res = await serviceApi.completeCall(+id);
      if (res.status == 200) {
        await serviceApi.postProject(application.id);
        message.success("전화 상담이 완료되었습니다.");
      }
    }
  };

  const handleDeleteApplication = () => {
    Modal.confirm({
      title: "서비스 신청을 삭제하시겠습니까?",
      content: "이 작업은 되돌릴 수 없습니다.",
      async onOk() {
        const res = await serviceApi.deleteServiceRequest(+id);
        if (res.status == 204) {
          message.success("서비스 신청이 삭제되었습니다.");
          history.push("/dashboard");
        } else {
          message.success("서비스 신청 삭제에 실패했습니다.");
        }
      },
    });
  };

  if (!application) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        padding: "40px",
        backgroundColor: "#f0f2f5",
        minHeight: "100vh",
      }}
    >
      <Card
        title={<Title level={2}>서비스 신청 현황</Title>}
        style={{
          maxWidth: 1000,
          margin: "0 auto",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        }}
        extra={
          <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={handleDeleteApplication}
          >
            신청 삭제
          </Button>
        }
      >
        <Steps current={getServiceStatus(application.status).index}>
          {steps.map((item) => (
            <Step
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </Steps>

        <Descriptions title="신청 정보" style={{ marginTop: 30 }} bordered>
          <Descriptions.Item label="서비스">
            {application.service.title}
          </Descriptions.Item>
          <Descriptions.Item label="신청일">
            {new Date(application.created_at).toISOString().split("T")[0]}
          </Descriptions.Item>
          <Descriptions.Item label="완료 희망일">
            {application.desired_completion_date}
          </Descriptions.Item>
          <Descriptions.Item label="매칭된 전문가 수">
            {application.matching_experts?.length || 0}
          </Descriptions.Item>
          {application.expert && (
            <Descriptions.Item label="선택된 전문가">
              {application.expert.username}
            </Descriptions.Item>
          )}
        </Descriptions>

        {application.status === "matching" && (
          <Card title="매칭된 전문가 목록" style={{ marginTop: 20 }}>
            <List
              itemLayout="horizontal"
              dataSource={application.matching_experts}
              renderItem={(expert) => (
                <List.Item
                  actions={[
                    <Button
                      type="primary"
                      onClick={() => handleSelectExpert(expert!)}
                    >
                      선택
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <img
                        src={expert.thumbnail}
                        alt={expert.nickname}
                        style={{ width: 64, height: 64, borderRadius: "50%" }}
                      />
                    }
                    title={expert?.nickname}
                    description={
                      <>
                        <Rate disabled defaultValue={expert.rating} />
                        <span style={{ marginLeft: 10 }}>
                          {expert.rating ? expert.rating.toFixed(1) : "N/A"}
                        </span>
                        <p>
                          <strong>경력:</strong> {expert.experience}년
                        </p>
                        <p>
                          <strong>완료한 프로젝트:</strong>{" "}
                          {expert.projects.length}개
                        </p>
                        <p>
                          <strong>전문 분야:</strong>{" "}
                          {expert.specialty != null ? expert.specialty : "N/A"}
                        </p>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        )}

        {/* {application.status === "matching" &&
          application.matchedExperts.length > 0 && (
            <Card title="매칭된 전문가 목록" style={{ marginTop: 20 }}>
              <List
                itemLayout="horizontal"
                dataSource={application.matchedExperts}
                renderItem={(expert) => (
                  <List.Item
                    actions={[
                      <Button
                        type="primary"
                        onClick={() => handleSelectExpert(expert)}
                      >
                        선택
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <img
                          src={expert.thumbnail}
                          alt={expert.name}
                          style={{ width: 64, height: 64, borderRadius: "50%" }}
                        />
                      }
                      title={expert.name}
                      description={
                        <>
                          <Rate disabled defaultValue={expert.rating} />
                          <span style={{ marginLeft: 10 }}>
                            {expert.rating ? expert.rating.toFixed(1) : "N/A"}
                          </span>
                          <p>
                            <strong>경력:</strong> {expert.experience}년
                          </p>
                          <p>
                            <strong>완료한 프로젝트:</strong>{" "}
                            {expert.projects.length}개
                          </p>
                          <p>
                            <strong>전문 분야:</strong>{" "}
                            {expert.specialty != null
                              ? expert.specialty
                              : "N/A"}
                          </p>
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </Card>
          )} */}

        {application.status === "matching" && application.expert && (
          <Card title="선택된 전문가" style={{ marginTop: 20 }}>
            <List
              itemLayout="horizontal"
              dataSource={[application.expert]}
              renderItem={(expert) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <img
                        src={""}
                        alt={expert.username}
                        style={{ width: 64, height: 64, borderRadius: "50%" }}
                      />
                    }
                    title={expert.username}
                    description={
                      ""
                      // <>
                      //   <Rate disabled defaultValue={expert.rating} />
                      //   <span style={{ marginLeft: 10 }}>
                      //     {expert.rating ? expert.rating.toFixed(1) : "N/A"}
                      //   </span>
                      //   <p>
                      //     <strong>경력:</strong> {expert.experience}년
                      //   </p>
                      //   <p>
                      //     <strong>완료한 프로젝트:</strong>{" "}
                      //     {expert.projects.length}개
                      //   </p>
                      //   <p>
                      //     <strong>전문 분야:</strong>{" "}
                      //     {expert.specialty != null ? expert.specialty : "N/A"}
                      //   </p>
                      // </>
                    }
                  />
                </List.Item>
              )}
            />
            <Button
              type="primary"
              onClick={handleConsultationComplete}
              style={{ marginTop: 20 }}
            >
              전화 상담 완료
            </Button>
          </Card>
        )}

        {application.status === "consulting" && (
          <Card title="전화 상담 완료" style={{ marginTop: 20 }}>
            <Paragraph>
              전화 상담이 완료되었습니다. 전문가가 프로젝트를 생성할 때까지
              기다려주세요.
            </Paragraph>
          </Card>
        )}

        {application.status === "in_progress" && (
          <Card title="프로젝트 시작" style={{ marginTop: 20 }}>
            <Paragraph>
              전문가가 프로젝트를 생성했습니다. 이제 프로젝트 페이지에서 작업을
              시작할 수 있습니다.
            </Paragraph>
            <Button
              type="primary"
              onClick={() => history.push(`/projects/${application.id}`)}
            >
              프로젝트로 이동
            </Button>
          </Card>
        )}

        <Button
          onClick={() => history.push("/dashboard")}
          style={{ marginTop: 20 }}
        >
          대시보드로 돌아가기
        </Button>
      </Card>
    </div>
  );
};

export default ServiceApplicationStatus;
