import { ImpId } from "../../../constants/ImpData";
import {
  IamportPaymentRequestModel,
  ImpCallbackResponseModel,
  ImpCertificationRequestModel,
  ImpCertificationResponseModel,
} from "./ImpModels";

export default interface IMP {
  init(id: string): void;
  request_pay(
    data: IamportPaymentRequestModel,
    callback?: (rsp: ImpCallbackResponseModel) => void
  ): void;
  certification(data: any, callback?: (res: any) => void): void;
}

declare global {
  interface Window {
    IMP: IMP;
  }
}

export class Imp {
  private static _initialize = (() => {
    const { IMP } = window;
    IMP.init(ImpId);
  })();

  public static requestPay = (
    data: IamportPaymentRequestModel,
    callback?: (rsp: ImpCallbackResponseModel) => void
  ) => {
    const { IMP } = window;
    IMP.request_pay(data, callback);
  };

  public static certification = (
    data: ImpCertificationRequestModel,
    callback?: (rsp: ImpCertificationResponseModel) => void
    ) => {
    const { IMP } = window;
    IMP.certification(data, callback);
  }
}
