import axios from "axios";
import { Cookies } from "../constants/Cookies";
import { ImpCallbackResponseModel } from "../models/payment/iamport/ImpModels";
import GlobalEnvironments from "../modules/globalEnvironment/GlobalEnvironment";
import { util } from "./util";

export const videoApi = {
  getCourseListAsync: async () => {
    return await axios.get(`${GlobalEnvironments.getApiUrl()}video/content`);
  },
  getCourseCurriculumAsync: async (courseId: string) => {
    const config = {
      params: {
        course_id: courseId,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}video/curriculum/`,
      config
    );
  },
  getCourseLastViewedAsync: async (courseId: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        course_id: courseId,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}video/last/`,
      config
    );
  },
  getCoursePaymentInfoAsync: async (courseId: number) => {
    const config: any = {
      params: {
        course_id: courseId,
      },
    };
    // 로그인했을 경우 토큰을 헤더에 담아서 요청
    const key = util.getCookie(Cookies.authKey);
    if (key) {
      config.headers = {
        Authorization: `Token ${key}`,
      };
    }

    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}video/payment/`,
      config
    );
  },
  getCourseInfoAsync: async (videoId: string) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      params: {
        video_id: videoId,
      },
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}video/course/`,
      config
    );
  },
  getMainCardCourseAsync: async () => {
    return await axios.get(`${GlobalEnvironments.getApiUrl()}video/card`);
  },
  postCoursePaymentCompleteAsync: async (res: ImpCallbackResponseModel) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}video/payment/complete/`,
      res,
      config
    );
  },
  getVideoPaymentHistoryAsync: async (offset?: number, limit?: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
      params: {
        offset,
        limit,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}video/payment/history/`,
      config
    );
  },
  getMyCourseAsync: async () => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.get(
      `${GlobalEnvironments.getApiUrl()}video/mycourse/`,
      config
    );
  },
  postReview: async (course_id: number, content: string, rating: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.post(
      `${GlobalEnvironments.getApiUrl()}video/review/`,
      { course_id: course_id, content: content, rating: rating },
      config
    );
  },
  deleteReview: async (review_id: number) => {
    const key = util.getCookie(Cookies.authKey);
    const config = {
      headers: {
        Authorization: `Token ${key}`,
      },
    };
    return await axios.delete(
      `${GlobalEnvironments.getApiUrl()}video/review?review_id=${review_id}`,
      config
    );
  },
};
