/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import styles from "./inquiryBoard.module.scss";
interface Props {}

export default (props: Props) => {
  return (
    <div>
      Inquiry Board
    </div>
  );
}