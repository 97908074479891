import { useEffect, useState } from "react";

function CompletionInputInfo(props) {
  const worklist = props.worklist;

  //수정, 저장 버튼에 대한 동작.
  const [input, setinput] = useState(true);

  const changeInput = (e) => {
    e.preventDefault();
    if (input === false) {
      setinput(!input);
    }
  };

  //금액 자동 콤마
  const [nonContractPrice, setNonContractPrice] = useState(worklist?.contractPrice);
  const [contractPrice, setContractPrice] = useState();

  const [nonCompletionCalPrice, setNonCompletionCalPrice] = useState(worklist?.completionCalPrice);
  const [completionCalPrice, setCompletionCalPrice] = useState(worklist?.completionCalPrice);

  const [nonSafetyCost, setNonSafetyCost] = useState(worklist?.expensedSafetyStateCost);
  const [safetyCost, setSafetyCost] = useState();
  const [nonEnviCost, setNonEnviCost] = useState(worklist?.expensedEnviStateCost);
  const [enviCost, setEnviCost] = useState();

  const autoCommaContractPrice = (e) => {
    let value = e.target.value;
    setContractPrice(convertCommaValue(value));
    setNonContractPrice(Number(value.replaceAll(',', '')));
  }

  const autoCommaCompletionCalPrice = (e) => {
    let value = e.target.value;
    setCompletionCalPrice(convertCommaValue(value));
    setNonCompletionCalPrice(Number(value.replaceAll(',', '')));
  }

  const autoCommaSafetyCost = (e) => {
    let value = e.target.value;
    setSafetyCost(convertCommaValue(value));
    setNonSafetyCost(Number(value.replaceAll(',', '')));
  }

  const autoCommaEnviCost = (e) => {
    let value = e.target.value;
    setEnviCost(convertCommaValue(value));
    setNonEnviCost(Number(value.replaceAll(',', '')));
  }


  const handleSubmitAsync = async (e) => {
    let data = {};
    for (let index = 0; index < e.target.length; index++) {
      const element = e.target[index];
      data[element.name] = element.value;
    }
    data = {
      ...props.worklist,
      ...data,
    };
    e.preventDefault();
    if (input === true) {
      setinput(!input);
    }
    data.contractPrice = nonContractPrice === undefined ? worklist.contractPrice : nonContractPrice;
    data.completionCalPrice = nonCompletionCalPrice === undefined ? worklist.completionCalPrice : nonCompletionCalPrice;
    data.expensedSafetyStateCost = nonSafetyCost === undefined ? worklist.expensedSafetyStateCost : nonSafetyCost;
    data.expensedEnviStateCost = nonEnviCost === undefined ? worklist.expensedEnviStateCost : nonEnviCost;
    await props.putWorkListAsync(data);
  };

  useEffect(() => {
    setContractPrice(convertCommaValue(props.worklist?.contractPrice || ""));
    setCompletionCalPrice(convertCommaValue(props.worklist?.completionCalPrice || ""));
    setSafetyCost(convertCommaValue(props.worklist?.expensedSafetyStateCost || ""));
    setEnviCost(convertCommaValue(props.worklist?.expensedEnviStateCost || ""));
  }, [
    props.worklist?.contractPrice, 
    props.worklist?.completionCalPrice,
     props.worklist?.expensedSafetyStateCost, 
     props.worklist?.expensedEnviStateCost
    ])

  function convertCommaValue(value) {
    value = Number(value.replaceAll(',', ''));
    if (isNaN(value)) {
      return 0;
    } else {
      const formatValue = value.toLocaleString('ko-KR');
      return formatValue;
    }
  }

  return (
    <div>

      <div className="doc-container-box">
        <form name="infoForm" onSubmit={handleSubmitAsync}>
          <div className="doc-header">
            <h1>준공 정보 입력</h1>
          </div>
          <div>
            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                공사명
              </div>
              <input
                defaultValue={worklist?.constructionName}
                className="doc-submit-input"
                placeholder="ex) ㅇㅇ시 상하수도 공사"
                disabled={!input}
                name="constructionName"
                maxLength="30"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계약일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.contractDate}
                disabled={!input}
                name="contractDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                계약금액
              </div>
              <input
                value={contractPrice}
                className="doc-submit-input"
                placeholder="계약금액을 입력해주세요."
                disabled={!input}
                name="contractPrice"
                onChange={autoCommaContractPrice}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계상된 안전관리비
              </div>
              <input
                value={safetyCost}
                className="doc-submit-input"
                placeholder="계상된 안전관리비를 입력해주세요."
                disabled={!input}
                name="expensedSafetyStateCost"
                onChange={autoCommaSafetyCost}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계상된 환경보전비
              </div>
              <input
                value={enviCost}
                className="doc-submit-input"
                placeholder="계상된 환경보전비를 입력해주세요."
                disabled={!input}
                name="expensedEnviStateCost"
                onChange={autoCommaEnviCost}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                착공일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.beginDate}
                disabled={!input}
                name="beginDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                준공일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.completionDate}
                disabled={!input}
                name="completionDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                준공예정일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.completionExpectDate}
                disabled={!input}
                name="completionExpectDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                준공정산금액
              </div>
              <input
                value={completionCalPrice}
                className="doc-submit-input"
                placeholder="준공정산금액을 입력해주세요."
                disabled={!input}
                name="completionCalPrice"
                onChange={autoCommaCompletionCalPrice}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                실제 준공일
              </div>
              <input
                type="date"
                defaultValue={worklist?.realCompletionDate}
                disabled={!input}
                name="realCompletionDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                귀하
              </div>
              <input
                defaultValue={worklist?.dear}
                className="doc-submit-input"
                placeholder="ex) 김ㅇㅇ"
                disabled={!input}
                name="dear"
                maxLength="20"
              />
            </div>
          </div>

          <div className="button_container">
            <button className={`doc_button_modi ${!input ? '' : 'btn_hidden'}`} onClick={changeInput}>
              <span style={{ fontSize: "15px", color: "white" }}>수정</span>
            </button>
            <button className={`doc_submit ${input ? '' : 'btn_hidden'}`} htmlType="submit">
              <span style={{ fontSize: "15px", color: "white" }}>저장</span>
            </button>
          </div>
        </form>
      </div>
    </div >
  );
}

export default CompletionInputInfo;