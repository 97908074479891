import { useEffect, useState } from "react";

export default (props: { forwardedRef: any }) => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // 초를 증가시킴
      setSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          // 59초에서 0으로 리셋하고 분을 증가시킴
          setMinutes((prevMinutes) => prevMinutes + 1);
          return 0;
        } else {
          return prevSeconds + 1;
        }
      });
    }, 1000); // 1초마다 갱신
    return () => clearInterval(intervalId); // 컴포넌트가 언마운트되면 인터벌 해제
  }, []);

  // mm:ss 형식으로 시간 표시
  const formattedTime = `${minutes < 10 ? "0" + minutes : minutes}:${
    seconds < 10 ? "0" + seconds : seconds
  }`;

  return (
    <div className="caption1 d-flex align-items-center">
      <span className="material-symbols-outlined me-1">timer</span>
      <input type="hidden" value={formattedTime} ref={props.forwardedRef} />
      {formattedTime}
    </div>
  );
};
