import { useHistory } from "react-router-dom";

export default (props: { alarms: any[] }) => {
  const history = useHistory();

  return (
    <div
      className="modal fade"
      id="alarmModal"
      tabIndex={-1}
      aria-labelledby="alarmModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="alarmModalLabel">
              알람
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="list-style-one">
              <ol className="list-group">
                {props.alarms.map((item, index) => {
                  return (
                    <li
                      className="list-group-item d-flex justify-content-between align-items-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push(item.link);
                      }}
                      key={index}
                    >
                      <div className="me-auto">
                        <div className="text-dark fw-bold">{item.type}</div>
                        <div className="caption1">{item.text}</div>
                      </div>
                      <span className="badge bg-primary rounded-pill">1</span>
                    </li>
                  );
                })}
              </ol>
            </div>
            {props.alarms.length === 0 ? (
              <div className="empty-notice my-4">
                읽지 않은 알람이 없습니다.
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
