import { Checkbox, Col, Row, Button } from "antd";
import { Link } from "react-router-dom";
import "./index.css";

function DocList() {
  var checkDoc = [];

  const onChange = (checkedValues) => {
    checkDoc = checkedValues;
  };

  const docListOnClick = () => {};

  return (
    <div>
      <div id="doc-header">
        <h1>서류 목록</h1>
        <h2 style={{ color: "#3498db" }}>필요 서류를 선택해 주세요!</h2>
      </div>
      <div>
        <Checkbox.Group style={{ width: "100%" }} onChange={onChange}>
          <div>
            <Row>
              <Col className="doc-card">
                <Checkbox value="계약서류">
                  <span className="doc-title">계약서류</span>
                  <span className="doc-detail">
                    공문/준공계/준공검사원/준공후사진/안전관리비사용내역/환경보전비사용내역
                  </span>
                </Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="착공계">착공계</Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="준공계">준공계</Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="준공금청구">준공금청구</Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="선금급신청서">선금급신청서</Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="선금급청구서">선금급청구서</Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="기성검사">기성검사</Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="기성대금신청">기성대금신청</Checkbox>
              </Col>
              <Col className="doc-card">
                <Checkbox value="선금급정산확인서">선금급정산확인서</Checkbox>
              </Col>
            </Row>
          </div>
        </Checkbox.Group>

        <Link to="/documents">
          <Button className="docListBtn" onClick={docListOnClick}>
            다음
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default DocList;
