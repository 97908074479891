import { SampleStatementListItem } from "../models/SampleStatementItem";

export enum SampleStatementMainType {
  "전체" = "전체",
  "토목" = "토목",
  "조경" = "조경",
  "건축" = "건축",
  "기타" = "기타",
}

export interface SampleStatementDetailModel extends SampleStatementListItem {
  products: {
    name: string;
    standard: string;
    unit: string;
    type?: string;
  }[];
  downloadUrls: string[];
  description: string;
}