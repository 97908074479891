/* eslint-disable import/no-anonymous-default-export */
import _, { parseInt } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "../../config/api";
import { pages } from "../../constants/PagePaths";
import LazyEvent from "../../modules/LazyEvent";
import queryString from "query-string";
import { util } from "../../config/util";
import AlertContext from "../../contexts/AlertContext";
import { Cookies } from "../../constants/Cookies";
import DupLoginContext from "../../contexts/DupLoginContext";
import { CategoryItem } from "../../models/CategoryItem";
import { FreeUseDay } from "../../constants/General";
import UserBoardListPresenter from "./UserBoardListPresenter";
import { UserBoardCategory, UserBoardListItem } from "../../models/userboard/UserBoardModel";
//import { UserBoardListDummyData } from "../../constants/UserBoardDummyData";


export default () => {
  const [userBoardItems, setUserBoardItems] = useState<
    UserBoardListItem[]
  >([]);
  const [originUserBoardItems, setOriginUserBoardItems] = useState<
    UserBoardListItem[]
  >([]);
  const [
    filteredUserBoardItems,
    setFilteredUserBoardItems,
  ] = useState<UserBoardListItem[]>([]);
  const [
    searchTempUserBoardItems,
    setSearchTempUserBoardItems,
  ] = useState<UserBoardListItem[]>([]);
  const history = useHistory();
  const searchValue = useRef("");
  const isSearching = useRef<boolean>(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState(
    UserBoardCategory.전체
  );
  const lazyEvent = new LazyEvent();
  const location = useLocation();
  const params = useRef<any>({});
  const alert = useContext(AlertContext);
  const dupLogin = useContext(DupLoginContext);
  const mainTypes: CategoryItem[] = Object.values(UserBoardCategory).map(
    (value, index) => {
      return {
        description: "",
        id: index.toString(),
        title: value,
      };
    }
  );
  // 로그인 안된 경우
  useEffect(() => {
    
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " + FreeUseDay + "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
      return;
    }

    // 로그인 된  경우
    new Promise(async () => {
      // res = axios.get(`${GlobalEnvironments.getApiUrl()}user_board`,config)
      const res = await dupLogin.checkDupLogin(() => api.getUserBoardList());
      // items = res로 받아온 데이터를 map하여 ...item, created,category,viewCount를 리턴
      const items = res?.data?.list?.map((item: any): UserBoardListItem => {
        return {
          ...item,
          // keywords:
          //   (item?.keywords &&
          //     JSON.parse(item?.keywords?.replaceAll("'", '"'))) ||
          //   [],
          created: new Date(item.created),
          category: item.category,
          // recommendCount: item.recommend_count,
          viewCount: item.view_count,
        };
      });
      // originUserBoardItems에 받아온 items 정보를 넣는다.
      //_.cloneDeep이 뭐지?
      setOriginUserBoardItems(_.cloneDeep(items));
      // filteredUserBoardItems에 받아온 items 정보를 넣는다.
      setFilteredUserBoardItems(items);

      // list변수에 items 할당
      let list = items;
      // feature: api완성되면 교체
      // let list = UserBoardListDummyData;
      // parsed에 useLocation()으로 받아온 정보를 쿼리스트링해서 할당
      // queryString.parse이 뭐지?
      const parsed = queryString.parse(location.search);
      // params는 객체므로, current라는 키에 parse값을 할당
      params.current = parsed;
      // 받아온 parsed의 객체 속성을 "전체"/"string" 인지에 비교
      // 전체일경우,
        // setSelectedCategory(parsed.industry)
        // list = item-> item.mainType === parsed?.industry 비교해서 T인 것만 배열로 리턴
        // filteredUserBoardItems에 list 할당 
      if (
        parsed?.industry &&
        UserBoardCategory[parsed.industry as UserBoardCategory] &&
        parsed?.industry !== "전체"
      ) {
        setSelectedCategory(parsed.industry as UserBoardCategory);
        list = list.filter((item: any) => {
          return item.mainType === parsed?.industry;
        });
        setFilteredUserBoardItems(_.cloneDeep(list));
      }
      if (parsed?.search && typeof parsed?.search === "string") {
        setSearchTempUserBoardItems(_.cloneDeep(list));
        isSearching.current = true;
        list = getSearchedItems(list, parsed.search.toString());
        searchValue.current = parsed.search.toString();
      }
      let minIndex = 0,
        maxIndex = pageSize;

      if (parsed?.page) {
        const page = +parsed.page;
        const totalPage =
          list?.length % pageSize === 0
            ? parseInt((list?.length / pageSize).toString())
            : parseInt((list?.length / pageSize).toString()) + 1;
        const initPage = page < 0 || page > totalPage ? 1 : page;

        setCurrentPageIndex(initPage);
        minIndex = (initPage - 1) * pageSize;
        maxIndex = initPage * pageSize;
      }
      setFilteredUserBoardItems(_.cloneDeep(list));
      !parsed?.search &&setSearchTempUserBoardItems(_.cloneDeep(list));
      sliceItems(_.cloneDeep(list), minIndex, maxIndex);
    });
  }, []);
  // 로그인 된 경우
  useEffect(() => {
    const minIndex = (currentPageIndex - 1) * pageSize;
    const maxIndex = currentPageIndex * pageSize;
    sliceItems(filteredUserBoardItems, minIndex, maxIndex);
  }, [pageSize]);
  // 페이지 변경
  const handlePageChange = (page: number) => {
    setCurrentPageIndex(page);
    const minIndex = (page - 1) * pageSize;
    const maxIndex = page * pageSize;

    sliceItems(filteredUserBoardItems, minIndex, maxIndex);
    params.current.page = page;
    refreshUrl();
  };
  // url 변경
  const refreshUrl = () => {
    const param = queryString.stringify(params.current);
    history.push(`${pages.userboardlist}?${param}`);
  };
  // items 자르기
  const sliceItems = (
    items: UserBoardListItem[],
    minIndex: number,
    maxIndex: number
  ) => {
    const slicedItems = items?.filter(
      (_, index) => index >= minIndex && index < maxIndex
    );
    setUserBoardItems(slicedItems);
  };
  // 카테고리 누르면 변경되는 로직
  const handleCategoryClick = (type: UserBoardCategory) => {
    setSelectedCategory(type);
    setCurrentPageIndex(1);
    params.current.page = 1;
    if (type === UserBoardCategory.전체) {
      setSearchTempUserBoardItems(originUserBoardItems);
      const searchedItems = getSearchedItems(
        originUserBoardItems,
        searchValue.current
      );
      setFilteredUserBoardItems(searchedItems);
      sliceItems(searchedItems, 0, pageSize);
    } else {
      refreshListByFilter(type);
    }
    params.current.industry = type;
    refreshUrl();
  };
  // 필터 누르면 리스트 변경되는 로직
  const refreshListByFilter = (type: UserBoardCategory) => {
    const filteredItems = getTypeFilteredList(type);
    setCurrentPageIndex(1);
    params.current.page = 1;
    setFilteredUserBoardItems(filteredItems);
    sliceItems(filteredItems, 0, pageSize);
    refreshUrl();
  };
  // 필터된 리스트에서 Type 얻기 ?
  const getTypeFilteredList = (type: UserBoardCategory) => {
    let filteredList = originUserBoardItems.filter((item) => {
      return item.category === type;
    });
    setSearchTempUserBoardItems(filteredList);
    if (isSearching.current) {
      filteredList = getSearchedItems(filteredList, searchValue.current);
    }
    return filteredList;
  };

  const handleShowSizeChange = (_: number, size: number): void => {
    setPageSize(size);
  };
  // value 받아서 검색하기
  const handleSearchValueChange = (value: string): void => {
    searchValue.current = value;
    if (!isSearching.current) {
      isSearching.current = true;
      setSearchTempUserBoardItems(filteredUserBoardItems);
    }
    if (value.length === 0) {
      setUserBoardItems(searchTempUserBoardItems);
      setFilteredUserBoardItems(searchTempUserBoardItems);
      isSearching.current = false;
    }
    lazyEvent.registerAsync(async () => {
      const searchFilteredList = getSearchedItems(
        searchTempUserBoardItems,
        value
      );
      setCurrentPageIndex(1);
      params.current.page = 1;
      setFilteredUserBoardItems(searchFilteredList);
      sliceItems(searchFilteredList, 0, pageSize);
      params.current.search = value;
      refreshUrl();
    });
  };
  // 검색결과 얻기 ? 
  const getSearchedItems = (
    searchList: UserBoardListItem[],
    searchValue: string
  ) => {
    return searchList?.filter((item) => {
      return (
        // item.keywords?.some((key) => key?.includes(searchValue)) ||
        item.category?.includes(searchValue) ||
        item.title.includes(searchValue)
      );
    });
  };
  // 개별 게시글 클릭했을때 페이지 이동 및 쿼리 전달
  const handleItemClick = (id: number) => {
    //console.log(id)
    history.push(`${pages.userboarddetail}/${id}`, {
      prevPath: location.pathname + location.search,
    });
  };
  // 글쓰기 버튼 눌렀을때 
  const handleWriteClick = () => {
    console.log(`write click`);
    
  };

  return (
    <UserBoardListPresenter
      currentPageIndex={currentPageIndex}
      mainTypes={mainTypes}
      selectedCategory={selectedCategory}
      userBoardItems={userBoardItems}
      totalLength={filteredUserBoardItems?.length}
      pageSize={pageSize}
      searchValue={searchValue.current}
      onPageChange={handlePageChange}
      onClickCategory={handleCategoryClick}
      onShowSizeChange={handleShowSizeChange}
      onSearchValueChange={handleSearchValueChange}
      onClickStatementItem={handleItemClick}
      onClickWrite={handleWriteClick}
    />
  );
};
