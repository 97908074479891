import { useState } from "react";
import { util } from "../../../config/util";
import {
  PaymentPackageDetailModelV2,
  PaymentPackageModelV2,
  PaymentProductDetailModelV2,
} from "../../../models/payment/PaymentProductModelV2";

interface Props {
  package: PaymentPackageModelV2;
  index: number;
  handleGroupedProductClick: (
    period: number,
    detailId: number,
    amount: number,
    packageId: number
  ) => void;
  deleteProduct: (
    period: number,
    detailId: number,
    amount: number,
    packageId: number
  ) => void;
}

export default (props: Props) => {
  const [selectedPeriod, setSelectedPeriod] = useState(30);
  const [isSelected, setIsSelected] = useState(false);
  return (
    <div id={"pay-option" + props.index} className="payment-box">
      <input
        type="checkbox"
        className={"btn-check"}
        id={"btn-check" + props.index}
        autoComplete="off"
        onChange={(e) => {
          setIsSelected(e.target.checked);
          const details = props.package.details;
          const selectedDetail = details.filter(
            (detail: PaymentPackageDetailModelV2) =>
              detail.period == selectedPeriod
          )[0];

          if (!e.target.checked) {
            props.deleteProduct(
              selectedPeriod,
              selectedDetail.id,
              selectedDetail.amount,
              props.package.id
            );
          } else {
            props.handleGroupedProductClick(
              selectedPeriod,
              selectedDetail.id,
              selectedDetail.amount,
              props.package.id
            );
          }
        }}
      />
      <label className="btnbox " htmlFor={"btn-check" + props.index}>
        <div className="payment-item">
          <div className="pay-check">
            <span className="material-symbols-outlined">task_alt</span>
          </div>
          <div className="payment-info package">
            <div className="title-wrap">
              <div className="subtitle">{props.package.subtitle}</div>
              <div className="title">{props.package.name}</div>
              <div className="content">
                <div className="pay-thum-wrap">
                  {props.package.products.map((product, index) => {
                    return (
                      <div className="pay-thum">
                        <span>{product}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="payment-option-wrap">
              <div className="payment-option">
                {props.package.details.map(
                  (detail: PaymentPackageDetailModelV2, index: number) => {
                    return (
                      <>
                        <input
                          type="radio"
                          className="btn-check"
                          name={"options" + props.package.id}
                          id={"options" + detail.id}
                          value={detail.id}
                          autoComplete="off"
                          checked={selectedPeriod == detail.period}
                          onChange={() => {
                            if (isSelected) {
                              props.handleGroupedProductClick(
                                detail.period,
                                detail.id,
                                detail.amount,
                                props.package.id
                              );
                            }
                            setSelectedPeriod(detail.period);
                          }}
                        />
                        <label
                          className="btn btn-outline"
                          htmlFor={"options" + detail.id}
                        >
                          {detail.period == 30
                            ? "1개월"
                            : detail.period == 90
                            ? "3개월"
                            : detail.period == 180
                            ? "6개월"
                            : "1년"}
                        </label>
                      </>
                    );
                  }
                )}
              </div>
              <div className="payment-content">
                {props.package.details.map((detail: any, index: number) => {
                  return (
                    <div
                      className={
                        index == 0
                          ? "payment-price basic"
                          : index == 1
                          ? "payment-price month"
                          : "payment-price year"
                      }
                      style={
                        selectedPeriod == detail.period
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div className="price">
                          <span>{util.addComma(detail.amount)}</span>원{" "}
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                              color: "var(--color-primary)",
                            }}
                          >{`(${Math.ceil(
                            (1 - detail.amount / detail?.origin_amount) * 100
                          )}% 할인)`}</span>
                        </div>
                      </div>
                      <div className="ticket">
                        <span>{detail.period}</span>일 이용권
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  );
};
