import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function CompletionInspector(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }} >
              <td colspan="4" style={{ fontSize: "50px", textAlign: "center", height: "150px", fontWeight: "600" }}>준 공 검 사 원</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td style={{ borderRight: "hidden" }}></td>
              <td style={{ borderRight: "hidden" }}></td>
              <td style={{ borderRight: "hidden" }}>공사감독자경유 : </td>
              <td style={{ textAlign: "right" }}>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>1.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>공</span>
                <span>사</span>
                <span>명</span>
              </span>　:
              </td>
              <td style={{ wordBreak: "break-all", wordWrap:"word-break" }} colspan="3">{worklist?.constructionName}</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>2.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>계</span>
                <span>약</span>
                <span>금</span>
                <span>액</span>
              </span>　:
              </td>
              <td colspan="3">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>3.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>준</span>
                <span>공</span>
                <span>정</span>
                <span>산</span>
                <span>금</span>
                <span>액</span>
              </span>　:
              </td>
              <td colspan="3">
                {`일금 ${props.krCompletionCalPrice}정 (￦${props.commaCompletionCalPrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>4.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>계</span>
                <span>약</span>
                <span>연</span>
                <span>월</span>
                <span>일</span>
              </span>　:
              </td>
              <td colspan="3">
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>5.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>착</span>
                <span>공</span>
                <span>연</span>
                <span>월</span>
                <span>일</span>
              </span>　:
              </td>
              <td colspan="3">
                {
                  moment(worklist?.beginDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.beginDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>6.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>준</span>
                <span>공</span>
                <span>예</span>
                <span>정</span>
                <span>연</span>
                <span>월</span>
                <span>일</span>
              </span>　:
              </td>
              <td colspan="3">
                {
                  moment(worklist?.completionExpectDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.completionExpectDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>7.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>준</span>
                <span>공</span>
                <span>연</span>
                <span>월</span>
                <span>일</span>
              </span>　:
              </td>
              <td colspan="3">
                {
                  moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.completionDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>8.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>붙</span>
                <span>임</span>
                <span>서</span>
                <span>류</span>
              </span>　:
              </td>
              <td colspan="3">준공사진</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ lineHeight: "30px", textIndent: "5px" }}>위 공사의 도급시행에 있어서 공사전반에 걸쳐 공사설계도서,
                제시방서, 품질관리기준 및 기타 약정대로 어김없이 준공하였음을 확약하오며
                만약 하자책임기반내에 공사의 시공, 감독 및 검사에 관하여 하자가 발견시는 즉시 변상
                또는 재시공할 것을 서약하고 이에 준공검사원을 제출합니다.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "15px", textAlign: "center" }}>
                {
                  moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.completionDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">전화</span>:　<span>{worklist?.phoneNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">팩스</span>:　<span>{worklist?.faxNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default CompletionInspector;