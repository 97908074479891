import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function UsePresentationSeal(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table className="bbb" style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td className="doc-title" colspan="4" style={{ fontSize: "50px", textAlign: "center", height: "150px" }}>사 용 인 감 계</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colspan="4">　</td>
            </tr>
          </table>
          <table className="aaa" style={{ tableLayout: "fixed", textAlign: "center", border: "1" }}>
            <tr>
              <td colspan="2" style={{ height: "40px" }}>
                <span style={{ width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>사</span>
                  <span>용</span>
                  <span>인</span>
                  <span>감</span>
                </span>
              </td>
              <td>
                <span style={{ width: "50px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>주</span>
                  <span>소</span>
                </span>
              </td>
              <td colSpan="2" style={{ wordBreak: "keep-all" }}>{worklist?.businessAddress}</td>
            </tr>
            <tr>
              <td colspan="2" rowspan="2" >　</td>
              <td style={{ height: "40px" }}>
                <span style={{ width: "50px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>상</span>
                  <span>호</span>
                </span>
              </td>
              <td colSpan="2">{worklist?.companyName}</td>
            </tr>
            <tr>
              <td style={{ height: "70px" }}>
                <span style={{ width: "50px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>성</span>
                  <span>명</span>
                </span>
                <div style={{ width: "58px", display: "flex", justifyContent: "space-between", marginLeft: "35px" }}>
                  <span>(대</span>
                  <span>표</span>
                  <span>자)</span>
                </div>
              </td>
              <td colSpan="2" style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</td>
            </tr>
          </table>
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px", fontSize: "16px", wordBreak: "keep-all", textIndent: "5px" }} colspan="4">위 인감은 본 조합이 사용하는 인감으로써 귀청에서 집행하는 아래건의 등록계약 수금에 사용하겠으며 위 인감 사용으로 인한 법률상의 모든 책임은 본 조합이 질 것을 확약하고 이에 사용인감계를 제출합니다.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td style={{ borderRight: "hidden", textAlign: "center" }}>건　　　명 : </td>
              <td style={{ wordBreak: "break-all", wordWrap:"word-break" }} colspan="3">{worklist?.constructionName}</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colspan="4" style={{ textAlign: "center", fontSize: "15px" }}>
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "80px" }}>
              <td colspan="4">　</td>
            </tr>
            <tr style={{ fontSize: "18px" }}>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default UsePresentationSeal;