import React from "react";
import styles from "./promptModal.module.scss";

interface appProps {
  onClose: (value?: string) => void;
  title: string;
  defaultValue?: string;
}

interface appState {
  value: string;
}

export default class PromptModal extends React.Component<appProps, appState> {
  constructor(props: any) {
    super(props);
    this.state = {
      value: props.defaultValue || "",
    };
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.alerts}>
          <div className={styles.title}>{this.props.title}</div>
          <div className={styles.content}>
            <input
              type="text"
              value={this.state.value}
              style={{width:'100%'}}
              onKeyDown={(e) =>
                e.key === "Enter" &&
                this.state.value != undefined &&
                this.props.onClose(this.state.value)
              }
              onChange={(event) => this.setState({ value: event.target.value })}
            />
          </div>
          <div className={styles["btn-wrapper"]}>
            <button
              className={styles["error-btn"]}
              onClick={() => {
                this.props.onClose();
              }}
            >
              취소
            </button>
            <button
              className={!this.state.value ? styles.dis : ""}
              onClick={() => {
                this.props.onClose(this.state.value);
              }}
            >
              확인(Enter)
            </button>
          </div>
        </div>
      </div>
    );
  }
}
