/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styled from "styled-components";

interface Props {
  contents: string;
  onClose: () => void;
  onConfirm: () => void;
}

export default (props: Props) => {
  return (
    <Container>
      <pre
        style={{
          height: "750px",
          whiteSpace: "pre-wrap",
        }}
      >
        {props.contents}
      </pre>
      <Button onClick={() => props.onConfirm()}>확인</Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: center;
`;
const Button = styled.button`
  margin: 0 auto;
  width: 285px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 6px;
  background-color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
  height: 38px;
  border: none;
  margin-top: 20px;
`;
