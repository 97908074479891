// import { Payment } from "@material-ui/icons";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { videoPages } from "../../../../constants/PagePaths";
import ScrollToTop from "../../../../modules/ScrollToTop";
import ChangePasswordContainer from "../../../changePassword/ChangePasswordContainer";
import WithdrawalContainer from "../../../withdrawal/WithdrawalContainer";
import VideoFindAccount from "../findAccount/VideoFindAccount";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import LectureContent from "../lectureContent/LectureContent";
import LectureList from "../lectureList/LectureList";
import VideoLogin from "../login/VideoLogin";
import MyLecture from "../myLecture/MyLecture";
import Nav from "../nav/Nav";
import Notice from "../notice/Notice";
import VideoPayment from "../payment/VideoPayment";
import Section from "../section/Section";
import Video from "../video/Video";
import VideoMyPage from "../videoMyPage/VideoMyPage";
import VideoSignUp from "../videoSignUp/VideoSignUp";

const VideoMain = () => {
  return (
    <React.Fragment>
      <Switch>
        <ScrollToTop>
          <Route path={videoPages.content} exact>
            <LectureList />
          </Route>
          <Route path={`${videoPages.curriculum}/:curriculumId`} exact>
            <LectureContent />
          </Route>
          <Route path={videoPages.login} exact>
            <VideoLogin checkUser={() => {}} />
          </Route>
          <Route path={videoPages.signUp} exact>
            <VideoSignUp />
          </Route>
          <Route path={videoPages.myPage} exact>
            <VideoMyPage />
          </Route>
          <Route path={videoPages.myLecture} exact>
            <MyLecture />
          </Route>
          <Route path={videoPages.notice} exact>
            <Notice />
          </Route>
          <Route path={`${videoPages.payment}/:id`} exact>
            <VideoPayment />
          </Route>
          <Route path={videoPages.findaccount} exact>
            <VideoFindAccount />
          </Route>
          <Route path={videoPages.changepassword} exact>
            <ChangePasswordContainer />
          </Route>
          <Route path={videoPages.withdrawal} exact>
            <WithdrawalContainer />
          </Route>
          <Route path={`${videoPages.course}/:videoId`} exact>
            <Video />
          </Route>
          <Route path={videoPages.index} exact>
            <Section />
          </Route>
        </ScrollToTop>
      </Switch>
    </React.Fragment>
  );
};

export default VideoMain;
