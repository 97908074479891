import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function DepositAccount(props) {
  const worklist = props.worklist;
  if(!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "50px", textAlign: "center", height: "150px", fontWeight: "600" }}>입 금 계 좌 지 정 서</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>1.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>공</span>
                <span>사</span>
                <span>명</span>
              </span>　:
              </td>
              <td style={{ wordBreak: "keep-all" }} colspan="3">{worklist?.constructionName}</td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>2.　<span style={{ width: "100px", display: "inline-flex", justifyContent: "space-between" }}>
                <span>청</span>
                <span>구</span>
                <span>금</span>
                <span>액</span>
              </span>　:
              </td>
              <td colspan="3">
                {`일금 ${props.krClaimPrice}정 (￦${props.commaClaimPrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "19px", textAlign: "center" }}>위 공사에 대하여 본사의 입금계좌를 아래와 같이 지정하였기에 통보합니다.</td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden", borderRight: "hidden", height: "50px" }}></td>
              <td style={{ borderRight: "hidden" }} colspan="2">　</td>
              <td style={{ borderBottom: "hidden" }}></td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}>　</td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span style={{ width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>은</span>
                  <span>행</span>
                  <span>명</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.bankName}</td>
              <td style={{ borderBottom: "hidden" }}>　</td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}>　</td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span style={{ width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>점</span>
                  <span>포</span>
                  <span>명</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.storeName}</td>
              <td style={{ borderBottom: "hidden" }}>　</td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}>　</td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span style={{ width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>예</span>
                  <span>금</span>
                  <span>종</span>
                  <span>류</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.typeOfDeposit}</td>
              <td style={{ borderBottom: "hidden" }}>　</td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}>　</td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span style={{ width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>좌</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.accountNum}</td>
              <td style={{ borderBottom: "hidden" }}>　</td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}>　</td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span style={{ width: "80px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>예</span>
                  <span>금</span>
                  <span>주</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.accountHolder}</td>
              <td style={{ borderBottom: "hidden" }}>　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "15px", textAlign: "center", height: "50px" }}>
                {
                  moment(worklist?.claimDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? '　'
                    : moment(worklist?.claimDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">전화</span>:　<span>{worklist?.phoneNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">팩스</span>:　<span>{worklist?.faxNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default DepositAccount;