/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState, createRef } from "react";
import "../../styles.scss";
import SimpleDatePicker from "../../components/simpleDatePicker/SimpleDatePicker";
import { api } from "../../config/api";
import DaumPostModal from "../../components/modal/daumPost/DaumPostModal";
import { Redirect } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { emailRegex } from "../../constants/RegexData";
import { Checkbox, Radio } from "@material-ui/core";
import styles from "./signUpPage.module.scss";
import { privacyPolicy, termsOfService } from "../../constants/Terms";
import { Imp } from "../../models/payment/iamport/IMP";
import {
  ImpCertificationRequestModel,
  ImpCertificationResponseModel,
} from "../../models/payment/iamport/ImpModels";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { FreeUseDay } from "../../constants/General";
import { BusinessApiResponseModel } from "../../models/apiModel/apiResponseModel";
import DuplicateEmail from "./components/DuplicationEmail";
import CertPhone from "./components/CertPhone";
import CertBusinessNumber from "./components/CertBusinessNumber";
import Privacy from "./components/Privacy";

enum MemberType {
  Person = "person",
  Company = "company",
}

interface Props {}

interface State {
  isEmailConfirmed: boolean;
  isPhoneConfirmed: boolean;
  isCompanyConfirmed: boolean;
  isPostShow: boolean;
  dateDialogOpen: boolean;
  birth: Date;
  zoneCode: string;
  fullAddress: string;
  isCompletedSignUp: boolean;
  isReturnToIndex: boolean;
  selectedGender: string;
  selectedMemberType: MemberType;
  agreeChecks: boolean[];
  isAllAgreeCheck: boolean;
  isEnableMotivOther: boolean;
  businessNumber: string;
}

export default class SignUpPage extends React.Component<Props, State> {
  state: State = {
    isEmailConfirmed: false,
    isPostShow: false,
    isPhoneConfirmed: false,
    isCompanyConfirmed: false,
    dateDialogOpen: false,
    birth: new Date("1990-01-01"),
    fullAddress: "",
    zoneCode: "",
    isCompletedSignUp: false,
    selectedGender: "mail",
    agreeChecks: [false, false, false, false],
    isAllAgreeCheck: false,
    isEnableMotivOther: false,
    isReturnToIndex: false,
    selectedMemberType: MemberType.Person,
    businessNumber: "",
  };

  idInput = createRef<HTMLInputElement>();
  pwInput1 = createRef<HTMLInputElement>();
  pwInput2 = createRef<HTMLInputElement>();
  nameInput = createRef<HTMLInputElement>();
  phoneNumberInput = createRef<HTMLInputElement>();
  addressInput = createRef<HTMLInputElement>();
  detailAddressInput = createRef<HTMLInputElement>();
  certificationKey = "";

  onChangeAgree = (index: number, value: boolean) => {
    const checkes = this.state.agreeChecks;
    checkes[index] = value;
    this.setState({
      isAllAgreeCheck: checkes.every((check) => check),
    });
  };

  onChangeAgreeAll = (checked: boolean) => {
    this.setState({
      agreeChecks: [checked, checked, checked, checked],
      isAllAgreeCheck: checked,
    });
  };

  handleGenderChange = (value: string) => {
    this.setState({
      selectedGender: value,
    });
  };

  checkDuplicateAsync = async () => {
    // if (true) {
    //   document.querySelector("#button-addon2")?.classList.toggle("show");
    // }

    if (!emailRegex.test(this.idInput.current?.value || "")) {
      this.context.showAlert("올바른 이메일 주소를 입력하세요.");
      return;
    }

    const res = await api.checkEmailAsync(this.idInput.current?.value || "");
    if (res) {
      this.setState({
        isEmailConfirmed: true,
      });
      this.context.showAlert(
        this.idInput.current?.value + "은 이용이 가능합니다."
      );
    } else {
      this.context.showAlert(
        this.idInput.current?.value +
          "은 이미 가입이 되어있습니다.\n다른 이메일을 입력해주세요."
      );
    }
  };

  onClickCertification = () => {
    if (
      !this.state.birth ||
      this.state.birth.getTime() === new Date("1990-01-01").getTime() ||
      !this.nameInput.current?.value ||
      !this.phoneNumberInput.current?.value
    ) {
      this.context.showAlert("이름, 생년월일, 휴대폰 번호를 입력해주세요.");
      return;
    }

    const data: ImpCertificationRequestModel = {
      merchant_uid: `mid_${new Date().getTime()}`,
      company: `https://${process.env.REACT_APP_DOMAIN}.com/`,
      name: this.nameInput.current?.value,
      phone: this.phoneNumberInput.current?.value,
    };
    Imp.certification(data, async (res: ImpCertificationResponseModel) => {
      if (!res.success) {
        if (res.error_code === "F0000") {
          return;
        }
        this.context.showAlert("인증 서비스에 문제가 발생했습니다.\n고객센터에 문의 부탁드립니다.\n이용에 불편을 드려 죄송합니다.")
        return;
      }
      try {
        const result = await api.postCertificationIsNotDuplicateAsync({
          imp_uid: res.imp_uid,
          birth: this.state.birth.format("yyyy-MM-dd"),
          name: this.nameInput.current?.value,
          phone: this.phoneNumberInput.current?.value,
        });

        if (result.status === 203) {
          this.context.showAlert(
            "기입된 정보와 본인인증 정보가 일치하지 않습니다."
          );
          return;
        }

        if (!result.data.is_duplicated) {
          this.setState({
            isPhoneConfirmed: true,
          });

          this.context.showAlert("인증이 완료되었습니다.");
          this.certificationKey = result.data.certification_key;
        } else {
          this.context.showAlert("이미 가입된 휴대전화 번호입니다.");
          this.certificationKey = "";
        }
      } catch (error) {
        console.error(error);
      }
    });
  };

  handleBusinessNumberChanged = (e: any) => {
    this.setState({
      businessNumber: e.target?.value || "",
      isCompanyConfirmed: false,
    });
  };

  componentDidMount(): void {
    const key = util.getCookie(Cookies.authKey);
    if (key) {
      this.setState({
        isReturnToIndex: true,
      });
    }
  }

  onChangeId = () => {
    this.setState({
      isEmailConfirmed: false,
    });
  };

  handlePhoneNumberChange = () => {
    this.setState({
      isPhoneConfirmed: false,
    });
  };

  handleDateClose = (result: Date) => {
    this.setState({
      birth: result || this.state.birth,
      dateDialogOpen: false,
    });
  };

  handlePrivacyClick = () => {
    this.context.showTextModal(privacyPolicy, () => {
      this.onChangeAgree(0, true);
    });
  };

  handleTermsClick = () => {
    this.context.showTextModal(termsOfService, () => {
      this.onChangeAgree(1, true);
    });
  };

  handleSubmitAsync = async (e: React.FormEvent<HTMLFormElement>) => {
    const values: { [key: string]: any[] } = {};
    try {
      e.preventDefault();

      if (!this.state.isEmailConfirmed) {
        this.context.showAlert("이메일 중복체크가 필요합니다.");
        return;
      }

      // pwInput1가 pattern에 맞지 않을 경우 alert
      if (!this.pwInput1.current?.checkValidity()) {
        this.context.showAlert("비밀번호는 8~15자리의 영문/숫자/특수문자 조합이어야 합니다.");
        return;
      }

      if (this.pwInput1.current?.value !== this.pwInput2.current?.value) {
        this.context.showAlert("비밀번호가 일치하지 않습니다.");
        return;
      }

      if (!this.detailAddressInput || !this.state.fullAddress) {
        this.context.showAlert("주소 입력이 필요합니다.");
        return;
      }

      // 회원유형이 기업이고 isCompanyConfirmed가 false일 경우 alert
      if (
        this.state.selectedMemberType === MemberType.Company &&
        !this.state.isCompanyConfirmed
      ) {
        this.context.showAlert("사업자 등록번호 확인이 필요합니다.");
        return;
      } else if (
        this.state.selectedMemberType === MemberType.Person &&
        !this.state.isPhoneConfirmed
      ) {
        this.context.showAlert("휴대폰 인증이 필요합니다.");
        return;
      }

      const form = e as any;
      for (let index = 0; index < form.target.length; index++) {
        const element = form.target[index];
        if (element.type === "text" || element.type === "number") {
          values[element.name] = element.value;
        } else if (
          (element.type === "radio" || element.type === "checkbox")
        ) {
          if (element.checked) {
            if (values[element.name] == undefined) {
              values[element.name] = [];
            }
            values[element.name].push(
              element.parentElement.innerText
            );
          }
        }
      }

      if (values.motivationOther) {
        if (!values.motivation) {
          values.motivation = [];
        }
        if (values.motiveOther.toString().trim().length < 2) {
          this.context.showAlert("기타 입력칸에 2글자 이상 입력해주세요!");
          return;
        }
        values?.motivation?.push(values.motiveOther);
      }

      if (values.interestOtherCheckbox) {
        if (!values.interest) {
          values.interest = [];
        }
        if (values.interestOtherInput.toString().trim().length < 2) {
          this.context.showAlert("기타 입력칸에 2글자 이상 입력해주세요!");
          return;
        }
        values?.interest?.push(values.interestOtherInput);
      }

      if (
        !values.interestOtherCheckbox &&
        (values.interest == undefined || values.interest?.length < 1)
      ) {
        this.context.showAlert("관심분야를 1개 이상 선택해주세요.");
        return;
      }

      if (!this.state.agreeChecks[0] || !this.state.agreeChecks[1]) {
        this.context.showAlert("필수 동의 체크해주세요.");
        return;
      }
    } catch (error) {
      console.error(error);
    }

    try {
      const requestData = {
        username: this.idInput.current?.value,
        password1: this.pwInput1.current?.value,
        password2: this.pwInput2.current?.value,
        phone:
          this.state.selectedMemberType === MemberType.Person
            ? this.phoneNumberInput.current?.value
            : values.companyContactNumber || values.faxNumber,
        last_name:
          this.state.selectedMemberType === MemberType.Person
            ? this.nameInput.current?.value
            : values.companyContactName || values.companyName,
        email: this.idInput.current?.value,
        postcode: this.state.zoneCode,
        addr:
          this.state.fullAddress + " " + this.detailAddressInput.current?.value,
        birth: this.state.birth,
        gender: this.state.selectedGender,
        is_message_agree: this.state.agreeChecks[2],
        is_email_agree: this.state.agreeChecks[3],
        interests: values.interest,
        motivations: values.motivation,
        company_name: values.companyName,
        certification_key: this.certificationKey || values.businessNumber,
        fax_number: values.faxNumber,
      };

      await api.postUserRegistrationAsync(requestData);
      this.context.showAlert(
        `완료되었습니다.\n무료기간 ${FreeUseDay}일이 지급되었습니다.\n많은 이용 바랍니다.`
      );
      this.setState({
        isCompletedSignUp: true,
      });
    } catch (error: any) {
      if (error?.code === 400) {
        this.context.showAlert(error?.message);
      } else {
        this.context.showAlert(
          "회원가입 도중 오류가 발생하였습니다.\n우측 하단 고객센터를 통해 문의 부탁드립니다."
        );
      }
    }
  };

  handlePw2Changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (this.pwInput1.current?.value === e.target.value) {
      this.pwInput2.current?.setCustomValidity("");
    } else {
      this.pwInput2.current?.setCustomValidity("비밀번호가 일치하지 않습니다.");
    }
  };

  handleClickAddress = (e: any) => {
    e.preventDefault();
    this.setState({
      isPostShow: true,
    });
  };

  handlePostComplete = (zoneCode: string, fullAddress: string) => {
    this.setState({
      zoneCode,
      fullAddress,
      isPostShow: false,
    });
  };

  handleMotivOther = (checked: boolean) => {
    this.setState({
      isEnableMotivOther: checked,
    });
  };

  handleCompanyCheckClick = async () => {
    if (!this.state.businessNumber) {
      this.context.showAlert("사업자번호를 입력해주세요.");
      return;
    }
    const res = await api.getBusinessNumberStatus(
      this.state.businessNumber ?? ""
    );
    const businessModel: BusinessApiResponseModel = res?.data?.data?.[0];
    if (businessModel?.b_stt_cd === "01" && businessModel.end_dt == "") {
      // api를 통해 사업자번호 중복확인 요청
      const isDuplicatedRes = await api.getBusinessNumberDuplicate(
        this.state.businessNumber
      );
      if (!isDuplicatedRes?.data?.is_duplicated) {
        this.context.showAlert("정상적인 사업자번호입니다.");
        this.setState({
          isCompanyConfirmed: true,
        });
      } else {
        this.context.showAlert("이미 가입된 사업자번호입니다.");
      }
    } else {
      this.context.showAlert("올바르지 않은 사업자번호입니다.");
    }
  };

  render() {
    if (this.state.isCompletedSignUp) {
      return <Redirect to={pages.login} />;
    } else if (this.state.isReturnToIndex) {
      return <Redirect to={pages.index} />;
    }
    return (
      <main id="wrap" className="bg-light-md">
        <DaumPostModal
          changeIsOpen={() => this.setState({ isPostShow: false })}
          isOpen={this.state.isPostShow}
          onComplete={this.handlePostComplete}
        />
        <section>
          <div className="container page">
            <div className="section-header">회원가입</div>
            <div className="col-md-7 mt-3 mx-auto form-wrap">
              <form
                onSubmit={this.handleSubmitAsync}
                className="row g-4 needs-validation"
                noValidate
              >
                <div className="col-md-12">
                  <label htmlFor="validationCustom01" className="form-label">
                    아이디(이메일)<span className="text-danger">*</span>
                  </label>
                  <div className="input-group ">
                    <input
                      ref={this.idInput}
                      type="email"
                      className="form-control"
                      id="validationCustom01"
                      placeholder="이메일 주소를 입력해주세요"
                      required
                      onChange={this.onChangeId}
                    />
                    {!this.state.isEmailConfirmed && (
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        id="button-addon2"
                        // data-bs-toggle="modal"
                        // data-bs-target="#checkmodal"
                        onClick={this.checkDuplicateAsync}
                      >
                        중복체크
                      </button>
                    )}
                    <div className="invalid-feedback">
                      필수 입력 항목입니다.
                    </div>
                  </div>
                </div>
                <div className="col-12 person-grid">
                  <div className="col-md-12">
                    <label htmlFor="validationCustom02" className="form-label">
                      비밀번호<span className="text-danger">*</span>
                    </label>
                    <input
                      ref={this.pwInput1}
                      type="password"
                      className="form-control"
                      id="validationCustom02"
                      placeholder="영문/숫자/특수문자 조합으로 8~15자리"
                      pattern="^((?=.*\d)(?=.*[A-z])(?=.*[\W]).{8,15})$"
                      required
                    />
                    <div className="invalid-feedback">
                      필수 입력 항목입니다.
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="validationCustom03" className="form-label">
                      비밀번호 확인<span className="text-danger">*</span>
                    </label>
                    <input
                      ref={this.pwInput2}
                      type="password"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="비밀번호 재입력"
                      pattern="^((?=.*\d)(?=.*[A-z])(?=.*[\W]).{8,15})$"
                      required
                      onChange={this.handlePw2Changed}
                    />
                    <div className="invalid-feedback">
                      필수 입력 항목입니다.
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label htmlFor="validationCustom04" className="form-label">
                    회원 유형<span className="text-danger">*</span>
                  </label>

                  <input
                    type="radio"
                    className="btn-check toggle1"
                    id="toggle1"
                    name="toggle"
                    checked={
                      this.state.selectedMemberType === MemberType.Person
                    }
                    onChange={(e: any) =>
                      this.setState({ selectedMemberType: e.target.value })
                    }
                    value={MemberType.Person}
                  />
                  <label className="btn  btn-outline" htmlFor="toggle1">
                    개인
                  </label>

                  <input
                    type="radio"
                    className="btn-check toggle2"
                    id="toggle2"
                    name="toggle"
                    checked={
                      this.state.selectedMemberType === MemberType.Company
                    }
                    onChange={(e: any) =>
                      this.setState({ selectedMemberType: e.target.value })
                    }
                    value={MemberType.Company}
                  />
                  <label className="btn btn-outline" htmlFor="toggle2">
                    기업
                  </label>

                  <div className="tab content1 mt-4">
                    <div className="person-grid">
                      <div className="col-md-12">
                        <label
                          htmlFor="validationUsername"
                          className="form-label"
                        >
                          이름<span className="text-danger">*</span>
                        </label>
                        <div className="input-group has-validation">
                          <input
                            ref={this.nameInput}
                            type="text"
                            className="form-control"
                            id="validationUsername"
                            onChange={this.handlePhoneNumberChange}
                            aria-describedby="inputGroupPrepend"
                            placeholder="이름을 입력해주세요"
                            required
                          />
                          <div className="invalid-feedback">
                            필수 입력 항목입니다.
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="validationbirth" className="form-label">
                          생년월일<span className="text-danger">*</span>
                        </label>
                        <div className="input-group has-validation">
                          <input
                            type="text"
                            className="form-control hasDatepicker"
                            id="datepicker"
                            onClick={() =>
                              this.setState({ dateDialogOpen: true })
                            }
                            onChange={this.handlePhoneNumberChange}
                            value={this.state.birth.format("yyyy-MM-dd")}
                            required
                          />
                          <div className="invalid-feedback">
                            필수 입력 항목입니다.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="validationphone" className="form-label">
                        휴대폰 번호<span className="text-danger">*</span>
                      </label>
                      <div className="input-group ">
                        <input
                          ref={this.phoneNumberInput}
                          onChange={this.handlePhoneNumberChange}
                          type="number"
                          className="form-control"
                          id="validationphone"
                          placeholder="'-'를 제외한 휴대폰 번호"
                          pattern="[0-9]{11}"
                          required
                        />
                        {!this.state.isPhoneConfirmed && (
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon3"
                            onClick={this.onClickCertification}
                          >
                            인증하기
                          </button>
                        )}
                        <div className="invalid-feedback">
                          필수 입력 항목입니다.
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab content2 mt-4">
                    <div className="col-md-12">
                      <label
                        htmlFor="validationCompanyname"
                        className="form-label"
                      >
                        기업명<span className="text-danger">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCompanyname"
                          aria-describedby="inputGroupPrepend"
                          placeholder="기업명을 입력해주세요"
                          name="companyName"
                          required
                        />
                        <div className="invalid-feedback">
                          필수 입력 항목입니다.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="validationnumber" className="form-label">
                        사업자번호<span className="text-danger">*</span>
                      </label>
                      <div className="input-group ">
                        <input
                          type="number"
                          className="form-control"
                          id="validationnumber"
                          name="businessNumber"
                          placeholder="사업자번호를 입력해주세요"
                          required
                          onChange={this.handleBusinessNumberChanged}
                        />
                        {!this.state.isCompanyConfirmed && (
                          <button
                            className="btn btn-outline-secondary"
                            type="button"
                            id="button-addon3"
                            onClick={this.handleCompanyCheckClick}
                          >
                            사업자번호 확인
                          </button>
                        )}
                        <div className="invalid-feedback">
                          필수 입력 항목입니다.
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <label htmlFor="validationfax" className="form-label">
                        Fax 번호<span className="text-danger">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <input
                          type="text"
                          className="form-control"
                          id="validationfax"
                          aria-describedby="inputGroupPrepend"
                          placeholder="Fax 번호를 입력해주세요"
                          name="faxNumber"
                          required
                        />
                        <div className="invalid-feedback">
                          필수 입력 항목입니다.
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 mt-3">
                      <label
                        htmlFor="validationBusinessname"
                        className="form-label"
                      >
                        담당자 성함<span className="text-danger">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <input
                          type="text"
                          className="form-control"
                          id="validationBusinessname"
                          aria-describedby="inputGroupPrepend"
                          placeholder="담당자 성함을 입력해주세요"
                          name="companyContactName"
                          required
                        />
                        <div className="invalid-feedback">
                          필수 입력 항목입니다.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-3">
                      <label htmlFor="validationNum" className="form-label">
                        담당자 연락처<span className="text-danger">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <input
                          type="text"
                          className="form-control"
                          id="validationNum"
                          aria-describedby="inputGroupPrepend"
                          placeholder="담당자 연락처를 입력해주세요"
                          required
                          name="companyContactNumber"
                        />
                        <div className="invalid-feedback">
                          필수 입력 항목입니다.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <label htmlFor="validationaddress" className="form-label">
                    주소<span className="text-danger">*</span>
                  </label>
                  <div className="col-md-12 input-group mb-2">
                    <input
                      ref={this.addressInput}
                      readOnly={true}
                      type="text"
                      className="w-25 form-control"
                      id="sample6_address"
                      placeholder="주소"
                      value={this.state.fullAddress}
                      onClick={this.handleClickAddress}
                    />
                    <input
                      readOnly={true}
                      type="text"
                      className=" form-control"
                      id="sample6_postcode"
                      placeholder="우편번호"
                      onClick={this.handleClickAddress}
                      value={this.state.zoneCode}
                    />
                    <input
                      type="button"
                      className="btn btn-outline-secondary"
                      onClick={this.handleClickAddress}
                      value="주소 검색"
                    />
                    <div className="invalid-feedback">
                      필수 입력 항목입니다.
                    </div>
                  </div>
                  <input
                    ref={this.detailAddressInput}
                    type="text"
                    className="form-control mb-2"
                    id="sample6_detailAddress"
                    placeholder="상세주소"
                  />
                </div>

                <div className="border-1"></div>

                <div className="col-md-12 d-flex align-items-center gap-3 mb-3">
                  <label htmlFor="gender" className="form-label flex-1 mb-0">
                    성별
                  </label>
                  <div className="flex-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="gender"
                      id="male"
                      value={"mail"}
                      checked={this.state.selectedGender === "mail"}
                      onChange={(e) => this.handleGenderChange(e.target.value)}
                    />
                    <label className="btn btn-outline" htmlFor="male">
                      남성
                    </label>
                    <input
                      type="radio"
                      className="btn-check"
                      name="gender"
                      id="female"
                      value={"femail"}
                      checked={this.state.selectedGender === "femail"}
                      onChange={(e) => this.handleGenderChange(e.target.value)}
                    />
                    <label className="btn btn-outline" htmlFor="female">
                      여성
                    </label>
                  </div>
                </div>

                <div className="col-md-12 d-flex align-items-center gap-3 mb-3">
                  <label
                    htmlFor="joining"
                    className="form-label align-items-center flex-1 mb-0"
                  >
                    가입동기
                    <br />
                    <span className="caption1">(1개 이상 선택)</span>
                  </label>
                  <div className="flex-3">
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                        name="interest"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox1"
                      >
                        온라인 검색
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox2"
                        value="option2"
                        name="interest"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox2"
                      >
                        카페, 블로그
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox3"
                        value="option3"
                        name="interest"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox3"
                      >
                        전단지, 판촉물
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox4"
                        value="option4"
                        name="interest"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox4"
                      >
                        유튜브
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox5"
                        value="option5"
                        name="interest"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox5"
                      >
                        지인 추천
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox6"
                        value="option6"
                        name="interest"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox6"
                      >
                        이전 직장에서 사용
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox7"
                        value="option7"
                        name="interest"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox7"
                      >
                        SNS(페이스북, 인스타그램 등)
                      </label>
                    </div>
                    <div className="form-check form-check-inline ">
                      <div className="d-flex align-items-center gap-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox8"
                          value="option8"
                          name="interestOtherCheckbox"
                          onChange={(e) =>
                            this.handleMotivOther(e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox8"
                        >
                          기타
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="2글자 이상 적어주세요"
                          aria-label="default input"
                          name="interestOtherInput"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 d-flex align-items-center gap-3 mb-3">
                  <label
                    htmlFor="interest"
                    className="form-label align-items-center flex-1 mb-0"
                  >
                    관심분야
                    <br />
                    <span className="caption1">(1개 이상 선택)</span>
                  </label>
                  <div className="flex-3">
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox9"
                        value="option9"
                        name="motivation"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox9"
                      >
                        시공 부문
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox10"
                        value="option10"
                        name="motivation"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox10"
                      >
                        설계 부문
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox11"
                        value="option11"
                        name="motivation"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox11"
                      >
                        공무 부문
                      </label>
                    </div>
                    <div className="form-check form-check-inline mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox12"
                        value="option12"
                        name="motivation"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineCheckbox12"
                      >
                        건설회계 부문
                      </label>
                    </div>
                    <div className="form-check form-check-inline ">
                      <div className="d-flex align-items-center gap-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineCheckbox13"
                          value="option13"
                          name="motivationOther"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineCheckbox13"
                        >
                          기타
                        </label>
                        <input
                          name="motiveOther"
                          className="form-control"
                          type="text"
                          placeholder="2글자 이상 적어주세요"
                          aria-label="default input"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 d-flex align-items-center gap-3">
                  <label htmlFor="options" className="form-label flex-1 mb-0">
                    회사명
                  </label>
                  <div className="flex-3">
                    <input
                      name="companyName"
                      className="form-control"
                      type="text"
                      placeholder="회사명을 적어주세요"
                      aria-label="default input"
                    />
                  </div>
                </div>

                <div className="border-1"></div>

                <div className="col-12">
                  <div className="form-check ps-0 mb-3">
                    <input
                      type="checkbox"
                      className="btn-check"
                      id="cbx_chkAll"
                      autoComplete="off"
                      checked={this.state.isAllAgreeCheck}
                      onChange={(e) => this.onChangeAgreeAll(e.target.checked)}
                    />
                    <label
                      className="btn btn-md btn-outline"
                      htmlFor="cbx_chkAll"
                    >
                      전체동의
                    </label>
                    <div className="invalid-feedback">필수 항목입니다.</div>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="agreeCheck0"
                      value=""
                      id="flexCheckDefault"
                      checked={this.state.agreeChecks[0]}
                      onChange={(e) => this.onChangeAgree(0, e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckDefault"
                    >
                      <span className="text-danger fw-bold me-2">필수</span>
                      <a
                        href="#"
                        className="fw-bold text-dark"
                        data-bs-toggle="modal"
                        data-bs-target="#privacy"
                      >
                        개인정보 처리방침
                      </a>
                      에 동의
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="agreeCheck1"
                      value=""
                      id="flexCheckChecked2"
                      checked={this.state.agreeChecks[1]}
                      onChange={(e) => this.onChangeAgree(1, e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked2"
                    >
                      <span className="text-danger fw-bold me-2">필수</span>
                      <a
                        href="#"
                        className="fw-bold text-dark"
                        data-bs-toggle="modal"
                        data-bs-target="#terms"
                      >
                        이용약관
                      </a>
                      에 동의
                    </label>
                  </div>
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="chk"
                      value=""
                      id="flexCheckChecked3"
                      checked={this.state.agreeChecks[2]}
                      onChange={(e) => this.onChangeAgree(2, e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked3"
                    >
                      <span className="text-dark fw-bold me-2">선택</span>문자
                      수신 동의(마케팅)
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="chk"
                      value=""
                      id="flexCheckChecked4"
                      checked={this.state.agreeChecks[3]}
                      onChange={(e) => this.onChangeAgree(3, e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexCheckChecked4"
                    >
                      <span className="text-dark fw-bold me-2">선택</span>이메일
                      수신 동의(마케팅)
                    </label>
                  </div>
                </div>
                <div className="col-12 bg-white pt-3 mo-fixed-bottom">
                  <button
                    className="btn btn-primary btn-xlg w-100 mb-4"
                    type="submit"
                  >
                    회원가입
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
        {this.state.dateDialogOpen ? (
          <SimpleDatePicker
            onClose={this.handleDateClose}
            open={this.state.dateDialogOpen}
            selectedDate={this.state.birth}
          ></SimpleDatePicker>
        ) : (
          <div></div>
        )}
        {/* <DuplicateEmail /> */}
        <CertPhone />
        <CertBusinessNumber />
        <Privacy />
      </main>
    );
  }
}

SignUpPage.contextType = AlertContext;
