import { useEffect, useState } from "react";

function ClaimInputInfo(props) {
  const worklist = props.worklist;

  //수정, 저장 버튼에 대한 동작.
  const [input, setinput] = useState(true);

  const changeInput = (e) => {
    e.preventDefault();
    if (input === false) {
      setinput(!input);
    }
  };

    //금액 자동 콤마
    const [nonContractPrice, setNonContractPrice] = useState(worklist?.contractPrice);
    const [contractPrice, setContractPrice] = useState();
  
    const [nonClaimPrice, setNonClaimPrice] = useState(worklist?.claimPrice);
    const [claimPrice, setClaimPrice] = useState();
  
    const autoCommaContractPrice = (e) => {
      let value = e.target.value;
      setContractPrice(convertCommaValue(value));
      setNonContractPrice(Number(value.replaceAll(',', '')));
    }
  
    const autoCommaClaimPrice = (e) => {
      let value = e.target.value;
      setClaimPrice(convertCommaValue(value));
      setNonClaimPrice(Number(value.replaceAll(',', '')));
    }

  const handleSubmitAsync = async (e) => {
    let data = {};
    for (let index = 0; index < e.target.length; index++) {
      const element = e.target[index];
      data[element.name] = element.value;
    }
    data = {
      ...props.worklist,
      ...data,
    };
    e.preventDefault();
    if (input === true) {
      setinput(!input);
    }
    data.contractPrice = nonContractPrice === undefined ? worklist.contractPrice : nonContractPrice;
    data.claimPrice = nonClaimPrice === undefined ? worklist.claimPrice : nonClaimPrice;
    await props.putWorkListAsync(data);
  };

  useEffect(() => {
    setContractPrice(convertCommaValue(props.worklist?.contractPrice || ""));
    setClaimPrice(convertCommaValue(props.worklist?.claimPrice || ""));
  }, [props.worklist?.contractPrice, props.worklist?.claimPrice])

  function convertCommaValue(value) {
    value = Number(value.replaceAll(',', ''));
    if (isNaN(value)) {
      return 0;
    } else {
      const formatValue = value.toLocaleString('ko-KR');
      return formatValue;
    }
  }

  return (
    <div>
      <div className="doc-container-box">
        <form name="infoForm" onSubmit={handleSubmitAsync}>
          <div className="doc-header">
            <h1>청구 정보 입력</h1>
          </div>
          <div>
            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                공사명
              </div>
              <input
                defaultValue={worklist?.constructionName}
                className="doc-submit-input"
                placeholder="ex) ㅇㅇ시 상하수도 공사"
                disabled={!input}
                name="constructionName"
                maxLength="30"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계약일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.contractDate}
                disabled={!input}
                name="contractDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계약금액
              </div>
              <input
                value={contractPrice}
                className="doc-submit-input"
                placeholder="계약금액을 입력해주세요."
                disabled={!input}
                name="contractPrice"
                onChange={autoCommaContractPrice}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                청구금액
              </div>
              <input
                value={claimPrice}
                className="doc-submit-input"
                placeholder="계약금액을 입력해주세요."
                disabled={!input}
                name="claimPrice"
                onChange={autoCommaClaimPrice}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                착공일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.beginDate}
                disabled={!input}
                name="beginDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                준공일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.completionDate}
                disabled={!input}
                name="completionDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                실제 준공일
              </div>
              <input
                type="date"
                defaultValue={worklist?.realCompletionDate}
                disabled={!input}
                name="realCompletionDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                청구일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.claimDate}
                disabled={!input}
                name="claimDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                귀하
              </div>
              <input
                defaultValue={worklist?.dear}
                className="doc-submit-input"
                placeholder="ex) 김ㅇㅇ"
                disabled={!input}
                name="dear"
                maxLength="20"
              />
            </div>
          </div>

          <div className="button_container">
            <button className={`doc_button_modi ${!input ? '' : 'btn_hidden'}`} onClick={changeInput}>
              <span style={{ fontSize: "15px", color: "white" }}>수정</span>
            </button>
            <button className={`doc_submit ${input ? '' : 'btn_hidden'}`} htmlType="submit">
              <span style={{ fontSize: "15px", color: "white" }}>저장</span>
            </button>
          </div>

        </form>
      </div>
    </div >
  );
}

export default ClaimInputInfo;