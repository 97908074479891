/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
import { Checkbox, Dialog } from "@material-ui/core";
import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { privacyPolicy } from "../../constants/Terms";
import AlertContext from "../../contexts/AlertContext";
import Privacy from "../signUp/components/Privacy";
import styles from "./inquiryPage.module.scss";

interface Props {
  attachedFiles: string[];
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onChangeFiles: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default (props: Props) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const alert = useContext(AlertContext);
  useEffect(() => {
    if (textareaRef != undefined && textareaRef.current != undefined) {
      textareaRef.current.value = props.attachedFiles.join("\n");

      textareaRef.current.style.height = "0px";
      textareaRef.current.style.height =
        35 + props.attachedFiles.length * 15 + "px";
    }
  }, [props.attachedFiles]);

  return (
    <main id="wrap" className="bg-light-md">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">문의하기</div>
            <div className="form-wrap col-md-7 mx-auto mt-3">
              <form className="row g-3" onSubmit={props.onSubmit}>
                <div className="col-md-6 pb-4">
                  <label htmlFor="validationCustom02" className="form-label">
                    이름(담당자명)<span className="text-danger ms-2">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    placeholder="이름(담당자명)을 입력해주세요"
                    name="name"
                    required
                  />
                  <div className="invalid-feedback">필수 입력 정보입니다.</div>
                </div>
                <div className="col-md-6 pb-4">
                  <label htmlFor="validationCustom03" className="form-label">
                    연락처<span className="text-danger ms-2">*</span>
                  </label>
                  <div className="input-group has-validation">
                    <input
                      type="number"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="연락처를 입력해주세요"
                      name="phone_number"
                      required
                    />
                    <div className="invalid-feedback">
                      필수 입력 정보입니다.
                    </div>
                  </div>
                </div>

                <div className="col-md-12 pb-4">
                  <label htmlFor="validationCustom01" className="form-label">
                    이메일<span className="text-danger ms-2">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="이메일을 입력해주세요"
                    name="email"
                    required
                  />
                  <div className="invalid-feedback">필수 입력 정보입니다.</div>
                </div>

                <div className="col-md-12 pb-4">
                  <label htmlFor="validationCustom04" className="form-label">
                    문의 종류<span className="text-danger ms-2">*</span>
                  </label>
                  <div className="col-12 check-wrap">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inquiry_type"
                        id="inlineRadio1"
                        value="option1"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        설계 내역
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inquiry_type"
                        id="inlineRadio2"
                        value="option2"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        계약 서류
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inquiry_type"
                        id="inlineRadio3"
                        value="option3"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio3"
                      >
                        이용권
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inquiry_type"
                        id="inlineRadio4"
                        value="option4"
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio4"
                      >
                        기타
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 pb-4">
                  <label htmlFor="validationCustom02" className="form-label">
                    제목<span className="text-danger ms-2">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    placeholder="제목을 입력해주세요"
                    name="title"
                    required
                  />
                  <div className="invalid-feedback">필수 입력 정보입니다.</div>
                </div>
                <div className="col-md-12 pb-4">
                  <div className="mb-3">
                    <label htmlFor="validationCustom02" className="form-label">
                      문의 내용<span className="text-danger ms-2">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      id="Textarea1"
                      placeholder=""
                      rows={6}
                      name="content"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 pb-4">
                  <div className="mb-3">
                    <label htmlFor="validationCustom02" className="form-label">
                      첨부파일
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      accept="*.*"
                      name="files"
                      id="selectFile"
                      onChange={props.onChangeFiles}
                      multiple
                    />
                  </div>
                </div>
                {/* <table className="table_write">
                  <tbody>
                    <tr className="fileTR">
                      <td>
                        <div className="ipt_file_area makeFlex ai_C">
                          <div className="filebox makeFlex ai_FE">
                            <div
                              className="changeFileName"
                              onClick="changeFileName(this)"
                            >
                              파일명을 수정하시려면 이곳을 클릭하세요
                            </div>
                            <input
                              className="upload-name"
                              placeholder="파일선택"
                              disabled="disabled"
                            />
                            <label>
                              업로드 +
                              <input
                                type="file"
                                className="upload-hidden"
                                accept="*.*"
                                name="files"
                                multiple
                                onChange={props.onChangeFiles}
                              />
                            </label>
                          </div>

                          <button
                            className="btn_del"
                            title="첨부파일 삭제"
                            onclick="delFile(this)"
                          >
                            <span className="material-symbols-outlined">
                              delete
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th colspan="2">
                        <div className="col-md-11 mx-auto mb-4">
                          <button
                            title="파일 추가"
                            id="btn_addFile"
                            onclick="add_fileTR()"
                            className="btn w-100 btn-md btn-light"
                          >
                            파일 추가 +
                          </button>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table> */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    required
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                  >
                    <span className="text-danger">(필수)</span>
                    <a
                      href="#"
                      className="text-dark"
                      data-bs-toggle="modal"
                      data-bs-target="#privacy"
                    >
                      개인정보 처리방침
                    </a>
                    에 동의
                  </label>
                </div>

                <div className="mo-fixed-bottom col-12 py-3">
                  <button
                    className="btn btn-primary btn-lg w-100 right-icon mx-auto justify-content-center"
                    type="submit"
                  >
                    문의하기
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </article>
      <Privacy />
    </main>
  );
};
