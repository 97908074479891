/* eslint-disable import/no-anonymous-default-export */
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import styles from "./findAccountPage.module.scss";

interface Props {
  selectedFindOption: number;
  isCertificationInProgress: boolean;
  foundedIdInfo: string;
  isSuccessFindPw: boolean;
  onClickFindOption: (index: number) => void;
  onClickBackToLogIn: () => void;
  onClickIdCertification: (name: string, phoneNum: string) => void;
  onClickPwCertification: (id: string, phoneNum: string) => void;
  onChangePhoneNumber: () => void;
  onClickChangePw: (key: string, keyConfirm: string) => void;
}

export default (props: Props) => {
  const pwInput = useRef<HTMLInputElement>(null);
  const idInput = useRef<HTMLInputElement>(null);
  const pwInputConfirm = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const phoneNumInputRef1 = useRef<HTMLInputElement>(null);
  const phoneNumInputRef2 = useRef<HTMLInputElement>(null);

  return (
    <main id="wrap" className="bg-light-md">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">아이디/비밀번호 찾기</div>
            <div
              className="col-sm-7 col-md-8 col-lg-6 col-xl-4 mt-3 mx-auto form-wrap aos-init aos-animate"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="find-id"
                    data-bs-toggle="tab"
                    data-bs-target="#find-id-pane"
                    type="button"
                    role="tab"
                    aria-controls="find-id-pane"
                    aria-selected="true"
                  >
                    아이디 찾기
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="find-pw"
                    data-bs-toggle="tab"
                    data-bs-target="#find-pw-pane"
                    type="button"
                    role="tab"
                    aria-controls="find-pw-pane"
                    aria-selected="false"
                    tabIndex={-1}
                  >
                    비밀번호 찾기
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                {!props.foundedIdInfo ? (
                  <div
                    className="tab-pane fade show active"
                    id="find-id-pane"
                    role="tabpanel"
                    aria-labelledby="find-id"
                    tabIndex={0}
                  >
                    <h4 className="text-dark mt-4">아이디(이메일) 찾기</h4>
                    <div className="body2  mb-6">
                      아이디는 회원가입시 인증한 이메일 계정입니다.
                    </div>
                    <form className="row g-3 needs-validation">
                      <div className="col-md-12">
                        <label htmlFor="Inputname" className="form-label">
                          이름
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Inputname"
                          placeholder="이름을 입력해주세요"
                          ref={nameRef}
                          key="idInput"
                          required
                        />
                        <div className="invalid-feedback">
                          이름을 입력해주세요.
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="validationCustom02"
                          className="form-label"
                        >
                          휴대폰 번호
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom02"
                          key="phoneInput"
                          ref={phoneNumInputRef1}
                          placeholder="-없이 숫자만 입력해주세요."
                          onChange={props.onChangePhoneNumber}
                          required
                        />
                        <div className="invalid-feedback">
                          휴대폰 번호를 입력해주세요.
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-xlg btn-primary w-100"
                          type="button"
                          onClick={() =>
                            props.onClickIdCertification(
                              nameRef.current?.value || "",
                              phoneNumInputRef1.current?.value || ""
                            )
                          }
                        >
                          인증 &amp; 아이디 찾기
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div>
                    <div className="text-dark mt-4 h5">
                      고객님의 정보와 일치하는 아이디입니다.
                    </div>
                    <div className="text-dark h3">{props.foundedIdInfo}</div>
                    <div className={styles.founded_id_button_wrapper}>
                      <button
                        className="btn btn-xlg btn-primary w-100"
                        onClick={props.onClickBackToLogIn}
                      >
                        로그인하기
                      </button>
                    </div>
                  </div>
                )}
                {!props.isSuccessFindPw ? (
                  <div
                    className="tab-pane fade"
                    id="find-pw-pane"
                    role="tabpanel"
                    aria-labelledby="find-pw"
                    tabIndex={0}
                  >
                    <h4 className="text-dark mt-4">비밀번호 찾기</h4>
                    <div className="body2  mb-6">
                      아이디는 회원가입시 인증한 이메일 계정입니다.
                    </div>
                    <form className="row g-3 needs-validation">
                      <div className="col-md-12">
                        <label htmlFor="Inputname" className="form-label">
                          이메일(아이디)
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="Inputname"
                          placeholder="이메일(아이디)을 입력해주세요"
                          ref={idInput}
                          key="idInput2"
                          required
                        />
                        <div className="invalid-feedback">
                          이메일(아이디)을 입력해주세요.
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="validationCustom02"
                          className="form-label"
                        >
                          휴대폰 번호
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="validationCustom02"
                          ref={phoneNumInputRef2}
                          placeholder="-없이 숫자만 입력해주세요."
                          onChange={props.onChangePhoneNumber}
                          required
                        />
                        <div className="invalid-feedback">
                          휴대폰 번호를 입력해주세요.
                        </div>
                      </div>

                      <div className="col-12">
                        <button
                          className="btn btn-xlg btn-primary w-100"
                          type="button"
                          onClick={() =>
                            props.onClickPwCertification(
                              idInput.current?.value || "",
                              phoneNumInputRef2.current?.value || ""
                            )
                          }
                        >
                          인증 &amp; 비밀번호 찾기
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="mt-4">
                    <div className="col-md-12">
                      <label htmlFor="Inputpassword" className="form-label">
                        변경할 비밀번호
                      </label>
                      <input
                        id="Inputpassword"
                        ref={pwInput}
                        key="password1"
                        type="password"
                        placeholder="영문/숫자/특문 조합으로 8~15자리"
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-12 mt-2">
                      <label htmlFor="Inputpassword2" className="form-label">
                        비밀번호 확인
                      </label>
                      <input
                        id="Inputpassword2"
                        key="password2"
                        ref={pwInputConfirm}
                        type="password"
                        className="form-control"
                      />
                    </div>
                    <button
                      className="btn btn-xlg btn-primary w-100 mt-4"
                      onClick={() =>
                        props.onClickChangePw(
                          pwInput.current?.value || "",
                          pwInputConfirm.current?.value || ""
                        )
                      }
                    >
                      변경하기
                    </button>
                  </div>
                )}
              </div>
              <div className="mt-5 d-flex align-items-center">
                <span className="material-symbols-outlined me-2">west</span>{" "}
                <Link to={pages.login} className="text-dark fw-bold">
                  로그인 화면
                </Link>
                으로 돌아가기
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};
