import React, { useState } from 'react'
import { CommunityFormDetailModel } from '../../models/communityboard/communityFormDetailModel'
import UserBoardFormPresenter from './UserBoardFormPresenter'


const UserBoardFormContainer = () => {
  // 폼 카테고리
   const [formCategory,setFormCategory] = useState('')
  //폼 제목
  const [formTitle,setFormTitle] = useState('')
  // 폼 글내용
   const [formContnent, setFormContent]=useState('')
  // 폼 첨부파일
  // 첨부파일은 어떻게 가져오는가?

  // 글쓰기 버튼 누르면 제출되는 함수
  const handleToSubmit = (e: any) =>{
    e.preventDefault();
    // 정보가져오기
    //console.log(formCategory,formTitle,formContnent)
    
    // api post 요청하기
    
  }

  // form 정보 가져오기
  const getTitle=(e:any)=>{
    setFormTitle(e.target.value)
  }
  const getContent=(e:any)=>{
    setFormContent(e.target.value)
  }
  const getCategory=(e:any)=>{
    setFormCategory(e.target.value)
  }

  


  return (
    <>
    <UserBoardFormPresenter 
    onClickSubmit={handleToSubmit}
    getTitle={getTitle}
    getContent={getContent}
    getCategory={getCategory}
    />
    </>
    )
}

export default UserBoardFormContainer