import React from 'react';
import { Card, Typography, Space } from 'antd';
import { LikeOutlined, MessageOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import styles from './AIPostCard.module.scss';
import { pages } from '../../constants/PagePaths';

const { Text } = Typography;

interface AIPostCardProps {
  id: number;
  title: string;
  author: string;
  date: string;
  likes: number;
  comments: number;
  hasAIAnswer: boolean;
}

const AIPostCard: React.FC<AIPostCardProps> = ({ 
  id, title, author, date, likes, comments, hasAIAnswer
}) => {

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.format("yyyy-MM-dd HH:mm")}`;
  };

  return (
    <Card className={styles.aiPostCard} hoverable>
      <Link to={`${pages.communityAi}/${id}`} className={styles.cardLink}>
        <div className={styles.postContent}>
          <Text className={styles.postTitle}>{title}</Text>
          <div className={styles.postInfo}>
            <div className={styles.authorDateContainer}>
              <Text type="secondary" className={styles.author}>{author}</Text>
              <Text type="secondary" className={styles.date}>{formatDate(date)}</Text>
            </div>
            <div className={styles.postStats}>
              <span className={styles.iconWrapper}>
                <LikeOutlined className={styles.likeIcon} /> {likes}
              </span>
              <span className={styles.iconWrapper}>
                <MessageOutlined className={styles.commentIcon} /> {comments}
              </span>
              {hasAIAnswer && (
                <span className={styles.aiAnswerBadge}>AI 답변</span>
              )}
            </div>
          </div>
        </div>
      </Link>
    </Card>
  );
};

export default AIPostCard;
