export default (props: { handleOk4: any; agent?: any }) => {
  return (
    <form onSubmit={props.handleOk4}>
      <div
        className="modal fade"
        id="addAgent"
        aria-labelledby={props.agent ? "editAgentLabel" : "addAgentLabel"}
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="addAgentLabel">
                현장대리인 {props.agent ? "수정" : "추가"}
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="input-content">
                <div className="input-item">
                  <div className="title">현장대리인 성명</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="성명을 입력해주세요"
                      aria-label="project-name"
                      aria-describedby="basic-addon1"
                      name="deputyName"
                      defaultValue={props.agent?.deputyName}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 주민등록번호 (16자)</div>
                  <div className="contents">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="주민등록번호(16자)를 입력해주세요"
                      aria-label="company-name"
                      aria-describedby="basic-addon1"
                      name="deputyResidentNum"
                      defaultValue={props.agent?.deputyResidentNum}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 주소</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="주소를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="deputyAddress"
                      defaultValue={props.agent?.deputyAddress}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 직위</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="직위를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="deputyPosition"
                      defaultValue={props.agent?.deputyPosition}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 종목</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="종목을 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="deputyEvent"
                      defaultValue={props.agent?.deputyEvent}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 등급</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="등급을 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="deputyGrade"
                      defaultValue={props.agent?.deputyGrade}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 등록번호</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="등록번호를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="deputyRegistNum"
                      defaultValue={props.agent?.deputyRegistNum}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 취득일자</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      id="datepicker-start"
                      name="deputyGetDate"
                      defaultValue={props.agent?.deputyGetDate}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 갱신일자</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      id="datepicker-new"
                      name="deputyRenewDate"
                      defaultValue={props.agent?.deputyRenewDate}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 입사날짜</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      id="datepicker-company"
                      name="deputyJoinDate"
                      defaultValue={props.agent?.deputyJoinDate}
                    />
                  </div>
                </div>
                <div className="input-item">
                  <div className="title">현장대리인 근무처</div>
                  <div className="contents">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="근무처를 입력해주세요"
                      aria-label="orderer-name"
                      aria-describedby="basic-addon1"
                      name="deputyWorkPlace"
                      defaultValue={props.agent?.deputyWorkPlace}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer pt-4">
              <div className="modal-footer-wrap">
                <button
                  type="button"
                  className="btn btn-light btn-lg flex-1"
                  data-bs-dismiss="modal"
                >
                  취소
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-lg flex-2"
                  data-bs-dismiss="modal"
                >
                  <span className="material-symbols-outlined me-2">add</span>
                  {props.agent ? "수정" : "추가"}하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
