import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  Typography,
  Descriptions,
  Spin,
  Progress,
  Tabs,
  List,
  Comment,
  Avatar,
  InputNumber,
  Button,
  message,
  Tag,
  Modal,
  Space,
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Upload,
} from "antd";
import {
  UserOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  UploadOutlined,
  FileOutlined,
  DownloadOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ChatComponent from "../../components/ChatComponent";
import styles from "./ProjectDetailPage.module.scss";
import { useProjects } from "../../contexts/ProjectContext";
import { Project, Task, Attachment } from "../../types/project";
import { serviceApi } from "../../config/serviceApi";
import { api } from "../../config/api";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import dayjs from "dayjs";

import { TaskModel } from "../../models/service/TaskModel";
import { ProjectDetailModel } from "../../models/service/ProjectModel";
import { useRef } from "react";
import { TextAreaRef } from "antd/lib/input/TextArea";
import { UserInfoModel } from "../../models/user/UserInfo";
import { FileModel } from "../../models/service/ServiceRequestModel";

const { Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

const ProjectDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { updateTask, loading, refreshProjects } = useProjects();
  const [project, setProject] = useState<ProjectDetailModel | null>(null);
  const [editingProgress, setEditingProgress] = useState(false);
  const [newProgress, setNewProgress] = useState(0);
  const [selectedTask, setSelectedTask] = useState<TaskModel | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const [userRole, setUserRole] = useState<"expert" | "client" | null>(null);
  const [isAddTaskModalVisible, setIsAddTaskModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingServiceCost, setEditingServiceCost] = useState(false);
  const [newServiceCost, setNewServiceCost] = useState(0);
  const [isMessagingVisible, setIsMessagingVisible] = useState(false);
  const location = useLocation();
  const [isEditTaskModalVisible, setIsEditTaskModalVisible] = useState(false);
  const [editingTask, setEditingTask] = useState<TaskModel | null>(null);

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [attachments, setAttachments] = useState<string[]>([]);

  const commentRef = useRef<TextAreaRef>(null);
  const [myInfo, setMyInfo] = useState<UserInfoModel>();

  const registerComment = async () => {
    if (
      commentRef.current &&
      selectedTask &&
      commentRef.current.resizableTextArea
    ) {
      const res = await serviceApi.postComment(
        +id,
        selectedTask?.id,
        commentRef.current.resizableTextArea?.textArea.value
      );
      if (res.status == 201) {
        message.success("댓글이 등록되었습니다.");
      }
    }
  };

  useEffect(() => {
    async function fetch() {
      const projectRes = await serviceApi.getProject(+id);
      setProject(projectRes.data);
      const checkRes = await serviceApi.checkExpert();
      const isExpertView = checkRes.data.is_expert; //전문가 여부
      setUserRole(isExpertView ? "expert" : "client");
      const res = await api.getMyInfo();
      setMyInfo(res.data);
    }
    fetch();
  }, [id]);

  const handleProgressEdit = () => {
    setEditingProgress(true);
  };

  const handleProgressCancel = () => {
    setEditingProgress(false);
    setNewProgress(project?.progress || 0);
  };

  const handleProgressSave = async () => {
    if (project) {
      const res = await serviceApi.updateProject(project.id, newProgress);
      if (res.status == 200) {
        message.success("진행률이 업데이트되었습니다.");
        setEditingProgress(false);
        setProject((prevProject) => ({
          ...prevProject!,
          progress: newProgress,
        }));
      }
      refreshProjects(); // 프로젝트 데이터 즉시 새로고침
    }
  };

  const handleAddTask = async (values: any) => {
    if (project) {
      const res = await serviceApi.postProjectTask(project?.id, {
        title: values.title,
        description: values.description,
        deadline: new Date(values.dueDate).format("yyyy-MM-dd"),
        assignee_id: 1, //담당자 ?
        // attachments: attachments,
      });
      if (res.status == 200) {
        message.success("새 작업이 추가되었습니다.");
        setIsAddTaskModalVisible(false);
        form.resetFields();
      }
    }
  };

  const handleChangeFiles = async (e: UploadChangeParam<UploadFile<any>>) => {
    const megaByte = 1000 * 1000;
    const fileSizeLimit = 20; //10MB
    const fileCountLimit = 5;
    if (e.fileList) {
      if (e.fileList?.length === 0) return;
      const files = [];
      const filenames: string[] = [];
      let lengthSum = 0;
      for (let i = 0; i < (e.fileList.length || 0); i++) {
        let file = e.fileList[i];
        lengthSum += file.size!;
        if (lengthSum < fileSizeLimit * megaByte && i < fileCountLimit) {
          const res = await api.uploadFileAsync(file.originFileObj!);
          files.push(res.data.url);
          filenames.push(file.name);
        } else {
          message.error(
            `파일은 최대 ${fileCountLimit}개, 합계 ${fileSizeLimit}MB를 넘을 수 없습니다.`
          );
          break;
        }
      }
      setAttachments(filenames);
      const newFileList = e.fileList.map((file) => {
        if (file.uid === e.file.uid) {
          file.status = "done"; // 원하는 상태로 변경
        }
        return file;
      });
      setFileList(newFileList);
    }
  };

  const handleApprove = async (taskId: number) => {
    if (project) {
      const res = await serviceApi.proceedProjectTask(+id, taskId);
      if (res.status == 200) {
        message.success("작업이 승인되었습니다.");
      }
    }
  };

  const handleCancelApproval = (taskId: number) => {
    Modal.confirm({
      title: "승인을 취소하시겠습니까?",
      content: "이 작업의 승인을 취소하면 다시 승인 대기 상태가 됩니다.",
      onOk() {
        if (project) {
          const updatedTasks = project.tasks.map((task: TaskModel) =>
            task.id === taskId
              ? { ...task, status: "waiting_approval" as const }
              : task
          );
          const updatedProject = { ...project, tasks: updatedTasks };
          setProject(updatedProject);
          message.success("승이 취소되었습니다.");
        }
      },
    });
  };

  const getStatusTag = (status: string) => {
    switch (status) {
      case "pending":
        return (
          <Tag icon={<ClockCircleOutlined />} color="default">
            대기 중
          </Tag>
        );
      case "in_progress":
        return (
          <Tag icon={<SyncOutlined spin />} color="processing">
            진행 중
          </Tag>
        );
      case "waiting_approval":
        return (
          <Tag icon={<ClockCircleOutlined />} color="warning">
            승인 대기
          </Tag>
        );
      case "approved":
        return (
          <Tag icon={<CheckCircleOutlined />} color="success">
            승인 완료
          </Tag>
        );
      default:
        return null;
    }
  };

  const showTaskDetails = (task: TaskModel) => {
    setSelectedTask(task);
    setIsModalVisible(true);
  };

  const canApprove = (taskStatus: string) => {
    if (userRole === "expert") {
      return taskStatus === "waiting_approval";
    } else if (userRole === "client") {
      return taskStatus === "waiting_approval";
    }
    return false;
  };

  const showAddTaskModal = () => {
    setIsAddTaskModalVisible(true);
  };

  const formatFileSize = (bytes: number) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const handleServiceCostEdit = () => {
    setEditingServiceCost(true);
    setNewServiceCost(project?.service_cost || 0);
  };

  const handleServiceCostSave = async () => {
    if (project) {
      const res = await serviceApi.updateProject(
        project.id,
        undefined,
        newServiceCost
      );
      if (res.status == 200) {
        message.success("용역 비용이 업데이트되었습니다.");
        setEditingServiceCost(false);
        const updatedProject = { ...project, serviceCost: newServiceCost };
        setProject(updatedProject);
      }
    }
  };

  const handleServiceCostCancel = () => {
    setEditingServiceCost(false);
  };

  const renderProgressSection = () => {
    if (editingProgress) {
      return (
        <>
          <InputNumber
            min={0}
            max={100}
            value={newProgress}
            onChange={(value) => setNewProgress(value || 0)}
          />
          <Button
            onClick={handleProgressSave}
            type="primary"
            style={{ marginLeft: "10px" }}
          >
            저장
          </Button>
          <Button onClick={handleProgressCancel} style={{ marginLeft: "10px" }}>
            취소
          </Button>
        </>
      );
    }
    return (
      <>
        <Progress percent={project?.progress} />
        {userRole === "expert" && (
          <Button
            onClick={handleProgressEdit}
            type="link"
            icon={<EditOutlined />}
          />
        )}
      </>
    );
  };

  const renderServiceCostSection = () => {
    if (editingServiceCost) {
      return (
        <>
          <InputNumber
            value={newServiceCost}
            onChange={(value) => setNewServiceCost(value || 0)}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) =>
              value ? parseFloat(value.replace(/\$\s?|(,*)/g, "")) : 0
            }
          />
          <Button
            onClick={handleServiceCostSave}
            type="primary"
            style={{ marginLeft: "10px" }}
          >
            저장
          </Button>
          <Button
            onClick={handleServiceCostCancel}
            style={{ marginLeft: "10px" }}
          >
            취소
          </Button>
        </>
      );
    }
    return (
      <>
        {project?.service_cost !== undefined
          ? `${project.service_cost.toLocaleString()} 원`
          : "N/A"}
        {userRole === "expert" && (
          <Button
            onClick={handleServiceCostEdit}
            type="link"
            icon={<EditOutlined />}
          />
        )}
      </>
    );
  };

  const showEditTaskModal = (task: TaskModel) => {
    setEditingTask(task);
    setIsEditTaskModalVisible(true);
  };

  const handleEditTask = (values: any) => {
    if (project && editingTask) {
      const updatedTask: Partial<Task> = {
        title: values.title,
        description: values.description,
        dueDate: values.dueDate.format("YYYY-MM-DD"),
        assignedTo: values.assignedTo,
        attachments: values.attachments ? values.attachments.fileList : [],
      };
      updateTask(project.id, editingTask.id, updatedTask);
      setIsEditTaskModalVisible(false);
      message.success("작업이 수정되었습니다.");
      form.resetFields();
    }
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!project) {
    return <div>프로젝트를 찾을 수 없습니다.</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <Button
        icon={<ArrowLeftOutlined />}
        onClick={() => history.goBack()}
        style={{ marginBottom: "20px" }}
      >
        목록으로 돌아가기
      </Button>

      <Card>
        <div className={styles.projectHeader}>
          <Title level={2}>{project?.title}</Title>
          {project?.progress === 100 &&
          project.client.email === myInfo?.email ? (
            <Button
              type="primary"
              icon={<MessageOutlined />}
              size="large"
              className={styles.contactButton}
              onClick={() => setIsMessagingVisible(true)}
            >
              리뷰 쓰기
            </Button>
          ) : (
            ""
          )}
        </div>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="상태">
            {project?.status == "ongoging" ? "진행 중" : "완료"}
          </Descriptions.Item>
          <Descriptions.Item label="진행률">
            {renderProgressSection()}
          </Descriptions.Item>
          <Descriptions.Item label="시작">
            {project?.created_at}
          </Descriptions.Item>
          <Descriptions.Item label="종료">{""}</Descriptions.Item>
          <Descriptions.Item label="예산">
            {project?.service_cost !== undefined
              ? `${project.service_cost.toLocaleString()} 원`
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="용역 비용">
            {renderServiceCostSection()}
          </Descriptions.Item>
          <Descriptions.Item label="고객사">
            {project?.client.username}
          </Descriptions.Item>
          <Descriptions.Item label="담당자">
            {project?.expert.username}
          </Descriptions.Item>
          <Descriptions.Item label="설명" span={2}>
            {project?.description}
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
        <TabPane tab="작업 목록" key="1">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={showAddTaskModal}
            style={{ marginBottom: "20px" }}
          >
            새 작업 추가
          </Button>
          <List
            itemLayout="horizontal"
            dataSource={project?.tasks || []}
            renderItem={(item: TaskModel) => (
              <List.Item>
                <Row style={{ width: "100%" }}>
                  <Col span={16}>
                    <List.Item.Meta
                      title={<strong>{item.title}</strong>}
                      description={
                        <Space direction="vertical" size="small">
                          <Space>
                            {getStatusTag(item.status)}
                            <span>담당자: {item.assignedTo}</span>
                          </Space>
                          <span>마감일: {item.dueDate}</span>
                        </Space>
                      }
                    />
                  </Col>
                  <Col
                    span={8}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => showTaskDetails(item)}
                      icon={<EditOutlined />}
                      style={{ marginRight: "10px" }}
                    >
                      상세보기
                    </Button>
                    {item.status === "approved" && (
                      <Button
                        onClick={() => showEditTaskModal(item)}
                        type="primary"
                        icon={<EditOutlined />}
                      >
                        수정
                      </Button>
                    )}
                    {item.status === "pending" &&
                      ((userRole === "expert" &&
                        item.assignedTo === project?.client.username) ||
                        (userRole === "client" &&
                          item.assignedTo === project?.expert.username)) && (
                        <Button
                          onClick={() => handleApprove(item.id)}
                          type="primary"
                        >
                          승인
                        </Button>
                      )}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab="첨부파일" key="3">
          <List
            itemLayout="horizontal"
            dataSource={project.service_request.files} //파일
            renderItem={(item: FileModel) => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    icon={<DownloadOutlined />}
                    onClick={() => window.open(item.file_url, "_blank")}
                  >
                    다운로드
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={<FileOutlined style={{ fontSize: "24px" }} />}
                  title={item.file_name}
                  description={
                    <Space>
                      <span>크기: {formatFileSize(item.file_size)}</span>
                      <span>
                        업로드 날짜:{" "}
                        {new Date(item.uploaded_at).format("yyyy-MM-dd")}
                      </span>
                    </Space>
                  }
                />
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>

      <Modal
        title="작업 상세 정보"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="back" onClick={() => setIsModalVisible(false)}>
            닫기
          </Button>,
          selectedTask?.status === "waiting_approval" && (
            <Button
              key="approve"
              type="primary"
              onClick={() => handleApprove(selectedTask.id)}
            >
              승인
            </Button>
          ),
          selectedTask?.status === "approved" && (
            <Button
              key="cancel"
              type="default"
              onClick={() => handleCancelApproval(selectedTask.id)}
            >
              승인 취소
            </Button>
          ),
        ]}
      >
        {selectedTask && (
          <>
            <h3>{selectedTask.title}</h3>
            <p>{selectedTask.description}</p>
            <p>상태: {getStatusTag(selectedTask.status)}</p>
            <p>담당자: {selectedTask.assignedTo}</p>
            <p>마감일: {selectedTask.dueDate}</p>
            <h4>댓글</h4>
            <List
              dataSource={selectedTask.comments}
              renderItem={(comment) => (
                <Comment
                  author={comment.author.username}
                  datetime={comment.date}
                  actions={
                    comment.author.id === 1
                      ? [
                          <Space>
                            <Button type="link" onClick={() => {}}>
                              수정
                            </Button>
                            <Button type="link" danger onClick={() => {}}>
                              삭제
                            </Button>
                          </Space>,
                        ]
                      : []
                  }
                  content={
                    false ? (
                      <Space>
                        <Input.TextArea
                          value={""}
                          onChange={(e) => {}}
                          autoSize
                        />
                        <Button type="primary" size="small" onClick={() => {}}>
                          저장
                        </Button>
                        <Button size="small" onClick={() => {}}>
                          취소
                        </Button>
                      </Space>
                    ) : (
                      comment.content
                    )
                  }
                />
              )}
            />
            <div style={{ marginTop: "20px" }}>
              <Form.Item>
                <Input.TextArea
                  ref={commentRef}
                  placeholder="댓글을 입력하세요"
                  autoSize={{ minRows: 2 }}
                />
              </Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  registerComment();
                }}
                disabled={false}
              >
                댓글 작성
              </Button>
            </div>
          </>
        )}
      </Modal>

      <Modal
        title="새 작업 추가"
        visible={isAddTaskModalVisible}
        onCancel={() => setIsAddTaskModalVisible(false)}
        footer={null}
        getContainer={false}
        zIndex={1050} // zIndex 추가
      >
        <Form form={form} onFinish={handleAddTask} layout="vertical">
          <Form.Item
            name="title"
            label="작업 제목"
            rules={[{ required: true, message: "작업 목을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="작업 설명"
            rules={[{ required: true, message: "작업 설명을 입력해주세요" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="dueDate"
            label="마감일"
            rules={[
              { required: true, message: "마감일을 선택해주세요" },
              {
                validator: (_, value) => {
                  if (!value) return Promise.resolve();
                  const today = dayjs().startOf("day");
                  const inputDate = dayjs(value, "YYYY-MM-DD", true);

                  if (inputDate.isValid() && inputDate.isAfter(today)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("현재 날짜 이후로 입력해주세요.")
                  );
                },
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
              disabledDate={(current) =>
                !!current && current < dayjs().startOf("day")
              }
            />
          </Form.Item>
          <Form.Item name="assignedTo" label="담당자">
            <Input value={project.expert.username} />
          </Form.Item>
          <Form.Item
            name="attachments"
            label="첨부파일"
            valuePropName="fileList"
            getValueFromEvent={(e) => e && e.fileList}
          >
            <Upload
              fileList={fileList}
              action={""}
              name="file"
              listType="text"
              customRequest={() => {}}
              onChange={(e) => {
                if (e.file.status === "uploading") {
                  handleChangeFiles(e);
                }
              }}
            >
              <Button icon={<UploadOutlined />}>파일 선택</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              작업 추가
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="리뷰 쓰기"
        visible={isMessagingVisible}
        onCancel={() => setIsMessagingVisible(false)}
        footer={null}
        width={700}
      >
        <Form form={form} onFinish={handleEditTask} layout="vertical">
          <Form.Item
            name="rating"
            label="별점"
            rules={[{ required: true, message: "별점을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="리뷰 내용"
            rules={[{ required: true, message: "리뷰 내용을 입력해주세요" }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              작성 완료
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* <Modal
        title="메시지 보내기"
        visible={isMessagingVisible}
        onCancel={() => setIsMessagingVisible(false)}
        footer={null}
        width={700}
      >
        <ChatComponent
          currentUser={userRole === "expert" ? project.manager : project.client}
          expertId={project.manager}
        />
      </Modal> */}

      <Modal
        title="작업 수정"
        visible={isEditTaskModalVisible}
        onCancel={() => setIsEditTaskModalVisible(false)}
        footer={null}
        getContainer={false}
        zIndex={1050}
      >
        <Form
          form={form}
          onFinish={handleEditTask}
          layout="vertical"
          initialValues={
            editingTask
              ? {
                  title: editingTask.title,
                  description: editingTask.description,
                  dueDate: moment(editingTask.dueDate),
                  assignedTo: editingTask.assignedTo,
                }
              : {}
          }
        >
          <Form.Item
            name="title"
            label="작업 제목"
            rules={[{ required: true, message: "작업 제목을 입력해주세요" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="작업 설명"
            rules={[{ required: true, message: "작업 설명을 입력해주세요" }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name="dueDate"
            label="마감일"
            rules={[{ required: true, message: "마감일을 선택해주세요" }]}
          >
            <DatePicker
              style={{ width: "100%" }}
              getPopupContainer={(trigger) =>
                trigger.parentElement as HTMLElement
              }
            />
          </Form.Item>
          <Form.Item name="assignedTo" label="담당자">
            <Input disabled value={editingTask?.assignedTo} />
          </Form.Item>
          <Form.Item name="attachments" label="첨부파일">
            <Upload multiple listType="text">
              <Button icon={<UploadOutlined />}>파일 선택</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              수정 완료
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ProjectDetailPage;
