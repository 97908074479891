import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function ContractDocSkip(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table style={{ border: "hidden", tableLayout: "fixed" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td className="doc-title" style={{ fontSize: "40px", textAlign: "center", height: "150px" }} colspan="4">공사계약 붙임서류 생략서</td>
            </tr>

            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ borderRight: "hidden", textAlign: "center", height: "30px", fontSize: "20px" }}>□ 계약건명 :</td>
              <td style={{ fontSize: "20px", wordBreak: "break-all", wordWrap:"word-break"}} colspan="3">{worklist?.constructionName}</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "20px", height: "155px", textIndent: "5px" }}>지방자치를 당사자로 하는 계약에 관한 법률 시행령 제 49조 및 동법 시행규칙 제 47조의 규정에 의거 계약서 작성시 필요한 공사도급표준계약서의 붙임 서류중 다음 서류는 이미 공표되어 <u>그 내용을 충분히 인지 및 인정</u>하고 있으므로 서류 감축을 위해 생략하오며 이에 본 생략서를 제출합니다.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colspan="4" style={{ textAlign: "center" }}>다　　　　　음</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px" }} colspan="4">□ 생략하는 붙임서류</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px" }} colspan="4">　① 지방자치단체 입찰 및 계약집행기준(행정안전부 회계예규)</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px" }} colspan="4">　　- 회계예규 제 34호(2013.11.20)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px" }} colspan="4">　② 공사계약일반조건(행정안전부 회계예규)</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ lineHeight: "30px" }} colspan="4">　　- 회계예규 제 330호(2010.10.26)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "20px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colspan="4" style={{ textAlign: "center", fontSize: "15px" }}>
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "20px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ height: "40px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td style={{ fontSize: "20px" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>

          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default ContractDocSkip;