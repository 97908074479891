import 'antd/dist/antd.css';
import Modal from 'antd/lib/modal/Modal';
import { useEffect, useState } from 'react';



function ContractInputInfo(props) {
  const worklist = props.worklist;

  //수정, 저장 버튼에 대한 동작.
  const [input, setinput] = useState(true);

  const changeInput = (e) => {
    e.preventDefault();
    if (input === false) {
      setinput(!input);
    }
  };

  //금액 자동 콤마
  const [nonContractPrice, setNonContractPrice] = useState(worklist?.contractPrice);
  const [contractPrice, setContractPrice] = useState();

  const [nonContractGuaranteePrice, setNonContractGuaranteePrice] = useState(worklist?.contractGuaranteePrice);
  const [contractGuaranteePrice, setContractGuaranteePrice] = useState();

  const autoCommaContractPrice = (e) => {
    let value = e.target.value;
    setContractPrice(convertCommaValue(value));
    setNonContractPrice(Number(value.replaceAll(',', '')));
  }

  const autoCommaContractGuaranteePrice = (e) => {
    let value = e.target.value;
    setContractGuaranteePrice(convertCommaValue(value));
    setNonContractGuaranteePrice(Number(value.replaceAll(',', '')));
  }

  const handleSubmitAsync = async (e) => {
    let data = {};
    for (let index = 0; index < e.target.length; index++) {
      const element = e.target[index];
      data[element.name] = element.value;
    }
    data = {
      ...props.worklist,
      ...data,
    };
    e.preventDefault();
    if (input === true) {
      setinput(!input);
    }
    data.contractPrice = nonContractPrice === undefined ? worklist.contractPrice : nonContractPrice;
    data.contractGuaranteePrice = nonContractGuaranteePrice === undefined ? worklist.contractGuaranteePrice : nonContractGuaranteePrice;
    await props.putWorkListAsync(data);
  };

  useEffect(() => {
    setContractPrice(convertCommaValue(props.worklist?.contractPrice || ""));
    setContractGuaranteePrice(convertCommaValue(props.worklist?.contractGuaranteePrice || ""));
  }, [props.worklist?.contractPrice, props.worklist?.contractGuaranteePrice])

  function convertCommaValue(value) {
    value = Number(value.replaceAll(',', ''));
    if (isNaN(value)) {
      return 0;
    } else {
      const formatValue = value.toLocaleString('ko-KR');
      return formatValue;
    }
  }

  //modal open
  const [rateModalVisible, setRateModalVisible] = useState(false);
  const [termModalVisible, setTermModalVisible] = useState(false);

  const rateShowModal = (e) => {
    e.preventDefault();
    setRateModalVisible(true);
  }

  const termShowModal = (e) => {
    e.preventDefault();
    setTermModalVisible(true);
  }

  const handleCancel = () => {
    setRateModalVisible(false);
    setTermModalVisible(false);
  }

  return (
    <div>
      <div className="doc-container-box">
        <form
          name="infoForm"
          onSubmit={handleSubmitAsync}
        >
          <div className="doc-header">
            <h1>계약 정보 입력</h1>
          </div>

          <div style={{marginLeft: "30px"}}>
            <div className="info_line">
              <div className="doc-label" style={{ width: "160px" }}>
                기관명
              </div>
              <input
                defaultValue={worklist?.organName}
                className="doc-submit-input"
                placeholder="ex) 서울특별시"
                disabled={!input}
                name="organName"
                maxLength="20"
              />
            </div>

            <div
              className="info_line"  >
              <div className="doc-label" style={{ width: "160px" }}>
                발주처
              </div>
              <input
                defaultValue={worklist?.orderOrganization}
                className="doc-submit-input"
                placeholder="ex) ㅇㅇ시 ㅇㅇ사무소"
                disabled={!input}
                name="orderOrganization"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                공사명
              </div>
              <input
                defaultValue={worklist?.constructionName}
                className="doc-submit-input"
                placeholder="ex) ㅇㅇ시 상하수도 공사"
                disabled={!input}
                name="constructionName"
                maxLength="100"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                공사위치
              </div>
              <input
                defaultValue={worklist?.constructionAddress}
                className="doc-submit-input"
                placeholder="공사위치를 입력해주세요."
                disabled={!input}
                name="constructionAddress"
                maxLength="50"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계약지역
              </div>
              <input
                defaultValue={worklist?.contractRegion}
                className="doc-submit-input"
                placeholder="ex) 서울특별시"
                disabled={!input}
                name="contractRegion"
                maxLength="100"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계약일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.contractDate}
                disabled={!input}
                name="contractDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계약금액
              </div>
              <input
                value={contractPrice}
                className="doc-submit-input"
                placeholder="계약금액을 입력해주세요."
                disabled={!input}
                name="contractPrice"
                onChange={autoCommaContractPrice}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                계약보증금액
              </div>
              <input
                value={contractGuaranteePrice}
                className="doc-submit-input"
                placeholder="계약보증금액을 입력해주세요."
                disabled={!input}
                name="contractGuaranteePrice"
                onChange={autoCommaContractGuaranteePrice}
                maxLength="17"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                하자보수보증금율 (%)
              </div>
              <input
                defaultValue={worklist?.contractRepairRate}
                className="doc-submit-input"
                placeholder="숫자만 입력해 주세요. ex) 3"
                disabled={!input}
                name="contractRepairRate"
                maxLength="3"
              />
              <div>
                <button onClick={rateShowModal} className="ref_btn">참고</button>
              </div>
              <Modal className="repair_ref_chart" title="하자보수보증금율" style={{ textAlign: "center" }} width={1000} visible={rateModalVisible} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
                <table style={{ width: "800px", display: "inline-table"}}>
                  <tr style={{ height: "50px" }}>
                    <td style={{ width: "550px", fontSize: "16px", fontWeight: "600" }}>공종</td>
                    <td style={{ width: "130px", fontSize: "16px", fontWeight: "600" }}>요율</td>
                    <td style={{ width: "71px", fontSize: "16px", fontWeight: "600" }}>비고</td>
                  </tr>
                  <tr style={{ height: "40px" }}>
                    <td style={{textAlign: "left", paddingLeft: "5px"}}>철도, 댐, 터널, 철강교설치, 발전설비, 교량, 상하수도구조물 등 중요 구조물공사 및 조경공사</td>
                    <td>100분의 5</td>
                    <td>5%</td>
                  </tr>
                  <tr style={{ height: "40px" }}>
                    <td style={{textAlign: "left", paddingLeft: "5px"}}>공항, 항만, 삭도설치, 방파제, 사방, 간척 등 공사</td>
                    <td>100분의 4</td>
                    <td>4%</td>
                  </tr>
                  <tr style={{ height: "40px" }}>
                    <td style={{textAlign: "left", paddingLeft: "5px"}}>관개수로, 도로(포장공사를 포함한다), 매립, 상하수도관로, 하천, 일반건축 등 공사</td>
                    <td>100분의 3</td>
                    <td>3%</td>
                  </tr>
                  <tr style={{ height: "40px" }}>
                    <td style={{textAlign: "left", paddingLeft: "5px"}}>위 공사를 제외한 공사</td>
                    <td>100분의 2</td>
                    <td>2%</td>
                  </tr>
                  <tr style={{ height: "40px" }}>
                    <td style={{textAlign: "left", paddingLeft: "5px"}}>물품의 제조</td>
                    <td>100분의 3</td>
                    <td>3%</td>
                  </tr>
                  <tr style={{ height: "40px" }}>
                    <td style={{textAlign: "left", paddingLeft: "5px"}}>수리, 가공, 구매, 용역</td>
                    <td>100분의 2</td>
                    <td>2%</td>
                  </tr>
                </table>
              </Modal>
            </div>
            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                지체상금율 (%)
              </div>
              <input
                defaultValue={worklist?.compensationOfDeferment}
                className="doc-submit-input"
                placeholder="숫자만 입력해 주세요. ex) 0.1"
                disabled={!input}
                name="compensationOfDeferment"
                maxLength="5"
              />
            </div>
            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                하자담보책임기간
              </div>
              <input
                defaultValue={worklist?.contractRepairTerm}
                className="doc-submit-input"
                placeholder="ex) 2년"
                disabled={!input}
                name="contractRepairTerm"
                maxLength="5"
              />
              <button onClick={termShowModal} className="ref_btn">참고</button>
              <Modal className="repair_ref_chart" title="건설공사의 종류별 하자담보책임기간" style={{ textAlign: "center" }} width={1000} visible={termModalVisible} onCancel={handleCancel} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
              <table style={{ width: "800px", display: "inline-table", textAlign: "left"}}>
                  <tr style={{textAlign: "center", height: "40px", fontSize: "16px", fontWeight: "600"}}>
                    <td style={{width: "200px"}}>공사별</td>
                    <td>세부공종별</td>
                    <td style={{width: "80px"}}>책임기간</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="3">1. 교량</td>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>① 기둥사이의 거리가 50m 이상이거나 길이가 500m 이상인 교량의 철근콘크리트 또는 철골구조부</td>
                    <td style={{textAlign: "center"}}>10년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② 길이가 500m 미만인 교량의 철근콘크리트 또는 철골구조부</td>
                    <td style={{textAlign: "center"}}>7년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>③ 교량 중 ①·② 외의 공종(교면포장, 이음부, 난간시설 등)</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="2">2. 터널</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>① 터널(지하철을 포함한다)의 철근콘크리트 또는 철골구조부</td>
                    <td style={{textAlign: "center"}}>10년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② 터널 중 ①외의 공종</td>
                    <td style={{textAlign: "center"}}>5년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="2">3. 철도</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>① 교량·터널을 제외한 철도시설 중 철근콘크리트 또는 철골구조</td>
                    <td style={{textAlign: "center"}}>7년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② ①외의 시설</td>
                    <td style={{textAlign: "center"}}>5년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="2">4. 공항·삭도</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>① 철근콘크리트·철골구조부</td>
                    <td style={{textAlign: "center"}}>7년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② ①외의 시설</td>
                    <td style={{textAlign: "center"}}>5년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="2">5. 항만·사방간척</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>① 철근콘크리트·철골구조부</td>
                    <td style={{textAlign: "center"}}>7년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② ①외의 시설</td>
                    <td style={{textAlign: "center"}}>5년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="2">6. 도로</td>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>① 콘크리트 포장 도로 [암거(땅속 또는 구조물 속 도랑) 및 측구(길도랑)를 포함한다]</td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② 아스팔트 포장 도로 (암거 및 측구를 포함한다)</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="2">7. 댐</td>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>① 본체 및 여수로 (餘水路: 물이 일정량을 넘을 때 여분의 물을 빼내기 위하여 만든 물길을 말한다) 부분</td>
                    <td style={{textAlign: "center"}}>10년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② ①외의 시설</td>
                    <td style={{textAlign: "center"}}>5년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="2">8. 상.하수도</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>① 철근콘크리트·철골구조부</td>
                    <td style={{textAlign: "center"}}>7년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② 관로 매설·기기설치</td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px", fontWeight: "600"}}>9. 관계수로·매립</td>
                    <td></td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px", fontWeight: "600"}}>10. 부지정지</td>
                    <td></td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}}>11. 조경</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>조경시설물 및 조경식재</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="3">12. 발전·가스 및 산업설비</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>① 철근콘크리트·철근구조부</td>
                    <td style={{textAlign: "center"}}>7년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>② 압력이 1제곱센치미터당 10킬로그램 이상인 고압가스의 관로(부대기기를 포함한다) 설치공사</td>
                    <td style={{textAlign: "center"}}>5년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>③ ①·② 외의 시설</td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px", fontWeight: "600"}}>13. 기타 토목공사</td>
                    <td></td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="3">14. 건축</td>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>① 대형공공성 건축물(공동주택·종합병원·관광숙박시설·관람집회시설·대규모소매점과 16층 이상 기타 용도의 건축물)의 기둥 및 내력벽</td>
                    <td style={{textAlign: "center"}}>10년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>② 대형공공성 건축물 중 기둥 및 내력벽 외의 구조상 주요부분과 ① 외의 건축물 중 구조상 주요부분</td>
                    <td style={{textAlign: "center"}}>5년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>③ 건축물 중 ①·②와 제 15호의 전문공사를 제외한 기타부분</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", fontWeight: "600"}} rowSpan="19">15. 전문공사</td>
                    <td style={{paddingLeft: "5px", height: "30px"}}>① 실내건축</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>② 토공</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>③ 미장·타일</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>④ 방수</td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑤ 도장</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑥ 석공사·조적</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑦ 창호설치</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑧ 지붕</td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑨ 판금</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑩ 철물 (제 1호 내지 제 14호에 해당하는 철골을 제외한다)</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>⑪ 철근콘크리트 (제 1호부터 제 14호까지의 규정에 해당하는 철근콘크리트는 제외한다) 및 콘크리트 포장</td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑫ 급배수·공동구·지하저수조·냉난방·환기·공기조화·자동제어·가스·배연설비</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑬ 승강기 및 인양기기 설비</td>
                    <td style={{textAlign: "center"}}>3년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑭ 보일러 설치</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑮ ⑫·⑭ 외의 건물내 설비</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑯ 아스팔트 포장</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑰ 보링</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", wordBreak: "keep-all", height: "60px"}}>⑱ 건축물조립 (건축물의 기둥 및 내력벽의 조립을 제외하며, 이는 제 14호에 따른다)</td>
                    <td style={{textAlign: "center"}}>1년</td>
                  </tr>
                  <tr>
                    <td style={{paddingLeft: "5px", height: "30px"}}>⑲ 온실설치</td>
                    <td style={{textAlign: "center"}}>2년</td>
                  </tr>
                </table>
              </Modal>
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                착공일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.beginDate}
                disabled={!input}
                name="beginDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                준공일자
              </div>
              <input
                type="date"
                defaultValue={worklist?.completionDate}
                disabled={!input}
                name="completionDate"
                className="doc-submit-input"
                maxLength="20"
              />
            </div>

            <div
              className="info_line" >
              <div className="doc-label" style={{ width: "160px" }}>
                귀하
              </div>
              <input
                defaultValue={worklist?.dear}
                className="doc-submit-input"
                placeholder="ex) 김ㅇㅇ"
                disabled={!input}
                name="dear"
                maxLength="20"
              />
            </div>
          </div>

          <div className="button_container">
            <button style={{marginRight: "50px"}} className={`doc_button_modi ${!input ? '' : 'btn_hidden'}`} onClick={changeInput}>
              <span style={{ fontSize: "15px", color: "white" }}>수정</span>
            </button>
            <button style={{marginRight: "50px"}} className={`doc_submit ${input ? '' : 'btn_hidden'}`} htmlType="submit">
              <span style={{ fontSize: "15px", color: "white" }}>저장</span>
            </button>
          </div>

        </form>
      </div>
    </div >
  );
}

export default ContractInputInfo;