import './index.css';
import { Button, BackTop } from 'antd';
import { PrinterOutlined, UpCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

function ContractDepositPayment(props) {
  const worklist = props.worklist;
  if (!worklist) {
    return <div>로딩중...</div>
  }

  const printedBtn = () => {
    document.body.innerHTML = document.getElementById('printArea').innerHTML;
    window.print();
    window.location.reload();
  }

  return (
    <div>
      <div id="printArea">
        <div className="page-doc">
          <table style={{ textAlign: "center", tableLayout: "fixed" }}>
            <tr>
              <td style={{ paddingBottom: "50px" }} className="doc-title" colspan="4" >계약보증금납부서</td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>입</span>
                  <span>찰</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td>　</td>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>입</span>
                  <span>찰</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
              </td>
              <td>　</td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>건</span>
                  <span>명</span>
                </span>
              </td>
              <td style={{ wordBreak: "break-all", wordWrap:"word-break", textAlign: "left", paddingLeft: "5px" }} colspan="3">{worklist?.constructionName}</td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td>　</td>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>(예</span>
                  <span>정)</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
              </td>
              <td>
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>금</span>
                  <span>액</span>
                </span>
              </td>
              <td style={{ textAlign: "left", paddingLeft: "5px" }} colspan="3">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>보</span>
                  <span>증</span>
                  <span>금</span>
                  <span>액</span>
                </span>
              </td>
              <td style={{ textAlign: "left", paddingLeft: "5px" }} colspan="3">
                {`일금 ${props.krContractGuaranteePrice}정 (￦${props.commaContractGuaranteePrice})`}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>보</span>
                  <span>증</span>
                  <span>금</span>
                  <span>납</span>
                  <span>부</span>
                  <span>방</span>
                  <span>법</span>
                </span>
              </td>
              <td style={{ textAlign: "left", paddingLeft: "5px" }} colspan="3">□ 보증서　□ 보증보험증권　□ 보증금지급각서</td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td>
                <span style={{ width: "120px", display: "inline-flex", justifyContent: "space-between" }}>
                  <span>계</span>
                  <span>약</span>
                  <span>이</span>
                  <span>행</span>
                  <span>기</span>
                  <span>간</span>
                </span>
              </td>
              <td colspan="3">
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date' & moment(worklist?.completionDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : `${moment(worklist?.contractDate).format('yyyy년　MM월　DD일')}　~　${moment(worklist?.completionDate).format('yyyy년　MM월　DD일')}`
                }
              </td>
            </tr>

            <tr style={{ height: "80px", fontSize: "20px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden", textAlign: "left" }}>
              <td colspan="4">위의 금액을 계약보증금으로 납부합니다.</td>
            </tr>

            <tr>
              <td style={{ fontSize: "15px", height: "50px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }} colspan="4">
                {
                  moment(worklist?.contractDate).format('yyyy년　MM월　DD일') === 'Invalid date'
                    ? ''
                    : moment(worklist?.contractDate).format('yyyy년　MM월　DD일')
                }
              </td>
            </tr>

            <tr>
              <td colSpan="4" height="40px" style={{ borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}></td>
            </tr>

            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info"><span className="three-string">상호명</span>:　<span>{worklist?.companyName}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">주소</span>:　<span style={{ display: "inline-flex", width: "190px", wordBreak: "keep-all" }}>
                {worklist?.businessAddress}</span>
              </td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">전화</span>:　<span>{worklist?.phoneNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="two-string">팩스</span>:　<span>{worklist?.faxNum}</span></td>
            </tr>
            <tr style={{ height: "30px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info"><span className="three-string">대표자</span>:　<span style={{ letterSpacing: "10px" }}>{worklist?.representativeName}</span>(인)</td>
            </tr>
            <tr>
              <td colSpan="4" style={{ height: "80px", borderLeft: "hidden", borderRight: "hidden", borderBottom: "hidden" }}></td>
            </tr>
            <tr style={{ height: "40px", borderBottom: "hidden", borderLeft: "hidden", borderRight: "hidden" }}>
              <td style={{ fontSize: "20px", textAlign: "left" }} colSpan="4">{`${!worklist?.dear ? '' : worklist?.dear} 귀하`}</td>
            </tr>

          </table>
        </div>
      </div>
      <Button className="circleBtn" style={{ backgroundColor: "rgb(68, 132, 255)", float: "right", position: "fixed", right: "63px", bottom: "200px" }} onClick={printedBtn}>
        <div><PrinterOutlined className="printer-icon" style={{ fontSize: "30px" }} /></div>
        <div style={{ color: "white" }}>현재 페이지<div style={{ color: "white" }}>출력</div></div>
      </Button>
      <BackTop style={{ bottom: "150px" }}><UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} /></BackTop>
    </div>
  );
}

export default ContractDepositPayment;