import React from 'react'
import { UserBoardDetailModel } from '../../models/userboard/UserBoardModel';
import styles from "./UserBoardDetail.module.scss";


interface Props{
    boardDetail: UserBoardDetailModel;
    onClickList: ()=> void;
}

const UserBoardDetailPresenter = (props:Props) => {
    //console.log(props.boardDetail)
  return (
    <div className="notice-detail-wrapper">
    <div className="width-center">
      <div className="notice-title">게시글</div>
      <div className="notice-detail-content">
        <div className="notice-detail-title">
            <span>{props.boardDetail?.title}</span>
            <span>{props.boardDetail?.category}</span>      
         
        </div>
        <div className="notice_content_wrapper">
            <p>{props.boardDetail?.content}</p>
            <div>{props.boardDetail?.created?.format("yyyy.MM.dd")}</div>
        
        </div>
      </div>
     <div className="bottom_container">
        <button onClick={props.onClickList}>목록으로</button>
       
     </div>
    </div>
  </div>
  )
}

export default UserBoardDetailPresenter