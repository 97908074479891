/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { FreeUseDay } from "../../constants/General";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import { LoadingContext } from "../../contexts/LoadingContext";

interface Props {}

export default (props: Props) => {
  const [current, setCurrent] = useState<number>(1);
  const alertContext = useContext(AlertContext);
  const history = useHistory();
  const [downloadDataList, setDownloadDataList] = useState<any[]>();
  const [totalCount, setTotalCount] = useState<number>();
  const [lastPage, setLastPage] = useState<number>();
  const searchText = useRef<HTMLInputElement>(null);
  const loadingContext = useContext(LoadingContext);
  const defaultImage = "/images/ddusul/3d-file.png";
  const { search } = useLocation();
  const pageViewSize = 5;

  useEffect(() => {
    if (!util.isLogin()) {
      alertContext.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " +
          FreeUseDay +
          "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
      return;
    } else {
      new Promise(async () => {
        const isPurchased = await util.getIsPurchased();
        if (!isPurchased) {
          alertContext.showAlert(
            "이용권 구매 후 이용해주세요.\n회원가입시 " +
              FreeUseDay +
              "일 무료기간 제공중입니다.",
            () => {
              history.replace(pages.payment);
            }
          );
          return;
        }
      });
    }
    const query = new URLSearchParams(search);
    const q = query.get("q");
    if (q) {
      searchText.current.value = q;
    }
    handleSearchChange(q ?? "", 1);
  }, []);

  const handleSearchChange = async (value: string, page: number) => {
    const pageSize = 9;
    const res = await api.getDownloadDataSearch(
      value,
      pageSize * (page - 1),
      pageSize
    );
    const list = res.data.result;
    const lastPage =
      res.data.count % 9 > 0 ? res.data.count / 9 + 1 : res.data.count / 9;
    setLastPage(Math.floor(lastPage));
    setTotalCount(res.data.count);
    setDownloadDataList(list);
    setCurrent(page);
  };

  const handleFileClick = async (id: number) => {
    try {
      await loadingContext.showLoading("다운로드 중입니다.");
      const res = await api.getDownloadDataLink(id);
      const url = res.data.result;
      const fileName = url.split("/")[url.split("/").length - 1];
      const fileNameWithoutId = fileName.split("_").slice(2).join("_");

      const response = await axios.get(url, {
        responseType: "blob",
        headers: {
          "Content-Disposition": "attachment; filename=remnantwatch.hwp",
        },
      });

      const url2 = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download", fileNameWithoutId);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    loadingContext.closeLoading();
  };

  const pageList = () => {
    const totalPage: number = totalCount!;
    const lastPage = totalPage % 9 > 0 ? totalPage / 9 + 1 : totalPage / 9;
    const roundLastPage = Math.floor(lastPage);
    let pages = [];
    pages.push(1);

    if (current > roundLastPage - pageViewSize) {
      if (roundLastPage <= pageViewSize) {
        for (let i = 2; i < roundLastPage; i++) {
          pages.push(i);
        }
      } else {
        for (let i = roundLastPage - pageViewSize; i < roundLastPage; i++) {
          pages.push(i);
        }
      }
    } else if (roundLastPage < current + pageViewSize) {
      for (let i = 2; i < roundLastPage; i++) {
        pages.push(i);
      }
    } else {
      for (let i = current; i < current + 6; i++) {
        if (i != 1) {
          pages.push(i);
        }
      }
    }
    // setLastPage(roundLastPage);
    if (roundLastPage != 1) pages.push(roundLastPage);
    // console.log(pages);
    return pages;
  };

  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">자료실</div>
            <div className="col-12 pt-4">
              <form>
                <div className="input-group mb-9" id="search-form">
                  <button className="btn btn-md dropdown-toggle" type="button">
                    전체
                  </button>
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="검색어를 입력해주세요"
                    aria-label="Text input with dropdown button "
                    ref={searchText}
                    onChange={(event) => {
                      // console.log(event.target.value);
                      handleSearchChange(event.target.value, 1);
                    }}
                  />
                </div>
              </form>
            </div>
            {
              <>
                <div className="col-12">
                  <div className="mt-5">
                    <div>
                      TOTAL
                      <span className="text-primary fw-bold ms-1">
                        {totalCount}
                      </span>
                    </div>
                  </div>
                  <div
                    className="board-wrap aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-duration="900"
                  >
                    {downloadDataList?.map((data, index) => {
                      const fileSize = +data.fileSize;
                      // kb 혹은 mb 단위로 표시
                      const unit = fileSize > 900 * 900 ? "MB" : "KB";
                      const size =
                        fileSize > 900 * 900
                          ? (fileSize / 900 / 900).toFixed(2)
                          : (fileSize / 900).toFixed(2);

                      return (
                        <div
                          className="board-card"
                          key={data.id}
                          onClick={() => {
                            history.replace(
                              pages.data + "?q=" + searchText.current.value
                            );
                            history.push(pages.data + "/" + data.id);
                          }}
                        >
                          <a className="file-info">
                            <div className="file">
                              <div className="file-type">
                                <img
                                  src={`/images/ddusul/icon/${
                                    data.fileName.split(".")?.[1]
                                  }.png`}
                                  onError={(e) => {
                                    e.currentTarget.src = defaultImage;
                                  }}
                                />
                              </div>
                              <div className="title">{data.title}</div>
                              <div className="body2 text-gray-400">
                                {!isNaN(fileSize) ? (
                                  <>
                                    <span>{size}</span>
                                    {unit}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="file-down">
                              <span
                                className="material-symbols-outlined"
                                onClick={() => {
                                  handleFileClick(data.id);
                                }}
                              >
                                download
                              </span>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-md-5 mx-auto">
                  <div className="paging">
                    <a
                      id="prev"
                      onClick={() => {
                        if (current > 1)
                          handleSearchChange(
                            searchText.current.value,
                            current - 1
                          );
                      }}
                    >
                      <span className="material-symbols-outlined">
                        chevron_left
                      </span>
                    </a>
                    {pageList().map((page) => {
                      return (
                        <a
                          className={current === page ? "active" : ""}
                          onClick={() => {
                            handleSearchChange(searchText.current.value, page);
                          }}
                        >
                          {page}
                        </a>
                      );
                    })}

                    <a
                      id="next"
                      onClick={() => {
                        if (current < lastPage!)
                          handleSearchChange(
                            searchText.current.value,
                            current + 1
                          );
                      }}
                    >
                      <span className="material-symbols-outlined">
                        chevron_right
                      </span>
                    </a>
                  </div>
                </div>
              </>
            }
          </div>
        </section>
      </article>
    </main>
  );
};
