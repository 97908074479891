import React from "react";
import { Typography } from "antd";

import styles from "./ServiceStatusPage.module.scss";
import { ExternalProjectList } from "../../components/ExternalProjectList";

const { Title } = Typography;

const ServiceStatusPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <Title level={1}>용역 진행 현황</Title>
      <ExternalProjectList showAll={true} />
    </div>
  );
};

export default ServiceStatusPage;
