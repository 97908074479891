import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { LevelTestResContext } from "../../contexts/LevelTestContext";
import NextPrev from "./components/NextPrev";
import Timer from "./components/Timer";

export default () => {
  const levelTestRes = useContext(LevelTestResContext);
  const history = useHistory();
  const [levelTestAnswer, setLevelTestAnswer] = useState(0);
  const levelTestAnswerTimerRef = useRef<HTMLInputElement | null>(null);

  const next = () => {
    levelTestRes.setLevelAnswer(
      8,
      levelTestAnswer,
      levelTestAnswerTimerRef.current!.value
    );

    history.push(pages.leveltest + "/9");
  };

  const previous = () => {
    history.push(pages.leveltest + "/7");
  };
  return (
    <main id="wrap">
      <div className="progress top-progress">
        <div
          className="progress-bar "
          role="progressbar"
          aria-label="Basic example"
          style={{ width: "80%" }}
          aria-valuenow="80"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <section className="bg-light-md">
        <div className="container page py-120">
          <div className="form-wrap col-md-7 mx-auto ">
            <form>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="badge body2 text-bg-secondary">Question.8</div>
                <Timer forwardedRef={levelTestAnswerTimerRef} />
              </div>
              <h4 className="text-dark mb-5">
                다음 내용의 서류는 무엇인지 보기를 보고 선택하세요.
              </h4>
              <div className="mb-5">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td className="bg-gray-50">레미콘 타설</td>
                      <td colspan="3">1m³</td>
                    </tr>
                    <tr>
                      <td className="bg-gray-50">레미콘 타설</td>
                      <td>1.02m³</td>
                      <td colspan="2" className="text-end">
                        88,000
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-gray-50">보통인부</td>
                      <td>0.2인</td>
                      <td colspan="2" className="text-end">
                        142,825
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-gray-50">특별인부</td>
                      <td>0.012인</td>
                      <td colspan="2" className="text-end">
                        212,458
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className="d-flex flex-wrap gap-3 mb-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option8-1"
                    value={0}
                    checked={levelTestAnswer === 0}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option8-1"
                  >
                    수량집계표
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option8-2"
                    value={1}
                    checked={levelTestAnswer === 1}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option8-2"
                  >
                    일위대가표
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option8-3"
                    value={2}
                    checked={levelTestAnswer === 2}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option8-3"
                  >
                    단위수량산출서
                  </label>
                </div>
              </div>
              <NextPrev previous={previous} next={next} />
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};
