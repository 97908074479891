import './index.css';
import React, { useContext, useEffect, useState } from "react";
import { Pagination, Input, Button, Tooltip, Modal, Menu, Dropdown, Divider, Form } from "antd";
import { ContactsOutlined, EllipsisOutlined, PlusOutlined, PlusSquareOutlined, UserAddOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';

import { FreeUseDay } from "../../constants/General";
import { api } from '../../config/api';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import AlertContext from '../../contexts/AlertContext';
import { util } from '../../config/util';
import { Cookies } from '../../constants/Cookies';
import { pages } from '../../constants/PagePaths';
import { LoadingContext } from '../../contexts/LoadingContext';
import DupLoginContext from '../../contexts/DupLoginContext';
import Icon01 from "../../assets/img/icon/info.png";

dayjs.extend(relativeTime);

function WorkListPage() {

  //클릭하는 Hover의 id값 추출
  const [idState, setIdState] = useState();
  const getId = (e) => {
    let idTarget = e.currentTarget;
    
    let id = Number($(idTarget).attr('value'));
    
    setIdState(id);
    
  }

  //업무 목록 서버에서 불러오기
  const [worklists, setWorklists] = React.useState([]);
  const [current, setCurrent] = useState(1);
  const pageSize = 8;
  const [minIndex, setMinIndex] = useState();
  const [maxIndex, setMaxIndex] = useState();
  const history = useHistory();
  const loading = useContext(LoadingContext);
  const dupLogin = useContext(DupLoginContext);

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert("로그인 후 이용해주세요.\n회원가입시 " + FreeUseDay + "일 무료기간 제공중입니다.", () => {
        history.replace(pages.login);
      });
      return;
    } else {
      new Promise(async () => {
        try {
          await api.getMyInfo();
        } catch (err) {
          if (err?.response?.status === 401) {
            alert.showAlert(
              "다른 브라우저에서 로그인 혹은 로그아웃하여\n현재 브라우저에서 로그아웃 합니다.",
              () => {
                history.push(pages.index);
              }
            );
          }
        }
      })
    }

    loading.showLoading();
      if (worklists.length === 0) {
        dupLogin.checkDupLogin(() => api.getWorklists({ offset: 0, limit: worklists.length }).then((result) => {
          const worklists = result.data.worklist_list;
          setWorklists(worklists);
          setMinIndex(0);
          setMaxIndex(pageSize);
        }).catch((error) => {
          throw error;
        }, [pageSize]).finally(() => {
          loading.closeLoading();
        }))
      }

      if (companyInfo.length === 0) {
        dupLogin.checkDupLogin(() => api.getWorkCompanyList({ offset: 0, limit: companyInfo.length }).then((result) => {
          const companyInfo = result.data.company_list;
          setCompanyInfo(companyInfo);
          setCompanyMinIndex(0);
          setCompanyMaxIndex(companyPageSize);
          
        }).catch((error) => {
          throw error;
        }, [companyPageSize]).finally(() => {
          loading.closeLoading();
        }));
      }

      if (deputyInfo.length === 0) {
        dupLogin.checkDupLogin(() => api.getDeputyList({ offset: 0, limit: deputyInfo.length }).then((result) => {
          const deputyInfo = result.data.deputy_list;
          setDeputyInfo(deputyInfo);
          setDeputyMinIndex(0);
          setDeputyMaxIndex(deputyPageSize);
        }).catch((error) => {
          throw error;
        }).finally(() => {
          loading.closeLoading();
        }));
      }
  }, []);

  //pagenation
  const handleChange = (page) => {
    setCurrent(page);
    setMinIndex((page - 1) * pageSize);
    setMaxIndex(page * pageSize);
  }

  const [companyCurrent, setCompanyCurrent] = useState(1);
  const companyPageSize = 8;
  const [companyMinIndex, setCompanyMinIndex] = useState();
  const [companyMaxIndex, setCompanyMaxIndex] = useState();

  //업체 목록 서버에서 불러오기 (여러개)
  const [companyInfo, setCompanyInfo] = React.useState([]);

  //modal-company-pagenation
  const companyHandleChange = (page) => {
    setCompanyCurrent(page);
    setCompanyMinIndex((page - 1) * companyPageSize);
    setCompanyMaxIndex(page * companyPageSize)
  }

  const [deputyCurrent, setDeputyCurrent] = useState(1);
  const deputyPageSize = 8;
  const [deputyMinIndex, setDeputyMinIndex] = useState();
  const [deputyMaxIndex, setDeputyMaxIndex] = useState();

  //현장대리인 목록 서버에서 불러오기 (여러개)
  const [deputyInfo, setDeputyInfo] = React.useState([]);

  const deputyHandleChange = (page) => {
    setDeputyCurrent(page);
    setDeputyMinIndex((page - 1) * deputyPageSize);
    setDeputyMaxIndex(page * deputyPageSize)
  }

  ///////////////////////////////////////////////////////////////////
  //검색 필터 구현
  //빈 값 체크 함스
  const isEmpty = function (value) {
    if (value === "" || value === null || value === undefined || (value != null && typeof value === "object" && !Object.key(value).length)) {
      return true
    } else {
      return false
    }
  }

  const { Search } = Input;

  const [filterInput, setFilterInput] = useState('');
  const filterData = (e) => {
    if (isEmpty(filterInput)) return worklists
    if (!isEmpty(filterInput))
      return worklists.filter(({ constructionName }) => constructionName.includes(filterInput))
  }

  //알람 사용
  const alert = useContext(AlertContext);

  const handleWorklistDelete = () => {
    alert.showConfirmAlert("알림", "공사 목록을 삭제하시겠습니까?", "예", "아니오", () => {
      loading.showLoading();
      dupLogin.checkDupLogin(() => api.deleteWorklist(idState).then(() => {
        const index = worklists.findIndex(element => element.worklist_id === idState);
        const cloneWorklists = _.cloneDeep(worklists);
        cloneWorklists.splice(index, 1);
        setWorklists(cloneWorklists);
      }).finally(() => {
        loading.closeLoading();
      }));
    })
  };

  const menu = (
    <Menu onClick={handleWorklistDelete}>
      <Menu.Item style={{ color: "red" }}>
        삭제
      </Menu.Item>
    </Menu>
  )

  ///////////////////////////////////////////////////////////////////
  //팝업 창 구현
  //업체 정보
  const [isModalVisible1, setIsModalVisible1] = useState(false);

  const showModal1 = () => {
    setIsModalVisible1(true);
  };

  const handleOk1 = () => {
    setIsModalVisible1(false);
  };

  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };

  //업체 정보 신규 등록
  const [isModalVisible3, setIsModalVisible3] = useState(false);

  const showModal3 = () => {
    setIsModalVisible3(true);
  };

  const handleCancel3 = () => {
    form.resetFields();
    setIsModalVisible3(false);
  };

  //업체 정보 신규 등록에서 확인 클릭 시 동작
  const [form] = Form.useForm();
  const handleOk3 = (values) => {
    loading.showLoading();
    dupLogin.checkDupLogin(() => api.postWorkCompany(values).then((result) => {
      //window.location.reload();
      dupLogin.checkDupLogin(() => api.getWorkCompanyList({ offset: 0, limit: 100 }).then((result) => {
        const companyInfo = result.data.company_list;
        console.log('cInfo',companyInfo);
        setCompanyInfo(companyInfo);
      }).finally(() => {
        loading.closeLoading();
      }));
    }).catch((error) => {
      loading.closeLoading();
      throw error;
    }));
    form.resetFields();
    setIsModalVisible3(false);
  };

  //업체 정보 삭제 기능
  const handleCompanyDelete = () => {
    alert.showConfirmAlert("알림", "업체 정보를 삭제하시겠습니까?", "예", "아니오", () => {
      loading.showLoading();
      dupLogin.checkDupLogin(() => api.deleteWorkCompany(idState).then(() => {
        const index = companyInfo.findIndex(element => element.company_id === idState);
        const cloneCompanyInfo = _.cloneDeep(companyInfo);
        cloneCompanyInfo.splice(index, 1);
        setCompanyInfo(cloneCompanyInfo);
      }).finally(() => {
        loading.closeLoading();
      }));
    })
  };

  //업체 정보 수정 클릭 시 
  const [isModalVisible11, setIsModalVisible11] = useState(false);

  const showModal11 = () => {
    setIsModalVisible11(true);
  };

  const handleOk11 = (values) => {
    loading.showLoading();
    let selectCompanyInfo = companyInfo.filter((companyInfo) => companyInfo.company_id === idState);
    let object = { companyName: "", representativeName: "", corporateRegistNum: "", corporateNum: "", businessAddress: "", typeOfBusiness: "", phoneNum: "", faxNum: "", writer: "", bankName: "", accountNum: "", accountHolder: "" }
    if (values.companyName === undefined) object.companyName = selectCompanyInfo[0].companyName; else object.companyName = values.companyName;
    if (values.representativeName === undefined) object.representativeName = selectCompanyInfo[0].representativeName; else object.representativeName = values.representativeName;
    if (values.corporateRegistNum === undefined) object.corporateRegistNum = selectCompanyInfo[0].corporateRegistNum; else object.corporateRegistNum = values.corporateRegistNum;
    if (values.corporateNum === undefined) object.corporateNum = selectCompanyInfo[0].corporateNum; else object.corporateNum = values.corporateNum;
    if (values.businessAddress === undefined) object.businessAddress = selectCompanyInfo[0].businessAddress; else object.businessAddress = values.businessAddress;
    if (values.typeOfBusiness === undefined) object.typeOfBusiness = selectCompanyInfo[0].typeOfBusiness; else object.typeOfBusiness = values.typeOfBusiness;
    if (values.phoneNum === undefined) object.phoneNum = selectCompanyInfo[0].phoneNum; else object.phoneNum = values.phoneNum;
    if (values.faxNum === undefined) object.faxNum = selectCompanyInfo[0].faxNum; else object.faxNum = values.faxNum;
    if (values.writer === undefined) object.writer = selectCompanyInfo[0].writer; else object.writer = values.writer;
    if (values.bankName === undefined) object.bankName = selectCompanyInfo[0].bankName; else object.bankName = values.bankName;
    if (values.accountNum === undefined) object.accountNum = selectCompanyInfo[0].accountNum; else object.accountNum = values.accountNum;
    if (values.accountHolder === undefined) object.accountHolder = selectCompanyInfo[0].accountHolder; else object.accountHolder = values.accountHolder;
    if (values.typeOfDeposit === undefined) object.typeOfDeposit = selectCompanyInfo[0].typeOfDeposit; else object.typeOfDeposit = values.typeOfDeposit;
    if (values.storeName === undefined) object.storeName = selectCompanyInfo[0].storeName; else object.storeName = values.storeName;
    dupLogin.checkDupLogin(() => api.putWorkCompany(object, idState).then((result) => {
      handleCancel11();
      dupLogin.checkDupLogin(() => api.getWorkCompanyList({ offset: 0, limit: 100 }).then((result) => {
        const companyInfo = result.data.company_list;
        setCompanyInfo(companyInfo);
      }).finally(() => {
        loading.closeLoading();
      }));
    }).catch((error) => {
      loading.closeLoading();
      throw error;
    }))

  };

  const handleCancel11 = () => {
    setIsModalVisible11(false);
  };

  const companyMenu = (
    <Menu>
      <Menu.Item onClick={showModal11}>
        수정
      </Menu.Item>
      <Menu.Item onClick={handleCompanyDelete} style={{ color: 'red' }}>
        삭제
      </Menu.Item>
    </Menu>
  )

  ///////////////////////////////////////////////////////////////////////////
  //현장대리인 정보
  const [isModalVisible2, setIsModalVisible2] = useState(false);

  const showModal2 = () => {
    setIsModalVisible2(true);
  };

  const handleOk2 = () => {
    setIsModalVisible2(false);
  };

  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };
  //현장대리인 정보 신규 등록
  const [isModalVisible4, setIsModalVisible4] = useState(false);

  const showModal4 = () => {
    setIsModalVisible4(true);
  };

  const handleCancel4 = () => {
    form.resetFields();
    setIsModalVisible4(false);
  };

  //현장대리인 정보 신규 등록에서 확인 클릭 시 동작
  const handleOk4 = (values) => {
    loading.showLoading();
    if (values.deputyRegistDate === undefined) values.deputyRegistDate = moment(new Date());
    dupLogin.checkDupLogin(() => api.postDeputy(values).then((result) => {
      //window.location.reload();
      dupLogin.checkDupLogin(() => api.getDeputyList({ offset: 0, limit: 100 }).then((result) => {
        const deputyInfo = result.data.deputy_list;
        setDeputyInfo(deputyInfo);
      }))
    }).finally(() => {
      loading.closeLoading();
    }))
    form.resetFields();
    setIsModalVisible4(false);
  };

  //현장대리인 정보 삭제 기능
  const handleDeputyDelete = () => {
    alert.showConfirmAlert("알림", "현장대리인 정보를 삭제하시겠습니까?", "예", "아니오", () => {
      loading.showLoading();
      dupLogin.checkDupLogin(() => api.deleteDeputy(idState).then(() => {
        const index = deputyInfo.findIndex(element => element.deputy_id === idState);
        const cloneDeputyInfo = _.cloneDeep(deputyInfo);
        cloneDeputyInfo.splice(index, 1);
        setDeputyInfo(cloneDeputyInfo);
      }).finally(() => {
        loading.closeLoading();
      }));
    })
  };

  //현장대리인 정보 수정 기능
  const [isModalVisible22, setIsModalVisible22] = useState(false);

  const showModal22 = () => {
    setIsModalVisible22(true);
  };

  const handleOk22 = (values) => {
    loading.showLoading();
    let selectDeputyInfo = deputyInfo.filter((deputyInfo) => deputyInfo.deputy_id === idState);
    let object = { deputyName: "", deputyResidentNum: "", deputyAddress: "", deputyPosition: "", deputyEvent: "", deputyGrade: "", deputyRegistNum: "", deputyRegistDate: "" }
    if (values.deputyName === undefined) object.deputyName = selectDeputyInfo[0].deputyName; else object.deputyName = values.deputyName;
    if (values.deputyResidentNum === undefined) object.deputyResidentNum = selectDeputyInfo[0].deputyResidentNum; else object.deputyResidentNum = values.deputyResidentNum;
    if (values.deputyAddress === undefined) object.deputyAddress = selectDeputyInfo[0].deputyAddress; else object.deputyAddress = values.deputyAddress;
    if (values.deputyPosition === undefined) object.deputyPosition = selectDeputyInfo[0].deputyPosition; else object.deputyPosition = values.deputyPosition;
    if (values.deputyEvent === undefined) object.deputyEvent = selectDeputyInfo[0].deputyEvent; else object.deputyEvent = values.deputyEvent;
    if (values.deputyGrade === undefined) object.deputyGrade = selectDeputyInfo[0].deputyGrade; else object.deputyGrade = values.deputyGrade;
    if (values.deputyRegistNum === undefined) object.deputyRegistNum = selectDeputyInfo[0].deputyRegistNum; else object.deputyRegistNum = values.deputyRegistNum;
    if (values.deputyRegistDate === undefined) object.deputyRegistDate = selectDeputyInfo[0].deputyRegistDate; else object.deputyRegistDate = values.deputyRegistDate;

    if (values.deputyGetDate === undefined) object.deputyGetDate = selectDeputyInfo[0].deputyGetDate; else object.deputyGetDate = values.deputyGetDate;
    if (values.deputyRenewDate === undefined) object.deputyRenewDate = selectDeputyInfo[0].deputyRenewDate; else object.deputyRenewDate = values.deputyRenewDate;
    if (values.deputyJoinDate === undefined) object.deputyJoinDate = selectDeputyInfo[0].deputyJoinDate; else object.deputyJoinDate = values.deputyJoinDate;
    if (values.deputyWorkPlace === undefined) object.deputyWorkPlace = selectDeputyInfo[0].deputyWorkPlace; else object.deputyWorkPlace = values.deputyWorkPlace;

    
    dupLogin.checkDupLogin(() => api.putDeputy(object, idState).then((result) => {
      handleCancel22();
      dupLogin.checkDupLogin(() => api.getDeputyList({ offset: 0, limit: 100 }).then((result) => {
        const deputyInfo = result.data.deputy_list;
        setDeputyInfo(deputyInfo);
      }).finally(() => {
        loading.closeLoading();
      }))
    }).catch((error) => {
      loading.closeLoading();
      throw error;
    }));
  };

  const handleCancel22 = () => {
    setIsModalVisible22(false);
  };

  const deputyMenu = (
    <Menu>
      <Menu.Item onClick={showModal22}>
        수정
      </Menu.Item>
      <Menu.Item onClick={handleDeputyDelete} style={{ color: 'red' }}>
        삭제
      </Menu.Item>
    </Menu>
  )

  return (
    <div id="contents-body">
      <div id="body-name">
        <span className="bodytitle" style={{fontSize: '32px'}}>나의 계약서류</span>
        <div className="info-wrapper">
          <a href="https://api.ddusul.com/media/dk_image_1629087082879.pdf" target="blank" className="menual" >매뉴얼 보기</a>
        </div>
      </div>
      <div className="setting-container">
        <div className="search-box">
          <Tooltip title="search">
            <Search className="construction-search" placeholder="공사명 입력" onSearch={setFilterInput} allowClear enterButton />
          </Tooltip>
        </div>

        <div id="editBtn">
          <div id="newWorkBtn">
            <Link to="/newwork">
              <div className="squareBtn">
                <div><PlusOutlined className="plus-icon" style={{ fontSize: '30px' }} /></div>
                <div>계약서류<br/>생성</div>
              </div>
            </Link>
          </div>

          <div id="companyInfo">
            <button className="squareBtn" onClick={(showModal1)}>
              <div><UserAddOutlined className="user-icon" style={{ fontSize: '30px' }} /></div>
              <div>업체정보 <br/> 등록</div>
            </button>
            <Modal className="company-info-modal" title="업체 정보" width={600} style={{ textAlign: "center" }} visible={isModalVisible1} onOk={handleOk1} onCancel={handleCancel1} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
              <div>
                <span style={{ display: "inline-block", float: "left", width: "50%", fontSize: "14px", textAlign: "left", paddingLeft: "50px", fontWeight: "600", height: "30px", lineHeight: "30px" }}>업체명</span>
                <span style={{ display: "inline-block", float: "right", width: "40%", fontSize: "14px", textAlign: "center", fontWeight: "600", marginRight: "40px", height: "30px", lineHeight: "30px" }}>생성일</span>
                < Divider />
                {
                  companyInfo?.map((companyInfo, index) => index >= companyMinIndex && index < companyMaxIndex && (

                    <div id="company-list" key={index}>
                      <span style={{ display: "inline-block", float: "left", width: "50%", fontSize: "14px", textAlign: "left", paddingLeft: "50px", lineHeight: "40px" }}>{companyInfo.companyName}</span>
                      <div style={{ float: "right" }}>
                        <Dropdown overlay={companyMenu} trigger={'click'}>
                          <EllipsisOutlined style={{ fontSize: "30px", float: "right", marginRight: "10px", lineHeight: "40px" }} value={companyInfo.company_id} onClick={getId} />
                        </Dropdown>
                      </div>
                      <span style={{ display: "inline-block", float: "right", width: "40%", fontSize: "14px", lineHeight: "40px" }}>{dayjs(companyInfo.created).format('YYYY년 MM월 DD일')}</span>
                      < Divider />
                    </div>

                  ))
                }
                <Button style={{ fontSize: "20px", border: "none", height: "80px" }} onClick={(showModal3)}>
                  <PlusSquareOutlined className="plus-icon" style={{ fontSize: "30px", color: "#3498db" }} />
                  <span style={{ textAlign: "center", fontSize: "20px" }}>신규 등록</span>
                </Button>
                <Divider />
                <div id="modal-pagination">
                  <Pagination pageSize={companyPageSize} current={companyCurrent} total={companyInfo.length} onChange={companyHandleChange} />
                </div>
              </div>

            </Modal>

            <div>
              <Modal className="company-info-new" title="업체 정보 신규 등록" width={600} maskClosable={false} style={{ textAlign: "center", top: "70px" }} visible={isModalVisible3} onCancel={handleCancel3} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                  <Form form={form} name="companyNewSubmit" onFinish={handleOk3}>
                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>업체명</div>}
                      name="companyName">
                      <Input maxLength="20" className="submit-name" size="large" placeholder="ex) ㅇㅇ건설" style={{ width: "300px", float: "left", marginRight: "30px" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>대표자명</div>}
                      name="representativeName">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 김ㅇㅇ" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>사업자등록번호</div>}
                      name="corporateRegistNum">
                      <Input maxLength="12" className="submit-name" size="large" placeholder="ex) 000-00-00000" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>법인번호</div>}
                      name="corporateNum">
                      <Input maxLength="14" className="submit-name" size="large" placeholder="ex) 000000-0000000" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>주소</div>}
                      name="businessAddress">
                      <Input maxLength="100" className="submit-name" size="large" placeholder="ex) 서울시 마포구 공덕동 일원" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>업종</div>}
                      name="typeOfBusiness">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 실내건축공사 / ex) 토공사 / ex) 조경식재공사" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>전화번호</div>}
                      name="phoneNum">
                      <Input maxLength="13" className="submit-name" size="large" placeholder="ex) 000-0000-0000" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>팩스번호</div>}
                      name="faxNum">
                      <Input maxLength="13" className="submit-name" size="large" placeholder="ex) 000-0000-0000" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>작성자</div>}
                      name="writer">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 한ㅇㅇ" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>은행명</div>}
                      name="bankName">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) ㅇㅇ은행" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>계좌번호</div>}
                      name="accountNum">
                      <Input maxLength="20" className="submit-name" size="large" placeholder="ex) 000-0000-0000" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>예금주</div>}
                      name="accountHolder">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) (주)ㅇㅇ건설 / ex) 김ㅇㅇ" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>예금종류</div>}
                      name="typeOfDeposit">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 보통예금" style={{ width: "300px", float: "left" }} />
                    </Form.Item>
                    <Form.Item
                      label={<div className="company-new-label" style={{ width: "150px" }}>점포명</div>}
                      name="storeName">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) ㅇㅇ지부" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item className="company-info-new-btn-container" style={{ marginTop: "20px", marginBottom: "30px" }}>
                      <Button className="company-info-new-cancel" style={{ width: "100px", backgroundColor: "#bebebe", height: "30px", padding: "0px", marginRight: "15px" }} onClick={handleCancel3} size="large">
                        <span style={{ fontSize: "15px", color: "white" }}>취소</span>
                      </Button>
                      <Button className="company-info-new-ok" style={{ width: "100px", backgroundColor: "#3498db", height: "30px", padding: "0px" }} htmlType="submit" size="large">
                        <span style={{ fontSize: "15px", color: "white" }}>완료</span>
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            </div>

            <Modal className="company-info-modi" title="업체 정보 수정" width={600} maskClosable={false} style={{ textAlign: "center", top: "70px" }} onCancel={handleCancel11} visible={isModalVisible11} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
              <div>
                {
                  companyInfo.filter((companyInfo) => companyInfo.company_id === idState)
                    .map(function (companyInfo, index) {
                      return (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }} key={idState}>
                          <Form name="companyNewSubmit" onFinish={handleOk11}>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>업체명</div>}
                              name="companyName">
                              <Input maxLength="20" defaultValue={companyInfo.companyName} className="doc-submit-input" placeholder="ex) ㅇㅇ건설" style={{ width: "300px", float: "left", marginRight: "30px" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>대표자명</div>}
                              name="representativeName">
                              <Input maxLength="10" defaultValue={companyInfo.representativeName} className="doc-submit-input" placeholder="ex) 김ㅇㅇ" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>사업자증록번호</div>}
                              name="corporateRegistNum">
                              <Input maxLength="12" defaultValue={companyInfo.corporateRegistNum} className="doc-submit-input" placeholder="ex) 000-00-00000" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>법인번호</div>}
                              name="corporateNum">
                              <Input maxLength="14" defaultValue={companyInfo.corporateNum} className="doc-submit-input" placeholder="ex) 000000-0000000" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>업체주소</div>}
                              name="businessAddress">
                              <Input maxLength="100" defaultValue={companyInfo.businessAddress} className="doc-submit-input" placeholder="ex) 서울시 마포구 공덕동 일원" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>업종</div>}
                              name="typeOfBusiness">
                              <Input maxLength="10" defaultValue={companyInfo.typeOfBusiness} className="doc-submit-input" placeholder="ex) 실내건축공사 / ex) 토공사 / ex) 조경식재공사" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>전화번호</div>}
                              name="phoneNum">
                              <Input maxLength="13" defaultValue={companyInfo.phoneNum} className="doc-submit-input" placeholder="ex) 000-0000-0000" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>팩스번호</div>}
                              name="faxNum">
                              <Input maxLength="13" defaultValue={companyInfo.faxNum} className="doc-submit-input" placeholder="ex) 000-0000-0000" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>작성자</div>}
                              name="writer">
                              <Input maxLength="10" defaultValue={companyInfo.writer} className="doc-submit-input" placeholder="ex) 한ㅇㅇ" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>은행명</div>}
                              name="bankName">
                              <Input maxLength="10" defaultValue={companyInfo.bankName} className="doc-submit-input" placeholder="ex) ㅇㅇ은행" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>계좌번호</div>}
                              name="accountNum">
                              <Input maxLength="20" defaultValue={companyInfo.accountNum} className="doc-submit-input" placeholder="ex) 000-0000-0000" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-modi-label" style={{ width: "150px" }}>예금주</div>}
                              name="accountHolder">
                              <Input maxLength="10" defaultValue={companyInfo.accountHolder} className="doc-submit-input" placeholder="ex) (주)ㅇㅇ건설 / ex) 김ㅇㅇ" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="company-new-label" style={{ width: "150px" }}>예금종류</div>}
                              name="typeOfDeposit">
                              <Input maxLength="10" defaultValue={companyInfo.typeOfDeposit} className="submit-name" size="large" placeholder="ex) 보통예금" style={{ width: "300px", float: "left" }} />
                            </Form.Item>
                            <Form.Item
                              label={<div className="company-new-label" style={{ width: "150px" }}>점포명</div>}
                              name="storeName">
                              <Input maxLength="10" defaultValue={companyInfo.storeName} className="submit-name" size="large" placeholder="ex) ㅇㅇ지부" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item className="company-info-modi-btn-container" style={{ marginTop: "20px", marginBottom: "30px" }}>
                              <Button className="company-info-modi-cancel" style={{ width: "100px", backgroundColor: "#bebebe", height: "30px", padding: "0px", marginRight: "15px" }} onClick={handleCancel11} size="large">
                                <span style={{ fontSize: "15px", color: "white" }}>취소</span>
                              </Button>
                              <Button className="company-info-modi-ok" style={{ width: "100px", backgroundColor: "#3498db", height: "30px", padding: "0px" }} htmlType="submit" size="large">
                                <span style={{ fontSize: "15px", color: "white" }}>완료</span>
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      );
                    })

                }


              </div>
            </Modal>

          </div>

          <div id="orderInfo">
            <button className="squareBtn" onClick={(showModal2)}>
              <div><ContactsOutlined className="contact-icon" style={{ fontSize: '30px' }} /></div>
              <div>현장대리인<br/>정보</div>
            </button>
            <Modal className="deputy-info-modal" title="현장대리인 정보" width={600} style={{ textAlign: "center" }} visible={isModalVisible2} onOk={handleOk2} onCancel={handleCancel2} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
              <div>
                <span style={{ display: "inline-block", float: "left", width: "50%", fontSize: "14px", textAlign: "left", paddingLeft: "50px", fontWeight: "600", height: "30px", lineHeight: "30px" }}>현장대리인 성명</span>
                <span style={{ display: "inline-block", float: "right", width: "40%", fontSize: "14px", textAlign: "center", fontWeight: "600", marginRight: "40px", height: "30px", lineHeight: "30px" }}>생성일</span>
                <Divider />
                {
                  deputyInfo?.map((deputyInfo, index) => index >= deputyMinIndex && index < deputyMaxIndex && (

                    <div id="deputy-list" key={index}>
                      <span style={{ display: "inline-block", float: "left", width: "50%", fontSize: "14px", textAlign: "left", paddingLeft: "50px", lineHeight: "40px" }}>{deputyInfo.deputyName}</span>
                      <div style={{ float: "right" }}>
                        <Dropdown overlay={deputyMenu} trigger={'click'}>
                          <EllipsisOutlined style={{ fontSize: "30px", float: "right", marginRight: "10px", lineHeight: "40px" }} value={deputyInfo.deputy_id} onClick={getId} />
                        </Dropdown>
                      </div>
                      <span style={{ display: "inline-block", float: "right", width: "40%", fontSize: "14px", lineHeight: "40px" }}>{dayjs(deputyInfo.created).format('YYYY년 MM월 DD일')}</span>
                      < Divider />
                    </div>
                  ))
                }
                <Button style={{ fontSize: "20px", border: "none", height: "80px" }} onClick={(showModal4)}>
                  <PlusSquareOutlined className="plus-icon" style={{ fontSize: "30px", color: "#3498db" }} />
                  <span style={{ textAlign: "center", fontSize: "20px" }}>신규 등록</span>
                </Button>
                <Divider />
                <div id="modal-pagination">
                  <Pagination pageSize={deputyPageSize} current={deputyCurrent} total={deputyInfo.length} onChange={deputyHandleChange} />
                </div>
              </div>
            </Modal>

            <div>
              <Modal className="deputy-info-new" title="현장대리인 정보 신규 등록" maskClosable={false} width={600} style={{ textAlign: "center" }} visible={isModalVisible4} onCancel={handleCancel4} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
                  <Form form={form} name="deputyNewSubmit" onFinish={handleOk4}>
                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 성명</div>}
                      name="deputyName">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 이ㅇㅇ" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 주민번호</div>}
                      name="deputyResidentNum">
                      <Input maxLength="14" className="submit-name" size="large" placeholder="ex) 000000-0000000" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 주소</div>}
                      name="deputyAddress">
                      <Input maxLength="100" className="submit-name" size="large" placeholder="ex) 서울시 강서구 등촌동 ㅇㅇㅇ" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 직위</div>}
                      name="deputyPosition">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 과장 / ex) 부장" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 종목</div>}
                      name="deputyEvent">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 조경기능사 / ex) 토목산업기사 / ex) 건축기사" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 등급</div>}
                      name="deputyGrade">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="ex) 초급 / ex) 중급 / ex) 고급" style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 등록번호</div>}
                      name="deputyRegistNum">
                      <Input maxLength="20" className="submit-name" size="large" placeholder="자격증등록번호를 입력하세요." style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 취득일자</div>}
                      name="deputyGetDate">
                      <input
                        maxLength="20" 
                        type="date"
                        name="deputyGetDate"
                        className="doc-submit-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 갱신일자</div>}
                      name="deputyRenewDate">
                      <input
                        maxLength="20" 
                        type="date"
                        name="deputyRenewDate"
                        className="doc-submit-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 입사날짜</div>}
                      name="deputyJoinDate">
                      <input
                        maxLength="20" 
                        type="date"
                        name="deputyJoinDate"
                        className="doc-submit-input"
                      />
                    </Form.Item>
                    <Form.Item
                      label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 근무처</div>}
                      name="deputyWorkPlace">
                      <Input maxLength="10" className="submit-name" size="large" placeholder="현장대리인 근무처를 입력해주세요." style={{ width: "300px", float: "left" }} />
                    </Form.Item>

                    <Form.Item className="deputy-info-new-btn-container" style={{ marginTop: "30px", marginBottom: "30px" }}>
                      <Button className="deputy-info-new-cancel" style={{ width: "100px", backgroundColor: "#bebebe", height: "30px", padding: "0px", marginRight: "15px" }} onClick={handleCancel4} size="large">
                        <span style={{ fontSize: "15px", color: "white" }}>취소</span>
                      </Button>
                      <Button className="deputy-info-new-ok" style={{ width: "100px", backgroundColor: "#3498db", height: "30px", padding: "0px" }} htmlType="submit" size="large">
                        <span style={{ fontSize: "15px", color: "white" }}>완료</span>
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
            </div>
            <Modal className="deputy-info-modi" title="현장대리인 정보 수정" maskClosable={false} width={600} style={{ textAlign: "center" }} visible={isModalVisible22} onCancel={handleCancel22} cancelButtonProps={{ style: { display: 'none' } }} okButtonProps={{ style: { display: 'none' } }}>
              <div>
                {
                  deputyInfo.filter((deputyInfo) => deputyInfo.deputy_id === idState)
                    .map(function (deputyInfo, index) {
                      return (
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }} key={idState}>
                          <Form name="deputyModifySubmit" onFinish={handleOk22}>
                            <Form.Item
                              label={<div className="deputy-modi-label" style={{ width: "170px" }}>현장대리인 성명</div>}
                              name="deputyName">
                              <Input maxLength="10" defaultValue={deputyInfo.deputyName} className="submit-name" size="large" placeholder="ex) 이ㅇㅇ" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="deputy-modi-label" style={{ width: "170px" }}>현장대리인 주민번호</div>}
                              name="deputyResidentNum">
                              <Input maxLength="14" defaultValue={deputyInfo.deputyResidentNum} className="submit-name" size="large" placeholder="ex) 000000-0000000" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="deputy-modi-label" style={{ width: "170px" }}>현장대리인 주소</div>}
                              name="deputyAddress">
                              <Input maxLength="100" defaultValue={deputyInfo.deputyAddress} className="submit-name" size="large" placeholder="ex) 서울시 강서구 등촌동 ㅇㅇㅇ" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="deputy-modi-label" style={{ width: "170px" }}>현장대리인 직위</div>}
                              name="deputyPosition">
                              <Input maxLength="10" defaultValue={deputyInfo.deputyPosition} className="submit-name" size="large" placeholder="ex) 과장 / ex) 부장" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="deputy-modi-label" style={{ width: "170px" }}>현장대리인 종목</div>}
                              name="deputyEvent">
                              <Input maxLength="10" defaultValue={deputyInfo.deputyEvent} className="submit-name" size="large" placeholder="ex) 조경기능사 / ex) 토목산업기사 / ex) 건축기사" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="deputy-modi-label" style={{ width: "170px" }}>현장대리인 등급</div>}
                              name="deputyGrade">
                              <Input maxLength="10" defaultValue={deputyInfo.deputyGrade} className="submit-name" size="large" placeholder="ex) 초급 / ex) 중급 / ex) 고급" style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="deputy-modi-label" style={{ width: "170px" }}>현장대리인 등록번호</div>}
                              name="deputyRegistNum">
                              <Input maxLength="20" defaultValue={deputyInfo.deputyRegistNum} className="submit-name" size="large" placeholder="자격증등록번호를 입력하세요." style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item
                              label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 취득일자</div>}
                              name="deputyGetDate">
                              <input
                                maxLength="20" defaultValue={deputyInfo.deputyGetDate}
                                type="date"
                                name="deputyGetDate"
                                className="doc-submit-input"
                              />
                            </Form.Item>
                            <Form.Item
                              label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 갱신일자</div>}
                              name="deputyRenewDate">
                              <input
                                maxLength="20" defaultValue={deputyInfo.deputyRenewDate}
                                type="date"
                                name="deputyRenewDate"
                                className="doc-submit-input"
                              />
                            </Form.Item>
                            <Form.Item
                              label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 입사날짜</div>}
                              name="deputyJoinDate">
                              <input
                                maxLength="20" defaultValue={deputyInfo.deputyJoinDate}
                                type="date"
                                name="deputyJoinDate"
                                className="doc-submit-input"
                              />
                            </Form.Item>
                            <Form.Item
                              label={<div className="deputy-new-label" style={{ width: "170px" }}>현장대리인 근무처</div>}
                              name="deputyWorkPlace">
                              <Input maxLength="10" defaultValue={deputyInfo.deputyWorkPlace} className="submit-name" size="large" placeholder="현장대리인 근무처를 입력해주세요." style={{ width: "300px", float: "left" }} />
                            </Form.Item>

                            <Form.Item className="deputy-info-modi-btn-container" style={{ marginTop: "30px", marginBottom: "30px" }}>
                              <Button className="deputy-info-modi-cancel" style={{ width: "100px", backgroundColor: "#bebebe", height: "30px", padding: "0px", marginRight: "15px" }} onClick={handleCancel22} size="large">
                                <span style={{ fontSize: "15px", color: "white" }}>취소</span>
                              </Button>
                              <Button className="deputy-info-modi-ok" style={{ width: "100px", backgroundColor: "#3498db", height: "30px", padding: "0px" }} htmlType="submit" size="large">
                                <span style={{ fontSize: "15px", color: "white" }}>완료</span>
                              </Button>
                            </Form.Item>
                          </Form>
                        </div>
                      );
                    })
                }

              </div>
            </Modal>
          </div>
        </div>
      </div>

      <div className="work-list" >
        {
          filterData()?.map((worklist, index) => index >= minIndex && index < maxIndex && (

            <div className="work-card" key={index}>
              <Dropdown overlay={menu} trigger={'click'}>
                <EllipsisOutlined style={{ fontSize: "30px", float: "right", marginRight: "10px", padding: "5px", height: "25px", fontWeight: "600" }} value={worklist.worklist_id} onClick={getId} />
              </Dropdown>

              <Link className="work-link" to={`/worklists/${worklist.worklist_id}/documents/companyinfo`} >
                <div className="work-name">{worklist.constructionName}</div>
                <div className="work-contents">
                  <span className="company-name">업체명 : {worklist.companyName}</span>
                  <span className="deputy-name">현장대리인 : {worklist.deputyName}</span>
                  <span className="work-date">생성일 : {dayjs(worklist.created).format('YYYY-MM-DD')}</span>
                </div>
              </Link>
            </div>
          ))
        }
      </div>

      <div id="pagination">
        <Pagination pageSize={pageSize} current={current} total={worklists.length} onChange={handleChange} />
      </div>
    </div >
  );
}


export default WorkListPage;