import { useEffect } from "react";
import SurveyFormItem from "../../../components/survey/formItem/SurveyFormItem";
import { SurveySectionModel } from "../../../models/requestApplication/RequestApplicationModel";
import UserInfo from "./UserInfo";

interface Props {
  surveyModel: SurveySectionModel;
  currentFormData: { [key: string]: { [key: string]: string | string[] } };
  currentFormFileData: { [key: string]: { [key: string]: File[] } };
  isPrev: boolean;
  isNext: boolean;
  isSubmitable: boolean;
  formRef: React.RefObject<HTMLFormElement>;
  onChangeOption: (
    sectionTitle: string,
    questionTitle: string,
    changedValue: string | string[]
  ) => void;
  onChangeNextSection: (section: SurveySectionModel) => void;
  onClickMove: (type: "next" | "prev") => void;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onChangeFile: (
    sectionTitle: string,
    questionTitle: string,
    files: File[]
  ) => void;
}

export default (props: Props) => {
  const { surveyModel } = props;

  return (
    <section className="bg-light-md">
      <div className="container page py-120">
        {/* <UserInfo
          emailRef={props.emailRef}
          nameRef={props.nameRef}
          phoneRef={props.phoneRef}
        /> */}
        <div className="form-wrap col-md-7 mx-auto">
          <h3 className="mb-4 text-dark">{surveyModel?.title}</h3>
          <div className="infoBox-highlight mb-4">
            <span className="material-symbols-outlined">info</span>
            <div>{surveyModel?.description}</div>
          </div>
          <form
            className="row g-3"
            onSubmit={props.onSubmit}
            noValidate
            ref={props.formRef}
          >
            {surveyModel?.questions.map((question, index) => {
              return (
                <SurveyFormItem
                  key={`${surveyModel.title}_${question.title}_${index}`}
                  question={question}
                  defaultValue={
                    props.currentFormData?.[surveyModel.title]?.[question.title]
                  }
                  defaultFileValue={
                    props.currentFormFileData?.[surveyModel.title]?.[
                      question.title
                    ]
                  }
                  onChangeOption={(optionValue, childSection) => {
                    if (childSection) {
                      console.log(childSection);
                      props.onChangeNextSection(childSection);
                    }
                    props.onChangeOption(
                      surveyModel.title,
                      question.title,
                      optionValue
                    );
                  }}
                  onChangeFile={(file) => {
                    props.onChangeFile(surveyModel.title, question.title, file);
                  }}
                />
              );
            })}

            <div className="mo-fixed-bottom col-12 py-3 d-flex align-items-center gap-3">
              <a
                href="javascript:window.history.back();"
                className="btn btn-primary-soft btn-lg flex-1 left-icon mx-auto justify-content-center"
              >
                <span className="material-symbols-outlined me-2">
                  arrow_left_alt
                </span>
                이전
              </a>
              {props.isNext && !props.isSubmitable ? (
                <button
                  className="btn btn-primary btn-lg flex-1  right-icon mx-auto justify-content-center"
                  type="button"
                  onClick={() => {
                    props.onClickMove("next");
                  }}
                >
                  다음
                  <span className="material-symbols-outlined ms-2">send</span>
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-lg flex-1  right-icon mx-auto justify-content-center"
                  type="submit"
                >
                  제출하기
                  <span className="material-symbols-outlined ms-2">send</span>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
