/* eslint-disable import/no-anonymous-default-export */
import React, { useEffect, useRef, useState } from "react";
import YouTube from "react-youtube";
import styles from "./lecturePlayer.module.scss";

interface Props {
  url?: string;
  vimeoId?: string;
  playlist?: string;
}

export default (props: Props) => {
  const iframeRef = useRef<any>(null);
  const shieldRef = useRef<any>(null);

  return (
    <div className="video-player" style={{ width: "100%", height: "100%" }}>
      <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
        {props.vimeoId && (
          <iframe
            src={`https://player.vimeo.com/video/${props.vimeoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
            allow="autoplay; fullscreen; clipboard-write"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        )}
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
      <div className={styles.shield} ref={shieldRef}></div>
    </div>
  );
};
