import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import styles from "./MyLecture.module.scss";

const MyLecture = () => {
  return (
    <React.Fragment>
      <div className={styles.contain}>
        <div className={styles.contents_wrapper}>
          <div className={styles.dashboard}>나의 강의실 페이지입니다.</div>
          <div className={styles.lecture_contain}>
            <div className={styles.lecture_search}>
              <div className={styles.lecture_title}>
                <div>내강좌</div>
                <div>
                  <input></input>
                </div>
              </div>
              <div className={styles.lecture_filter}>
                <div>다른강좌보기</div>
                <div>필터</div>
              </div>
            </div>
            <div className={styles.lecture_list}>
              <ul>
                <li>1강</li>
                <li>2강</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyLecture;
