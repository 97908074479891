import { CloseOutlined } from "@ant-design/icons";
import { CircularProgress, Dialog } from "@material-ui/core";
import React from "react";
import { Project } from "../../../../config/models";
import styles from "./selectProject.module.scss";
import { api } from "../../../../config/api";
import SimpleConfirm from "../../confirm/SimpleConfirm";
import EditProjectModal from "../../editProject/EditProject";
import _ from "lodash";
import AlertContext from "../../../../contexts/AlertContext";

interface appProps {
  onClose: (args?: { projectId: number; originType: string }) => void;
  open: boolean;
  projects: Array<Project>;
  type: "copy" | "normal" | "contract" | "change" | "ready" | "complete";
}
interface appState {
  type: "copy" | "normal" | "contract" | "change" | "ready" | "complete";
  loading: boolean;
  candidates: Array<Project>;
}

export default class SelectProjectOriginModal extends React.Component<
  appProps,
  appState
> {
  onClose: (args?: { projectId: number; originType: string }) => void;
  constructor(props: any) {
    super(props);
    this.onClose = this.props.onClose.bind(this);
    const candidates = this.props.projects.filter((project: Project) => {
      switch (this.props.type) {
        case "change":
          return project.type === "contract";
        case "ready":
          return project.type === "contract";
        case "complete":
          return project.type === "contract";
        case "contract":
          return ["normal", "change", "copy"].includes(project.type);
      }
    });
    this.state = {
      type: props.type,
      loading: false,
      candidates,
    };
  }

  componentDidUpdate(
    prevProps: Readonly<appProps>,
    prevState: Readonly<appState>,
    snapshot?: any
  ): void {
    if (prevProps.open !== this.props.open) {
      const candidates = this.props.projects.filter((project: Project) => {
        switch (this.props.type) {
          case "change":
            return project.type === "contract";
          case "ready":
            return project.type === "contract";
          case "complete":
            return project.type === "contract";
          case "contract":
            return ["normal", "change", "copy"].includes(project.type);
        }
      });
      this.setState({
        candidates,
      });
    }
  }
  getTypeString(type: string) {
    switch (type) {
      case "change":
        return "변경";
      case "ready":
        return "기성";
      case "complete":
        return "준공";
      case "contract":
        return "계약";
      case "normal":
        return "일반";
    }
  }

  render() {
    return (
      <div
        className={this.props.open ? "modal fade show" : "modal fade"}
        id="project-addModal"
        tabIndex={-1}
        aria-labelledby="project-addLabel"
        style={this.props.open ? { display: "block" } : { display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="project-addLabel">
                새로운 {this.getTypeString(this.props.type)} 프로젝트
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.onClose();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="SubTitle3 text-dark mb-3">
                {this.state.candidates.length > 0
                  ? "계약 프로젝트를 만들 주체를 선택해 주세요."
                  : "계약 프로젝트를 먼저 생성해주세요."}
              </div>
              <div className="project-list">
                {this.state.candidates.map(
                  (project: Project, index: number) => (
                    <div
                      className="project-item"
                      data-bs-dismiss="modal"
                      onClick={() =>
                        this.onClose({
                          projectId: project.id,
                          originType: project.type,
                        })
                      }
                    >
                      <a href="#">
                        <div className="category badge text-bg-secondary">
                          {this.getTypeString(project.type)}
                        </div>
                        <div className="title">
                          {project.name}
                          <span className="number">
                            {this.state.candidates.length - index + "차"}
                          </span>
                        </div>
                        <div className="date">
                          {project.created.format("yyyy-MM-dd")}
                        </div>
                      </a>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
