/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import styles from "./paymentConfirmContent.module.scss";
interface Props {
  close: () => void;
}

export default (props: Props) => {
  return (
    <div className={styles.pcc_container}>
      <div className={styles.warning_image}></div>
      <button className={styles.close} onClick={props.close}>
        닫기
      </button>
    </div>
  );
};
