import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { LevelTestResContext } from "../../contexts/LevelTestContext";
import Timer from "./components/Timer";

export default () => {
  const levelTestRes = useContext(LevelTestResContext);
  const history = useHistory();
  const [levelTestAnswer, setLevelTestAnswer] = useState(0);
  const levelTestAnswerTimerRef = useRef<HTMLInputElement | null>(null);

  const next = () => {
    levelTestRes.setLevelAnswer(
      1,
      levelTestAnswer,
      levelTestAnswerTimerRef.current!.value
    );
    history.push(pages.leveltest + "/2");
  };

  return (
    <main id="wrap">
      <div className="progress top-progress">
        <div
          className="progress-bar "
          role="progressbar"
          aria-label="Basic example"
          style={{ width: "10%" }}
          aria-valuenow="10"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <section className="bg-light-md">
        <div className="container page py-120">
          <div className="form-wrap col-md-7 mx-auto ">
            <form>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="badge body2 text-bg-secondary">Question.1</div>
                <Timer forwardedRef={levelTestAnswerTimerRef} />
              </div>
              <h4 className="text-dark mb-5">
                다음 중 1m × 0.5m × 3m 식에서 맞는 단위의 보기를 선택하세요.
              </h4>
              <div
                className="d-flex flex-wrap gap-3 mb-5 aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option1-1"
                    value={0}
                    checked={levelTestAnswer === 0}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option1-1"
                  >
                    m
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option1-2"
                    value={1}
                    checked={levelTestAnswer === 1}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option1-2"
                  >
                    m²
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="option1-3"
                    value={2}
                    checked={levelTestAnswer === 2}
                    onChange={(e) =>
                      setLevelTestAnswer(parseInt(e.target.value))
                    }
                  />
                  <label
                    className="btn btn-outline-darkgray btn-lg w-100"
                    htmlFor="option1-3"
                  >
                    m³
                  </label>
                </div>
              </div>
              <div className="mo-fixed-bottom w-100 d-flex gap-3 py-3">
                <a
                  onClick={() => {
                    next();
                  }}
                  className="btn btn-lg btn-primary-soft flex-1"
                >
                  <span className="ps-3">다음</span>
                  <span className="material-symbols-outlined ms-2">
                    arrow_right_alt
                  </span>
                </a>
              </div>
            </form>
          </div>
        </div>
      </section>
    </main>
  );
};
