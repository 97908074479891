import React, { useEffect, useState } from "react";
import { api } from "../../config/api";
import { Post } from "../../models/communityModel";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { Spin } from "antd";

export default function MyPosts() {
  const [posts, setPosts] = useState<Post[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 5;
  const history = useHistory();

  useEffect(() => {
    fetchMyPosts();
  }, [currentPage]);

  const fetchMyPosts = async () => {
    try {
      const offset = (currentPage - 1) * limit;
      const response = await api.getMyPosts(offset, limit);
      setPosts(response.posts);
      setTotalCount(response.total);
      const totalPage = Math.ceil(response.total / limit);
      setTotalPages(totalPage < 1 ? 1 : totalPage);
    } catch (error) {
      console.error('게시글을 불러오는데 실패했습니다:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  const handlePrevClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  const handleNextClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.format("yyyy.MM.dd HH:mm");
  };

  const handlePostClick = (post: Post) => {
    history.push(`${pages.community}/${post.category}/${post.id}`);
  };

  if (loading) return (
    <div className="loading-container">
      <Spin size="large" />
    </div>
  );

  return (
    <section className="content">
      <h4 className="text-dark mb-4">나의 게시글</h4>
      <div className="round-box">
        {posts.length === 0 ? (
          <p className="text-center py-4">작성한 게시글이 없습니다.</p>
        ) : (
          <>
            <div className="list-style-one">
              {posts.map((post) => (
                <div 
                  key={post.id} 
                  className="post-item p-3 border-bottom cursor-pointer" 
                  onClick={() => handlePostClick(post)}
                  style={{ cursor: 'pointer' }}
                >
                  <h5>{post.title}</h5>
                  <div 
                    className="text-gray-500 mb-2"
                    dangerouslySetInnerHTML={{ 
                      __html: post.content.length > 300 
                        ? post.content.slice(0, 300) + '...' 
                        : post.content 
                    }}
                  />
                  <div className="d-flex justify-content-between">
                    <small className="text-gray-400">{formatDate(post.createdAt)}</small>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-8 col-lg-9 col-xl-5 mx-auto mt-5 mb-3">
              <div className="paging">
                <a
                  href="#"
                  id="prev"
                  onClick={handlePrevClick}
                  className={currentPage === 1 ? "disabled" : ""}
                >
                  <span className="material-symbols-outlined">chevron_left</span>
                </a>
                {[...Array(totalPages)].map((_, index) => (
                  <a
                    href="#"
                    key={index + 1}
                    className={currentPage === index + 1 ? "active" : ""}
                    onClick={(e) => {
                      e.preventDefault();
                      handlePageClick(index + 1);
                    }}
                  >
                    {index + 1}
                  </a>
                ))}
                <a
                  href="#"
                  id="next"
                  onClick={handleNextClick}
                  className={currentPage === totalPages ? "disabled" : ""}
                >
                  <span className="material-symbols-outlined">chevron_right</span>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
