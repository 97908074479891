/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { api } from "../../config/api";
import AlertContext from "../../contexts/AlertContext";
import DupLoginContext from "../../contexts/DupLoginContext";
import CouponBoxModel from "../../models/coupon/CouponBoxModel";
import CouponPaymentModel from "../../models/coupon/CouponPaymentModel";

interface Props {
  coupons?: CouponBoxModel[];
  isSelectable?: boolean;
  selectedCoupon?: CouponPaymentModel;
  refreshCoupons: () => void;
  onConfirm: (key?: string) => void;
}

export default (props: Props) => {
  const [isRegistMode, setIsRegistMode] = useState(false);
  const couponInput1 = useRef<HTMLInputElement>(null);
  const couponInput2 = useRef<HTMLInputElement>(null);
  const couponInput3 = useRef<HTMLInputElement>(null);
  const [selectedCouponKey, setSelectedCouponKey] = useState(
    props.selectedCoupon?.key
  );
  const alert = useContext(AlertContext);
  const dlContext = useContext(DupLoginContext);

  useEffect(() => {
    return () => {
      // props.onConfirm(selectedCouponKey);
    };
  }, []);

  const handleClickCouponRegister = () => {
    setIsRegistMode(true);
  };

  const handleClickCouponAdd = async () => {
    const couponKey =
      (couponInput1.current?.value || "") +
      (couponInput2.current?.value || "") +
      (couponInput3.current?.value || "");
    const res = await dlContext.checkDupLogin(() =>
      api.getCouponUsableAsync(couponKey)
    );
    if (res.data.can_use) {
      const registCouponRes = await api.postRegistCouponAsync(couponKey);
      if (registCouponRes.status === 200 && registCouponRes.data.data === 1) {
        props.refreshCoupons();
        setIsRegistMode(false);
      } else {
        alert.showAlert(registCouponRes.data.message, () => {
          props.refreshCoupons();
        });
      }
    } else {
      alert.showAlert(res.data.message, () => {
        props.refreshCoupons();
      });
    }
  };

  return (
    <Container>
      <Title>쿠폰함</Title>
      {isRegistMode ? (
        <React.Fragment>
          <CouponRegisterContainer>
            <CouponRegisterInput maxLength={4} ref={couponInput1} />
            <span>{` - `}</span>
            <CouponRegisterInput maxLength={4} ref={couponInput2} />
            <span>{` - `}</span>
            <CouponRegisterInput maxLength={4} ref={couponInput3} />
          </CouponRegisterContainer>
          <RegisterButtonBox>
            <Button
              style={{ width: "150px" }}
              onClick={() => setIsRegistMode(false)}
            >
              뒤로가기
            </Button>
            <Button onClick={() => handleClickCouponAdd()}>확인</Button>
          </RegisterButtonBox>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <CouponRegisterBox>
            <CouponRegister onClick={handleClickCouponRegister}>
              쿠폰 등록
            </CouponRegister>
          </CouponRegisterBox>
          <CouponBoxContainer>
            <CouponBold>
              <Text>쿠폰명</Text>
              <Created>만료일자</Created>
              <div>쿠폰 코드</div>
            </CouponBold>
            {props.coupons?.map((coupon: CouponBoxModel) => {
              return (
                <Coupon
                  key={coupon.key}
                  isSelectable={props.isSelectable}
                  isSelected={selectedCouponKey === coupon.key}
                  onClick={() => {
                    if (selectedCouponKey === coupon.key) {
                      setSelectedCouponKey("");
                    } else {
                      setSelectedCouponKey(coupon.key);
                    }
                  }}
                >
                  <Text>{coupon.name}</Text>
                  <Created>
                    {new Date(coupon.expiryDate).format("yyyy-MM-dd")}
                  </Created>
                  <div>{coupon.key}</div>
                </Coupon>
              );
            })}
          </CouponBoxContainer>
          <Button onClick={() => props.onConfirm(selectedCouponKey)}>
            확인
          </Button>
        </React.Fragment>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const Title = styled.div`
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
`;
const CouponRegisterBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const CouponRegisterContainer = styled.div`
  display: flex;
  height: 90%;
  padding: 30px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 24px;
  white-space: pre-line;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const CouponRegister = styled.div`
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 6px;
  background-color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
  border: none;
`;
const RegisterButtonBox = styled.div`
  flex-direction: row;
  display: flex;
`;
const CouponRegisterInput = styled.input`
  display: flex;
  text-align: center;
  width: 100px;
  height: 40px;
  font-size: 16px;
  border: 1px solid rgb(224, 224, 224);
  border-radius: 3px;
  outline: none !important;
  box-sizing: border-box;
  transition: 0.25s;
  :focus {
    border: 1px solid var(--color-primary);
    outline: none !important;
    box-sizing: border-box;
  }
`;
const CouponBoxContainer = styled.div`
  height: 90%;
  padding: 30px;
  overflow: auto;
  overflow-x: hidden;
`;
const CouponBold = styled.div`
  display: flex;
  width: 100%;
  font-weight: bold;
  flex-direction: row;
  min-height: 30px;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgrey;
  div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`;
const Coupon = styled.div<{ isSelectable?: boolean; isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  min-height: 30px;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 10px;
  border-bottom: 1px solid lightgrey;
  div {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  ${(props) => {
    if (props.isSelected) {
      return css`
        cursor: pointer;
        border: 1px solid;
        border-color: var(--color-primary);
        color: var(--color-primary);
      `;
    }
    if (props.isSelectable) {
      return css`
        cursor: pointer;
        &:hover {
          border: 1px solid;
          border-color: var(--color-primary);
          color: var(--color-primary);
        }
      `;
    }
  }}
`;
const Text = styled.div`
  font-size: 16px;
  width: 150px;
`;
const Created = styled.div`
  font-size: 15px;
  margin-right: 10px;
`;
const Button = styled.button`
  margin: 0 auto;
  margin-bottom: 30px;
  width: 285px;
  font-size: 16px;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 6px;
  background-color: var(--color-primary);
  font-weight: bold;
  cursor: pointer;
  height: 38px;
  border: none;
  margin-top: 20px;
`;
