import { VideoDataModel } from "../models/VideoDataModel";

export const VideoData: VideoDataModel[] = [
  {
    category_id: 0, //설계내역, 계약 서류, 견적서 등 카테고리
    is_admin_visible: null,
    id: 0,
    created: "2022-03-14T18:22:48.429", //강의 개설일
    name: "(초급) 조경설계 강의", //강의 대분류
    description:
      "조경설계를 하고 싶은분들, 조경설계서류를 알고 싶은분들 초급 조경설계 하나면 마스터 할 수 있습니다.", //강의설명
    star: 5, //평점 평균,
    allContent: 15, //모든강의수
    amount: 30, //강의금액
    // thumbnail: "https://sulfac-image.s3.ap-northeast-2.amazonaws.com/None_1671081110882867.jpg", //대표이미지
    thumbnail:
      "https://www.safety.or.kr/resources/safety/img/business/top/dfs.jpg", //대표이미지

    explanation: [
      //강의완료 후 당신의 모습
      "설계내역서 기본적인 구조 지식 및 작성가능",
      "수량산출서의 기본적인 지식 및 작성가능",
      "일위대가표의 기본적인 지식 및 작성가능",
    ],
    RecommendationTitle: [
      {
        //이런분들에게 추천드립니다.
        title: "지루한 설계강의가 싫으신분!",
        subTitle:
          "설계자만 알아들을 수 있는 어려운 설명말고 아무것도 모르는 나도 알아들을 수 있는 강의 찾으시는 분들.",
        logoType: "logo1", //사용 이미지
      },
      {
        title: "서류 만능이 되고 싶은 분들!",
        subTitle:
          "엑셀/서류 다양한 서류가 있고 필요한 서류만 알고 싶고 몇 개의 서류만 알면 다른건 쉽게 혼자 해결하고 싶은 분들.",
        logoType: "logo2", //사용 이미지
      },
      {
        title: "직접 만들면서 배우고 싶으신 분들!",
        subTitle:
          "일방적으로 듣기만 하는 강의가 아닌 실제로 같이 프로젝트를 만들면서 요점만 빠르게 배우는 걸 좋아하시는 분들.",
        logoType: "logo3", //사용 이미지
      },
    ],
    //총 00시간 강의를 00주만에 끝내보세요
    runTimeHour: 10, //강의 총 시간을 시간단위로 환산
    runTimeWeek: 4, //강의 총 시간을 주단위 환산
    lectureTitle: [
      //강의커리큘럼
      {
        title: "수량산출서의 이해", //강의 소분류
        subTitle: [
          {
            title: "단위의 개념", // 강의 제목
            isFree: true, //무료강의 여부
            type: "video", //이용약관과 문서양식과 구분
            runtime: 5, //강의영상 길이
            videoLink: "https://youtu.be/on3AJ3cxNws", //강의영상 링크
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f", //강의자료노션링크
          },
          {
            title: "단위 수량산출서의 개념",
            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "총괄 수량집계표의 개념 ",

            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "내역서적용수량의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "단가산출의 이해",
        subTitle: [
          {
            title: "일위대가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(중기) 단가산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "내역서의 이해",
        subTitle: [
          {
            title: "내역서의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "공종별집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "원가계산의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "조경식재(초급) 이해",
        subTitle: [
          {
            title: "설계교육 시작 전 내용정리",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "수량집계표 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "일위대가표 만들기",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
    ],
    comment: [
      //평점
      {
        id: 0,
        userName: "정코딩", //작성자
        grade: 5, //사용자 부여 별 평점 (1~5개)
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.", //강의평가내용
        created: "2023-01-9T18:22:48.429", //작성일시
      },
      {
        id: 1,
        userName: "서코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-09T18:22:48.429",
      },
      {
        id: 2,
        userName: "이코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-08T18:22:48.429",
      },
      {
        id: 3,
        userName: "양코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-07T18:22:48.429",
      },
      {
        id: 4,
        userName: "최코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-06T18:22:48.429",
      },
    ],
  },
  {
    category_id: 1,
    is_admin_visible: null,
    id: 1,
    created: "2022-03-14T18:22:48.429",
    name: "(초급)도면의 이해",
    description:
      "조경설계를 하고 싶은분들, 조경설계서류를 알고 싶은분들 초급 조경설계 하나면 마스터 할 수 있습니다.",
    star: 5,
    allContent: 15,
    amount: 30,
    thumbnail:
      "https://previews.123rf.com/images/kasza/kasza1311/kasza131100069/23987086-%EA%B1%B4%EC%B6%95-%EC%84%A4%EA%B3%84-%EB%B0%8F-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8%EC%9D%98-%ED%9E%99-%EC%A7%91-%EA%B8%B0%EC%88%A0-%EB%8F%84%EB%A9%B4%EC%9D%98-%EB%8F%84%EB%A9%B4-%EC%B2%AD%EC%82%AC%EC%A7%84.jpg", //대표이미지

    explanation: [
      "설계내역서 기본적인 구조 지식 및 작성가능",
      "수량산출서의 기본적인 지식 및 작성가능",
      "일위대가표의 기본적인 지식 및 작성가능",
    ],
    RecommendationTitle: [
      {
        title: "지루한 설계강의가 싫으신분!",
        subTitle:
          "설계자만 알아들을 수 있는 어려운 설명말고 아무것도 모르는 나도 알아들을 수 있는 강의 찾으시는 분들.",
        logoType: "logo1",
      },
      {
        title: "서류 만능이 되고 싶은 분들!",
        subTitle:
          "엑셀/서류 다양한 서류가 있고 필요한 서류만 알고 싶고 몇 개의 서류만 알면 다른건 쉽게 혼자 해결하고 싶은 분들.",
        logoType: "logo2",
      },
      {
        title: "직접 만들면서 배우고 싶으신 분들!",
        subTitle:
          "일방적으로 듣기만 하는 강의가 아닌 실제로 같이 프로젝트를 만들면서 요점만 빠르게 배우는 걸 좋아하시는 분들.",
        logoType: "logo3",
      },
    ],
    runTimeHour: 10,
    runTimeWeek: 4,
    lectureTitle: [
      {
        title: "도면의 이해",
        subTitle: [
          {
            title: "위치도의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "계획평면도의 개념",
            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "횡.종단면도의 개념",

            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(정.하.우.좌)도면의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "상세도면의 개념",
            isFree: true,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "수량산출서의 이해",
        subTitle: [
          {
            title: "단위의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "총괄 수량집계표의 개념",

            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "내역서적용 수량의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "단가산출의 이해",
        subTitle: [
          {
            title: "일위대가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(중기) 단가산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "내역서의 이해",
        subTitle: [
          {
            title: "내역서의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "공종별집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "원가계산의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "조경식재(초급) 이해",
        subTitle: [
          {
            title: "설계교육 시작 전 내용정리",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "수량집계표 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "일위대가표 만들기",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
    ],
    comment: [
      {
        id: 0,
        userName: "정코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-9T18:22:48.429",
      },
      {
        id: 1,
        userName: "서코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-09T18:22:48.429",
      },
      {
        id: 2,
        userName: "이코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-08T18:22:48.429",
      },
      {
        id: 3,
        userName: "양코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-07T18:22:48.429",
      },
      {
        id: 4,
        userName: "최코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-06T18:22:48.429",
      },
    ],
  },
  {
    category_id: 1,
    is_admin_visible: null,
    id: 1,
    created: "2022-03-14T18:22:48.429",
    name: "(초급)설계의 이해",
    description:
      "조경설계를 하고 싶은분들, 조경설계서류를 알고 싶은분들 초급 조경설계 하나면 마스터 할 수 있습니다.",
    star: 5,
    allContent: 15,
    amount: 30,
    thumbnail:
      "https://image.utoimage.com/preview/cp864374/2017/01/201701119153_500.jpg", //대표이미지

    explanation: [
      "설계내역서 기본적인 구조 지식 및 작성가능",
      "수량산출서의 기본적인 지식 및 작성가능",
      "일위대가표의 기본적인 지식 및 작성가능",
    ],
    RecommendationTitle: [
      {
        title: "지루한 설계강의가 싫으신분!",
        subTitle:
          "설계자만 알아들을 수 있는 어려운 설명말고 아무것도 모르는 나도 알아들을 수 있는 강의 찾으시는 분들.",
        logoType: "logo1",
      },
      {
        title: "서류 만능이 되고 싶은 분들!",
        subTitle:
          "엑셀/서류 다양한 서류가 있고 필요한 서류만 알고 싶고 몇 개의 서류만 알면 다른건 쉽게 혼자 해결하고 싶은 분들.",
        logoType: "logo2",
      },
      {
        title: "직접 만들면서 배우고 싶으신 분들!",
        subTitle:
          "일방적으로 듣기만 하는 강의가 아닌 실제로 같이 프로젝트를 만들면서 요점만 빠르게 배우는 걸 좋아하시는 분들.",
        logoType: "logo3",
      },
    ],
    runTimeHour: 10,
    runTimeWeek: 4,
    lectureTitle: [
      {
        title: "도면의 이해",
        subTitle: [
          {
            title: "위치도의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "계획평면도의 개념",
            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "횡.종단면도의 개념",

            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(정.하.우.좌)도면의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "상세도면의 개념",
            isFree: true,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "수량산출서의 이해",
        subTitle: [
          {
            title: "단위의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "총괄 수량집계표의 개념",

            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "내역서적용 수량의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "단가산출의 이해",
        subTitle: [
          {
            title: "일위대가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(중기) 단가산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "내역서의 이해",
        subTitle: [
          {
            title: "내역서의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "공종별집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "원가계산의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "조경식재(초급) 이해",
        subTitle: [
          {
            title: "설계교육 시작 전 내용정리",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "수량집계표 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "일위대가표 만들기",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
    ],
    comment: [
      {
        id: 0,
        userName: "정코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-9T18:22:48.429",
      },
      {
        id: 1,
        userName: "서코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-09T18:22:48.429",
      },
      {
        id: 2,
        userName: "이코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-08T18:22:48.429",
      },
      {
        id: 3,
        userName: "양코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-07T18:22:48.429",
      },
      {
        id: 4,
        userName: "최코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-06T18:22:48.429",
      },
    ],
  },
  {
    category_id: 2,
    is_admin_visible: null,
    id: 2,
    created: "2022-03-14T18:22:48.429",
    name: "도면의 이해",
    description:
      "조경설계를 하고 싶은분들, 조경설계서류를 알고 싶은분들 초급 조경설계 하나면 마스터 할 수 있습니다.",
    star: 5,
    allContent: 15,
    amount: 30,
    thumbnail:
      "https://image.utoimage.com/preview/cp864374/2015/11/201511053801_500.jpg", //대표이미지

    explanation: [
      "설계내역서 기본적인 구조 지식 및 작성가능",
      "수량산출서의 기본적인 지식 및 작성가능",
      "일위대가표의 기본적인 지식 및 작성가능",
    ],
    RecommendationTitle: [
      {
        title: "지루한 설계강의가 싫으신분!",
        subTitle:
          "설계자만 알아들을 수 있는 어려운 설명말고 아무것도 모르는 나도 알아들을 수 있는 강의 찾으시는 분들.",
        logoType: "logo1",
      },
      {
        title: "서류 만능이 되고 싶은 분들!",
        subTitle:
          "엑셀/서류 다양한 서류가 있고 필요한 서류만 알고 싶고 몇 개의 서류만 알면 다른건 쉽게 혼자 해결하고 싶은 분들.",
        logoType: "logo2",
      },
      {
        title: "직접 만들면서 배우고 싶으신 분들!",
        subTitle:
          "일방적으로 듣기만 하는 강의가 아닌 실제로 같이 프로젝트를 만들면서 요점만 빠르게 배우는 걸 좋아하시는 분들.",
        logoType: "logo3",
      },
    ],
    runTimeHour: 10,
    runTimeWeek: 4,
    lectureTitle: [
      {
        title: "도면의 이해",
        subTitle: [
          {
            title: "위치도의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "계획평면도의 개념",
            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "횡.종단면도의 개념",

            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(정.하.우.좌)도면의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "상세도면의 개념",
            isFree: true,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "수량산출서의 이해",
        subTitle: [
          {
            title: "단위의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "총괄 수량집계표의 개념",

            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "내역서적용 수량의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "단가산출의 이해",
        subTitle: [
          {
            title: "일위대가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(중기) 단가산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "내역서의 이해",
        subTitle: [
          {
            title: "내역서의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "공종별집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "원가계산의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "조경식재(초급) 이해",
        subTitle: [
          {
            title: "설계교육 시작 전 내용정리",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "수량집계표 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "일위대가표 만들기",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
    ],
    comment: [
      {
        id: 0,
        userName: "정코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-9T18:22:48.429",
      },
      {
        id: 1,
        userName: "서코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-09T18:22:48.429",
      },
      {
        id: 2,
        userName: "이코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-08T18:22:48.429",
      },
      {
        id: 3,
        userName: "양코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-07T18:22:48.429",
      },
      {
        id: 4,
        userName: "최코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-06T18:22:48.429",
      },
    ],
  },
  {
    category_id: 3,
    is_admin_visible: null,
    id: 3,
    created: "2022-03-14T18:22:48.429",
    name: "조경의 이해",
    description:
      "조경설계를 하고 싶은분들, 조경설계서류를 알고 싶은분들 초급 조경설계 하나면 마스터 할 수 있습니다.",
    star: 5,
    allContent: 15,
    amount: 30,
    thumbnail:
      "https://sulfac-image.s3.ap-northeast-2.amazonaws.com/None_1671081110882867.jpg", //대표이미지

    explanation: [
      "설계내역서 기본적인 구조 지식 및 작성가능",
      "수량산출서의 기본적인 지식 및 작성가능",
      "일위대가표의 기본적인 지식 및 작성가능",
    ],
    RecommendationTitle: [
      {
        title: "지루한 설계강의가 싫으신분!",
        subTitle:
          "설계자만 알아들을 수 있는 어려운 설명말고 아무것도 모르는 나도 알아들을 수 있는 강의 찾으시는 분들.",
        logoType: "logo1",
      },
      {
        title: "서류 만능이 되고 싶은 분들!",
        subTitle:
          "엑셀/서류 다양한 서류가 있고 필요한 서류만 알고 싶고 몇 개의 서류만 알면 다른건 쉽게 혼자 해결하고 싶은 분들.",
        logoType: "logo2",
      },
      {
        title: "직접 만들면서 배우고 싶으신 분들!",
        subTitle:
          "일방적으로 듣기만 하는 강의가 아닌 실제로 같이 프로젝트를 만들면서 요점만 빠르게 배우는 걸 좋아하시는 분들.",
        logoType: "logo3",
      },
    ],
    runTimeHour: 10,
    runTimeWeek: 4,
    lectureTitle: [
      {
        title: "도면의 이해",
        subTitle: [
          {
            title: "위치도의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "계획평면도의 개념",
            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "횡.종단면도의 개념",

            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(정.하.우.좌)도면의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "상세도면의 개념",
            isFree: true,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "수량산출서의 이해",
        subTitle: [
          {
            title: "단위의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "총괄 수량집계표의 개념",

            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "내역서적용 수량의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "단가산출의 이해",
        subTitle: [
          {
            title: "일위대가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(중기) 단가산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "내역서의 이해",
        subTitle: [
          {
            title: "내역서의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "공종별집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "원가계산의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "조경식재(초급) 이해",
        subTitle: [
          {
            title: "설계교육 시작 전 내용정리",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "수량집계표 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "일위대가표 만들기",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
    ],
    comment: [
      {
        id: 0,
        userName: "정코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-9T18:22:48.429",
      },
      {
        id: 1,
        userName: "서코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-09T18:22:48.429",
      },
      {
        id: 2,
        userName: "이코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-08T18:22:48.429",
      },
      {
        id: 3,
        userName: "양코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-07T18:22:48.429",
      },
      {
        id: 4,
        userName: "최코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-06T18:22:48.429",
      },
    ],
  },
  {
    category_id: 4,
    is_admin_visible: null,
    id: 4,
    created: "2022-03-14T18:22:48.429",
    name: "건축의 이해",
    description:
      "조경설계를 하고 싶은분들, 조경설계서류를 알고 싶은분들 초급 조경설계 하나면 마스터 할 수 있습니다.",
    star: 5,
    allContent: 15,
    amount: 30,
    thumbnail:
      "https://sulfac-image.s3.ap-northeast-2.amazonaws.com/None_1671081110882867.jpg", //대표이미지

    explanation: [
      "설계내역서 기본적인 구조 지식 및 작성가능",
      "수량산출서의 기본적인 지식 및 작성가능",
      "일위대가표의 기본적인 지식 및 작성가능",
    ],
    RecommendationTitle: [
      {
        title: "지루한 설계강의가 싫으신분!",
        subTitle:
          "설계자만 알아들을 수 있는 어려운 설명말고 아무것도 모르는 나도 알아들을 수 있는 강의 찾으시는 분들.",
        logoType: "logo1",
      },
      {
        title: "서류 만능이 되고 싶은 분들!",
        subTitle:
          "엑셀/서류 다양한 서류가 있고 필요한 서류만 알고 싶고 몇 개의 서류만 알면 다른건 쉽게 혼자 해결하고 싶은 분들.",
        logoType: "logo2",
      },
      {
        title: "직접 만들면서 배우고 싶으신 분들!",
        subTitle:
          "일방적으로 듣기만 하는 강의가 아닌 실제로 같이 프로젝트를 만들면서 요점만 빠르게 배우는 걸 좋아하시는 분들.",
        logoType: "logo3",
      },
    ],
    runTimeHour: 10,
    runTimeWeek: 4,
    lectureTitle: [
      {
        title: "도면의 이해",
        subTitle: [
          {
            title: "위치도의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "계획평면도의 개념",
            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "횡.종단면도의 개념",

            isFree: true,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(정.하.우.좌)도면의 개념",
            isFree: true,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "상세도면의 개념",
            isFree: true,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "수량산출서의 이해",
        subTitle: [
          {
            title: "단위의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "총괄 수량집계표의 개념",

            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "내역서적용 수량의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "단가산출의 이해",
        subTitle: [
          {
            title: "일위대가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "(중기) 단가산출서의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가의 개념",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "내역서의 이해",
        subTitle: [
          {
            title: "내역서의 개념",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "공종별집계표의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "원가계산의 개념",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
      {
        title: "조경식재(초급) 이해",
        subTitle: [
          {
            title: "설계교육 시작 전 내용정리",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "단위 수량산출서 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "수량집계표 만들기",
            isFree: false,
            type: "video",
            runtime: 10,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "일위대가표 만들기",
            isFree: false,
            type: "video",
            runtime: 5,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "기계경비 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "시중노임단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
          {
            title: "자재단가 만들기",
            isFree: false,
            type: "video",
            runtime: 3,
            videoLink: "https://youtu.be/on3AJ3cxNws",
            reference:
              "https://maize-bite-f7a.notion.site/2-80f6550a8a0548ec84b8af9663423d0f",
          },
        ],
      },
    ],
    comment: [
      {
        id: 0,
        userName: "정코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-9T18:22:48.429",
      },
      {
        id: 1,
        userName: "서코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-09T18:22:48.429",
      },
      {
        id: 2,
        userName: "이코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-08T18:22:48.429",
      },
      {
        id: 3,
        userName: "양코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-07T18:22:48.429",
      },
      {
        id: 4,
        userName: "최코딩",
        grade: 5,
        content:
          "쉽게 접할 수 없던 건축 실무 강의를 개설해주셔서 정말 감사합니다.",
        created: "2023-01-06T18:22:48.429",
      },
    ],
  },
];
