import { Radio, Form, Input, Button, Select, Modal } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";

import { api } from "../../config/api";
import {
  SafetyManagementItemData,
  SafetyManagementStateItemData,
} from "../../constants/SafetyManagementItemData";
import {
  EnviManageData,
  EnviManageStateData,
} from "../../constants/EnviManageData";
import { PhotoListContentData } from "../../constants/PhotoListData";
import { LoadingContext } from "../../contexts/LoadingContext";
import DupLoginContext from "../../contexts/DupLoginContext";
import AddModal from "./components/AddCompanyModal";
import { pages } from "../../constants/PagePaths";
import AddCompanyModal from "./components/AddCompanyModal";
import AddAgentModal from "./components/AddAgentModal";

function NewWorkPage() {
  //업체 정보 선택 시 동작
  const [selectCompany, setSelectCompany] = useState();
  const onChangeCompany = (event) => {
    setSelectCompany(
      companyInfo.filter((companyInfo) => companyInfo.companyName === event.target.value)
    );
  };

  //현장대리인 정보 선택 시 동작
  const [selectDeputy, setSelectDeputy] = useState();
  const dupLogin = useContext(DupLoginContext);
  const onChangeDeputy = (event) => {
    setSelectDeputy(
      deputyInfo.filter((deputyInfo) => deputyInfo.deputyName === event.target.value)
    );
  };

  ////////////////////////////////////////////////////////////////////////

  //확인 클릭 시 동작
  const history = useHistory();
  const loading = useContext(LoadingContext);

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);
    const values = {};

    // FormData의 키와 값을 반복하여 values 객체에 저장
    formData.forEach(function (value, key) {
      values[key] = value;
    });
    loading.showLoading();
    let object = {
      contractKind: values.contractKind,
      constructionName: values.constructionName,
      typeOfWork: values.typeOfWork,
      companyName:
        selectCompany === undefined ? "" : selectCompany[0].companyName,
      representativeName:
        selectCompany === undefined ? "" : selectCompany[0].representativeName,
      corporateRegistNum:
        selectCompany === undefined ? "" : selectCompany[0].corporateRegistNum,
      corporateNum:
        selectCompany === undefined ? "" : selectCompany[0].corporateNum,
      businessAddress:
        selectCompany === undefined ? "" : selectCompany[0].businessAddress,
      typeOfBusiness:
        selectCompany === undefined ? "" : selectCompany[0].typeOfBusiness,
      phoneNum: selectCompany === undefined ? "" : selectCompany[0].phoneNum,
      faxNum: selectCompany === undefined ? "" : selectCompany[0].faxNum,
      writer: selectCompany === undefined ? "" : selectCompany[0].writer,
      bankName: selectCompany === undefined ? "" : selectCompany[0].bankName,
      accountNum:
        selectCompany === undefined ? "" : selectCompany[0].accountNum,
      accountHolder:
        selectCompany === undefined ? "" : selectCompany[0].accountHolder,
      deputyName: selectDeputy === undefined ? "" : selectDeputy[0].deputyName,
      deputyResidentNum:
        selectDeputy === undefined ? "" : selectDeputy[0].deputyResidentNum,
      deputyAddress:
        selectDeputy === undefined ? "" : selectDeputy[0].deputyAddress,
      deputyPosition:
        selectDeputy === undefined ? "" : selectDeputy[0].deputyPosition,
      deputyEvent:
        selectDeputy === undefined ? "" : selectDeputy[0].deputyEvent,
      deputyGrade:
        selectDeputy === undefined ? "" : selectDeputy[0].deputyGrade,
      deputyRegistNum:
        selectDeputy === undefined ? "" : selectDeputy[0].deputyRegistNum,
      deputyRegistDate:
        selectDeputy === undefined
          ? moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
          : selectDeputy[0].deputyRegistDate,

      deputyGetDate:
        selectDeputy === undefined
          ? moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
          : selectDeputy[0].deputyGetDate,
      deputyRenewDate:
        selectDeputy === undefined
          ? moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
          : selectDeputy[0].deputyRenewDate,
      deputyJoinDate:
        selectDeputy === undefined
          ? moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD")
          : selectDeputy[0].deputyJoinDate,
      deputyWorkPlace:
        selectDeputy === undefined ? "" : selectDeputy[0].deputyWorkPlace,
      typeOfDeposit:
        selectCompany === undefined ? "" : selectCompany[0].typeOfDeposit,
      storeName: selectCompany === undefined ? "" : selectCompany[0].storeName,

      contractDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      GuaranteeDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      claimDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      beginDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      completionDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      completionExpectDate: moment(new Date(), "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      ),
      realCompletionDate: moment(new Date(), "YYYY-MM-DD").format("YYYY-MM-DD"),
      compensationOfDeferment: 0.1,

      safetyManagePlan: SafetyManagementItemData,
      safetyManageState: SafetyManagementStateItemData,
      enviManagePlan: EnviManageData,
      enviManageState: EnviManageStateData,
      photoListContent: PhotoListContentData,

      completionInspectionDate: moment(new Date(), "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      ),
    };

    dupLogin.checkDupLogin(() =>
      api
        .postWorklist(object)
        .then((result) => {
          history.replace(`/costprojects/worklist`);
          loading.closeLoading();
        })
        .catch((error) => {
          loading.closeLoading();
          throw error;
        })
    );
  };

  const onFinishFailed = (errorInfo) => {};

  /***
   * 한 번만 호출
   */
  const [companyInfo, setCompanyInfo] = React.useState([]);
  const [deputyInfo, setDeputyInfo] = React.useState([]);
  useEffect(() => {
    //업체 목록 서버에서 불러오기 (여러개)
    if (companyInfo.length === 0) {
      dupLogin.checkDupLogin(() =>
        api
          .getWorkCompanyList({ offset: 0, limit: companyInfo.length })
          .then((result) => {
            const companyInfo = result.data.company_list;
            setCompanyInfo(companyInfo);
          })
      );
    }

    //현장대리인 목록 서버에서 불러오기 (여러개)
    if (deputyInfo.length === 0) {
      dupLogin.checkDupLogin(() =>
        api
          .getDeputyList({ offset: 0, limit: deputyInfo.length })
          .then((result) => {
            const deputyInfo = result.data.deputy_list;
            setDeputyInfo(deputyInfo);
          })
      );
    }
  }, []);

  //업체 정보 신규 등록
  const [isModalVisible3, setIsModalVisible3] = useState(false);

  const showModal3 = () => {
    setIsModalVisible3(true);
  };

  const handleCancel3 = () => {
    form.resetFields();
    setIsModalVisible3(false);
  };

  //업체 정보 신규 등록에서 확인 클릭 시 동작
  const [form] = Form.useForm();
  const handleOk3 = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);
    var values = {};
    
    // FormData의 키와 값을 반복하여 values 객체에 저장
    formData.forEach(function(value, key) {
      values[key] = value;
    });
    loading.showLoading();
    await dupLogin.checkDupLogin(() =>
      api
        .postWorkCompany(values)
        .then(async () => {
          await dupLogin.checkDupLogin(() =>
            api
              .getWorkCompanyList({ offset: 0, limit: companyInfo.length })
              .then((result) => {
                const companyInfo = result.data.company_list;
                setCompanyInfo(companyInfo);
              })
              .catch((error) => {
                console.error("에러발생 : ", error);
              })
              .finally(() => {
                // clear form data
                event.target.reset();
                loading.closeLoading();
              })
          );
        })
        .catch((error) => {
          loading.closeLoading();
          throw error;
        })
    );
  };

  //현장대리인 정보 신규 등록
  const [isModalVisible4, setIsModalVisible4] = useState(false);

  const showModal4 = () => {
    setIsModalVisible4(true);
  };

  const handleCancel4 = () => {
    form.resetFields();
    setIsModalVisible4(false);
  };

  //현장대리인 정보 신규 등록에서 확인 클릭 시 동작
  const handleOk4 = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(event.target);
    var values = {};
    
    // FormData의 키와 값을 반복하여 values 객체에 저장
    formData.forEach(function(value, key) {
      values[key] = value;
    });
    loading.showLoading();
    if (values.deputyRegistDate === undefined)
      values.deputyRegistDate = moment(new Date());
    dupLogin.checkDupLogin(() =>
      api
        .postDeputy(values)
        .then((result) => {
          dupLogin.checkDupLogin(() =>
            api
              .getDeputyList({ offset: 0, limit: 100 })
              .then((result) => {
                const deputyInfo = result.data.deputy_list;
                setDeputyInfo(deputyInfo);
              })
              .catch((error) => {
                console.error("에러발생 : ", error);
              })
              .finally(() => {
                event.target.reset();
                loading.closeLoading();
              })
          );
        })
        .catch((error) => {
          loading.closeLoading();
          throw error;
        })
    );
  };

  return (
    <main id="wrap" className="bg-light-md">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">신규 공사 생성 (공사 기본정보)</div>
            <div className="form-wrap col-md-9 col-lg-9 col-xl-7 mx-auto mt-3">
              <form
                className="row g-3"
                name="신규 공사 생성"
                onSubmit={onSubmit}
              >
                <div className="col-md-12 pb-4 ">
                  <Form.Item>
                    <label htmlFor="validationCustom04" className="form-label">
                      계약서 종류
                    </label>
                    <div className="col-12 check-wrap">
                      <div className="d-flex align-items-center gap-2">
                        <input
                          type="radio"
                          className="btn-check"
                          name="contractKind"
                          id="option2"
                          autoComplete="off"
                          value="normal"
                          checked
                        />
                        <label className="btn btn-outline" htmlFor="option2">
                          수의일반계약
                        </label>
                      </div>
                    </div>
                  </Form.Item>
                </div>
                <div className="col-md-6 pb-4">
                  <label htmlFor="validationCustom02" className="form-label">
                    공사명<span className="text-danger ms-2">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    placeholder="공사명을 입력해주세요"
                    name="constructionName"
                    required
                  />
                  <div className="invalid-feedback">필수 입력 정보입니다.</div>
                </div>
                <div className="col-md-6 pb-4">
                  <label htmlFor="validationCustom03" className="form-label">
                    공종<span className="text-danger ms-2">*</span>
                  </label>
                  <div className="input-group has-validation">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom03"
                      placeholder="공종을 입력해주세요"
                      required
                      name="typeOfWork"
                    />
                    <div className="invalid-feedback">
                      필수 입력 정보입니다.
                    </div>
                  </div>
                </div>
                <div className="col-md-12 pb-4">
                  <label htmlFor="validationCustom01" className="form-label">
                    업체 정보
                  </label>
                  <div className="d-flex align-items-center gap-2">
                    <select
                      className="form-select form-select-lg"
                      aria-label="업체 정보"
                      onChange={onChangeCompany}
                      name="businessinfo"
                    >
                      <option selected="">업체 정보를 선택해주세요.</option>
                      {companyInfo.map((companyInfo) => (
                        <option value={companyInfo.companyName}>
                          {companyInfo.companyName}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary-soft"
                      data-bs-target="#addCompany"
                      data-bs-toggle="modal"
                    >
                      추가하기
                    </button>
                  </div>
                </div>
                <div className="col-md-12 pb-4">
                  <label htmlFor="validationCustom01" className="form-label">
                    현장 대리인 정보
                  </label>
                  <div className="d-flex align-items-center gap-2">
                    <select
                      className="form-select form-select-lg"
                      aria-label="현장 대리인 정보"
                      onChange={onChangeDeputy}
                      name="deputyinfo"
                    >
                      <option selected="">
                        현장 대리인 정보를 선택해주세요.
                      </option>
                      {deputyInfo.map((deputyInfo) => (
                        <option value={deputyInfo.deputyName}>
                          {deputyInfo.deputyName}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="btn btn-lg btn-primary-soft"
                      data-bs-target="#addAgent"
                      data-bs-toggle="modal"
                    >
                      추가하기
                    </button>
                  </div>
                </div>
                <div className="mo-fixed-bottom col-12 py-3">
                  <div className="d-flex align-items-center gap-3">
                    <Link
                      to={pages.costprojects + "/worklist"}
                      className="btn btn-light btn-lg flex-1 left-icon justify-content-center"
                    >
                      <span className="material-symbols-outlined me-2">
                        keyboard_backspace
                      </span>
                      이전
                    </Link>
                    <button
                      className="btn btn-primary btn-lg flex-1 left-icon  justify-content-center"
                      type="submit"
                    >
                      <span className="material-symbols-outlined me-2">
                        done
                      </span>
                      저장하기
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </article>
      <AddCompanyModal handleOk3={handleOk3} />
      <AddAgentModal handleOk4={handleOk4} />
    </main>
  );
}

export default NewWorkPage;
