import { useLocation } from "react-router-dom";
import { LevelTestResProvider } from "../../contexts/LevelTestContext";
import LevelTest from "./LevelTest";
import LevelTest1 from "./LevelTest1";
import LevelTest10 from "./LevelTest10";
import LevelTest2 from "./LevelTest2";
import LevelTest3 from "./LevelTest3";
import LevelTest4 from "./LevelTest4";
import LevelTest5 from "./LevelTest5";
import LevelTest6 from "./LevelTest6";
import LevelTest7 from "./LevelTest7";
import LevelTest8 from "./LevelTest8";
import LevelTest9 from "./LevelTest9";
import LevelTestComplete from "./LevelTestComplete";

export default () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const levelTestPage = (id: any) => {
    if (id == 0) {
      return <LevelTest />;
    }
    if (id == 1) {
      return <LevelTest1 />;
    }
    if (id == 2) {
      return <LevelTest2 />;
    }
    if (id == 3) {
      return <LevelTest3 />;
    }
    if (id == 4) {
      return <LevelTest4 />;
    }
    if (id == 5) {
      return <LevelTest5 />;
    }
    if (id == 6) {
      return <LevelTest6 />;
    }
    if (id == 7) {
      return <LevelTest7 />;
    }
    if (id == 8) {
      return <LevelTest8 />;
    }
    if (id == 9) {
      return <LevelTest9 />;
    }
    if (id == 10) {
      return <LevelTest10 />;
    }
    if (id.toString() == "complete") {
      return <LevelTestComplete />;
    }
  };

  return <LevelTestResProvider>{levelTestPage(id)}</LevelTestResProvider>;
};
