import React, { useCallback, useContext, useEffect, useState } from "react";
import EstimateModal from "../../../components/modal/estimateModal/EstimateModal";
import { api } from "../../../config/api";
import { util } from "../../../config/util";
import AlertContext from "../../../contexts/AlertContext";
import { EstimateService } from "../../../models/EstimateService";

interface Props {
  minIndex: number;
  maxIndex: number;
}

export default () => {
  const [estimateData, setEstimateData] = useState<EstimateService[]>([]);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const statusMapper: { [key in string]: string } = {
    request: "상담중",
    progress: "진행중",
    completed: "납품완료",
  };
  const alert = useContext(AlertContext);
  const onClickToggleModal = useCallback(() => {
    setOpenModal(!isOpenModal);
  }, [isOpenModal]);

  useEffect(() => {
    api
      .getEstimate()
      .then((result) => {
        const response: EstimateService[] = result.data.estimate_services;
        // response.estimate_services를 status 최신 순으로 정렬후, 같은 status 내에서는 Date순으로 정렬
        response.sort((a, b) => {
          if (a.status === b.status) {
            return a.start_date > b.start_date ? -1 : 1;
          } else {
            return a.status > b.status ? -1 : 1;
          }
        });

        setEstimateData(response);
      })
      .catch((error) => {
        console.error("에러발생 : ", error);
      });
  }, []);

  return (
    <section className="request">
      <div className="container">
        <div className="request-title">
          <h1 className="text-white col-md-10">
            아직도 건설 서류가 어려우세요?
            <br />
            정직한 가격, 신속한 업무처리.
            <br />
            뚜껑설계 용역서비스를 이용해보세요
          </h1>
          <a
            className="btn btn-xlg right-icon btn-white"
            href="/requestapplication"
          >
            의뢰 신청 바로가기
            <span className="material-symbols-outlined">chevron_right</span>
          </a>
        </div>
      </div>
      <div className="container">
        <div className="counter-box row mt-9">
          <div className="counter-box-item col-md-4 text-center text-white">
            <h1 className="text-white">
              <span className="counterUp">645</span>+
            </h1>
            <div className="SubTitle2">용역 의뢰 건수</div>
            <i className="fa fa-users"></i>
          </div>
          <div className="counter-box-item col-md-4 text-center text-white">
            <h1 className="text-white">
              <span className="counterUp">9,219,000,000</span>+
            </h1>
            <div className="SubTitle2">총 공사비</div>
            <i className="fa fa-desktop"></i>
          </div>
          <div className="counter-box-item col-md-4 text-center text-white">
            <h1 className="text-white">
              <span className="counterUp">45</span>+
            </h1>
            <div className="SubTitle2">의뢰 지역</div>
            <i className="fa fa-coffee"></i>
          </div>
        </div>
      </div>
      <div className="marquee">
        <div className="marquee--inner">
          <div className="request-card-wrap">
            {estimateData?.map((item, index) => {
              return (
                <div
                  className="request-card"
                  onClick={() => {
                    alert.showContentModal(
                      <EstimateModal
                        onClickToggleModal={onClickToggleModal}
                        estimateService={estimateData[index]}
                      ></EstimateModal>,
                      {
                        width: "900px;",
                      }
                    );
                  }}
                >
                  <div>
                    <div className="badge text-bg-primary-soft mb-2">
                      {item.address}
                    </div>
                    <div className="SubTitle2">{item.service_type}</div>
                  </div>
                  <div className="price">
                    <span>{util.addComma(item.amount)}</span>원
                  </div>
                  <div className="date">{item.end_date}</div>
                  <div className="chip-wrap">
                    <div className="chip-small chip-primary ">
                      {statusMapper[item.status]}
                    </div>
                    <div className="chip-small chip-light ">
                      <div className="avatar-name">
                        <span className="avatar-img">
                          <img src="/images/ddusul/icon/level1.png" />
                        </span>
                        <span>{item.company_name}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};
