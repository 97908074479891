import { Router } from "@material-ui/icons";
import { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { pages } from "../../constants/PagePaths";
import { Imp } from "../../models/payment/iamport/IMP";
import {
  ImpCertificationRequestModel,
  ImpCertificationResponseModel,
} from "../../models/payment/iamport/ImpModels";
import GlobalEnvironments from "../../modules/globalEnvironment/GlobalEnvironment";

export default (props: any) => {
  const location = useLocation();
  const linkLocation = location.pathname;
  const strArray = linkLocation.split("/");
  const history = useHistory();

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    const provider = strArray[2];
    const code = params.get("code")!;
    const redirectUrl = GlobalEnvironments.getAppUrl() + "oauth2/" + provider;
    if (!code) {
      history.replace(pages.login);
      history.push(pages.login);
    }
    new Promise(async () => {
      const res = await api.socialLogin(provider, code, redirectUrl);
      if (res.data.uid) {
        history.replace(pages.login);
        history.push({
          pathname: pages.cert,
          state: {
            provider: provider,
            providerId: res.data.uid,
            email: res.data.email,
          },
        });
      } else {
        const key = res.data.key;
        const now = new Date();
        util.setCookie({
          cookieName: Cookies.authKey,
          cookieValue: key,
          cookieExpire: new Date(now.setFullYear(now.getFullYear() + 1)),
          cookieDomain: GlobalEnvironments.getDomainUrl(),
          cookiePath: "/",
        });
        props.checkUser();

        history.replace(pages.login);
        history.push(pages.index);
      }
    });
  }, []);

  return <></>;
};
