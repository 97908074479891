export default class LazyEvent {
  private _isRunEvent: boolean = false;
  private _latestEvent: (() => void) | null | undefined;
  private _timeout: number;

  constructor(timeout: number = 800) {
    this._timeout = timeout;
  }

  public async registerAsync(event: () => Promise<void>) {
    if (this._isRunEvent) {
      this._latestEvent = event;
      return;
    }

    this._isRunEvent = true;
    await event();

    setTimeout(async () => {
      this._isRunEvent = false;
      const copyLatestEvent = this._latestEvent;
      if (this._latestEvent) {
        this._latestEvent = null;
        await copyLatestEvent?.();
      }
    }, this._timeout);
  }
}
