import styles from "./Section.module.scss";
import { Link, useHistory } from "react-router-dom";
import { pages, videoPages } from "../../../../constants/PagePaths";
import { useEffect, useState } from "react";
import { videoApi } from "../../../../config/videoApi";
import { util } from "../../../../config/util";
import LectureListSection from "./LectureListSection";
import { lectureModels } from "../../../main/components/RecommendLeture";

const Section = () => {
  const [cardItems, setCardItems] = useState([]);
  const history = useHistory();
  const [lectures, setLectures]: any = useState([]);
  const userCounts: any = {
    0: "250명",
    1: "230명",
    2: "190명",
  };
  useEffect(() => {
    new Promise(async () => {
      const cardRes = await videoApi.getMainCardCourseAsync();
      setCardItems(cardRes.data.courses);
      // getCourse();
    });
  }, []);

  const getCourse = async (filter?: string) => {
    const res = await videoApi.getCourseListAsync();
    res.data.courses.sort(function (a: any, b: any) {
      return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
    });

    let searchModels = lectureModels;
    if (filter) {
      searchModels = lectureModels.filter(
        (lecture) => lecture.category == filter
      );
    }
    const orderedCourses = searchModels.map((lectureModel) =>
      res.data.courses.find((course: any) => course.id === lectureModel.id)
    );
    setLectures(orderedCourses);
  };

  return (
    <main id="wrap" className="bg-dark">
      <article>
        <section className="page-top-banner-two">
          <div className="container">
            <div className="text-box">
              <div>쉽고 재미있게 공부하자!</div>
              <h2 className="text-white">나의 가치를 성장시키는 강의</h2>
            </div>
          </div>
        </section>
        <section>
          <div className="container page">
            <div className="section-header text-white">BEST 강의</div>
            <div
              className="card-wrap column3 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {cardItems.map((card: any, index) => {
                return (
                  <div
                    className="card pointer"
                    onClick={() => history.push(`video/curriculum/${card.id}`)}
                  >
                    <img
                      src={card.card_image}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">{card.title}</h5>
                      <div className="body2 mb-2">{card.description}</div>
                      <div className="card-text mb-2">
                        <div className="card-caption">
                          {card.review_avg != null ? (
                            <div className="rating">
                              <span>{card.review_avg}</span>
                              <div className="star-wrap">
                                {Array.from({ length: card.review_avg }).map(
                                  (_, index) => (
                                    <span></span>
                                  )
                                )}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          {/*  */}
                          <div className="price">
                            <span className="discount">
                              <span>{util.addComma(card.origin_amount)}</span>원
                            </span>
                            <span>{util.addComma(card.amount)}</span>원
                          </div>
                        </div>
                      </div>
                      <div className="badge-wrap">
                        <span className="badge rounded-pill text-bg-primary-soft">
                          {userCounts[index]}
                        </span>
                        <span className="badge rounded-pill text-bg-primary">
                          {card.category}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        {/* <section>
          <div className="container">
            <div
              className="pt-6 pb-4 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <a
                href={pages.leveltest + "/0"}
                className="bar-banner-wrap float-on-hover"
              >
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div className="bar-banner-txt">
                    <h2>레벨 테스트 타이틀 영역입니다</h2>
                    <div className="body2">서브타이틀 영역입니다</div>
                  </div>
                  <div className="bar-banner-img">
                    <img src="/images/ddusul/3d-testpaper.png" />
                  </div>
                </div>
                <span className="material-symbols-outlined text-white">
                  arrow_forward_ios
                </span>
              </a>
            </div>
          </div>
        </section> */}
        <LectureListSection />
      </article>
    </main>
  );
};

export default Section;
