import { UserBoardCategory, UserBoardDetailModel, UserBoardListItem } from "../models/userboard/UserBoardModel";

export const UserBoardListDummyData: UserBoardListItem[] = [
  {
    id: 0,
    title: "고민입니다...",
    category:UserBoardCategory.고민상담,
    created: new Date(),
    viewCount:1,
  },
  {
    id: 1,
    title: "일자리 구하멘!",
    category:UserBoardCategory.구인구직,
    created:new Date(),
    viewCount:1,

  },
  {
    id: 2,
    title: "같이 낚시 가실분?",
    category:UserBoardCategory.기타,
    created:new Date(),
    viewCount:1,

  },
  {
    id: 3,
    title: "다운님이 요청한 자료 공유합니다.",
    category:UserBoardCategory.자료공유,
    created:new Date(),
    viewCount:1,

  },
  {
    id: 4,
    title: "내가 만든 멋진 현장을 보시라~~!!!",
    category:UserBoardCategory.전체,
    created:new Date(),
    viewCount:1,

  },
];


export const UserBoardDetailDummyData: UserBoardDetailModel={
  content: "고민이 많습니다.",
  id: 0,
  title: "고민입니다...",
  category:UserBoardCategory.고민상담,
  created: new Date(),
  viewCount:1,
}

