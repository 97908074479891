import { useContext, useRef, useState } from "react";
import ExcelPresenter from "./ExcelPresenter";
import { api } from "../../config/api";
import AlertContext from "../../contexts/AlertContext";
import { util } from "../../config/util";
import { LoadingContext } from "../../contexts/LoadingContext";
import { ExcelX2File, ExcelX2FileSheet } from "../../models/excelModel/excelX2";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";
import { CategoryItem } from "../../models/CategoryItem";
import { ExcelItem } from "../../models/excelModel/excelItem";

export default () => {
  const [originOption, setOriginOption] = useState("black");
  const [divideOption, setDivideOption] = useState("fixed");
  const [isOriginUpper, setIsOriginUpper] = useState(true);
  const [isHiddenSheetShow, setIsHiddenSheetShow] = useState(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [fileInfos, setFileInfos] = useState<ExcelX2File[]>([]);

  const alertContext = useContext(AlertContext);
  const loading = useContext(LoadingContext);
  const history = useHistory();
  const megaByte = 1000 * 1000;
  const fileCountLimit = 3;
  const fileSizeSum = useRef(0);
  const sheetCellSum = useRef(0);
  const categoryPermissionMapper: any = {
    0: "EXCEL_X2",
    1: "EXCEL_FLUCTUATION",
    2: "EXCEL_REPORT",
    3: "EXCEL_CONTRACT",
    4: "EXCEL_READY",
    5: "free",
    6: "free",
    7: "free",
  };
  const macroType: ExcelItem[] = [
    {
      id: "0",
      title: "두 줄로 나누기",
      description: "엑셀파일의 당초/변경을 손쉽게 처리하세요.",
      lectureId: "50",
    },
    {
      id: "1",
      title: "증감 내역서",
      description: "엑셀파일의 증감 표시를 손쉽게 처리하세요.",
      lectureId: "52",
    },
    {
      id: "2",
      title: "변경 실정보고서",
      description: "변경 엑셀파일의 실정보고서 작성을 손쉽게 처리하세요.",
      lectureId: "58",
    },
    {
      id: "3",
      title: "계약 내역서",
      description: "엑셀파일의 계약내역서 작성을 손쉽게 처리하세요.",
      lectureId: "49",
    },
    {
      id: "4",
      title: "기성 내역서",
      description: "엑셀파일의 기성내역서 작성을 손쉽게 처리하세요.",
      lectureId: "30",
    },
    {
      id: "5",
      title: "변경 준공만들기",
      description: "변경 엑셀파일의 두줄을 서로 같게 만들어 드립니다.",
      tag: "무료",
      lectureId: "52",
    },
    {
      id: "6",
      title: "변경 한줄만들기",
      description: "변경 엑셀파일의 두줄을 한줄로 손쉽게 만드세요.",
      tag: "무료",
      lectureId: "52",
    },
    {
      id: "7",
      title: "변경 색상교체",
      description: "변경 엑셀파일의 당초/변경행 색상교체를 손쉽게 처리하세요.",
      tag: "무료",
      lectureId: "52",
    },
  ];
  /** 파일 사이즈 총 합 제한
   * 개당 사이즈 곱하기 개수로 제한
   */
  const fileSizeLimit = 30 * fileCountLimit;

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // set dragging over style
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    handleFiles(Array.from(e.dataTransfer.files));
  };

  const handleChangeFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      if (e.target.files?.length === 0) return;

      handleFiles(Array.from(e.target.files));
    }
  };

  const handleFiles = async (files: File[]) => {
    const uploadedfiles = [];
    const infos: ExcelX2File[] = [];
    // 파일의 확장자가 xlsx가 아니라면 경고 후 xlsx인 파일만 필터

    if (files.some((file) => file.name.split(".").pop() !== "xlsx")) {
      alertContext.showAlert("엑셀(.xlsx) 파일만 업로드할 수 있습니다.");
      files = files.filter((file) => file.name.split(".").pop() === "xlsx");
    }
    for (let i = 0; i < (files.length || 0); i++) {
      let file = files[i];
      fileSizeSum.current += file.size;
      if (
        fileSizeSum.current < fileSizeLimit * megaByte &&
        i + fileInfos?.length < fileCountLimit
      ) {
        const res = await api.uploadFileAsync(file, "excelX2");
        uploadedfiles.push(res.data.url);
        // get file extension

        infos.push({
          name: file.name,
          url: res.data.url,
          size: file.size,
          isEntireChecked: false,
        });
      } else {
        alertContext.showAlert(
          `파일은 최대 ${fileCountLimit}개, 합계 ${fileSizeLimit}MB를 넘을 수 없습니다.`
        );
        break;
      }
    }
    setFileInfos([...fileInfos, ...infos]);
    getSheetNames(uploadedfiles, infos);
  };

  const getSheetNames = async (files: string[], infos: ExcelX2File[]) => {
    loading.showLoading("엑셀시트 목록을 불러오는 중입니다.");
    try {
      const res = await api.getSheetNamesAsync(files);
      let errorMessage = "";
      const errorFiles: any = [];
      loading.closeLoading();
      if (res.status === 200) {
        infos.forEach((fileInfo, index) => {
          if (res.data.data[fileInfo.url]?.find(Boolean).errorMessage) {
            errorFiles.push({
              name: fileInfo.name,
              errorMessage:
                res.data.data[fileInfo.url]?.find(Boolean).errorMessage,
              index,
            });
          } else {
            fileInfo.sheets = res.data.data[fileInfo.url].map(
              (sheetInfo: {
                sheetName: string;
                isHide: boolean;
                lastRow: number;
                lastColumn: number;
                sheetIndex: number;
                errorMessage: string;
              }): ExcelX2FileSheet => {
                if (sheetInfo.errorMessage) {
                  errorMessage += sheetInfo.errorMessage + "\n";
                }
                return {
                  name: sheetInfo.sheetName,
                  checked: false,
                  isHidden: sheetInfo.isHide,
                  lastRow: sheetInfo.lastRow,
                  lastColumn: sheetInfo.lastColumn,
                  sheetIndex: sheetInfo.sheetIndex,
                };
              }
            );
          }
        });
        if (errorFiles.length > 0) {
          errorFiles?.forEach((errorSheet: any) => {
            errorMessage += `${errorSheet.name}: ${errorSheet.errorMessage}\n`;
            infos.splice(errorSheet.index, 1);
          });
          alertContext.showAlert(
            "처리되지 않은 파일이 있습니다.\n아래 사유를 확인해주세요.\n\n" +
              errorMessage
          );
        } else if (errorMessage) {
          alertContext.showAlert(errorMessage);
        }
        setFileInfos([...fileInfos, ...infos]);
      }
    } catch (err: any) {
      loading.closeLoading();

      alertContext.showAlert("오류가 발생했습니다.\n고객센터에 문의해주세요.");
    }
  };

  const handleDelete = (index: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const newFileInfos = [...fileInfos];
    newFileInfos[index].sheets?.forEach((sheet) => {
      if (sheet.checked) {
        sheetCellSum.current -= sheet.lastRow * sheet.lastColumn;
      }
    });
    newFileInfos.splice(index, 1);
    setFileInfos(newFileInfos);
    fileSizeSum.current -= fileInfos[index].size;
  };

  const handleOnOriginChange = (value: string) => {
    setOriginOption(value);
  };

  const handleOnOriginRowChange = (value: boolean) => {
    setIsOriginUpper(value);
  };

  const handleOnDivideChange = (value: string) => {
    setDivideOption(value);
  };

  const handleEntireCheck = (index: number) => {
    if (fileInfos[index].isEntireChecked) {
      const newFileInfos = [...fileInfos];
      newFileInfos[index].isEntireChecked = false;
      newFileInfos[index].sheets = newFileInfos[index].sheets?.map((sheet) => {
        if (sheet.checked) {
          sheetCellSum.current -= sheet.lastRow * sheet.lastColumn;
        }
        sheet.checked = false;
        return sheet;
      });

      setFileInfos(newFileInfos);
    } else {
      const newFileInfos = [...fileInfos];
      newFileInfos[index].isEntireChecked = true;
      newFileInfos[index].sheets = newFileInfos[index].sheets?.map((sheet) => {
        if (!isHiddenSheetShow && sheet.isHidden) return sheet;
        if (!sheet.checked) {
          sheetCellSum.current += sheet.lastRow * sheet.lastColumn;
        }
        sheet.checked = true;
        return sheet;
      });

      setFileInfos(newFileInfos);
    }
  };

  const handleSheetClick = (fileIndex: number, sheet: ExcelX2FileSheet) => {
    const newFileInfos = [...fileInfos];
    const checked = sheet.checked;

    if (newFileInfos[fileIndex].sheets?.length ?? 0 > 0) {
      sheet.checked = !checked;
      if (checked) {
        newFileInfos[fileIndex].isEntireChecked = false;
        sheetCellSum.current -= sheet.lastRow * sheet.lastColumn;
      } else {
        sheetCellSum.current += sheet.lastRow * sheet.lastColumn;
      }
    }

    newFileInfos[fileIndex].isEntireChecked = (
      newFileInfos[fileIndex].sheets ?? []
    ).every((sheet) =>
      isHiddenSheetShow ? sheet.checked : sheet.isHidden ? true : sheet.checked
    );

    setFileInfos(newFileInfos);
  };

  const handleExecute = async () => {
    if (!util.isLogin()) {
      alertContext.showAlert("로그인이 필요합니다.", () => {
        history.replace(pages.login);
      });
      return;
    }
    // 이용권 확인
    const res = await api.getMyInfo();

    const expire = res.data.expiry_dates.find((expiry) => {
      if (categoryPermissionMapper[selectedCategoryIndex] === "free") {
        return true;
      }
      if (categoryPermissionMapper[selectedCategoryIndex] === expiry.name) {
        if (expiry.expiry_date) {
          const now = new Date();
          const expiryDate: any = new Date(expiry.expiry_date);
          if (now > expiryDate) {
            return null;
          }
          return true;
        } else {
          return null;
        }
      }
    });
    if (!expire) {
      alertContext.showAlert("이용권이 없습니다.\n이용권을 구매해주세요.");
      history.push(pages.payment);
      return;
    }
    if (fileInfos.length === 0) {
      alertContext.showAlert("파일을 업로드해주세요.");
      return;
    }
    const data: {
      s3Url: string;
      selectedSheets: number[];
    }[] = [];

    for (const fileInfo of fileInfos) {
      if (fileInfo.sheets?.every((sheet) => !sheet.checked)) {
        alertContext.showAlert("시트를 선택해주세요.");
        return;
      }

      const selectedSheets: number[] = [];
      fileInfo.sheets?.forEach((sheet) => {
        if (sheet.checked) {
          selectedSheets.push(sheet.sheetIndex);
        }
      });

      data.push({
        s3Url: fileInfo.url,
        selectedSheets,
      });
    }
    loading.showLoading(
      `엑셀파일을 처리하는 중입니다.\n행과 열이 많을수록 긴 시간이 소요됩니다.`
    );
    try {
      const requests = getRequests(data);
      const responses = await Promise.all(requests);
      loading.closeLoading();
      const urls: string[] = [];
      const messages: string[] = [];
      responses.forEach((res) => {
        if (res.status === 200) {
          const urlExist = res.data.urls?.length > 0;
          const messageExist = res.data.messages?.length > 0;
          if (urlExist) {
            urls.push(...res.data.urls);
          }
          if (messageExist) {
            messages.push(...res.data.messages);
          }
        }
      });
      const urlExist = urls?.length > 0;
      const messageExist = messages?.length > 0;
      if (urlExist) {
        if (messageExist) {
          // 부분적으로 처리된 경우
          setFileInfos(
            fileInfos.filter((fileInfo) => {
              return !urls?.includes(fileInfo.url);
            })
          );
          alertContext.showAlert(
            "부분적으로 처리되었습니다.\n정상파일은 다운로드 폴더를 확인해주세요.\n오류파일은 아래 메시지를 확인해주세요.\n\n" +
              messages.join("\n")
          );
        } else {
          // 정상처리된 경우
          alertContext.showAlert(
            "성공적으로 처리되었습니다.\n다운로드 폴더를 확인해주세요."
          );
        }
        sheetCellSum.current = 0;
        // download files
        for (let url of urls) {
          // 모든 +를 %2B로 치환
          url = url.replace(/\+/g, "%2B");

          util.downloadFile(url);

          // 0.5초 대기
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        setFileInfos([]);
      } else {
        // 정상처리된 결과가 없는 경우
        sheetCellSum.current = 0;
        alertContext.showAlert(
          "처리중 오류가 발생했습니다.\n아래 사유를 확인해주세요.\n\n" +
            messages.join("\n")
        );
        setFileInfos([]);
      }
    } catch (err: any) {
      loading.closeLoading();

      alertContext.showAlert("오류가 발생했습니다.\n고객센터에 문의해주세요.");
    }
  };

  const getRequests = (data: { s3Url: string; selectedSheets: number[] }[]) => {
    let macroFunc: any;
    switch (selectedCategoryIndex) {
      case 0:
        macroFunc = api.postExcelX2Execute;
        break;
      case 1:
        macroFunc = api.postExcelFluctuationExecute;
        break;
      case 2:
        macroFunc = api.postExcelChangeReportExecute;
        break;
      case 3:
        macroFunc = api.postExcelContractExecute;
        break;
      case 4:
        macroFunc = api.postExcelReadyExecute;
        break;
      case 5:
        macroFunc = api.postExcelOverrideExecute;
        break;
      case 6:
        macroFunc = api.postExcelDeleteExecute;
        break;
      case 7:
        macroFunc = api.postExcelColorExecute;
        break;

      default:
        break;
    }
    const requests = data.map((d) =>
      macroFunc({
        data: [d],
        originOption,
        divideOption,
        isOriginUpper,
      })
    );
    return requests;
  };

  const handleHiddenSheetClick = () => {
    setIsHiddenSheetShow(!isHiddenSheetShow);
    refreshAllEntireChecked(!isHiddenSheetShow);
  };

  const refreshAllEntireChecked = (isHiddenSheetShow: boolean) => {
    const newFileInfos = [...fileInfos];
    newFileInfos.forEach((fileInfo) => {
      fileInfo.isEntireChecked = (fileInfo.sheets ?? []).every((sheet) =>
        isHiddenSheetShow
          ? sheet.checked
          : sheet.isHidden
          ? true
          : sheet.checked
      );
    });
    setFileInfos(newFileInfos);
  };

  return (
    <ExcelPresenter
      fileInfos={fileInfos}
      fileCountLimit={fileCountLimit}
      originOption={originOption}
      divideOption={divideOption}
      originRowOption={isOriginUpper}
      isHiddenSheetShow={isHiddenSheetShow}
      macroType={macroType}
      selectedCategoryIndex={selectedCategoryIndex}
      handleDragOver={handleDragOver}
      handleDrop={handleDrop}
      handleChangeFiles={handleChangeFiles}
      handleDelete={handleDelete}
      handleOnDivideChange={handleOnDivideChange}
      handleOnOriginChange={handleOnOriginChange}
      handleOnOriginRowChange={handleOnOriginRowChange}
      handleEntireCheck={handleEntireCheck}
      handleSheetClick={handleSheetClick}
      handleExecute={handleExecute}
      handleHiddenSheetClick={handleHiddenSheetClick}
      onClickCategory={(index: number) => setSelectedCategoryIndex(index)}
    />
  );
};
