/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../config/api";
import "./myPage.scss";
import AlertContext from "../../contexts/AlertContext";
import {
  HistoryPoductModel,
  PaymentHistoryModel,
} from "../../models/payment/PaymentHistoryModel";
import CouponBoxModel from "../../models/coupon/CouponBoxModel";
import {
  IamportPayMethod,
  payMethodMapper,
} from "../../models/payment/iamport/ImpModels";
import { util } from "../../config/util";

interface Props {}

export default () => {
  const [paymentHistory, setPaymentHistory] = useState<PaymentHistoryModel[]>(
    []
  );
  const [historyProducts, setHistoryProducts] = useState<
    HistoryPoductModel[][]
  >([]);
  const alert = useContext(AlertContext);
  const history = useHistory();

  useEffect(() => {
    new Promise(async () => {
      try {
        const payHistory = await api.getPaymentHistoryAsync(0, 5);
        const { products, paymentHistories } = getHistories(payHistory);
        setHistoryProducts(products);
        setPaymentHistory(paymentHistories);
        console.log(products);
        console.log(paymentHistories);
        const coupons = await getCoupons(0, 5);
      } catch (error) {
        console.error(error);
      }
    });
  }, []);

  const getCoupons = async (offset?: number, limit?: number) => {
    const couponsRes = await api.getCouponHistoryAsync(offset, limit);
    const tempCoupons = couponsRes.data.coupons.map(
      (coupon: any): CouponBoxModel => {
        return {
          name: coupon.name,
          expiryDate: coupon.expiry_date,
          key: coupon.key,
        };
      }
    );
    return tempCoupons;
  };

  const handleClickVbankInfo = (index: number) => {
    const vbankDate = +(paymentHistory?.[index]?.vbankDate ?? "0");
    const date = new Date(vbankDate * 1000);
    alert.showAlert(
      `입금 기한 : ${date.format("yyyy.MM.dd HH:mm")}까지\n입금 계좌 : ${
        paymentHistory?.[index]?.vbankName
      } ${paymentHistory?.[index]?.vbankNum}\n계좌명 : ${
        paymentHistory?.[index]?.vbankHolder
      }`
    );
  };

  const getHistories = (payHistory: any) => {
    const products = payHistory.data.products.map((products: any) => {
      return products.map((prod: any) => {
        return {
          id: prod.id,
          paymentHistoryId: prod.payment_history_id,
          productId: prod.product_id,
          productPrice: prod.product_price,
          productName: prod.product_name,
          startDate: prod.start_date ? new Date(prod.start_date).format('yyyy.MM.dd') : '',
          expireDate: prod.expire_date ? new Date(prod.expire_date).format('yyyy.MM.dd') : '',
          productDiscount: prod.product_discount,
        };
      });
    });
    const paymentHistories = payHistory.data.histories.map(
      (historyItem: any): PaymentHistoryModel => {
        const payMethod =
          Object.values(IamportPayMethod).find(
            (value) => value === historyItem.pay_method
          ) || IamportPayMethod.card;
        // milliseconds to seconds. cause its not unix Epoch value
        const paidAt = new Date(historyItem.paid_at * 1000)
          .format("yyyy.MM.dd")
          .trim();
        const expireDate = new Date(historyItem.expire_date)
          .format("yyyy.MM.dd")
          .trim();
        const startDate = new Date(historyItem.start_date)
          .format("yyyy.MM.dd")
          .trim();
        const status =
          historyItem.paid_at == undefined || historyItem.paid_at === 0
            ? new Date(historyItem.vbank_date).getTime() < Date.now()
              ? "만료"
              : "대기"
            : historyItem.canceled_time
            ? "취소"
            : "완료";
        return {
          id: historyItem.id,
          amount: historyItem.paid_amount?.toLocaleString() + "원",
          payMethod: payMethodMapper[payMethod],
          prodName: historyItem.product_name
            ? `[${historyItem.product_name}]`
            : "",
          receiptUrl: historyItem.receipt_url,
          payRegistDate: paidAt,
          payRequestDate: new Date(historyItem.create_time).format(
            "yyyy.MM.dd"
          ),
          payTerm: startDate && expireDate ? `${startDate}~${expireDate}` : "",
          status,
          vbankHolder: historyItem.vbank_holder,
          vbankName: historyItem.vbank_name,
          vbankNum: historyItem.vbank_num,
          vbankDate: historyItem.vbank_date,
        };
      }
    );
    return { products, paymentHistories };
  };

  return (
    <section className="content">
      <h3>거래내역</h3>

      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="payment-tab"
            data-bs-toggle="tab"
            data-bs-target="#payment-tab-pane"
            type="button"
            role="tab"
            aria-controls="payment-tab-pane"
            aria-selected="true"
          >
            결제내역
          </button>
        </li>
        {/* <li className="nav-item" role="presentation">
          <button
            className="nav-link"
            id="refund-tab"
            data-bs-toggle="tab"
            data-bs-target="#refund-tab-pane"
            type="button"
            role="tab"
            aria-controls="refund-tab-pane"
            aria-selected="false"
            tabIndex={-1}
          >
            환불내역
          </button>
        </li> */}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active"
          id="payment-tab-pane"
          role="tabpanel"
          aria-labelledby="payment-tab"
          tabIndex={0}
        >
          <div
            className="pay-list aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {historyProducts.length > 0 && paymentHistory.length > 0
              ? historyProducts.map((products, index) => {
                  return products.map((product) => {
                    console.log(product);
                    const history = paymentHistory.filter((h) => {
                      return h.id === product.paymentHistoryId;
                    })[0];
                    return (
                      <div className="pay-list-item bg-white">
                        <div className="pay-state">
                          <div
                            className={
                              history.status == "대기"
                                ? "state wait"
                                : "state complete"
                            }
                          >
                            {history.status}
                          </div>
                          <div className="date">{history.payRequestDate}</div>
                        </div>
                        <div className="pay-item">
                          <div className="title-lg">{product.productName}</div>
                          <div className="border-1"></div>
                          <div className="list-style-sm">
                            <ul className="list-group">
                              <li className="list-group-item">
                                <div className="title">이용기간</div>
                                <div className="body">
                                  {product.startDate} ~ {product.expireDate}
                                </div>
                              </li>
                              <li className="list-group-item">
                                <div className="title">결제수단</div>
                                <div className="body">
                                  <div className="body">
                                    {history.payMethod}
                                  </div>
                                </div>
                              </li>
                              <li className="list-group-item">
                                <div className="title">결제금액</div>
                                <div className="body">
                                  <div className="body">
                                    {util.addComma(product.productPrice)}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="border-1"></div>
                          {history?.payMethod?.includes("무통장") &&
                          history.status === "대기" ? (
                            <button
                              type="button"
                              className="btn btn-sm left-icon"
                              onClick={() => {
                                handleClickVbankInfo(index);
                              }}
                            >
                              <span className="material-symbols-outlined me-2">
                                receipt_long
                              </span>
                              입금정보 확인하기
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="btn btn-sm left-icon"
                              onClick={() => {
                                window.open(history?.receiptUrl);
                              }}
                            >
                              <span className="material-symbols-outlined me-2">
                                receipt_long
                              </span>
                              영수증 보기
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  });
                })
              : ""}
          </div>
          {paymentHistory.length == 0 ? (
            <div
              className="section-empty aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src="/images/ddusul/3d-danger.png" />
              <p>내용이 없습니다.</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="tab-pane fade"
          id="refund-tab-pane"
          role="tabpanel"
          aria-labelledby="refund-tab"
          tabIndex={0}
        >
          <div
            className="pay-list aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {paymentHistory.map((history, index) => {
              return (
                <div className="pay-list-item bg-white">
                  <div className="pay-state">
                    <div
                      className={
                        history.status == "대기"
                          ? "state wait"
                          : "state complete"
                      }
                    >
                      {history.status}
                    </div>
                    <div className="date">{history.payRequestDate}</div>
                  </div>
                  <div className="pay-item">
                    <div className="title-lg">
                      {historyProducts[index]?.length === 0 && history.prodName}
                      {historyProducts[index]?.map((prod) => prod.productName)}
                    </div>
                    <div className="border-1"></div>
                    <div className="list-style-sm">
                      <ul className="list-group">
                        <li className="list-group-item">
                          <div className="title">이용기간</div>
                          <div className="body">2024.11.08</div>
                        </li>
                        <li className="list-group-item">
                          <div className="title">결제수단</div>
                          <div className="body">
                            <div className="body">{history.payMethod}</div>
                          </div>
                        </li>
                        <li className="list-group-item">
                          <div className="title">결제금액</div>
                          <div className="body">
                            <div className="body">
                              {util.addComma(history.amount)}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="border-1"></div>
                    <button
                      type="button"
                      className="btn btn-sm left-icon"
                      onClick={() => {
                        window.open(history?.receiptUrl);
                      }}
                    >
                      <span className="material-symbols-outlined me-2">
                        receipt_long
                      </span>
                      영수증 보기
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          {paymentHistory.length == 0 ? (
            <div
              className="section-empty aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <img src="/images/ddusul/3d-danger.png" />
              <p>내용이 없습니다.</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </section>
  );
};
