/* eslint-disable import/no-anonymous-default-export */
import { LectureItem } from "../../models/LectureModel";
import styles from "./lectureRoom.module.scss";
import YouTube from "react-youtube";
import moment from "moment";

interface Props {
  lectures?: LectureItem[];
  selectedLecture?: LectureItem;
  playlist?: string;
  onClickOtherLecture: (index: number) => void;
}

export default (props: Props) => {
  return (
    <div className={styles.study_mode_wrapper}>
      <div className={styles.vedio_with_lecture_list}>
        <div className={styles.iframe_wrapper}>
          {/** iframe **/}
          <YouTube
            className={styles.iframe_wrapper}
            videoId={props.selectedLecture?.link}
            opts={{
              playerVars: {
                list: props.playlist || "",
                listType: "playlist",
                // showinfo: 1, // deprecated.
                modestbranding: 1,
                rel: 0,
                // autoplay: 1, // 자동재생 여부
                iv_load_policy: 3,
              },
            }}
            onReady={(e) => {
              e.target.setVolume(50);
            }}
            onPlay={() => {}}
            onEnd={() => {}}
          />
        </div>
        <div className={styles.lecture_text_contents_wrapper}>
        <div className={styles.title}>{props.selectedLecture?.title}</div>
        <div className={styles.lecture_date}>{moment(props.selectedLecture?.created).format('YYYY.MM.DD')}</div>
        <div className={styles.description}>
          {props.selectedLecture?.description}
        </div>
      </div>
      </div>
      <div className={styles.play_list_wrapper}>
          <div className={styles.list_wrapper}>
            {props.lectures?.map((lecture, index) => {
              return (
                <div
                  className={styles.item}
                  key={index}
                  onClick={() => props.onClickOtherLecture(lecture.id)}
                >
                  <div className={styles.thumbnail}>
                    <img
                      alt=""
                      src={
                        lecture.thumbnailUrl ||
                        `https://img.youtube.com/vi/${lecture.link}/0.jpg`
                      }
                    />
                  </div>
                  <div className={styles.item_text_wrapper}>
                    <div className={styles.title}>{lecture.title}</div>
                    <div className={styles.description}>
                      {moment(lecture.created).format('YYYY.MM.DD')}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.end_line}></div>
        </div>
    </div>
  );
};
