import { StarHalf } from "@material-ui/icons";

type ApplicationModel = {
  [key in keyof typeof ApplicationField]: ApplicationRadioModel;
};
type ApplicationKorModel = {
  [key in keyof typeof ApplicationField]: string;
};
export enum ApplicationField {
  "INTEGRATION" = "INTEGRATION",
  // "FREELANCER" = "FREELANCER",
  // "PROGRAM" = "PROGRAM",
}

export const ApplicationKorMapper: ApplicationKorModel = {
  INTEGRATION: "적산 및 용역",
  // FREELANCER: "용역 프리랜서 지원",
  // PROGRAM: "프로그램 개발",
};

export const applicationModel: ApplicationModel = {
  INTEGRATION: {
    firstDepthName: "의뢰 업종",
    firstDepth: ["토목", "조경", "건축", "전기", "설비", "기타"],
    secondDepthName: "의뢰 업무",
    secondDepth: [
      "적산",
      "수의계약설계",
      "설계변경",
      "서류검토",
      "경리서류",
      "견적",
      "기타",
    ],
  },
  // FREELANCER: {
  //   firstDepthName: "경력 분야",
  //   firstDepth: ["토목", "조경", "건축", "전기", "설비", "기타"],
  //   secondDepthName: "수행 가능 업무",
  //   secondDepth: [
  //     "적산",
  //     "수의계약설계",
  //     "설계변경",
  //     "서류검토",
  //     "경리서류",
  //     "견적",
  //     "기타",
  //   ],
  // },
  // PROGRAM: {
  //   firstDepthName: "개발 분야",
  //   secondDepthName: "가용 예산",
  //   firstDepth: [
  //     "웹사이트",
  //     "업무자동화 프로그램",
  //     "플랫폼",
  //     "광고·디자인",
  //     "기타",
  //   ],
  //   secondDepth: [
  //     "100만원 이하",
  //     "100~300만원",
  //     "300~500만원",
  //     "500만원 이상",
  //     "미정",
  //   ],
  // },
};

export interface ApplicationRadioModel {
  firstDepth: string[];
  firstDepthName: string;
  secondDepth: string[];
  secondDepthName: string;
}

export interface SurveyModel {
  section: SurveySectionModel;
}

export interface SurveySectionModel {
  /** 섹션 제목 */
  title: string;
  /** 섹션 설명 */
  questions: SurveyQuestionModel[];
  description?: string;
  /** 자동 인식 구현하기 전 간략한 구현. todo: 요소 순회하면서 자식있는지 확인 */
  isHasChild?: boolean;
}

export interface SurveyQuestionModel {
  /** 질문 이름 */
  title: string;
  /** 질문 유형 */
  // 아래 type들을 union type으로 묶어서 사용할 수 있습니다.
  type:
    | "radio"
    | "checkbox"
    | "shortText"
    | "file"
    | "date"
    | "explainPicture"
    | "longText";
  isRequire?: boolean;
  /** 질문 선택지 */
  options?: SurveyQuestionOptionModel[];
  /** 질문 설명 */
  description?: string;
  /** 질문 기타 선택 가능 여부 */
  isAllowEtc?: boolean;
  isNumbering?: boolean;
  inputType?: string;
  explainPictureUrl?: string;
  acceptFileExtensions?: string[];
  etcChildSection?: SurveySectionModel;
  placeholder?: string;
  captions?: string[];
  size?: string;
}

export interface SurveyQuestionOptionModel {
  /** 선택지 이름 */
  title: string;
  /** 선택지 분기 페이지 */
  childSection?: SurveySectionModel;
  typeName?: string;
}

export const SurveyData: SurveySectionModel = {
  title: "의뢰 요청 정보",
  description: "고객센터 : 070-7537-2587,  E-mail :  ddk@ddukeong.com",
  isHasChild: true,
  questions: [
    {
      title: "이메일",
      type: "shortText",
      size: "half",
      inputType: "email",
      isRequire: true,
    },
    {
      title: "성함",
      type: "shortText",
      size: "half",
      isRequire: true,
    },
    {
      title: "연락처",
      type: "shortText",
      inputType: "phone",
      isRequire: true,
      description:
        "용역 의뢰 확인 후, 영업일 1-2일 내에 뚜껑설계 담당자가 유선으로 연락드립니다.",
    },
    {
      title: "의뢰 분야",
      type: "radio",
      isRequire: true,
      isNumbering: true,
      options: [
        {
          title: "수의계약 설계",
          typeName: "negotiated",
          childSection: {
            title: "수의계약 설계",
            isHasChild: false,
            description: "관공서(지방자치단체, 공공기관 등) 건설공사 설계 의뢰",
            questions: [
              {
                title: "요청서류",
                isRequire: true,
                type: "checkbox",
                isAllowEtc: true,
                options: [
                  {
                    title:
                      "설계내역서 (일위대가, 단가산출, 기계경비, 노임단가, 자재단가, 원가 포함)",
                  },
                  { title: "수량산출서 (별도의 EXCEL 파일로 제작)" },
                  { title: "도면(위치도)" },
                  { title: "도면(평면도)" },
                  { title: "도면(단면도)" },
                  { title: "도면(상세도)" },
                ],
              },
              {
                title: "공사 업종",
                isRequire: true,
                type: "radio",
                isAllowEtc: true,
                description: `계약하는 면허의 업종을 체크해주세요. *항목에 없는 경우, 기타란에 직접 입력해주세요.*
                `,
                options: [
                  { title: "토목" },
                  { title: "건축" },
                  { title: "기계설비" },
                  { title: "조경" },
                  { title: "실내건축" },
                  { title: "철근 콘크리트" },
                  { title: "상하수도" },
                  { title: "석공" },
                  { title: "도장" },
                ],
              },
              {
                title: "공사명",
                isRequire: false,
                description: `모르실 경우,  "모름" 으로 기재해주세요.`,
                type: "shortText",
                size: "half",
                placeholder: "공사명을 입력해주세요",
              },
              {
                title: "공사위치",
                isRequire: false,
                description: `모르실 경우,  "모름" 으로 기재해주세요.`,
                type: "shortText",
                size: "half",
                placeholder: "공사위치를 입력해주세요",
              },
              {
                title: "공사기간 (원가계산 시 필요)",
                isRequire: false,
                description: `모르실 경우,  "모름" 으로 기재해주세요.`,
                type: "shortText",
                size: "half",
                placeholder: "공사기간을 입력해주세요",
              },
              {
                title: "발주처",
                isRequire: false,
                description: `모르실 경우,  "모름" 으로 기재해주세요.`,
                type: "shortText",
                size: "half",
                placeholder: "예시)서울시청",
              },
              {
                title: "공사내용 (상세)",
                isRequire: true,
                description: `설계를 수행하기 위해 필요한 내용을 기재해주세요.
                  상세히 적어주시면, 빠른 용역 수행에 큰 도움이 됩니다.`,
                type: "longText",
              },
              {
                title: "현장 사진",
                isRequire: false,
                description: "현장 사진을 첨부해주세요.",
                type: "file",
                acceptFileExtensions: ["jpg", "jpeg", "png"],
              },
              {
                title: "참고 자료",
                isRequire: false,
                description: "설계를 수행하기 위해 필요한 파일을 첨부해주세요.",
                type: "file",
              },
              {
                title: "설계 금액 (예상)",
                isRequire: false,
                description: `설계 시, 맞춰야 하는 계약 금액을 알려주세요. 
                일반수의계약의 경우 남성기업 2200만원 이하, 여성기업 5000만원 이하.
                모르실 경우,  "모름" 으로 기재해주세요.`,
                type: "shortText",
                placeholder: "설계 금액을 입력해주세요",
              },
              {
                title: "용역 수행 완료 희망 일자",
                isRequire: true,
                description: `*최소 7일 이후의 기간으로 설정해주세요* 
                긴급 요청 시 비용 추가 발생 (7일 이내의 기간으로 설정 시, 긴급으로 분류됩니다) `,
                type: "date",
              },
              {
                title: "의뢰기업 사업자등록증 첨부",
                isRequire: true,
                type: "file",
              },
              {
                title: "세금계산서 발행 이메일 주소",
                isRequire: true,
                description:
                  "사업자등록증 내에 별도 표기시, 입력 안하셔도 됩니다.",
                type: "shortText",
                placeholder: "세금계산서 발행 이메일 주소를 입력해주세요",
              },
              {
                title: "의뢰기업 전화번호 (회사 전화번호)",
                isRequire: true,
                description:
                  "설계내역서(갑지)에 기재할 회사 전화번호를 기재해주세요.",
                type: "shortText",
                size: "half",
                placeholder: "의뢰기업 전화번호 (회사 전화번호)를 입력해주세요",
              },
              {
                title: "의뢰기업 팩스번호 (회사 팩스번호)",
                isRequire: true,
                description:
                  "설계내역서(갑지)에 기재할 회사 팩스번호를 기재해주세요.",
                type: "shortText",
                size: "half",
                placeholder: "의뢰기업 팩스번호 (회사 팩스번호)를 입력해주세요",
              },
            ],
          },
        },
        {
          title: "설계변경",
          typeName: "designchange",
          childSection: {
            title: "설계변경",
            isHasChild: false,
            description: "변경에 필요한 도면, 수량, 단가, 내역 작업 용역",
            questions: [
              {
                title: "설계변경 절차 안내",
                isRequire: false,
                type: "explainPicture",
                description:
                  "해당 절차를 확인한 후, 필요한 서류를 요청해주세요.",
                explainPictureUrl:
                  "https://sulfac-image.s3.ap-northeast-2.amazonaws.com/None_1672107326311713.png",
              },
              {
                title: "요청서류",
                isRequire: true,
                type: "checkbox",
                isAllowEtc: true,
                options: [
                  {
                    title:
                      "설계변경내역서 (일위대가, 단가산출, 기계경비, 노임단가, 자재단가, 원가 포함)",
                  },
                  { title: "변경 수량산출서 (기존의 수량산출서로 작업)" },
                  { title: "변경도면(위치도)" },
                  { title: "변경도면(평면도)" },
                  { title: "변경도면(단면도)" },
                  { title: "변경도면(상세도)" },
                  {
                    title:
                      "변경 계약내역서 (설계변경내역서를 기초로 낙찰율을 적용한 계약내역서)",
                  },
                ],
              },
              {
                title: "공사 업종",
                isRequire: true,
                type: "radio",
                isAllowEtc: true,
                description: `계약된 업종을 체크해주세요. *항목에 없는 경우, 기타란에 직접 입력해주세요.*
                `,
                options: [
                  { title: "토목" },
                  { title: "건축" },
                  { title: "기계설비" },
                  { title: "조경" },
                  { title: "실내건축" },
                  { title: "철근 콘크리트" },
                  { title: "상하수도" },
                  { title: "석공" },
                  { title: "도장" },
                ],
              },
              {
                title: "계약금액",
                description: "당초 계약금액을 기재해주세요.",
                isRequire: true,
                type: "shortText",
                placeholder: "계약금액을 입력해주세요",
              },
              {
                title: "내역서 및 수량산출서 당초/변경, 흑서/적서 위치",
                isRequire: true,
                type: "radio",
                isAllowEtc: true,
                description: `설계변경의 내역서와 수량산출서는 당초/변경 2줄로 내역을 작업합니다.
                  흑서와 적서의 위치를 알려주세요.`,
                options: [
                  { title: "당초 - 흑서 / 변경 - 적서" },
                  { title: "당초 - 적서 / 변경 - 흑서" },
                ],
              },
              {
                title: "신설 품목",
                description: `설계변경 시, 신규로 추가되는 품목을 알려주세요. *수량을 모를 경우, 품목만이라도 기재해주세요*`,
                isRequire: false,
                type: "shortText",
                placeholder: "신설품목을 입력해주세요",
                captions: [
                  "예시)",
                  "1. 목재창호 설치 3개소 추가",
                  "2. 수성페인트 붓칠 500㎡ 추가",
                ],
              },
              {
                title: "물량 변경 품목",
                description: `설계변경 시, 물량만 변경되는 기존 품목을 알려주세요.
                *수량을 모를 경우, 품목만이라도 기재해주세요*`,
                isRequire: false,
                type: "shortText",
                placeholder: "물량 변경 품목을 입력해주세요",
                captions: [
                  "예시)",
                  "1. 레미콘 타설 500㎥ 에서 200㎥ 로 감소",
                  "2. 초화류 식재 100주 에서 200 주로 증가",
                ],
              },
              {
                title: "외부 견적서 품목",
                description: `표준품셈에 근거한 일위대가 또는 단가산출을 사용하기 어려운 품목인 경우,
                견적서로 대체하기 위해 설계자에게 견적서를 제출해야합니다.
                모르실 경우,  "모름" 으로 기재해주세요.`,
                isRequire: false,
                type: "shortText",
                placeholder: "외부 견적서 품목을 입력해주세요",
                captions: [
                  "예시)",
                  "1. 창호 자재 및 설치",
                  "2. 알루미늄 맞춤 제작",
                ],
              },
              {
                title: "설계변경 전반적인 내용",
                description: `공사에 대한 전반적인 내용을 상세히 기재해주세요.`,
                isRequire: false,
                type: "longText",
                captions: [
                  "예시)",
                  "기존의 계약에서 난간을 추가하여 설치해야합니다.",
                  "기존의 기초 물량이 잘못 산출되어, 재 산출하여 변경해야합니다.",
                ],
              },
              {
                title: "용역 수행 완료 희망 일자",
                isRequire: true,
                description: `*최소 7일 이후의 기간으로 설정해주세요* 
                긴급 요청 시 비용 추가 발생 (7일 이내의 기간으로 설정 시, 긴급으로 분류됩니다) `,
                type: "date",
              },
              {
                title:
                  "기존 (계약내역서, 수량산출서, 도면 등 관련서류) 파일 첨부",
                isRequire: true,
                type: "file",
                acceptFileExtensions: ["xlsx", "xls", "zip"],
              },
              {
                title: "의뢰 기업 사업자등록증 첨부",
                isRequire: true,
                type: "file",
                acceptFileExtensions: ["jpg", "jpeg", "png", "zip", "pdf"],
              },
              {
                title: "세금계산서 발행 이메일 주소",
                description: `사업자등록증 내에 별도 표기시, 입력 안하셔도 됩니다.`,
                isRequire: true,
                type: "shortText",
                placeholder: "세금계산서 발행 이메일 주소를 입력해주세요",
              },
            ],
          },
        },
        {
          title: "계약서류(계약내역서, 기성내역서, 준공내역서)",
          typeName: "documentreview",
          childSection: {
            title: "계약서류 (계약내역서, 기성내역서, 준공내역서)",
            isHasChild: true,
            description:
              "각종 계약 서류부터, 준공 서류까지 업무에 필요한 모든 서류",
            questions: [
              {
                title: "요청 서류",
                options: [
                  {
                    title: "계약내역서",
                    childSection: {
                      title: "계약서류-계약내역서",
                      questions: [
                        {
                          title: "계약 낙찰율",
                          isRequire: true,
                          description: `계약서에 기재되어 있는 계약금액과 낙찰율을 입력해주세요.

                          예시) 20,000,000만원, 88.87%`,
                          type: "shortText",
                        },
                        {
                          title: "원가계산서 항목조정 요청사항",
                          isRequire: false,
                          description: `원가계산서의 항목 중, 요율 조정 또는 추가로 반영해야하는 사항이 있으면 기재해주세요.
                          모르실 경우,  "모름" 으로 기재해주세요.

                          예시) 
                          - 이윤의 요율을 10%로 설정해주세요. 
                          - 금액을 조정할 때, 이윤과 일반관리비로 조정해주세요.
                          - 간접노무비의 요율을 10%로 조정해주세요`,
                          type: "longText",
                        },
                        {
                          title: "설계내역서 및 계약관련 서류 파일 첨부",
                          description: `계약내역서를 제작할 설계내역서 파일과 관련서류를 첨부해주세요.

                          예시) 
                          전자계약서.PDF
                          설계내역서.excel`,
                          type: "file",
                          isRequire: true,
                        },
                        {
                          title: "용역 수행 완료 희망 일자",
                          isRequire: true,
                          description: `*최소 2일 이후의 기간으로 설정해주세요* 
                          긴급 요청 시 비용 추가 발생 (2일 이내의 기간으로 설정 시, 긴급으로 분류됩니다) `,
                          type: "date",
                        },
                        {
                          title: "의뢰 기업 사업자등록증 첨부",
                          isRequire: true,
                          type: "file",
                          acceptFileExtensions: [
                            "jpg",
                            "jpeg",
                            "png",
                            "zip",
                            "pdf",
                          ],
                        },
                        {
                          title: "세금계산서 발행 이메일 주소",
                          description: `사업자등록증 내에 별도 표기시, 입력 안하셔도 됩니다.`,
                          isRequire: true,
                          type: "shortText",
                          placeholder:
                            "세금계산서 발행 이메일 주소를 입력해주세요",
                        },
                        {
                          title: "의뢰기업 전화번호 (회사 전화번호)",
                          description: `내역서(갑지)에 기재할 회사 전화번호를 기재해주세요.`,
                          isRequire: true,
                          type: "shortText",
                        },
                        {
                          title: "의뢰기업 팩스번호 (회사 팩스번호)",
                          description: `내역서(갑지)에 기재할 회사 팩스번호를 기재해주세요.`,
                          isRequire: true,
                          type: "shortText",
                        },
                      ],
                    },
                  },
                  {
                    title: "기성내역서",
                    childSection: {
                      title: "계약서류-기성내역서",
                      questions: [
                        {
                          title: "기성 차수",
                          isRequire: true,
                          type: "radio",
                          isAllowEtc: true,
                          description:
                            "현재 의뢰하시는 기성 차수를 입력해주세요.",
                          options: [
                            { title: "기성 1차" },
                            { title: "기성 2차" },
                            { title: "기성 3차" },
                          ],
                        },
                        {
                          title: "기성 적용 품목",
                          description: `기성 청구 시, 기성을 적용 할 품목과 수량을 알려주세요.
                          *정확한 품목과 수량이 있어야 기성 진행이 가능합니다*
                          
                          예시)
                          1. 레미콘 타설 500㎥ 중 200㎥ 기성
                          2. 초화류 식재 200주 중 100 주 기성`,
                          type: "longText",
                        },
                        {
                          title: "기성 금액 입력",
                          description: `별도의 수량 지정 없이,
                          기성금액을 지정하여 기성을 진행하시는 경우에, 원하는 기성금액을 알려주세요.
                          전체 품목/수량의 비율을 조정하여 기성금액을 맞추어드립니다.
                          모르실 경우 "모름"이라고 기재해주세요.
                          
                          예시)
                          총 계약금액 10,000,000 중 기성금액 6,000,000 적용`,
                          type: "shortText",
                        },
                        {
                          title: "원가 경비 고정 항목",
                          description: `원가계산서의 경비 항목 중, 
                          기성률을 적용하지 않고 0원으로 적용해야 하는 항목을 체크해주세요.`,
                          type: "checkbox",
                          options: [
                            { title: "산재보험료" },
                            { title: "고용보험료" },
                            { title: "건강보험료" },
                            { title: "연금보험료" },
                            { title: "노인장기요양보험료" },
                            { title: "건설기계대여금" },
                            { title: "퇴직공제부금비" },
                            { title: "산업안전관리비" },
                            { title: "환경보전비" },
                            { title: "없음(모름)" },
                          ],
                        },
                        {
                          title:
                            "전회 기성내역서, 파일 첨부\n없으면 다음 단계로 이동하세요.",
                          description: `전회 기성을 진행하셨다면, 기성내역서 파일을 첨부해주세요.`,
                          isRequire: false,
                          type: "file",
                          acceptFileExtensions: ["xlsx", "xls", "zip"],
                        },
                        {
                          title: "(계약내역서, 공사계약서) 파일 첨부",
                          description: `기성내역서를 제작할 때, 기준점을 설정하기 위해 계약내역서가 필요합니다.
                          가장 최근에 계약된 계약내역서를 첨부해주세요.`,
                          isRequire: true,
                          type: "file",
                          acceptFileExtensions: ["xlsx", "xls", "zip"],
                        },
                        {
                          title: "용역 수행 완료 희망 일자",
                          isRequire: true,
                          description: `*최소 5일 이후의 기간으로 설정해주세요* 
                          긴급 요청 시 비용 추가 발생 (5일 이내의 기간으로 설정 시, 긴급으로 분류됩니다) `,
                          type: "date",
                        },
                        {
                          title: "의뢰 기업 사업자등록증 첨부",
                          isRequire: true,
                          type: "file",
                          acceptFileExtensions: [
                            "jpg",
                            "jpeg",
                            "png",
                            "zip",
                            "pdf",
                          ],
                        },
                        {
                          title: "세금계산서 발행 이메일 주소",
                          description: `사업자등록증 내에 별도 표기시, 입력 안하셔도 됩니다.`,
                          isRequire: true,
                          type: "shortText",
                          placeholder:
                            "세금계산서 발행 이메일 주소를 입력해주세요",
                        },
                      ],
                    },
                  },
                  {
                    title: "준공내역서",
                    childSection: {
                      title: "계약내역서-준공내역서",
                      questions: [
                        {
                          title: "계약내역서 첨부 *EXCEL 파일*",
                          description: `준공내역서를 제작할 때, 기준이 될 계약내역서 파일을 첨부해주세요.`,
                          isRequire: true,
                          type: "file",
                          acceptFileExtensions: ["xlsx", "xls", "zip"],
                        },
                        {
                          title: "원가 경비 정산 항목",
                          description: `원가계산서의 경비 항목 중, 실제 사용된 금액을 알려주세요.
                          *보험료납부확인서*에 기재된 금액과 동일해야합니다.
                          모르실 경우 "모름"이라고 기재해주세요.
                          
                          예시)
                          건강보험료 - 200,000 계약자 - 100,000
                          연금보험료 - 200,000 계약자 - 100,000
                          노인장기요양보험료 - 200,000 계약자 - 100,000
                          건설기계대여금 - 200,000 계약자 - 100,000
                          퇴직공제부금비 - 200,000 계약자 - 100,000
                          산업안전관리비 - 200,000 계약자 - 100,000
                          환경보전비 - 200,000 계약자 - 100,000
                          하도급대금지급보증서발급 수수료 - 200,000 계약자 - 100,000`,
                          type: "longText",
                        },
                        {
                          title: "용역 수행 완료 희망 일자",
                          isRequire: true,
                          description: `*최소 3일 이후의 기간으로 설정해주세요* 
                          긴급 요청 시 비용 추가 발생 (3일 이내의 기간으로 설정 시, 긴급으로 분류됩니다) `,
                          type: "date",
                        },
                        {
                          title: "의뢰 기업 사업자등록증 첨부",
                          isRequire: true,
                          type: "file",
                          acceptFileExtensions: [
                            "jpg",
                            "jpeg",
                            "png",
                            "zip",
                            "pdf",
                          ],
                        },
                        {
                          title: "세금계산서 발행 이메일 주소",
                          description: `사업자등록증 내에 별도 표기시, 입력 안하셔도 됩니다.`,
                          isRequire: true,
                          type: "shortText",
                          placeholder:
                            "세금계산서 발행 이메일 주소를 입력해주세요",
                        },
                      ],
                    },
                  },
                ],
                isRequire: true,
                type: "radio",
              },
            ],
          },
        },
        {
          title: "적산",
          typeName: "integration",
          childSection: {
            title: "적산",
            description: "공사의 필요한 단가 및 수량 등을 계산하는 용역",
            questions: [
              {
                title: "요청서류",
                isRequire: true,
                type: "checkbox",
                isAllowEtc: true,
                options: [
                  {
                    title:
                      "견적내역서 (일위대가, 단가산출, 기계경비, 노임단가, 자재단가, 원가 포함)",
                  },
                  { title: "수량산출서 (별도의 EXCEL 파일로 제작)" },
                  {
                    title:
                      "간편 견적서 (수량이 존재하는 상황에서 단가 작업만 진행)",
                  },
                ],
              },
              {
                title: "공사 업종",
                isRequire: true,
                type: "radio",
                isAllowEtc: true,
                description: `의뢰하는 공사에 대한 업종을 체크해주세요.
                *항목에 없는 경우, 기타란에 직접 입력해주세요.*`,
                options: [
                  { title: "토목" },
                  { title: "건축" },
                  { title: "기계설비" },
                  { title: "조경" },
                  { title: "실내건축" },
                  { title: "철근 콘크리트" },
                  { title: "상하수도" },
                  { title: "석공" },
                  { title: "도장" },
                ],
              },
              {
                title: "예상 공사 금액",
                description: `적산을 수행한 후, 총 공사비에 대한 예상 금액을 기재해주세요.
                수행 일자와 용역 금액에 참고 될 예정입니다.
                전혀 모르시는 경우에는 "모름"이라고 기재해주세요.`,
                type: "shortText",
                placeholder: "예상 공사 금액을 입력해주세요",
              },
              {
                title: "공사 내용",
                description: `적산을 수행해야 하는 공사의 대략적인 내용을 입력해주세요.`,
                type: "shortText",
                placeholder: "예상 공사 금액을 입력해주세요",
                captions: [
                  "예시)",
                  "- 타운하우스 공사",
                  "- 원룸 공사",
                  "- 조경 공사",
                  "- 개인 주택",
                ],
              },
              {
                title: "**도면(CAD)파일** ",
                description: `적산을 수행해야 하는 도면파일을 업로드해주세요.
                반드시 CAD 파일로 업로드해야 합니다.
                1.평면도
                2.상세도
                3.단면도
                등 가지고 계신 모든 도면 파일을 첨부해주세요.
                
                없으신 경우 다음 단계로 이동해주세요.`,
                type: "file",
                acceptFileExtensions: ["dwg", "zip"],
              },
              {
                title: "용역 수행 완료 희망 일자",
                isRequire: true,
                description: `*최소 15일 이후의 기간으로 설정해주세요* 
                긴급 요청 시 비용 추가 발생 (15일 이내의 기간으로 설정 시, 긴급으로 분류됩니다) `,
                type: "date",
              },
              {
                title: "의뢰 기업 사업자등록증 첨부",
                type: "file",
                description: `기업이 아닌, 일반 개인의 경우, 다음 단계로 이동해주세요.`,
                acceptFileExtensions: ["jpg", "jpeg", "png", "zip", "pdf"],
              },
              {
                title: "세금계산서 발행 이메일 주소",
                description: `기업이 아닌, 일반 개인의 경우, 다음 단계로 이동해주세요.`,
                type: "shortText",
                placeholder: "세금계산서 발행 이메일 주소를 입력해주세요",
              },
            ],
          },
        },
        {
          title: "공내역서 단가 작업",
          typeName: "estimate",
          childSection: {
            title: "공내역서 단가 작업",
            description: `공내역서란?  ->  내역서에 단가 및 금액만이 빠진 것. 수량은 존재해야함.
              뚜껑설계 용역팀이 내역서의 단가와 금액을 작성해드립니다.`,
            questions: [
              {
                title: "요청서류",
                isRequire: true,
                type: "checkbox",
                isAllowEtc: true,
                options: [
                  {
                    title:
                      "1. 공내역서 (수량이 존재하는 상황에서 단가 작업만 진행)",
                  },
                ],
              },
              {
                title: "공사 업종",
                isRequire: true,
                type: "radio",
                isAllowEtc: true,
                description: `의뢰하는 공사에 대한 업종을 체크해주세요.
                  *항목에 없는 경우, 기타란에 직접 입력해주세요.*`,
                options: [
                  { title: "토목" },
                  { title: "건축" },
                  { title: "기계설비" },
                  { title: "조경" },
                  { title: "실내건축" },
                  { title: "철근 콘크리트" },
                  { title: "상하수도" },
                  { title: "석공" },
                  { title: "도장" },
                ],
              },
              {
                title: "공내역서 파일 첨부 **EXCEL 파일**",
                description: `작업해야하는 공내역서 파일을 첨부해주세요.`,
                isRequire: true,
                type: "file",
                acceptFileExtensions: ["xlsx", "xls", "zip"],
              },
              {
                title: "예상 계약 금액",
                description: `공내역서에 총 단가를 기입한 후,
                예상되는 금액 또는 원하는 금액을 기입해주세요.
                없으실 경우 "모름"이라고 입력해주세요.`,
                type: "shortText",
                placeholder: "예상 계약 금액을 입력해주세요",
                captions: [
                  "예시)",
                  "- 총 도급공사비 1,500,000,000 원으로 예상됩니다.",
                  "- 총 도급공사비 1,200,000,000 원으로 맞춰주세요.",
                ],
              },
              {
                title: "단가 지정 품목",
                description: `자재단가 중 금액을 지정하거나 유의해야하는 품목이 있으면 기재해주세요.
                없으신 경우 "없음"이라고 입력해주세요.`,
                type: "shortText",
                placeholder: "단가 지정 품목을 입력해주세요",
                captions: [
                  "예시)",
                  "- 레미콘 ㎥당 80,000원 정도로 해주세요.",
                  '- 레미콘은 조달청 나라장터에서 "물품식별번호"가 23734420인 품목으로 설정해주세요.',
                ],
              },
              {
                title: "용역 수행 완료 희망 일자",
                isRequire: true,
                description: `*최소 7일 이후의 기간으로 설정해주세요* 
                긴급 요청 시 비용 추가 발생 (7일 이내의 기간으로 설정 시, 긴급으로 분류됩니다) `,
                type: "date",
              },
              {
                title: "의뢰 기업 사업자등록증 첨부",
                type: "file",
                description: `기업이 아닌, 일반 개인의 경우, 다음 단계로 이동해주세요.`,
                acceptFileExtensions: ["jpg", "jpeg", "png", "zip", "pdf"],
              },
              {
                title: "세금계산서 발행 이메일 주소",
                description: `기업이 아닌, 일반 개인의 경우, 다음 단계로 이동해주세요.`,
                type: "shortText",
                placeholder: "세금계산서 발행 이메일 주소를 입력해주세요",
              },
            ],
          },
        },
        {
          title: "기타 용역",
          typeName: "etc",
          childSection: {
            title: "기타 용역",
            description: "용역을 요청하는 내용을 자유롭게 기재해주세요.",
            questions: [
              {
                title: "요청사항 상세 기재",
                description: `뚜껑설계 용역 신청 목록에 없는 내용 중, 필요한 의뢰가 있다면
                자유롭게 기재해주세요.
                담당자가 확인 후 영업일 1-2일 내에 유선으로 연락드리겠습니다.`,
                isRequire: true,
                type: "longText",
              },
              {
                title: "설계내역서 및 계약관련 서류 파일 첨부",
                description: `의뢰하는 용역에 필요한 서류가 있다면 첨부해주세요.`,
                type: "file",
              },
            ],
          },
        },
      ],
    },
  ],
};
