import { useContext, useRef, useState } from "react";
import ExcelPresenter from "./ExcelContractPresenter";
import { api } from "../../config/api";
import AlertContext from "../../contexts/AlertContext";
import { util } from "../../config/util";
import { LoadingContext } from "../../contexts/LoadingContext";
import { ExcelX2File, ExcelX2FileSheet } from "../../models/excelModel/excelX2";
import { useHistory } from "react-router-dom";
import { pages } from "../../constants/PagePaths";

export default () => {
  const [originOption, setOriginOption] = useState("black");
  const [divideOption, setDivideOption] = useState("fixed");
  const [isHiddenSheetShow, setIsHiddenSheetShow] = useState(false);

  const [fileInfos, setFileInfos] = useState<ExcelX2File[]>([]);

  const alertContext = useContext(AlertContext);
  const loading = useContext(LoadingContext);
  const history = useHistory();
  const megaByte = 1000 * 1000;
  const fileCountLimit = 3;
  const fileSizeSum = useRef(0);
  const sheetCellSum = useRef(0);
  /** 파일 사이즈 총 합 제한
   * 개당 사이즈 곱하기 개수로 제한
   */
  const fileSizeLimit = 30 * fileCountLimit;

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // set dragging over style
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    handleFiles(Array.from(e.dataTransfer.files));
  };

  const handleChangeFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      if (e.target.files?.length === 0) return;

      handleFiles(Array.from(e.target.files));
    }
  };

  const handleFiles = async (files: File[]) => {
    const uploadedfiles = [];
    const infos: ExcelX2File[] = [];
    // 파일의 확장자가 xlsx가 아니라면 경고 후 xlsx인 파일만 필터

    if (files.some((file) => file.name.split(".").pop() !== "xlsx")) {
      alertContext.showAlert("엑셀(.xlsx) 파일만 업로드할 수 있습니다.");
      files = files.filter((file) => file.name.split(".").pop() === "xlsx");
    }
    for (let i = 0; i < (files.length || 0); i++) {
      let file = files[i];
      fileSizeSum.current += file.size;
      if (
        fileSizeSum.current < fileSizeLimit * megaByte &&
        i + fileInfos?.length < fileCountLimit
      ) {
        const res = await api.uploadFileAsync(file, "excelX2");
        uploadedfiles.push(res.data.url);
        // get file extension

        infos.push({
          name: file.name,
          url: res.data.url,
          size: file.size,
          isEntireChecked: false,
        });
      } else {
        alertContext.showAlert(
          `파일은 최대 ${fileCountLimit}개, 합계 ${fileSizeLimit}MB를 넘을 수 없습니다.`
        );
        break;
      }
    }
    setFileInfos([...fileInfos, ...infos]);
    getSheetNames(uploadedfiles, infos);
  };

  const getSheetNames = async (files: string[], infos: ExcelX2File[]) => {
    loading.showLoading("엑셀시트 목록을 불러오는 중입니다.");
    try {
      const res = await api.getSheetNamesAsync(files);
      let errorMessage = "";
      const errorFiles: any = [];
      loading.closeLoading();
      if (res.status === 200) {
        infos.forEach((fileInfo, index) => {
          if (res.data.data[fileInfo.url]?.find(Boolean).errorMessage) {
            errorFiles.push({
              name: fileInfo.name,
              errorMessage:
                res.data.data[fileInfo.url]?.find(Boolean).errorMessage,
              index,
            });
          } else {
            fileInfo.sheets = res.data.data[fileInfo.url]
              ?.filter((sheetInfo: any) => {
                if (sheetInfo.lastColumn > 100 || sheetInfo.lastRow > 10000) {
                  errorMessage += `${fileInfo.name}-${sheetInfo.sheetName}: 엑셀의 최대 행은 10,000, 최대 열은 100입니다.\n확인 후 공백이 많을 경우 삭제해주세요.`;
                  return false;
                }
                return true;
              })
              .map(
                (sheetInfo: {
                  sheetName: string;
                  isHide: boolean;
                  lastRow: number;
                  lastColumn: number;
                  sheetIndex: number;
                  errorMessage: string;
                }): ExcelX2FileSheet => {
                  if (sheetInfo.errorMessage) {
                    errorMessage += sheetInfo.errorMessage + "\n";
                  }
                  return {
                    name: sheetInfo.sheetName,
                    checked: false,
                    isHidden: sheetInfo.isHide,
                    lastRow: sheetInfo.lastRow,
                    lastColumn: sheetInfo.lastColumn,
                    sheetIndex: sheetInfo.sheetIndex,
                  };
                }
              );
          }
        });
        if (errorFiles.length > 0) {
          errorFiles?.forEach((errorSheet: any) => {
            errorMessage += `${errorSheet.name}: ${errorSheet.errorMessage}\n`;
            infos.splice(errorSheet.index, 1);
          });
          alertContext.showAlert(
            "처리되지 않은 파일이 있습니다.\n아래 사유를 확인해주세요.\n\n" +
              errorMessage
          );
        } else if (errorMessage) {
          alertContext.showAlert(errorMessage);
        }
        setFileInfos([...fileInfos, ...infos]);
      }
    } catch (err: any) {
      loading.closeLoading();

      alertContext.showAlert("오류가 발생했습니다.\n고객센터에 문의해주세요.");
    }
  };

  const handleDelete = (index: number) => (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const newFileInfos = [...fileInfos];
    newFileInfos[index].sheets?.forEach((sheet) => {
      if (sheet.checked) {
        sheetCellSum.current -= sheet.lastRow * sheet.lastColumn;
      }
    });
    newFileInfos.splice(index, 1);
    setFileInfos(newFileInfos);
    fileSizeSum.current -= fileInfos[index].size;
  };

  const handleOnOriginChange = (value: string) => {
    setOriginOption(value);
  };

  const handleOnDivideChange = (value: string) => {
    setDivideOption(value);
  };

  const handleEntireCheck = (index: number) => {
    if (fileInfos[index].isEntireChecked) {
      const newFileInfos = [...fileInfos];
      newFileInfos[index].isEntireChecked = false;
      newFileInfos[index].sheets = newFileInfos[index].sheets?.map((sheet) => {
        if (sheet.checked) {
          sheetCellSum.current -= sheet.lastRow * sheet.lastColumn;
        }
        sheet.checked = false;
        return sheet;
      });

      setFileInfos(newFileInfos);
    } else {
      const newFileInfos = [...fileInfos];
      newFileInfos[index].isEntireChecked = true;
      newFileInfos[index].sheets = newFileInfos[index].sheets?.map((sheet) => {
        if (!isHiddenSheetShow && sheet.isHidden) return sheet;
        if (!sheet.checked) {
          sheetCellSum.current += sheet.lastRow * sheet.lastColumn;
        }
        sheet.checked = true;
        return sheet;
      });

      setFileInfos(newFileInfos);
    }
  };

  const handleSheetClick = (fileIndex: number, sheet: ExcelX2FileSheet) => {
    const newFileInfos = [...fileInfos];
    const checked = sheet.checked;

    if (newFileInfos[fileIndex].sheets?.length ?? 0 > 0) {
      sheet.checked = !checked;
      if (checked) {
        newFileInfos[fileIndex].isEntireChecked = false;
        sheetCellSum.current -= sheet.lastRow * sheet.lastColumn;
      } else {
        sheetCellSum.current += sheet.lastRow * sheet.lastColumn;
      }
    }

    newFileInfos[fileIndex].isEntireChecked = (
      newFileInfos[fileIndex].sheets ?? []
    ).every((sheet) =>
      isHiddenSheetShow ? sheet.checked : sheet.isHidden ? true : sheet.checked
    );

    setFileInfos(newFileInfos);
  };

  const handleExecute = async () => {
    if (!util.isLogin()) {
      alertContext.showAlert("로그인이 필요합니다.", () => {
        history.replace(pages.login);
      });
      return;
    }
    if (fileInfos.length === 0) {
      alertContext.showAlert("파일을 업로드해주세요.");
      return;
    }
    const data: {
      s3Url: string;
      selectedSheets: number[];
    }[] = [];

    for (const fileInfo of fileInfos) {
      if (fileInfo.sheets?.every((sheet) => !sheet.checked)) {
        alertContext.showAlert("시트를 선택해주세요.");
        return;
      }

      const selectedSheets: number[] = [];
      fileInfo.sheets?.forEach((sheet) => {
        if (sheet.checked) {
          selectedSheets.push(sheet.sheetIndex);
        }
      });

      data.push({
        s3Url: fileInfo.url,
        selectedSheets,
      });
    }
    loading.showLoading(
      `엑셀파일을 처리하는 중입니다.\n잠시만 기다려주세요.`
    );
    try {
      const requests = data.map((d) =>
        api.postExcelContractExecute({
          data: [d],
        })
      );
      const responses = await Promise.all(requests);

      loading.closeLoading();
      const urls: string[] = [];
      const messages: string[] = [];
      responses.forEach((res) => {
        if (res.status === 200) {
          const urlExist = res.data.urls?.length > 0;
          const messageExist = res.data.messages?.length > 0;
          if (urlExist) {
            urls.push(...res.data.urls);
          }
          if (messageExist) {
            messages.push(...res.data.messages);
          }
        }
      });
      const urlExist = urls?.length > 0;
      const messageExist = messages?.length > 0;
      if (urlExist) {
        if (messageExist) {
          // 부분적으로 처리된 경우
          setFileInfos(
            fileInfos.filter((fileInfo) => {
              return !urls?.includes(fileInfo.url);
            })
          );
          alertContext.showAlert(
            "부분적으로 처리되었습니다.\n정상파일은 다운로드 폴더를 확인해주세요.\n오류파일은 아래 메시지를 확인해주세요.\n\n" +
              messages.join("\n")
          );
        } else {
          // 정상처리된 경우
          alertContext.showAlert(
            "성공적으로 처리되었습니다.\n다운로드 폴더를 확인해주세요."
          );
        }
        // download files
        for (let url of urls) {
          // 모든 +를 %2B로 치환
          url = url.replace(/\+/g, "%2B");
      
          util.downloadFile(url);
      
          // 0.5초 대기
          await new Promise(resolve => setTimeout(resolve, 500));
        }
        setFileInfos([]);
      } else {
        // 정상처리된 결과가 없는 경우
        alertContext.showAlert(
          "처리중 오류가 발생했습니다.\n아래 사유를 확인해주세요.\n\n" +
            messages.join("\n")
        );
        setFileInfos([]);
      }
    } catch (err: any) {
      loading.closeLoading();

      alertContext.showAlert("오류가 발생했습니다.\n고객센터에 문의해주세요.");
    }
  };

  const handleHiddenSheetClick = () => {
    setIsHiddenSheetShow(!isHiddenSheetShow);
    refreshAllEntireChecked(!isHiddenSheetShow);
  };

  const refreshAllEntireChecked = (isHiddenSheetShow: boolean) => {
    const newFileInfos = [...fileInfos];
    newFileInfos.forEach((fileInfo) => {
      fileInfo.isEntireChecked = (fileInfo.sheets ?? []).every((sheet) =>
        isHiddenSheetShow
          ? sheet.checked
          : sheet.isHidden
          ? true
          : sheet.checked
      );
    });
    setFileInfos(newFileInfos);
  };

  return (
    <ExcelPresenter
      fileInfos={fileInfos}
      fileCountLimit={fileCountLimit}
      originOption={originOption}
      divideOption={divideOption}
      isHiddenSheetShow={isHiddenSheetShow}
      handleDragOver={handleDragOver}
      handleDrop={handleDrop}
      handleChangeFiles={handleChangeFiles}
      handleDelete={handleDelete}
      handleOnDivideChange={handleOnDivideChange}
      handleOnOriginChange={handleOnOriginChange}
      handleEntireCheck={handleEntireCheck}
      handleSheetClick={handleSheetClick}
      handleExecute={handleExecute}
      handleHiddenSheetClick={handleHiddenSheetClick}
    />
  );
};
