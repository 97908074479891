/* eslint-disable import/no-anonymous-default-export */
import React, { useState } from "react";
import { BackTop, Button } from "antd";
import { PrinterOutlined, UpCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import styles from "./beginEnviManageInfoPage.module.scss";
import { Worklist } from "../../../../models/worklist/WorklistModel";
import {
  EnviManagePlanModel,
  EnviPlanItem,
} from "../../../../models/beginConstDoc/EnviManagePlanModel";
import { EnviManageData } from "../../../../constants/EnviManageData";

interface Props {
  worklist: Worklist;
  putWorkListAsync: (data: any) => void;
}

export default (props: Props) => {
  const worklist = props.worklist;
  const putWorkListAsync = async (data: any) => props.putWorkListAsync(data);

  if (!worklist?.enviManagePlan) {
    return <div>로딩중...</div>;
  }

  const temp = !worklist ? EnviManageData : worklist.enviManagePlan;
  const [useModel, setUseModel] = useState<EnviManagePlanModel>(temp);

  const printedBtn = async () => {
    await saveData();
    const printBody = document.getElementById("printArea") as HTMLElement;
    document.body.innerHTML = printBody.innerHTML;
    window.print();
    window.location.reload();
  };

  /**
   * onBlur시에 동작
   */
  const putEnviInfo = (e: any, itemIndex: number, enviIndex: number) => {
    let data = worklist;
    data = {
      ...worklist,
      ...data,
    };

    let newArr = useModel.EnviPlanItems.map((item: EnviPlanItem, i: any) => {
      const cloneItem = _.cloneDeep(item);
      const cloneEnviItems = _.cloneDeep(item.EnviItems);

      if (itemIndex === i) {
        const subItem: any = cloneEnviItems?.[enviIndex];
        if (e.target.name === "productPrice") {
          subItem &&
            (subItem["commaProductPrice"] = convertCommaValue(e.target.value));
          subItem &&
            (subItem["productPrice"] = e.target.value.replaceAll(",", ""));
        } else {
          subItem &&
            (subItem[e.target.name] = e.target.value.replaceAll(",", "")); //각 input에 맞는 값이 입력
        }
        cloneEnviItems && (cloneEnviItems[enviIndex] = subItem);

        const usageItemsLength =
          cloneEnviItems === undefined ? 0 : cloneEnviItems?.length;
        let sumCalPrice = 0;
        for (let i = 0; i < usageItemsLength; i++) {
          let productPrice =
            cloneEnviItems === undefined ? 0 : cloneEnviItems[i].productPrice;
          sumCalPrice = Number(sumCalPrice) + Number(productPrice);
        }
        cloneItem && (cloneItem["totalPrice"] = sumCalPrice); //각 항목의 금액 합계 적용
        return { ...cloneItem, EnviItems: cloneEnviItems };
      } else {
        return cloneItem;
      }
    });

    const tempData: EnviManagePlanModel = {
      EnviPlanItems: newArr,
    };
    data.enviManagePlan = tempData;
    setUseModel(tempData);
  };

  const saveData = async () => {
    const data = _.cloneDeep(worklist);
    data.enviManagePlan = useModel;
    await putWorkListAsync(data);
  };

  /**
   * 금액 자동 (,) 적용
   */
  const handlePrice = (e: any, itemIndex: number, enviIndex: number) => {
    const newArr = useModel.EnviPlanItems.map(
      (planItem: EnviPlanItem, i: any) => {
        const cloneItem = _.cloneDeep(planItem);
        const cloneEnviItems = _.cloneDeep(planItem.EnviItems);
        if (itemIndex === i) {
          const subItem: any = cloneEnviItems?.[enviIndex];
          subItem &&
            (subItem["commaProductPrice"] = convertCommaValue(e.target.value));
          cloneEnviItems && (cloneEnviItems[enviIndex] = subItem);
          return { ...cloneItem, EnviItems: cloneEnviItems };
        } else {
          return cloneItem;
        }
      }
    );
    let tempData: EnviManagePlanModel = {
      EnviPlanItems: newArr,
    };
    setUseModel(tempData);
  };

  function convertCommaValue(value: any) {
    value = Number(value.replaceAll(",", ""));
    if (isNaN(value)) {
      return 0;
    } else {
      const formatValue = value.toLocaleString("ko-KR");
      return formatValue;
    }
  }

  return (
    <div>
      <div id="printArea">
        <div className={styles.beginSafetyContainer}>
          <div className={styles.page}>
            <div className={styles.title}>항목별 사용계획</div>
            <div
              style={{
                height: "15px",
                lineHeight: "15px",
                textAlign: "right",
                fontSize: "13px",
              }}
            >
              {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.beginDate).format("yyyy년　MM월")}
            </div>
            <div className={styles.container_body}>
              <div className={styles.table_header}>
                <div className={styles.item_name}>항목</div>
                <div className={styles.envi_name}>품명</div>
                <div className={styles.envi_price}>금액</div>
                <div className={styles.envi_note}>비고</div>
              </div>

              {useModel?.EnviPlanItems?.map(
                (item: EnviPlanItem, itemIndex: number) => {
                  return (
                    <div className={styles.envi_plan_items}>
                      <div className={styles.item_name}>
                        <div className={styles.item_num}>{itemIndex + 1}.</div>
                        <div className={styles.item_num_name}>
                          {item.itemName}
                        </div>
                      </div>

                      <div className={styles.envi_items_container}>
                        {Array.from(
                          { length: Number(item.EnviItems?.length) },
                          (_, enviIndex) => {
                            return (
                              <div className={styles.envi_items}>
                                <div className={styles.envi_sub_item}>
                                  {item.EnviItems?.[enviIndex].EnviSubItem}
                                </div>

                                <div className={styles.product_name}>
                                  <input
                                    onBlur={(e: any) => {
                                      putEnviInfo(e, itemIndex, enviIndex);
                                    }}
                                    className={styles.product_name_input}
                                    defaultValue={
                                      item.EnviItems?.[enviIndex].productName
                                    }
                                    name="productName"
                                  />
                                </div>

                                <div className={styles.product_price}>
                                  <input
                                    onBlur={(e: any) => {
                                      putEnviInfo(e, itemIndex, enviIndex);
                                    }}
                                    onChange={(e: any) => {
                                      handlePrice(e, itemIndex, enviIndex);
                                    }}
                                    className={styles.product_price_input}
                                    //defaultValue={item.EnviItems?.[enviIndex].productPrice === 0 ? '' : Number(item.EnviItems?.[enviIndex].productPrice).toLocaleString()}
                                    value={
                                      item?.EnviItems?.[enviIndex]
                                        .commaProductPrice === "0"
                                        ? ""
                                        : item?.EnviItems?.[enviIndex]
                                            .commaProductPrice
                                    }
                                    name="productPrice"
                                  />
                                </div>

                                <div className={styles.note}>
                                  <input
                                    onBlur={(e: any) => {
                                      putEnviInfo(e, itemIndex, enviIndex);
                                    }}
                                    className={styles.note_input}
                                    defaultValue={
                                      item.EnviItems?.[enviIndex].note
                                    }
                                    name="note"
                                  />
                                </div>
                              </div>
                            );
                          }
                        )}
                        <div className={styles.envi_items}>
                          <div
                            className={styles.envi_sub_item}
                            style={{
                              fontWeight: 600,
                              textAlign: "center",
                              paddingRight: "5px",
                            }}
                          >
                            소계
                          </div>

                          <div className={styles.product_name}>　</div>

                          <div className={styles.total_price}>
                            {item.totalPrice === 0
                              ? ""
                              : item.totalPrice.toLocaleString()}
                          </div>

                          <div className={styles.note}>　</div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className={styles.save_container}>
            <button className={styles.square_btn} onClick={saveData}>
              <span>저장</span>
            </button>
          </div>
        </div>
      </div>
      <Button
        className="circleBtn"
        style={{
          backgroundColor: "rgb(68, 132, 255)",
          float: "right",
          position: "fixed",
          right: "63px",
          bottom: "200px",
        }}
        onClick={printedBtn}
      >
        <div>
          <PrinterOutlined
            className="printer-icon"
            style={{ fontSize: "30px" }}
          />
        </div>
        <div style={{ color: "white" }}>
          현재 페이지<div style={{ color: "white" }}>출력</div>
        </div>
      </Button>
      <BackTop style={{ bottom: "150px" }}>
        <UpCircleOutlined className="back-top" style={{ fontSize: "40px" }} />
      </BackTop>
    </div>
  );
};
