import { useContext, useEffect, useRef, useState } from "react";
import "./noticeDetail.scss";
import { api } from "../../../config/api";
import { useHistory, useLocation } from "react-router-dom";
import { Notice } from "../../../config/models";
import { pages } from "../../../constants/PagePaths";
import AlertContext from "../../../contexts/AlertContext";

function NoticeDetail() {
  const alertContext = useContext(AlertContext);
  const location = useLocation();
  const linkLocation = location.pathname;
  const strArray = linkLocation.split("/");
  const noticeId = Number(strArray[2]);
  const [notice, setNotice] = useState<Notice>();
  const history = useHistory();
  const [comments, setComments] = useState([]);
  const commentRef = useRef(null);
  const [state, setState] = useState(0);
  useEffect(() => {
    api
      .getNotice({ id: noticeId })
      .then((result) => {
        // console.log(result);
        const notice = result.data.notice;
        const n = new Notice(notice);
        setNotice(n);
        setComments(result.data.comments);
      })
      .catch((error) => {
        console.error("에러발생 : ", error);
      });
  }, [state]);

  const refresh = () => {
    setState(state + 1);
  };

  const handleToList = () => {
    history.push(pages.noticelist);
  };

  const postComment = async () => {
    if (commentRef) {
      const res = await api.postNoticeComment(
        notice!.id,
        commentRef.current.value
      );
      if (res.status === 200) {
        alertContext.showAlert("등록되었습니다.", () => {});
      } else {
        alertContext.showAlert("등록에 실패하였습니다.", () => {});
      }
      refresh();
      commentRef.current.value = "";
    }
  };

  const deleteComment = async (id: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      const res = await api.deleteNoticeComment(id);
      if (res.status === 200) {
        alertContext.showAlert("삭제되었습니다.", () => {});
      } else {
        alertContext.showAlert("삭제에 실패하였습니다.", () => {});
      }
      refresh();
    }
  };

  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container page ">
            <a
              href={pages.noticelist}
              className="btn text-gray-400 mb-3 left-icon back-btn"
            >
              <span className="material-symbols-outlined me-2">
                keyboard_backspace
              </span>
              목록으로
            </a>
            <div className="col-12">
              <div className="board-content">
                <div className="title">{notice?.title}</div>
                <div className="author">
                  <div>
                    등록일
                    <span className="ms-2">
                      {notice?.date.format("yyyy-MM-dd")}
                    </span>
                  </div>
                  {/* <div>
                    조회수<span className="ms-2">00000</span>
                  </div> */}
                </div>
                <div className="py-4 border-bottom">
                  <pre
                    dangerouslySetInnerHTML={{
                      __html: notice?.text.replaceAll("<p></p>", "<br/>") ?? "",
                    }}
                  ></pre>
                </div>
                {true ? (
                  ""
                ) : (
                  <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center mt-5">
                      <div className="d-flex gap-2">
                        <a href="#" className="btn btn-lg btn-outline">
                          <span className="material-symbols-outlined me-2">
                            edit
                          </span>
                          수정
                        </a>
                        <a href="#" className="btn btn-lg btn-danger">
                          <span className="material-symbols-outlined me-2">
                            delete
                          </span>
                          삭제
                        </a>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-lg btn-primary-soft"
                        >
                          <span className="material-symbols-outlined me-2">
                            edit
                          </span>
                          글쓰기
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="py-2 border-top d-flex gap-1">
                <input
                  type="text"
                  className="form-control"
                  placeholder="댓글을 입력해보세요!"
                  ref={commentRef}
                />
                <button
                  className="btn btn-lg btn-primary-soft"
                  onClick={() => {
                    postComment();
                  }}
                >
                  등록하기
                </button>
              </div>
              <div className="d-flex flex-column gap-1">
                {comments.map((comment: any) => {
                  return (
                    <article
                      key={comment.id}
                      className="py-2 px-2 d-flex justify-content-between"
                    >
                      <div className="d-flex flex-column">
                        <div className="d-flex gap-2">
                          <div className="fw-bold">{comment.user_name}</div>
                          {/* <div className="text-muted">2024.06.08</div> */}
                        </div>
                        <div>{comment.text}</div>
                      </div>
                      {comment.deletable ? (
                        <button
                          type="button"
                          className="btn btn-danger-soft"
                          onClick={() => {
                            deleteComment(comment.id);
                          }}
                        >
                          삭제
                        </button>
                      ) : (
                        ""
                      )}
                    </article>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
}

export default NoticeDetail;
