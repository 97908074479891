/* eslint-disable eqeqeq */
/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import { pages } from "../../constants/PagePaths";
import AlertContext from "../../contexts/AlertContext";
import InquiryPresenter from "./InquiryPresenter";

interface Props {}

export default (props: Props) => {
  const megaByte = 1000 * 1000;
  const fileSizeLimit = 20; //10MB

  const fileCountLimit = 5;
  const [isSended, setIsSended] = useState(false);
  const [inquiryFiles, setInquiryFiles] = useState<File[]>([]);
  const [fileNames, setFileNames] = useState<string[]>([]);
  const alertContext = useContext(AlertContext);
  const history = useHistory();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (isSended) return;
    const formData = new FormData();
    for (let index = 0; index < e.target.length; index++) {
      const element = e.target[index];
      if (
        (element.type === "text" ||
          element.type === "textarea" ||
          element.type === "email" ||
          element.type === "number") &&
        element.name != ""
      ) {
        formData.append(element.name, element.value);
      } else if (element.type === "radio") {
        if (element.checked) {
          formData.append(element.name, element.parentElement.innerText);
        }
      }
    }

    inquiryFiles.forEach((file) => formData.append("files", file));
    formData.append("enctype", "multipart/form-data");
    setIsSended(true);
    alertContext.showAlert("전송중입니다...");
    try {
      const res = await api.postInquiry(formData);
      if (res.status === 200) {
        alertContext.showAlert(
          "문의 신청이 완료되었습니다.\n기재하신 이메일을 통해\n추후 개별 연락이 진행될 예정입니다.",
          () => {
            const key = util.getCookie(Cookies.authKey);
            if (key) {
              history.push(pages.mypageqna);
            } else {
              history.push(pages.index);
            }
          }
        );
      }
    } catch (err) {
      alertContext.showAlert(
        "문의 전송 중 오류가 발생하였습니다.\n 우측 하단 아이콘을 통해 고객센터에 문의 부탁드립니다."
      );
    }
  };

  const handleFileAttachAsync = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    if (e.target.files) {
      if (e.target.files?.length === 0) return;
      const files = [];
      const filenames: string[] = [];
      let lengthSum = 0;
      for (let i = 0; i < (e.target.files.length || 0); i++) {
        let file = e.target.files[i];
        lengthSum += file.size;
        if (lengthSum < fileSizeLimit * megaByte && i < fileCountLimit) {
          files.push(file);
          filenames.push(file.name);
        } else {
          alertContext.showAlert(
            `파일은 최대 ${fileCountLimit}개, 합계 ${fileSizeLimit}MB를 넘을 수 없습니다.`
          );
          break;
        }
      }
      setInquiryFiles(files);
      setFileNames(filenames);
    }
  };
  return (
    <InquiryPresenter
      attachedFiles={fileNames}
      onSubmit={handleSubmit}
      onChangeFiles={handleFileAttachAsync}
    />
  );
};
