import "./index.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import moment from "moment";
import { useHistory } from "react-router-dom";
import styles from "./print.module.scss";

function Print(props) {
  const worklist = props.worklist;

  const location = useLocation();
  const linkLocation = location.pathname;
  const strArray = linkLocation.split("/");
  const id = Number(strArray[2]);

  //일괄 출력 array
  const docArr = new Array();

  useEffect(() => {
    if (worklist) {
      const uploadOrImage = document.getElementsByClassName("uploadOrImage");
      for (var i = 0; i < uploadOrImage.length; i++) {
        if (uploadOrImage[i].innerText === "업로드") {
          uploadOrImage[
            i
          ].innerHTML = `<div style='position: absolute; border-top: 1px solid; width: 103%; height: 100%; transform: rotate(-28deg); left: -10px'></div>
        <div style='position: absolute; border-top: 1px solid; width: 103%; height: 100%; transform: rotate(28deg); left: -10px'></div>`;
        }
      }
      const uploadOrImage2 = document.getElementsByClassName("uploadOrImage2");
      for (var i = 0; i < uploadOrImage2.length; i++) {
        if (uploadOrImage2[i].innerHTML.match("업로드")) {
          uploadOrImage2[
            i
          ].innerHTML = `<div style='position: absolute; border-top: 1px solid; width: 131%; height: 100%; transform: rotate(-49deg);left: -56px'></div>
          <div style='position: absolute; border-top: 1px solid; width: 131%; height: 100%; transform: rotate(49deg);left: -56px'></div>`;
        }
      }
    }
    document.body.innerHTML = document.getElementById("printArea").innerHTML;

    setTimeout(() => {
      window.print();
      history.push(`/worklists/${id}/documents/companyinfo`);
      window.location.reload();
    }, 500);
  }, [worklist]);

  const history = useHistory();

  const selectDoc = props.selectDoc ? props.selectDoc : "";
  const selectDoc1 = props.selectDoc1 ? props.selectDoc1 : "";
  const selectDoc2 = props.selectDoc2 ? props.selectDoc2 : "";
  const selectDoc3 = props.selectDoc3 ? props.selectDoc3 : "";

  // //생년월일 만들기
  const birthYear = worklist?.deputyResidentNum?.substring(0, 2);
  const birthMonth = worklist?.deputyResidentNum?.substring(2, 4);
  const birthDay = worklist?.deputyResidentNum?.substring(4, 6);
  const birth = `19${birthYear}년 ${birthMonth}월 ${birthDay}일`;

  if (!worklist) {
    return <div>로딩중...</div>;
  }

  //착공서류 - 산업안전보건관리비 사용계획서
  if (!worklist?.safetyManagePlan) {
    return <div>로딩중...</div>;
  }
  const safetyManagePlan = worklist.safetyManagePlan.usagePlanItems;
  let safetyPlanTotalPrice = 0;
  for (let i = 0; i < safetyManagePlan?.length; i++) {
    safetyPlanTotalPrice += safetyManagePlan[i].totalPrice;
  }
  //착공서류 - 환경관리비 사용계획서
  if (!worklist?.enviManagePlan) {
    return <div>로딩중...</div>;
  }
  const enviManagePlan = worklist.enviManagePlan.EnviPlanItems;
  let enviPlanTotalPrice = 0;
  for (let i = 0; i < enviManagePlan.length; i++) {
    enviPlanTotalPrice += enviManagePlan[i].totalPrice;
  }
  //준공서류 - 산업안전보건관리비 사용계획서
  if (!worklist?.safetyManageState) {
    return <div>로딩중...</div>;
  }
  const safetyManageState = worklist.safetyManageState.usageStateItems;
  let safetyStateTotalPrice = 0;
  for (let i = 0; i < safetyManageState?.length; i++) {
    safetyStateTotalPrice += safetyManageState[i].totalPrice;
  }
  //준공서류 - 환경관리비 사용내역서
  if (!worklist?.enviManageState) {
    return <div>로딩중...</div>;
  }
  const enviManageState = worklist.enviManageState.EnviStateItems;
  let enviStateTotalPrice = 0;
  for (let i = 0; i < enviManageState.length; i++) {
    enviStateTotalPrice += enviManageState[i].totalPrice;
  }
  //사진대장 2칸
  const twoImg = props.images1;
  const photoListContents = worklist?.photoListContent;
  const useContent = photoListContents?.photoListContents;
  if (!twoImg) {
    return <div>로딩중...</div>;
  }
  //사진대장 4칸
  const fourImg = props.images2;
  if (!fourImg) {
    return <div>로딩중...</div>;
  }

  ///////////////////////////////////////////////////////////////////////////////
  for (var i = 0; i < Object.keys(selectDoc).length; i++) {
    //계약서류
    if (selectDoc[i] === "견적서") {
      docArr.push(
        <div className="page-doc">
          <table
            style={{
              tableLayout: "fixed",
              borderBottom: "hidden",
              margin: "0 auto",
            }}
          >
            <tr style={{ lineHeight: "150px" }}>
              <td
                className="doc-title"
                colspan="8"
                align="center"
                style={{ paddingBottom: "50px" }}
              >
                견 적 서
              </td>
            </tr>
            <tr style={{ height: "50px" }}>
              <td style={{ borderRight: "hidden", paddingLeft: "5px" }}>
                공 사 명 :{" "}
              </td>
              <td style={{ wordBreak: "keep-all" }} colSpan="3">
                {worklist?.constructionName}
              </td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>등</span>
                  <span>록</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td align="center" colspan="3">
                {worklist?.corporateRegistNum}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td align="center" className="nonLine" colspan="4">{`${
                !worklist?.dear ? "" : worklist?.dear
              } 귀하`}</td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>상</span>
                  <span>호</span>
                </span>
              </td>
              <td align="center" colspan="3">
                {worklist?.companyName}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td colspan="4" align="center">
                {moment(worklist?.contractDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? ""
                  : moment(worklist?.contractDate).format("yyyy년　MM월　DD일")}
              </td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>주</span>
                  <span>소</span>
                </span>
              </td>
              <td style={{ wordBreak: "keep-all" }} align="center" colspan="3">
                {worklist?.businessAddress}
              </td>
            </tr>

            <tr style={{ height: "50px" }}>
              <td colspan="4" align="center">
                아래와 같이 <u>견적</u> 합니다.
              </td>
              <td align="center">
                <span
                  style={{
                    width: "60px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>대</span>
                  <span>표</span>
                </span>
              </td>
              <td
                colSpan="2"
                align="right"
                className="nonLine"
                style={{ letterSpacing: "10px" }}
              >
                {worklist?.representativeName}
              </td>
              <td
                style={{ paddingRight: "5px" }}
                align="right"
                className="nonLine"
              >
                (인)
              </td>
            </tr>
          </table>

          <table style={{ marginLeft: "1px" }}>
            <tr className="nonLine">
              <td style={{ height: "30px" }} colspan="7">
                　
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }} height="30px">
                공사금액
              </td>
              <td align="center" colSpan="6" style={{ fontWeight: "600" }}>
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>

            <tr align="center">
              <td width="200px" height="30px">
                품　명
              </td>
              <td width="50px">규 격</td>
              <td width="50px">수 량</td>
              <td width="50px">단 위</td>
              <td width="50px">단 가</td>
              <td>금 액</td>
              <td width="50px">비 고</td>
            </tr>

            <tr align="center">
              <td style={{ wordBreak: "keep-all" }} height="30px">
                {worklist?.constructionName}
              </td>
              <td></td>
              <td style={{ fontSize: "13px" }}>1</td>
              <td style={{ fontSize: "13px" }}>식</td>
              <td></td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                {props.commaContractPrice}
              </td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr>
              <td height="30px"></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>　</td>
            </tr>

            <tr align="center" style={{ fontWeight: "600" }}>
              <td className="center" height="30px">
                합　　　계
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                {props.commaContractPrice}
              </td>
              <td>　</td>
            </tr>
          </table>
        </div>
      );
    } else if (selectDoc[i] === "수의계약서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table
              className="bbb"
              style={{ tableLayout: "fixed", border: "hidden" }}
            >
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  className="doc-title"
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    height: "150px",
                  }}
                  colspan="4"
                >
                  각 　　　　서
                </td>
              </tr>

              <tr style={{ borderBottom: "hidden", height: "60px" }}>
                <td colspan="4">　</td>
              </tr>
            </table>
            <table
              className="aaa"
              style={{ border: "1", tableLayout: "fixed", textAlign: "center" }}
            >
              <tr>
                <td style={{ height: "50px", width: "100px" }}>
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>업</span>
                    <span>체</span>
                    <span>명</span>
                  </span>
                </td>
                <td>{worklist?.companyName}</td>
                <td style={{ width: "100px" }}>
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>소</span>
                    <span>재</span>
                    <span>지</span>
                  </span>
                </td>
                <td style={{ wordBreak: "keep-all" }}>
                  {worklist?.businessAddress}
                </td>
              </tr>
              <tr>
                <td style={{ height: "50px" }}>
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>대</span>
                    <span>표</span>
                    <span>자</span>
                  </span>
                </td>
                <td style={{ letterSpacing: "10px " }}>
                  {worklist?.representativeName}
                </td>
                <td>
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>업</span>
                    <span>종</span>
                    <span>(등</span>
                    <span>록)</span>
                  </span>
                </td>
                <td>{worklist?.typeOfBusiness}</td>
              </tr>
            </table>
            <table style={{ tableLayout: "fixed", border: "hidden" }}>
              <tr style={{ borderBottom: "hidden", height: "120px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "30px",
                    textIndent: "5px",
                    wordBreak: "keep-all",
                    fontSize: "15px",
                  }}
                  colspan="4"
                >
                  상기 본인(법인)은 귀 청과 수의계약을 체결함에 있어서 붙임
                  결격사유중 어느 사유에도 해당되지 않으며 차후에 이러한 사실이
                  발견된 경우 계약의 해지(해제) 및 부정당업자의 제재처분을
                  받아도 하등의 이유를 제기하지 않겠습니다.
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "100px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "100px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "110px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr>
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>

          <div className="page-doc">
            <div style={{ marginBottom: "5px" }}>{`< 붙 임 >`}</div>
            <table>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "50px",
                    paddingLeft: "10px",
                    height: "80px",
                  }}
                >
                  ⓐ 수의계약 견적서 제출일 현재 부도·파산·해산·영업정지 등이
                  확정된 사실
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "50px",
                    paddingLeft: "10px",
                    height: "130px",
                  }}
                >
                  ⓑ 「건설산업기본법」에 의한 일반공사 또는
                  「엔지니어링기술진흥법」에 의한 기술용역의 경우 수의계약
                  견적서 제출일 현재 기술자 보유 현황이 당해공사 시공에 필요한
                  업종등록 기준에 미달하는 사실
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "50px",
                    paddingLeft: "10px",
                    height: "80px",
                  }}
                >
                  ⓒ 부정당업자 제재가 진행중인 사실
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "50px",
                    paddingLeft: "10px",
                    height: "180px",
                  }}
                >
                  ⓓ 수의계약 안내공고일 기준 최근 1년 이내에 지방계약법 시행령
                  제 92조 또는 다른 법령의 규정에 의하여 부실시공, 담합행위,
                  입찰 및 계약서류 위조 또는 허위제출, 입·낙찰 또는 계약이행
                  관련 뇌물제공자로서 부정당업자 제재처분을 받은 사실이 있는 자
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "50px",
                    paddingLeft: "10px",
                    height: "180px",
                  }}
                >
                  ⓔ 수의계약 안내공고일 기준 최근 1년 이내에 당해 자치단체의
                  입찰 및 계약, 계약이행 과정에서 10일이상 계약이행 지연,
                  시공과정에 정당한 이행명령 거부, 하자보증기간내 5회 이상
                  하자보수, 기타 당해 자치단체와의 계약이행 과정에서 물의를
                  일으키는 등 신용이 떨어져 계약체결이 곤란하다고 판단되는자
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "50px",
                    paddingLeft: "10px",
                    height: "130px",
                  }}
                >
                  ⓕ 수의계약 안내공고일 기준 최근 1년 이내에 당해 자치단체
                  계약체결과 관련하여 계약에 응하지 않거나, 포기서를 제출한
                  사실이 있거나 계약이행도중 정당한 이유없이 포기서를 제출한
                  사실이 있는 자
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    lineHeight: "50px",
                    paddingLeft: "10px",
                    height: "80px",
                  }}
                >
                  ⓖ 수의계약체결일 현재 지방계약법 제 33조에 해당되는 자
                </td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc[i] === "표준계약서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table border="1">
              <tr>
                <td
                  colspan="5"
                  rowspan="2"
                  style={{
                    textAlign: "center",
                    fontSize: "35px",
                    fontWeight: "bold",
                  }}
                >
                  공사도급표준계약서
                </td>
                <td style={{ textAlign: "center", width: "120px" }}>
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                </td>
                <td style={{ textAlign: "right" }}>　제　　　　호　</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>고</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                </td>
                <td style={{ textAlign: "right" }}>　제　　　　호　</td>
              </tr>

              <tr>
                <td style={{ textAlign: "center", width: "40px" }} rowspan="8">
                  <p>
                    계
                    <p>
                      약<p>자</p>
                    </p>
                  </p>
                </td>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>발</span>
                    <span>주</span>
                    <span>처</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4">
                  {worklist?.orderOrganization}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" rowspan="4">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>대</span>
                    <span>상</span>
                    <span>자</span>
                  </span>
                </td>
                <td
                  style={{
                    borderBottom: "hidden",
                    borderRight: "hidden",
                    paddingLeft: "5px",
                    width: "110px",
                    height: "25px",
                  }}
                >
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>상호</span>
                    <span>또는</span>
                    <span>법인</span>
                  </span>
                  :
                </td>
                <td style={{ borderBottom: "hidden" }} colspan="3">
                  {worklist?.companyName}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderBottom: "hidden",
                    borderRight: "hidden",
                    paddingLeft: "5px",
                    height: "25px",
                  }}
                >
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>법</span>
                    <span>인</span>
                    <span>등</span>
                    <span>록</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                  :
                </td>
                <td style={{ borderBottom: "hidden" }} colspan="3">
                  {worklist?.corporateNum}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderBottom: "hidden",
                    borderRight: "hidden",
                    paddingLeft: "5px",
                  }}
                  height="25px"
                >
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>주</span>
                    <span>소</span>
                  </span>
                  :
                </td>
                <td
                  style={{
                    borderBottom: "hidden",
                    wordBreak: "keep-all",
                    width: "380px",
                  }}
                  colspan="3"
                >
                  {worklist?.businessAddress}
                </td>
              </tr>
              <tr>
                <td
                  style={{ borderRight: "hidden", paddingLeft: "5px" }}
                  height="25px"
                >
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>대</span>
                    <span>표</span>
                    <span>자</span>
                  </span>
                  :
                </td>
                <td style={{ borderRight: "hidden" }}>
                  {worklist?.representativeName}
                </td>
                <td style={{ borderRight: "hidden" }} height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>전</span>
                    <span>화</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                  :
                </td>
                <td>{worklist?.phoneNum}</td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} rowspan="3" colspan="2">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>연</span>
                    <span>대</span>
                    <span>보</span>
                    <span>증</span>
                    <span>인</span>
                  </span>
                </td>
                <td
                  style={{
                    borderBottom: "hidden",
                    borderRight: "hidden",
                    paddingLeft: "5px",
                    width: "110px",
                    height: "25px",
                  }}
                >
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>상호</span>
                    <span>또는</span>
                    <span>법인</span>
                  </span>
                  :
                </td>
                <td
                  style={{ borderBottom: "hidden", borderRight: "hidden" }}
                ></td>
                <td
                  style={{
                    borderBottom: "hidden",
                    borderRight: "hidden",
                    height: "25px",
                  }}
                >
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>법</span>
                    <span>인</span>
                    <span>등</span>
                    <span>록</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                  :
                </td>
                <td style={{ borderBottom: "hidden" }}></td>
              </tr>
              <tr>
                <td
                  style={{
                    borderBottom: "hidden",
                    borderRight: "hidden",
                    paddingLeft: "5px",
                  }}
                  height="25px"
                >
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>주</span>
                    <span>소</span>
                  </span>
                  :
                </td>
                <td
                  style={{ borderBottom: "hidden", borderRight: "hidden" }}
                ></td>
                <td
                  style={{ borderRight: "hidden", borderBottom: "hidden" }}
                  height="25px"
                >
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>전</span>
                    <span>화</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                  :
                </td>
                <td style={{ borderBottom: "hidden" }}></td>
              </tr>
              <tr>
                <td
                  style={{ borderRight: "hidden", paddingLeft: "5px" }}
                  height="25px"
                >
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "5px",
                    }}
                  >
                    <span>대</span>
                    <span>표</span>
                    <span>자</span>
                  </span>
                  :
                </td>
                <td colspan="3"></td>
              </tr>

              <tr>
                <td style={{ textAlign: "center" }} rowspan="12">
                  <p>
                    계
                    <p>
                      약
                      <p>
                        내<p>용</p>
                      </p>
                    </p>
                  </p>
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>사</span>
                    <span>명</span>
                  </span>
                </td>
                <td
                  style={{ paddingLeft: "5px", wordBreak: "keep-all" }}
                  colspan="4"
                >
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4">
                  {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>총</span>
                    <span>공</span>
                    <span>사</span>
                    <span>부</span>
                    <span>기</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4"></td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>보</span>
                    <span>증</span>
                    <span>금</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4">
                  {`일금 ${props.krContractGuaranteePrice}정 (￦${props.commaContractGuaranteePrice})`}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>현</span>
                    <span>장</span>
                  </span>
                </td>
                <td
                  style={{ paddingLeft: "5px", wordBreak: "keep-all" }}
                  colspan="4"
                >
                  {worklist?.constructionAddress}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>지</span>
                    <span>체</span>
                    <span>상</span>
                    <span>금</span>
                    <span>율</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4">
                  0.10%
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>물</span>
                    <span>가</span>
                    <span>변</span>
                    <span>동</span>
                    <span>계</span>
                    <span>약</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4"></td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>금</span>
                    <span>액</span>
                    <span>조</span>
                    <span>정</span>
                    <span>방</span>
                    <span>법</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4"></td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>착</span>
                    <span>공</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4">
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                  "Invalid date"
                    ? ""
                    : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>준</span>
                    <span>공</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4">
                  {moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.completionDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  <span
                    style={{
                      width: "90px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>기</span>
                    <span>타</span>
                    <span>사</span>
                    <span>항</span>
                  </span>
                </td>
                <td style={{ paddingLeft: "5px" }} colspan="4"></td>
              </tr>

              <tr>
                <td style={{ paddingLeft: "5px" }} colspan="7" height="25px">
                  하자담보책임 (복합공종의 경우 공종별 구분 기재)
                </td>
              </tr>

              <tr>
                <td style={{ textAlign: "center" }} colspan="2" height="25px">
                  공　　　종
                </td>
                <td style={{ textAlign: "center" }} colspan="2">
                  공종별 계약금액
                </td>
                <td style={{ textAlign: "center" }} colspan="2">
                  하자보수보증금율(%) 및 금액
                </td>
                <td style={{ textAlign: "center" }}>하자담보책임기간</td>
              </tr>
              <tr>
                <td
                  style={{ textAlign: "center" }}
                  colspan="2"
                  className="nonLine"
                  height="25px"
                >
                  {props.worklist.typeOfWork}
                </td>
                <td style={{ textAlign: "center" }} colspan="2" rowspan="2">
                  ￦{props.commaContractPrice}
                </td>
                <td
                  style={{ textAlign: "center", borderRight: "hidden" }}
                  rowspan="2"
                >
                  ({worklist?.contractRepairRate})% 금
                </td>
                <td style={{ textAlign: "center" }} rowspan="2">
                  {props.commaRepairPrice} - 원정
                </td>
                <td style={{ textAlign: "center" }} rowspan="2">
                  {worklist?.contractRepairTerm}
                </td>
              </tr>
              <tr>
                <td
                  style={{ textAlign: "center" }}
                  colspan="2"
                  className="nonLine"
                  height="25px"
                >
                  공사
                </td>
              </tr>

              <tr>
                <td
                  style={{
                    lineHeight: "30px",
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    textIndent: "5px",
                  }}
                  colspan="7"
                >
                  계약담당공무원과 계약대상자는 상호 대등한 입장에서 붙임의
                  계약문서에 의하여 위의 공 사에 대한 도급계약을 체결하고 신의에
                  따라 성실히 계약상의 의무를 이행할 것을 확약하며, 연대보증인은
                  계약자와 연대하여 계약상의 의무를 이행할 것을 확약한다.
                  이계약의 증거로 서 계약서를 작성하여 당사자가 기명날인한 후
                  각각 1통씩 보관한다.
                </td>
              </tr>

              <tr>
                <td
                  style={{ paddingLeft: "5px" }}
                  colspan="2"
                  className="nonLine"
                  height="25px"
                >
                  붙임서류
                </td>
                <td colspan="5" className="nonLine">
                  1. 공사입찰유의서 1부
                </td>
              </tr>
              <tr>
                <td colspan="2" className="nonLine" height="25px"></td>
                <td colspan="5" className="nonLine">
                  2. 공사계약일반조건 1부
                </td>
              </tr>
              <tr>
                <td colspan="2" className="nonLine" height="25px"></td>
                <td colspan="5" className="nonLine">
                  3. 공사계약특수조건 1부
                </td>
              </tr>
              <tr>
                <td colspan="2" className="nonLine" height="25px"></td>
                <td colspan="5" className="nonLine">
                  4. 설계서 1부
                </td>
              </tr>
              <tr>
                <td className="nonLine" colspan="2" height="25px"></td>
                <td className="nonLine" colspan="5">
                  5. 산출내역서 1부
                </td>
              </tr>

              <tr>
                <td className="nonLine" colspan="5" height="25px"></td>
                <td className="nonLine" colspan="2">
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>

              <tr>
                <td
                  colspan="5"
                  className="nonLine"
                  height="25px"
                  style={{ textAlign: "right" }}
                >
                  <span
                    style={{
                      width: "97.17px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "25px",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>대</span>
                    <span>상</span>
                    <span>자</span>
                  </span>
                  <span>{worklist?.companyName}</span>
                </td>
                <td
                  className="nonLine"
                  style={{ letterSpacing: "10px", textAlign: "center" }}
                >
                  {worklist?.representativeName}
                </td>
                <td className="nonLine">(인)</td>
              </tr>

              <tr>
                <td
                  colspan="5"
                  className="nonLine"
                  height="25px"
                  style={{ textAlign: "right" }}
                >
                  <span
                    style={{
                      width: "97.17px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                      marginRight: "25px",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>담</span>
                    <span>당</span>
                    <span>공</span>
                    <span>무</span>
                    <span>원</span>
                  </span>
                  <span style={{ visibility: "hidden" }}>
                    {worklist?.companyName}
                  </span>
                </td>
                <td className="nonLine"></td>
                <td className="nonLine">(인)</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc[i] === "사용인감계") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table
              className="bbb"
              style={{ tableLayout: "fixed", border: "hidden" }}
            >
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  className="doc-title"
                  colspan="4"
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    height: "150px",
                  }}
                >
                  사 용 인 감 계
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colspan="4">　</td>
              </tr>
            </table>
            <table
              className="aaa"
              style={{ tableLayout: "fixed", textAlign: "center", border: "1" }}
            >
              <tr>
                <td colspan="2" style={{ height: "40px" }}>
                  <span
                    style={{
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>사</span>
                    <span>용</span>
                    <span>인</span>
                    <span>감</span>
                  </span>
                </td>
                <td>
                  <span
                    style={{
                      width: "50px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>주</span>
                    <span>소</span>
                  </span>
                </td>
                <td colSpan="2" style={{ wordBreak: "keep-all" }}>
                  {worklist?.businessAddress}
                </td>
              </tr>
              <tr>
                <td colspan="2" rowspan="2">
                  　
                </td>
                <td style={{ height: "40px" }}>
                  <span
                    style={{
                      width: "50px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>상</span>
                    <span>호</span>
                  </span>
                </td>
                <td colSpan="2">{worklist?.companyName}</td>
              </tr>
              <tr>
                <td style={{ height: "70px" }}>
                  <span
                    style={{
                      width: "50px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>성</span>
                    <span>명</span>
                  </span>
                  <div
                    style={{
                      width: "58px",
                      display: "flex",
                      justifyContent: "space-between",
                      marginLeft: "35px",
                    }}
                  >
                    <span>(대</span>
                    <span>표</span>
                    <span>자)</span>
                  </div>
                </td>
                <td colSpan="2" style={{ letterSpacing: "10px" }}>
                  {worklist?.representativeName}
                </td>
              </tr>
            </table>
            <table style={{ tableLayout: "fixed", border: "hidden" }}>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    lineHeight: "30px",
                    fontSize: "16px",
                    wordBreak: "keep-all",
                    textIndent: "5px",
                  }}
                  colspan="4"
                >
                  위 인감은 본 조합이 사용하는 인감으로써 귀청에서 집행하는
                  아래건의 등록계약 수금에 사용하겠으며 위 인감 사용으로 인한
                  법률상의 모든 책임은 본 조합이 질 것을 확약하고 이에
                  사용인감계를 제출합니다.
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td style={{ borderRight: "hidden", textAlign: "center" }}>
                  건　　　명 :{" "}
                </td>
                <td style={{ wordBreak: "keep-all" }} colspan="3">
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td
                  colspan="4"
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "80px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ fontSize: "18px" }}>
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc[i] === "계약보증금지급각서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table style={{ tableLayout: "fixed", border: "hidden" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  className="doc-title"
                  colspan="4"
                  style={{
                    fontSize: "40px",
                    textAlign: "center",
                    height: "150px",
                  }}
                >
                  계약보증금지급각서
                </td>
              </tr>

              <tr style={{ borderBottom: "hidden" }}>
                <td colspan="4">　</td>
              </tr>

              <tr
                style={{
                  height: "50px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  ○　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>건</span>
                    <span>명</span>
                  </span>
                  　:
                </td>
                <td style={{ wordBreak: "keep-all" }} colspan="3">
                  {worklist?.constructionName}
                </td>
              </tr>

              <tr
                style={{
                  height: "50px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  ○　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
                </td>
              </tr>

              <tr
                style={{
                  height: "50px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  ○　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>일</span>
                    <span>자</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>

              <tr
                style={{
                  height: "50px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  ○　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>이</span>
                    <span>행</span>
                    <span>기</span>
                    <span>간</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {(moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) ===
                    "Invalid date") &
                  (moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) ===
                    "Invalid date")
                    ? ""
                    : `${moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}　~　${moment(worklist?.completionDate).format(
                        "yyyy년　MM월　DD일"
                      )}`}
                </td>
              </tr>
              <tr
                style={{
                  height: "50px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  ○　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>보</span>
                    <span>증</span>
                    <span>금</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {`일금 ${props.krContractGuaranteePrice}정 (￦${props.commaContractGuaranteePrice})`}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ height: "170px", borderBottom: "hidden" }}>
                <td
                  style={{ lineHeight: "30px", textIndent: "5px" }}
                  colspan="4"
                >
                  귀청과 상기 계약을 체결함에 있어서 지방자치단체를 당사자로
                  하는 계약에 관한 법률 제 15조 제 3항 (계약보증금세입조치) 및
                  동시행령 제 54조 (계약보증금세입조치)의 규정에 의하여 해당
                  계약보증금이 귀청에 귀속사유가 발생할 때에는 즉시 현금으로
                  납입할 것을 확약하고, 지방자치단체를 당사자로 하는 계약에 관한
                  법률 시행령 제 53조 제 2항의 규정 (계약보증금납입확약)에 의거
                  본 각서를 제출합니다.
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "45px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td
                  colspan="4"
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "45px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "60px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ fontSize: "20px", height: "30px" }}>
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc[i] === "계약보증금납부서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table style={{ textAlign: "center", tableLayout: "fixed" }}>
              <tr>
                <td
                  style={{ paddingBottom: "50px" }}
                  className="doc-title"
                  colspan="4"
                >
                  계약보증금납부서
                </td>
              </tr>

              <tr style={{ height: "50px" }}>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>입</span>
                    <span>찰</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                </td>
                <td>　</td>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>입</span>
                    <span>찰</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                </td>
                <td>　</td>
              </tr>

              <tr style={{ height: "50px" }}>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>건</span>
                    <span>명</span>
                  </span>
                </td>
                <td
                  style={{
                    wordBreak: "keep-all",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                  colspan="3"
                >
                  {worklist?.constructionName}
                </td>
              </tr>

              <tr style={{ height: "50px" }}>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                </td>
                <td>　</td>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>(예</span>
                    <span>정)</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                </td>
                <td>
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>

              <tr style={{ height: "50px" }}>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                </td>
                <td
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                  colspan="3"
                >
                  {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
                </td>
              </tr>

              <tr style={{ height: "50px" }}>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>보</span>
                    <span>증</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                </td>
                <td
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                  colspan="3"
                >
                  {`일금 ${props.krContractGuaranteePrice}정 (￦${props.commaContractGuaranteePrice})`}
                </td>
              </tr>

              <tr style={{ height: "50px" }}>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>보</span>
                    <span>증</span>
                    <span>금</span>
                    <span>납</span>
                    <span>부</span>
                    <span>방</span>
                    <span>법</span>
                  </span>
                </td>
                <td
                  style={{ textAlign: "left", paddingLeft: "5px" }}
                  colspan="3"
                >
                  □ 보증서　□ 보증보험증권　□ 보증금지급각서
                </td>
              </tr>

              <tr style={{ height: "50px" }}>
                <td>
                  <span
                    style={{
                      width: "120px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>이</span>
                    <span>행</span>
                    <span>기</span>
                    <span>간</span>
                  </span>
                </td>
                <td colspan="3">
                  {(moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) ===
                    "Invalid date") &
                  (moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) ===
                    "Invalid date")
                    ? ""
                    : `${moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}　~　${moment(worklist?.completionDate).format(
                        "yyyy년　MM월　DD일"
                      )}`}
                </td>
              </tr>

              <tr
                style={{
                  height: "80px",
                  fontSize: "20px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                  textAlign: "left",
                }}
              >
                <td colspan="4">위의 금액을 계약보증금으로 납부합니다.</td>
              </tr>

              <tr>
                <td
                  style={{
                    fontSize: "15px",
                    height: "50px",
                    borderBottom: "hidden",
                    borderLeft: "hidden",
                    borderRight: "hidden",
                  }}
                  colspan="4"
                >
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>

              <tr>
                <td
                  colSpan="4"
                  height="40px"
                  style={{
                    borderBottom: "hidden",
                    borderLeft: "hidden",
                    borderRight: "hidden",
                  }}
                ></td>
              </tr>

              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">전화</span>:　
                  <span>{worklist?.phoneNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">팩스</span>:　
                  <span>{worklist?.faxNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr>
                <td
                  colSpan="4"
                  style={{
                    height: "80px",
                    borderLeft: "hidden",
                    borderRight: "hidden",
                    borderBottom: "hidden",
                  }}
                ></td>
              </tr>
              <tr
                style={{
                  height: "40px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc[i] === "청렴계약이행") {
      docArr.push(
        <div>
          <div className="page-doc">
            <p
              className="doc-title"
              style={{
                fontSize: "38px",
                textAlign: "center",
                height: "100px",
                marginTop: "50px",
              }}
            >
              청렴계약이행을 위한 입찰특별유의서
            </p>

            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제1조(목 적) 이 청렴계약이행을 위한 입찰특별유의서는{" "}
              {worklist?.contractRegion} 청렴계약제 시행을 위하여{" "}
              {worklist?.contractRegion}에서 집행하는 공사․용역․물품구매 등에
              대한 입찰에 있어 입찰에 참가하고자 하는 자가 입찰유의서 외에
              특별히 유의할 사항을 정함을 목적으로 한다.
            </p>
            <p style={{ height: "30px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제2조(입찰참가업체의 청렴계약이행서약서 제출) ①입찰에 참여하는
              업체는 발주기관과 입찰참여업체가 상호신뢰를 바탕으로 입찰, 계약,
              계약이행과정에서의 담합 등 불공정행위나 관계공무원에게 금품․향응등
              뇌물이나 부당한 이익을 제공하지 않고, 이를 위반시
              입찰참가자격제한, 계약해지 등을 감수하겠다는『
              {worklist?.contractRegion} 청렴계약이행 서약서』〔별지1〕를
              업체대표가 서명하여 입찰등록시 제출한 것으로 간주하며, 낙찰자로
              선정된 업체만 별도 업체대표가 계약체결시 계약상대자는 청렴계약이행
              서약내용을 그대로 {worklist?.contractRegion}{" "}
              청렴계약이행특수조건으로 약정하는데 동의하고 준수하여야 한다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              {" "}
              ②입찰에서 낙찰자로 선정된 자가 계약체결시 청렴계약이행 서약서
              내용을 {worklist?.contractRegion} 청렴계약이행 특수조건에 명기
              하여 계약하는 것을 거부하여 낙찰 통지를 받은후 10일이내에 계약이
              체결되지 아니하는 경우에는 낙찰취소 조치를 받게 된다.
            </p>
            <p style={{ height: "30px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제3조(발주부서 청렴계약 이행서약서 교부) 청렴계약 대상
              공사․용역․물품구매 등의 입찰에 낙찰된 자는 계약체결시 발주부서에서
              교부하는 '청렴계약이행서약서'에 업체대표자가 서명하여 발주부서에
              제출하면 {worklist?.contractRegion}는 발주기관의 장 및
              담당공무원이 입찰, 계약 및 계약이행과정에서 입찰참가업체로부터
              어떠한 명목의 금품이나 향응을 요구하거나 받지 않으며 위반시에는
              관계법에 따라 징계 등 문책을 감수하겠다고 함께 서명한『
              {worklist?.contractRegion} 청렴계약이행서약서』〔별지 1〕를
              교부한다.
            </p>
            <p style={{ height: "30px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제4조(부정당업자의 입찰참가자격제한) ①입찰에 참가하는 자가
              입찰가격이나 특정인의 낙찰을 위하여 담합 등 불공정 행위를
              하였을때에는 다음 각호의 1에서 정하는 바에 의하여{" "}
              {worklist?.contractRegion}에서 시행하는 입찰에 참가제한을 받게
              된다.
            </p>
          </div>

          <div className="page-doc">
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              1. 경쟁입찰에 있어서 특정인의 낙찰을 위하여 담합을 주도한 자는{" "}
              {worklist?.contractRegion}가 발주하는 입찰에 1년이상 2년이하의
              범위내에서 입찰참가 자격을 제한한다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              2. 경쟁입찰에 있어서 입찰가격을 서로 상의하여 미리 입찰가격을
              협정하거나 특정인의 낙찰을 위하여 담합한 자는{" "}
              {worklist?.contractRegion}가 발주하는 입찰에 6개월이상 1년미만의
              범위내에서 입찰참가자격을 제한한다.
            </p>
            <p style={{ height: "30px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ② 입찰담합 등 불공정행위를 한 경우에는 제1항과 병행하여 독점규제
              및 공정거래에관한법령에 따라 공정거래위원회에 고발 등 조치를
              하는데 일체 이의를 제기하지 않는다.
            </p>
            <p style={{ height: "80px" }}>　</p>
            <p
              style={{
                lineHeight: "25px",
                letterSpacing: "3px",
                height: "100px",
              }}
            >
              ③ 입찰․계약체결 및 계약이행 과정에서 관계공무원에게 직․간접적으로
              금품, 향응 등의 뇌물이나 부당한 이익을 제공하지 않으며 이를
              위반하였을 때에는 다음 각호의 1에서 정하는 바에 의하여{" "}
              {worklist?.contractRegion}에서 시행하는 입찰에 참가제한을 받게
              된다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              1. 계약체결 또는 그 이행과 관련하여 관계공무원에게 뇌물을
              제공함으로써 그 계약을 체결하였거나, 시공중 편의를 받아 시공을
              부실하게 한자와 입찰 또는 낙찰자 결정과 관련하여 관계공무원에게
              뇌물을 제공한자는 {worklist?.contractRegion}가 발주하는 입찰에
              6개월이상 1년미만의 범위내에서 입찰참가자격을 제한한다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              2. 뇌물을 주어서 계약조건이 낙찰자에게 유리하게 되도록 계약을
              체결하였거나 그 이행을 부실하게 할 목적을 관계공무원에게 뇌물을
              제공한자(제1호에 해당하는 자를 제외한 자)는{" "}
              {worklist?.contractRegion}가 발주하는 입찰에 1월이상 6개월미만의
              범위내에서 입찰 참가자격을 제한한다.
            </p>
            <p style={{ height: "80px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ④ 제1항 내지 제2항의 입찰참가자격제한의 {worklist?.contractRegion}{" "}
              처분과 관련하여 입찰에 참가하는 업체는 {worklist?.contractRegion}
              를 상대로 손해배상을 청구하거나 배제하는 입찰에 관하여 민․형사상
              이의를 제기하지 않는다.
            </p>
          </div>

          <div className="page-doc">
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제5조(계약해지 등) ①입찰, 계약체결 및 계약이행과 관련하여
              관계공무원에게 뇌물을 제공한 자는 다음 각호의 1에서 정하는 바에
              의하여 당해 계약에 대한 조치를 받는다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              1. 계약체결 전의 경우에는 적격낙찰자 결정대상에서 제외하거나
              낙찰자가 결정된 경우에는 그 결정을 취소한다.
            </p>
            <div style={{ height: "25px" }}>　</div>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              2. 계약체결 이후부터 공사착공 전의 경우에는 당해 계약을 해제한다.
            </p>
            <div style={{ height: "25px" }}>　</div>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              3. 공사착공 이후에는 계약의 전부 또는 일부를 해지한다. 다만,
              공사성격, 진도(進度), 규모, 공사기간 등을 감안하여 부득이한
              경우에는 그러하지 아니하다.
            </p>
            <div style={{ height: "25px" }}>　</div>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              4. 계약상대자는 제1호 내지 제3호의 규정에 의하여 계약의 해지 또는
              해제에 대하여 민․형사상 일체 이의를 제기하지 않는다.
            </p>
            <p style={{ height: "50px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제6조(기타사항) ① 입찰에 참가하는 업체의 임․직원(하도급업체
              포함)과 대리인이 관계공무원에게 뇌물을 제공하거나 담합 등
              불공정행위를 하지 않도록 하는 업체윤리강령과 내부비리 제보자에
              대하여도 일체의 불이익 처분을 하지 않는 사규를 제정하도록 적극
              노력하여야 한다.
            </p>
            <p style={{ height: "50px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ② 본건 입찰, 계약체결, 계약이행, 준공과 관련하여{" "}
              {worklist?.contractRegion}에서 시행하는 청렴계약 옴부즈만이
              요구하는 자료제출, 서류열람, 현장확인 등 활동에 적극 협력하여야
              한다.
            </p>
            <p style={{ height: "25px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ③ 본건 관련 하도급계약체결 및 이행에 있어서 하도급자로부터 금품을
              수수하거나 부당 또는 불공정한 행위를 하지 아니한다.
            </p>
          </div>

          <div className="page-doc">
            <p
              className="title"
              style={{ fontSize: "40px", textAlign: "center", height: "48px" }}
            >
              청 렴 계 약 이 행 특 수 조 건
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제1조(목 적) 이 청렴계약이행특수조건은 {worklist?.contractRegion}{" "}
              계약담당공무원과 계약상대자가 체결하는 공사․용역․물품구매 등의
              도급계약에 있어 계약일반조건 외에 청렴계약을 위한 내용을 특별히
              규정함을 목적으로 한다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제2조(청렴계약이행 준수의무) ①공사․용역․물품구매 등의 입찰에
              청렴계약 이행서약서를 제출하고 참가하여 낙찰된 계약상대자는
              계약체결 및 이행과 관련하여 어떠한 명분으로도 관계공무원에게
              직․간접적으로 금품․향응 등의 뇌물이나 부당한 이익을 제공하여서는
              아니된다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제3조(부정당업자의 입찰참가자격제한) ① 입찰에 참가하는 자가
              입찰가격이나 특정인의 낙찰을 위하여 담합 등 불공정 행위를
              하였을때에는 다음 각호의 1에서 정하는 바에 의하여{" "}
              {worklist?.contractRegion}에서 시행하는 입찰에 참가제한을 받게
              된다.
            </p>

            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              1. 경쟁입찰에 있어서 특정인의 낙찰을 위하여 담합을 주도한 자는{" "}
              {worklist?.contractRegion}에서 발주하는 입찰에 1년이상 2년이하의
              범위내에서 입찰참가자격을 제한한다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              2. 경쟁입찰에 있어서 입찰가격을 서로 상의하여 미리 입찰가격을
              협정하거나 특정인의 낙찰을 위하여 담합한 경우에는{" "}
              {worklist?.contractRegion}가 발주하는 입찰에 6개월이상 1년미만의
              범위내에서 입찰참가 자격을 제한한다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ② 입찰담합 등 불공정행위를 한 경우에는 제1항과 병행하여 독점규제
              및 공정거래에관한법령에 따라 공정거래위원회에 고발 등 조치를
              하는데 일체 이의를 제기하지 않는다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ③ 입찰․계약체결 및 계약이행 과정에서 관계공무원에게 직․간접적으로
              금품, 향응 등의 뇌물이나 부당한 이익을 제공하지 않으며 이를
              위반하였을 때에는 다음 각호의 1에 해당하는 기간동안{" "}
              {worklist?.contractRegion}에서 시행하는 입찰에 참가제한을 받게
              된다.
            </p>

            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              1. 계약체결 또는 그 이행과 관련하여 관계공무원에게 뇌물을
              제공함으로써 그 계약을 체결하였거나, 시공 중 편의를 받아 시공을
              부실하게 한자와 입찰 또는 낙찰자 결정과 관련하여 관계공무원에게
              뇌물을 제공한자는 {worklist?.contractRegion}가 발주하는 입찰에
              6개월이상 1년미만의 범위내 에서 입찰참가자격을 제한한다.
            </p>
          </div>

          <div className="page-doc">
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              2. 뇌물을 주어서 계약조건이 낙찰자에게 유리하게 되도록 계약을
              체결하였거나 그 이행을 부실하게 할 목적을 관계공무원에게 뇌물을
              제공한자(제1호에 해당하는 자를 제외한 자)는{" "}
              {worklist?.contractRegion}가 발주하는 입찰에 1월이상 6개월미만의
              범위내에서 입찰 참가자격을 제한한다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ④ 제1항 내지 제2항의 규정에 의하여 입찰참가자격을 제한하는{" "}
              {worklist?.contractRegion}
              처분을 받은 자는 {worklist?.contractRegion}를 상대로 손해배상을
              청구하거나 배제하는 입찰에 관하여 민․형사상 이의를 제기하지
              않는다.{" "}
            </p>
            <p style={{ height: "30px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제4조(계약해지 등) ①입찰, 계약체결 및 계약이행과 관련하여
              관계공무원에게 뇌물을 제공한 자는 다음 각호의 1에서 정하는 바에
              의하여 당해 계약에 대한 조치를 받는다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              1. 계약체결 전의 경우에는 적격낙찰자 결정대상에서 제외 또는
              결정통보를 취소한다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              2. 계약체결 이후 공사착공 전의 경우에는 당해 계약을 해제한다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              3. 공사착공 이후에는 발주처에서 전체 또는 일부 계약해지 한다.
              다만, 공사성격, 진도(進度), 규모, 공사기간 등을 감안하여
              발주처에서 그러하지 아니할 수 있다.
            </p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              4. 계약상대자는 제1호 내지 제3호의 처리에 대하여 민․형사상 일체
              이의를 제기하지 않는다.
            </p>
            <p style={{ height: "30px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              제5조(기타사항)① 업체의 임․직원(하도급업체 포함)과 대리인이
              관계공무원에게 뇌물을 제공하거나 담합 등 불공정행위를 하지 않도록
              하는 업체윤리강령과 내부비리 제보자에 대하여도 일체의 불이익
              처분을 하지 않는 사규를 제정하도록 적극 노력한다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ② 본건 입찰, 계약체결, 계약이행, 준공과 관련하여{" "}
              {worklist?.contractRegion}에서 시행하는 청렴계약 옴부즈만이
              요구하는 자료제출, 서류열람, 현장확인 등 활동에 적극 협력하여야
              한다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              ③ 본건 관련 하도급계약체결 및 이행에 있어서 하도급자로부터 금품을
              수수하거나 부당 또는 불공정한 행위를 하지 아니한다.
            </p>
          </div>

          <div className="page-doc">
            <p
              className="title"
              style={{ fontSize: "40px", textAlign: "center", height: "100px" }}
            >
              {worklist?.contractRegion} 청렴계약이행서약서
            </p>
            <p className="subTitle">
              󰏚 공사(용역, 물품) 계약명 :{" "}
              <u style={{ wordBreak: "keep-all" }}>
                {worklist?.constructionName}
              </u>
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              당사는 부패없는 투명한 기업경영과 공정한 행정이 지역사회발전과
              국가 경쟁력에 중요한 관건이 됨을 깊이 인식하며, 국제적으로도 OECD
              뇌물방지 협약이 발효되고 부패기업 및 국가에 대한 제재가 강화되고
              있는 시점에서 청렴계약제 시행 취지에 적극 호응하여{" "}
              {worklist?.contractRegion}에서 시행하는 공사(용역, 물품) 입찰에
              참여함에 있어 당사 및 하도급업체의 임직원과 대리인은{" "}
              {worklist?.contractRegion} 청렴계약옴부즈만설치운영규칙과 청렴계약
              이행을 위한 입찰특별유의서 및 특수조건이 정하는 내용을 성실히
              준수할 것을 다짐하며 다음과 같이 서약합니다.
            </p>
            <p style={{ height: "50px" }}>　</p>
            <p style={{ textAlign: "center" }}>다　　　　　　　음</p>
            <p style={{ height: "50px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              1. 이번 입찰에 참여함에 있어 입찰가격이나 특정인의 낙찰을 위하여
              담합등 불공정 행위를 하였을 때에는 청렴계약 이행을 위한
              입찰특별유의서 및 특수조건에서 정하는 기간동안 입찰참가제한을
              감수하겠습니다.{" "}
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              2. 입찰․계약체결 및 계약이행과정에서 관계공무원에게 직․간접적으로
              금품, 향응 등의 뇌물이나 부당한 이익을 제공하지 않겠으며, 이를
              위반시에는 청렴계약 이행을 위한 입찰특별유의서 및 특수조건에서
              정하는 기간동안 입찰참가자격이 제한됨을 감수하겠습니다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              3. 또한 입찰담합등 불공정 행위를 한 사실이 드러날 경우 독점규제 및
              공정거래에 관한 법률이 정하는바에 따라 공정거래에 관한법률이
              정하는 바에따라 공정거래위원회에 우리시가 고발하여 과징금등을
              부과하도록 하는데 어떠한 이의도 제기하지 않겠습니다.
            </p>
          </div>

          <div className="page-doc">
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              4. 입찰, 계약체결 및 계약이행과 관련하여 관계공무원에게 뇌물을
              제공한 사실이 드러날 경우에는 계약체결 이전의 경우에는 낙찰자결정
              취소, 공사 착공전에는 계약취소, 공사착공 이후에는 발주처에서 전체
              또는 일부계약을 해지하여도 감수하고 민․형사상 이의를 제기하지
              않겠습니다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              5. 회사 임․직원이 관계 공무원에게 뇌물을 제공하거나 담합 등 불공정
              행위를 하지 않도록 하는 회사윤리 강령과 내부비리 제보자에 대해서도
              일체의 불이익처분을 하지 않는 사규를 제정토록 노력하겠습니다.{" "}
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              6. 본건 입찰, 계약체결, 계약이행, 준공과 관련하여{" "}
              {worklist?.contractRegion}에서 시행하는 청렴계약 옴부즈만이
              요구하는 자료제출, 서류 열람, 현장확인 등 활동에 적극
              협조하겠습니다.
            </p>
            <p style={{ height: "20px" }}>　</p>
            <p style={{ lineHeight: "25px", letterSpacing: "3px" }}>
              위 청렴계약이행서약은 상호신뢰를 바탕으로 한 약속으로서 반드시
              지킬 것이며, 낙찰자로 결정될 시 본 서약내용을 그대로
              계약특수조건으로 계약하여 이행하고, 입찰참가자격제한, 계약해지등{" "}
              {worklist?.contractRegion}의 조치와 관련하여 당사가{" "}
              {worklist?.contractRegion}를 상대로 손해배상을 청구하거나 당사를
              배제하는 입찰에 관하여 민․형사상 이의를 제기하지 않을 것을
              서약합니다.
            </p>
            <p style={{ height: "60px" }}>　</p>
            <p style={{ textAlign: "center", fontSize: "20px" }}>
              {moment(worklist?.contractDate).format("yyyy년　MM월　DD일")}
            </p>
            <p style={{ height: "40px" }}>　</p>
            <p
              style={{
                textAlign: "right",
                letterSpacing: "2px",
                paddingRight: "130px",
              }}
            >
              (서 약 자)
            </p>
            <p style={{ textAlign: "right" }}>
              회 사 명 :{" "}
              <span style={{ width: "200px", display: "inline-flex" }}>
                {worklist?.companyName}
              </span>
            </p>
            <p style={{ textAlign: "right" }}>
              대 표 자 :{" "}
              <span
                style={{
                  width: "200px",
                  display: "inline-flex",
                  letterSpacing: "5px",
                }}
              >
                {worklist?.representativeName}
              </span>
            </p>
          </div>
        </div>
      );
    } else if (selectDoc[i] === "공사계약특수조건") {
      docArr.push(
        <div>
          <div className="page-doc">
            <p
              className="doc-title"
              style={{ fontSize: "30px", textAlign: "center", height: "70px" }}
            >
              공사계약 특수조건
            </p>
            <p style={{ lineHeight: "20px" }}>
              <strong>제 1조(목적)</strong> 이 공사계약특수조건(이하
              "특수조건"이라 한다)은 {worklist?.contractRegion} 계약담당공무원과
              계약상대자가 체결하는 공사도급계약의 내용을 규정함을 목적으로
              한다.
            </p>
            <p style={{ lineHeight: "20px" }}>
              <strong>제 2조(정의)</strong> ① 이 특수조건에서 사용하는 용어의
              정의는 특수조건에서 달리 정하는 경우를 제외하고는 회계예규
              공사계약일반조건(이하 "일반조건"이라 한다) 제 2조에서 정한 바에
              따른다. <br />② 공사의 착공, 감독, 하도급관리, 대가의 지급, 검사,
              재해방지조치, 인수, 하자관리 등 공사현장에서 계약이행과 관련된
              사항에 대해서는 달리 규정한 경우를 제외하고는{" "}
              {worklist?.contractRegion}장(그 위임을 받은 공무원을 포함한다.
              이하 같다)을 계약담당공무원으로 본다.
            </p>
            <p style={{ lineHeight: "20px" }}>
              <strong>제 3조(수입인지 및 공채의 매입</strong> 계약상대자는
              인지세법 및 제주도지역개발기금설치조례 등에서 정한 바에 따라
              수입인지의 매입 및 제주도지역개발공채(대금청구시 마다 청구금액의
              2.5%)의 매입필증을 계약담당공무원에게 제출하여야 한다.
            </p>
            <p style={{ lineHeight: "20px" }}>
              <strong>제 4조(장기계속계약의 잔여공사 계약)</strong>{" "}
              장기계속계약의 경우 제2차이후 공사계약은 부기한 총공사
              부기금액에서 이미 계약된 금액을 공제한 잔여금액의 범위안에서
              계약을 체결하여야 한다.
            </p>
            <p style={{ lineHeight: "20px" }}>
              <strong>제 5조(공사보험의 가입)</strong> ① 계약상대자는 회계예규
              공사손해보험 가입업무 집행요령(이하 "집행요령"이라 한다) 및
              일반조건 제10조에서 정하는 바에 의하여 계약담당공무원이 선정하는
              보험회사와 건설공사보험 또는 조립보험(이하 "공사보험"이라 한다)에
              가입하여야 한다. <br />
              ② 집행요령 제5조에서 정한 손해의 담보에 대한 계약상대자의
              자기부담금은 매 건당 각각 3천만원이하로 한다. <br />
              ③ 보험약관은 특별히 정한 경우를 제외하고는 독일식 보통약관으로
              한다. <br />
              ④ 보험회사는 공사보험 계약일로부터 1개월이내에 위험도 조사보고서를
              제출하되 보험가입금액이 300억원이상인 경우에는 공정율 50%전후에 기
              제출한 위험도 조사보고서의 내용을 보완하여 제출하여야 하며,
              계약상대자는 보험회사로부터 제출된 위험도 조사보고서에 따른 적절한
              위험방지 조치를 취하여야 한다. <br />
              ⑤ 공사계약금액이 증감된 경우 보험가입금액의 증감은 집행요령
              제5조제4항에 의하며, 계약상대자는 계약금액 변경일로부터 14일이내에
              보험계약을 변경처리하여야 한다. <br />
              ⑥ 계약상대자는 운용요령 제10조제2호에 따라 계약금액의 변경,
              설계변경, 공사중단 등의 공사계약 변경사항을 보험회사에 통지하여야
              한다. <br />
              ⑦ 공사보험대상공사에 대한 공사보험가입 지연으로 발생하는
              보험사고의 보상․배상은 계약상대자 부담으로 하며 보험대상공사의
              준공일까지 공사보험에 가입하지 않는 경우에는 예정가격조서상에
              계상된 보험료를 감액 조치한다. <br />
              ⑧ 계약상대자는 보험사 선정시 당해보험사의 재정상태, 담보등력등을
              감안하여 건실한 보험사를 선정하여야 하며 부실보험사 선정으로 보상
              또는 배상을 받지 못한 경우에는 계약상대자가 부담으로 한다. <br />⑨
              계약상대자는 예정가격조서상에 계상된 보험료와 보험가입시 실제
              납입한 보험료간의 차액발생을 이유로 보험가입을 거절하거나 동
              차액의 정산을 요구할 수 없으며, {worklist?.contractRegion}는
              보험가입 이전에 청구하는 기성대가에 대하여는 지급을 유보할 수
              있다. <br />⑩ 보험회사 및 계약상대자는 보험계약서류, 위험도
              조사보고서, 보험사고 발생 및 처리현황, 보험계약종결보고서를{" "}
              {worklist?.contractRegion}에 제출하여야 하며, 보고 또는 제출을
              태만히 한 경우에는 {worklist?.contractRegion} 입찰․계약과정과
              보험사 선정에 불이익을 부여하여도 이의를 제기할 수 없다.{" "}
            </p>
            <p style={{ lineHeight: "20px" }}>
              <strong>제6조(공사의 착공)</strong> 계약상대자는 계약체결일로부터
              7일이내에 공사를 착공하고 착공계를 제출하여야 한다.
            </p>
          </div>

          <div className="page-doc">
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제7조(선금의 지급 및 반환)</strong> ① 선금급을 지급 요청할
              때는 지방자치단체 선금지급요령에 의거 증권 또는 보증서 및 선금급
              사용계획서와 선금급율에 의거 하도급 대금을 현금으로 지급하고 이를
              이행하지 않을 경우에는 {worklist?.contractRegion}의 어떠한
              조치에도 이의를 제기하지 않겠다는 각서를 제출하여야 한다. <br />②
              선금급 수령 후 하도급 계약체결 통보시 하수급인에 대한 선금급
              지급증빙서(세금계산서 등)를 첨부하여야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제8조(기성부분 지급)</strong> 공사 착공후 준공시 까지
              기성부분 검사를 필하고 기성부분에 대한 대가를 지급할 수 있다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제9조(하수급인의 선정)</strong> 계약상대자는
              건설산업기본법령등의 규정에 의한 공사의 하도급을 시행하고자 할
              때에는 제주도내에 주된 영업소를 둔 전문건설업자에게 하도급 계약을
              하여야 한다. 다만, 제주도내에 해당업종의 전문건설업자가 없을
              경우에는 그러하지 아니하다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제10조(하도급 대금의 지급보증 등)</strong> 계약상대자는
              건설산업기본법령 및 공사계약일반조건의 규정에 의한 하도급 계약
              체결시에 하수급인에 대하여 하도급 대금의 지급보증을 이행하여야
              하며, 하도급 승낙요청 또는 통지시에 계약상대자는 계약담당무원에게
              하도급대금 지급을 보증하는 문서를 제출하여야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제11조(노임지급)</strong> 계약담당공무원이 원활한
              계약이행을 위하여 필요하다고 인정할 경우에는 공사현장에 종사하는
              근로자의 노임지급과 관련하여 필요한 지시를 할 수 있으며, 이에
              대하여 계약상대자가 이행하지 아니할 경우 계약담당공무원은 당해
              공사대금에서 노임을 공제하여 근로자에게 지불할 수 있다. 다만,
              현장근로자의 노임을 직접 공제 해서는 아니되는 정당한 사유가 있음을
              계약상대자가 증명한 경우에는 그러하지 아니하다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제12조(환경오염 방지등)</strong> ① 계약상대자는 대기오염,
              수질오염, 소음, 진동, 악취등으로 환경피해 발생 및 인근 주민이나
              통행인에게 불편이 없도록 대기환경보전법, 수질환경보전법,
              소음․진동규제법, 폐기물관리법, 오수․염물질 종류와 예상량,
              처리방법, 처리시행자등이 포함된 환경오염방지계획을 수립하여야
              한다. <br />
              ② 계약상대자는 공사착공신고서 제출시 제1항의 환경오염방지계획서를
              계약담당공무원에게 제출하여 승인을 받아야 하며, 계약담당공무원이
              보완을 요청하였을 때에는 이에 따라야 한다. <br />③ 계약상대자는
              환경오염방지계획서를 현장에 비치하고, 오염방지 이행실적을 기록
              유지하여야 하며, 계약담당공무원의 환경오염방지계획 및 이행실적
              제출 요청시에는 이에 따라야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제13조(적격심사 관련사항의 준수의무)</strong> ①
              지방자치단체 시설공사적격심사 세부기준에 따라 제출한 다음사항은
              일반조건 제61조에 의한다. 다만, 적격심사시 제출한 서류중 부적정
              판정 또는 관계 법령에 적합하지 아니한 사항에 대하여는 착공시까지{" "}
              {worklist?.contractRegion}장에게 수정 제출하여 승인을 받아야 한다.{" "}
              <br />
              1. 현장관리계획의 적정성 평가를 위하여 제출한 현장인력관리계획{" "}
              <br />
              2. 공사관리계획의 적정성 평가를 위하여 제출한
              공정․품질․안전․하도급관리계획 및 환경보전계획 <br />② 계약상대자가
              시공중 불가피한 사유로 제1항 각호의 계획을 변경하고자 할 경우에는
              당초의 내용과 비교하여 동등이상의 범위내에서{" "}
              {worklist?.contractRegion}장의 사전 승인을 받아야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>
                제14조(안전관리비 및 건설근로자퇴직공제부담의 목적외 사용금지
                등)
              </strong>{" "}
              ① 계약상대자가 계약금액에 포함된 안전관리비 및
              건설근로자퇴직공제부금을 산업안전보건관계법령 또는
              건설산업기본법령에서 정한 목적 이외에 사용하거나 상기 목적에
              사용하지 아니한 경우 그 금액에 대하여는 계약금액에서 감액조정한다.{" "}
              <br />
              ② 계약상대자는 제1항에서 정한 안전관리비를 목적외에 사용하지
              않았음을 이유로 안전관리를 소홀히 하여서는 아니된다. <br />
              ③ 계약상대자가 산업안전보건관계법령등에 의한 재해예방 기술지도중
              일반기술지도를 받지 아니한 경우에는 안전관리비의 40%에 해당하는
              금액을, 전문기술지도를 받지 아니한 경우에는 안전관리비의 30%에
              해당하는 금액을 계약대금에서 감액한다. 다만, 다음의 각호의
              경우에는 그러하지 아니한다. <br />
              1. 공사기간이 3월미만의 건설공사{" "}
            </p>
          </div>

          <div className="page-doc">
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              2. 육지와 연결되지 아니한 도서지역(제주도를 제외한다)에서
              행하여지는 공사 <br />
              3. 법 제48조 제3항의 규정에 의한 유해․위험방지계획서를 제출대상{" "}
              <br />④ 계약상대자가 산업안전보건법령 등에 의하여 기술지도계약을
              체결하지 아니한 경우에는 법30조 제1항의 규정에 의하여 계상한
              안전관리비의 20%에 해당하는 금액을 감액하며, 기술지도계약을
              지연체결하여 수수료가 조정된 경우에는 조정된 금액만큼 안전관리비를
              감액한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제15조(공사감독관의 지시)</strong> 계약상대자는
              공사감독관의 구두지시가 있을 경우(지시내용의 이행 전후에 관계없이)
              이를 지체없이 서면으로 확인하여야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제16조(계약상대자에 의한 도면)</strong> ① 공사 일부분의
              시공상세도면을 계약상대자가 작성토록 설계서에 명시된 경우
              계약상대자는 필요한 시공상세도면․계산서 등을 작성 제출하여 당해
              공종의 착공전까지 공사감독관의 확인을 받아야 한다. <br />
              ② 계약상대자는 제1항의 시공상세도를 제출한 후 공사감독관의 이에
              대한 서면승인을 받기 이전까지 당해 공종을 착수해서는 아니된다.
              시공상세도에 대한 공사감독관의 승인을 제1항에 따른 계약상대자의
              책임을 감면시키지 아니한다. <br />③ 공사감독관은 공사전․공사중
              또는 준공여부와 관계없이 계약상대자가 제공한 시공상세도에서의
              결함을 발견한 경우에는 그의 시공상세도를 변경․수정토록 하고 그에
              따라 시공토록 지시할 수 있다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제17조(인접공사 계약자에 대한 협조)</strong> ①
              계약상대자는 {worklist?.contractRegion}의 요구가 있을 경우 본
              공사현장내 또는 인접 공사현장(이하 “인접공사”라 한다)에서 다음
              각호의 자가 행하는 그들의 공사 이행에 필요한 모든 편의를 제공하야
              한다. <br />
              1. {worklist?.contractRegion}와 계약된 타 계약자 및 그들의 고용인{" "}
              <br />
              2. 인접 공사현장의 시공자 <br />② 본 계약의 이행에 있어 공사 전체
              또는 일부분이 인접공사 계약자에 의한 공사의 적절한 시행 또는 그
              결과에 의존할 때에는, 계약상대자는 본 계약의 이행에 악영향을 끼칠
              수 있는 명백한 위반 또는 결함을 조사하여 즉시 서면으로
              공사감독관에게 통보하여야 한다. 계약상대자가 이러한 사항을
              공사감독관에게 통보하지 아니한 경우 계약상대자는 인접공사 계약자에
              의한 상기 작업이 본 공사를 진행하는데 적합함을 인정하는 것으로
              본다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제18조(하자담보)</strong> 일반조건 제39조에서 정한
              하자담보책임기간은 준공검사를 완료한 날로부터 계약서에 정한 바에
              따른다. 다만, 공종별 하자담보책임기간은 시행규칙 제68조에서 정한
              바에 따른다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제19조(하자보수 책임승계 등)</strong> ① 전 계약상대자가
              이행한 공사를 계속하여 시공하는 계약상대자 또는 그 연대보증인은 그
              자신의 귀책사유로 인하여 하자가 발생하지 아니하였다는 것을
              증명하지 못하는한 전 계약상대자에 의하여 이행된 부분에 보수의
              책임을 진다. 다만, 하자책임 구분이 분명한 경우는 그러하지
              아니한다. <br />
              ② 일반조건 제41조에서 정한 하자보수보증금은 계약서에 정한 바에
              따른다. 다만, 공종별 하자보수보증금율은 시행규칙 제70조에서 정한
              바에 따른다. <br />③ 계약상대자는 하자보수 보증기간 계류중에는
              6개월마다 1회씩 정기적으로 하자발생여부를 순회점검 확인하고 하자가
              발생하였을 경우에는 {worklist?.contractRegion}에 서면 보고하여야
              하며, {worklist?.contractRegion}의 지시에 따라 지체없이 하자
              보수를 하여야 한다.
            </p>
            <p style={{ lineHeight: "20px" }}>
              <strong>제20조(공사관리)</strong> ① 계약담당공무원은 계약관리를
              위하여 필요하다고 인정할 경우에는 공사현장에 대하여 다음 각호의
              사항을 조사․점검하거나 계약상대자에게 자료를 요구할 수 있다.{" "}
              <br />
              1. 시공상태 <br />
              2. 안전관리 상태 <br />
              3. 설계변경 등 계약내용 변경에 관한 사항 <br />
              4. 공사현장 관리상태 <br />
              5. 하도급에 관한 사항 <br />
              6. 기타 계약조건 이행사항
            </p>
          </div>

          <div className="page-doc">
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              ② 계약담당공무원은 제1항 의한 조사․점검 결과 계약상대자가 계약조건
              및 설계서 등의 계약내용과 다르게 시공한 사항에 대하여는
              계약상대자에게 통보하여 시정토록 조치할 수 있다. <br />③
              계약담당공무원은 제1항 또는 제2항의 결과에 대하여 시행령 제23조 및
              제41조의 규정에 의한 심사에 반영할 수 있다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제21조(영세민 우선고용)</strong> 계약상대자는 취로증
              소지자를 우선적으로 공사 현장에 고용하여야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제22조(감액 또는 환불)</strong> 계약상대자는 본 계약을
              위하여 설계서 내역중 정부가 정한 건설표준품셈, 물품단가 또는
              노임단가 기준보다 과다하게 책정되었거나 제잡비율 적용에 착오가
              있을 경우 계약체결 및 준공 후라도 발견되어 정부 또는 감사기관 및{" "}
              {worklist?.contractRegion}로부터 감액 또는 환불 요구가 있을 때에는
              이의없이 수락하여야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제23조(법령의 준수 등)</strong> ① 계약상대자는 각종 법령,
              조례, 규칙 또는 공사 수행과 관련하여 공공기관에 의해 요구되는
              사항을 준수하여야 하며, 법령 및 계약내용등이 상호 일치되지 않거나
              모순으로 계약이행에 어려움이 예상될 경우 계약상대자는 지체없이
              계약담당공무원에게 이를 서면으로 확인하여야 한다. <br />
              ② 계약상대자는 여하한 경우에도 이 계약체결․이행함에 있어서
              관련공무원에게 금품 또는 향응을 제공하여서는 아니된다. <br />③
              제2항의 규정을 위반함으로써 받게 되는 모든 불이익에 대한 책임은
              계약상대자에게 있다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제24조(공사안전관리)</strong> 계약상대자는 안전관리자를
              현장에 상주시켜 공사시 발생되는 제반위험상태를 사전에 방지하도록
              하며, 공사 현장 표지판에 안전관리 책임자의 성명을 명시하여야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제25조(과적운행금지)</strong> 공사현장 건설자재 등
              운송하는 모든 차량 및 중기는 과적 운행을 해서는 아니된다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제26조(계약의 해지․해제사유로 인한 보증시공 청구)</strong>{" "}
              ① 일반조건 제51조 제1항 각호의 사유로 인하여 계약을 해지․해제할 수
              있을 경우에는 연대보증인 또는 공사이행보증회사에게 보증시공을
              청구하거나 공동수급체구성원에게 잔여공사를 완성하게 할 수 있다.{" "}
              <br />② 계약상대자(공동수급체 구성원을 포함한다. 이하 같다)의
              부도발생으로 인하여 준공기한까지 공사를 완성할 가능성이 없다고
              인정될 경우에는 제1항을 준용한다. 이 때에 계약상대자의 동의를
              필요요건으로 하지 않을 수 있다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제27조(피해보상)</strong> 공사목적물의 인도전에 발생한
              손해가 {worklist?.contractRegion}의 책임이 아닌 사유로 인한 것은
              모두 이를 계약상대자의 부담으로 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제28조(보칙)</strong> ① 회계예규 공동도급계약운용요령에
              의거 공동수급체가 제출한 공동수급협정서는 계약담당공무원의 사전
              승인 없이는 변경할 수 없다. <br />
              ② 계약이행과 관련한 기간의 계산은 특별한 규정이 없는 한 민법에서
              정한 바에 따른다. <br />
              ③ 일반조건 제28조에 의하여 물가변동에 의한 계약금액을 조정할
              경우에는 특별한 사유가 없는 한 지수조정율을 적용한다. <br />
              ④ 계약상대자는 계약서에 명시된 주소가 변경되었을 때에는 즉시
              계약담당공무원 및 공사감독관에게 서면으로 주소변경 신고를 하여야
              하며, 주소변경 신고를 하지 아니함으로써 발생하는 불이익은
              계약상대자에게 귀속된다. <br />⑤ 계약상대자의 전화․팩스번호 등
              의사전달 수단의 변경시에도 제4항을 준용한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>
                제29조(공사 시공 관리대장 작성 및 통보.의무이행 등)
              </strong>{" "}
              계약상대자는 시공관리 대장을 작성하여 착공일로부터 30일이내에
              계약담당공무원에게 통보하여야 한다.
            </p>
            <p style={{ lineHeight: "20px", marginBottom: "10px" }}>
              <strong>제30조(조문의 해석)</strong> 조문의 해석에 의문이 있을
              경우에는 계약담당공무원의 해석에 따른다.
            </p>
          </div>
        </div>
      );
    } else if (selectDoc[i] === "계약붙임서류생략서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table style={{ border: "hidden", tableLayout: "fixed" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  className="doc-title"
                  style={{
                    fontSize: "40px",
                    textAlign: "center",
                    height: "150px",
                  }}
                  colspan="4"
                >
                  공사계약 붙임서류 생략서
                </td>
              </tr>

              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    borderRight: "hidden",
                    textAlign: "center",
                    height: "30px",
                    fontSize: "20px",
                  }}
                >
                  □ 계약건명 :
                </td>
                <td
                  style={{ fontSize: "20px", wordBreak: "keep-all" }}
                  colspan="3"
                >
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{
                    fontSize: "20px",
                    height: "155px",
                    textIndent: "5px",
                  }}
                >
                  지방자치를 당사자로 하는 계약에 관한 법률 시행령 제 49조 및
                  동법 시행규칙 제 47조의 규정에 의거 계약서 작성시 필요한
                  공사도급표준계약서의 붙임 서류중 다음 서류는 이미 공표되어{" "}
                  <u>그 내용을 충분히 인지 및 인정</u>하고 있으므로 서류 감축을
                  위해 생략하오며 이에 본 생략서를 제출합니다.
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colspan="4" style={{ textAlign: "center" }}>
                  다　　　　　음
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ lineHeight: "30px" }} colspan="4">
                  □ 생략하는 붙임서류
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ lineHeight: "30px" }} colspan="4">
                  　① 지방자치단체 입찰 및 계약집행기준(행정안전부 회계예규)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ lineHeight: "30px" }} colspan="4">
                  　　- 회계예규 제 34호(2013.11.20)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ lineHeight: "30px" }} colspan="4">
                  　② 공사계약일반조건(행정안전부 회계예규)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ lineHeight: "30px" }} colspan="4">
                  　　- 회계예규 제 330호(2010.10.26)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "20px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td
                  colspan="4"
                  style={{ textAlign: "center", fontSize: "15px" }}
                >
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "20px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "40px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td style={{ fontSize: "20px" }} colSpan="4">{`${
                  !worklist?.dear ? "" : worklist?.dear
                } 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  for (var i = 0; i < Object.keys(selectDoc1).length; i++) {
    //착공서류
    if (selectDoc1[i] === "착공계") {
      docArr.push(
        <div className="page-doc">
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr>
              <td
                colspan="4"
                style={{
                  fontSize: "50px",
                  textAlign: "center",
                  height: "150px",
                  borderBottom: "hidden",
                  fontWeight: "600",
                }}
              >
                착　　공　　계
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td style={{ borderRight: "hidden" }}></td>
              <td style={{ borderRight: "hidden" }}></td>
              <td style={{ borderRight: "hidden" }}>공사감독자경유 : </td>
              <td style={{ textAlign: "right" }}>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "20px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                1.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>공</span>
                  <span>사</span>
                  <span>명</span>
                </span>
                　:
              </td>
              <td style={{ wordBreak: "keep-all" }} colspan="3">
                {worklist?.constructionName}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                2.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>공</span>
                  <span>사</span>
                  <span>위</span>
                  <span>치</span>
                </span>
                　:
              </td>
              <td colspan="3">{worklist?.constructionAddress}</td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                3.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>약</span>
                  <span>금</span>
                  <span>액</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                4.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>약</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.contractDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? ""
                  : moment(worklist?.contractDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                5.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>착</span>
                  <span>공</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? ""
                  : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                6.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>준</span>
                  <span>공</span>
                  <span>예</span>
                  <span>정</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.completionExpectDate).format(
                  "yyyy년　MM월　DD일"
                ) === "Invalid date"
                  ? ""
                  : moment(worklist?.completionExpectDate).format(
                      "yyyy년　MM월　DD일"
                    )}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "25px", textAlign: "center" }}>
                위와 같이 착공하였기에 착공신고서를 제출합니다.
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "50px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td
                rowspan="2"
                style={{
                  borderRight: "hidden",
                  textAlign: "center",
                  height: "30px",
                  verticalAlign: "top",
                }}
              >
                붙임
              </td>
              <td colspan="3">1. 현장대리인 및 관리인 선임계 1부.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colspan="3">2. 예정공정표 1부.</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "15px", textAlign: "center" }}>
                {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? ""
                  : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info">
                <span className="three-string">상호명</span>:　
                <span>{worklist?.companyName}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">주소</span>:　
                <span
                  style={{
                    display: "inline-flex",
                    width: "190px",
                    wordBreak: "keep-all",
                  }}
                >
                  {worklist?.businessAddress}
                </span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">전화</span>:　
                <span>{worklist?.phoneNum}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">팩스</span>:　
                <span>{worklist?.faxNum}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="three-string">대표자</span>:　
                <span style={{ letterSpacing: "10px" }}>
                  {worklist?.representativeName}
                </span>
                (인)
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td
                style={{ fontSize: "20px", textAlign: "left" }}
                colSpan="4"
              >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      );
    } else if (selectDoc1[i] === "예정공정표") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table
              className="bbb"
              style={{ tableLayout: "fixed", border: "hidden" }}
            >
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    height: "150px",
                    fontWeight: "600",
                  }}
                >
                  공 사 예 정 공 정 표
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  1.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>사</span>
                    <span>명</span>
                  </span>
                  　:
                </td>
                <td style={{ wordBreak: "keep-all" }} colspan="3">
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  2.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>사</span>
                    <span>위</span>
                    <span>치</span>
                  </span>
                  　:
                </td>
                <td colspan="3">{worklist?.constructionAddress}</td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  3.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  4.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  5.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>착</span>
                    <span>공</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                  "Invalid date"
                    ? ""
                    : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  6.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>준</span>
                    <span>공</span>
                    <span>예</span>
                    <span>정</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.completionExpectDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? ""
                    : moment(worklist?.completionExpectDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  7.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>첨</span>
                    <span>부</span>
                    <span>서</span>
                    <span>류</span>
                  </span>
                  　:
                </td>
                <td colspan="3">공정표</td>
              </tr>
              <tr>
                <td colspan="4" style={{ height: "30px" }}>
                  　
                </td>
              </tr>
            </table>

            <table
              className="bbb"
              style={{ tableLayout: "fixed", border: "hidden" }}
            >
              <tr style={{ borderBottom: "hidden", height: "70px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ fontSize: "25px", textAlign: "center" }}
                >
                  위와 같이 공사예정공정표를 제출합니다.
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "70px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ fontSize: "15px", textAlign: "center" }}
                >
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                  "Invalid date"
                    ? ""
                    : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">전화</span>:　
                  <span>{worklist?.phoneNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">팩스</span>:　
                  <span>{worklist?.faxNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc1[i] === "현장대리인계") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table
              className="bbb"
              style={{ tableLayout: "fixed", border: "hidden" }}
            >
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    height: "150px",
                    fontWeight: "600",
                  }}
                >
                  현 장 대 리 인 계
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td colspan="4">　</td>
              </tr>
              <tr
                style={{
                  lineHeight: "30px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  1.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>사</span>
                    <span>명</span>
                  </span>
                  　:
                </td>
                <td style={{ wordBreak: "keep-all" }} colspan="3">
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr
                style={{
                  lineHeight: "30px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  2.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>사</span>
                    <span>위</span>
                    <span>치</span>
                  </span>
                  　:
                </td>
                <td colspan="3">{worklist?.constructionAddress}</td>
              </tr>
              <tr
                style={{
                  lineHeight: "30px",
                  borderBottom: "hidden",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  3.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td style={{ borderRight: "hidden" }}>
                  　○
                  <span
                    style={{
                      marginLeft: "10px",
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>현</span>
                    <span>장</span>
                    <span>대</span>
                    <span>리</span>
                    <span>인</span>
                  </span>
                </td>
                <td colspan="3">　</td>
              </tr>

              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  <span
                    style={{
                      marginLeft: "40px",
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>주</span>
                    <span>소</span>
                  </span>
                  　:
                </td>
                <td colspan="3">{worklist?.deputyAddress}</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  <span
                    style={{
                      marginLeft: "40px",
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>성</span>
                    <span>명</span>
                  </span>
                  　:
                </td>
                <td colspan="3" style={{ letterSpacing: "10px" }}>
                  {worklist?.deputyName}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  <span
                    style={{
                      marginLeft: "40px",
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>주</span>
                    <span>민</span>
                    <span>등</span>
                    <span>록</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                  　:
                </td>
                <td colspan="3">{worklist?.deputyResidentNum}</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ lineHeight: "30px", borderBottom: "hidden" }}>
                <td colspan="4" style={{ height: "30px" }}>
                  ○ 기술분야 자격
                </td>
              </tr>
            </table>

            <table
              className="aaa"
              border="1"
              style={{ tableLayout: "fixed", textAlign: "center" }}
            >
              <tr>
                <td rowspan="2">종　　목</td>
                <td rowspan="2">등　　급</td>
                <td rowspan="2">등 록 번 호</td>
                <td>취 득 연 월 일</td>
              </tr>
              <tr>
                <td>갱 신 연 월 일</td>
              </tr>
              <tr>
                <td rowspan="2">{worklist?.deputyEvent}</td>
                <td rowspan="2">{worklist?.deputyGrade}</td>
                <td rowspan="2">{worklist?.deputyRegistNum}</td>
                <td>
                  {moment(worklist?.deputyGetDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.deputyGetDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr>
                <td>
                  {moment(worklist?.deputyRenewDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.deputyRenewDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
            </table>

            <table
              className="bbb"
              style={{ tableLayout: "fixed", border: "hidden" }}
            >
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ fontSize: "20px", textAlign: "center" }}
                >
                  위와 같이 현장대리인을 선정하였기 현장대리인 선임계를
                  제출합니다.
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colspan="4">　</td>
              </tr>

              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ fontSize: "15px", textAlign: "center" }}
                >
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">전화</span>:　
                  <span>{worklist?.phoneNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">팩스</span>:　
                  <span>{worklist?.faxNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colspan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc1[i] === "재직증명원") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table style={{ tableLayout: "fixed", textAlign: "center" }}>
              <tr>
                <td
                  className="doc-title"
                  colspan="9"
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    height: "200px",
                  }}
                >
                  재 직 증 명 원
                </td>
              </tr>
              <tr>
                <td style={{ height: "30px" }}>
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>주</span>
                    <span>소</span>
                  </span>
                </td>
                <td colspan="8">{worklist?.deputyAddress}</td>
              </tr>
              <tr>
                <td style={{ height: "30px" }}>
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>생</span>
                    <span>년</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                </td>
                <td colspan="4">{birth}</td>
                <td rowspan="2" colspan="2">
                  주 민 등 록 번 호
                </td>
                <td rowspan="2" colspan="2">
                  {worklist?.deputyResidentNum}
                </td>
              </tr>
              <tr>
                <td style={{ height: "30px" }}>
                  <span
                    style={{
                      width: "60px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>성</span>
                    <span>명</span>
                  </span>
                </td>
                <td colspan="4" style={{ letterSpacing: "10px" }}>
                  {worklist?.deputyName}
                </td>
              </tr>
              <tr>
                <td colspan="9" style={{ height: "30px" }}>
                  자　격　종　목　　및　　등　급
                </td>
              </tr>
              <tr style={{ height: "30px" }}>
                <td rowspan="2" colSpan="2">
                  종　　　목
                </td>
                <td rowspan="2">　등　급　</td>
                <td rowspan="2" colSpan="2">
                  등　록　번　호
                </td>
                <td colspan="4">취　득　연　월　일</td>
              </tr>
              <tr>
                <td colspan="4" style={{ height: "30px" }}>
                  갱　신　연　월　일
                </td>
              </tr>
              <tr style={{ height: "30px" }}>
                <td rowspan="2" colspan="2">
                  {worklist?.deputyEvent}
                </td>
                <td rowspan="2">{worklist?.deputyGrade}</td>
                <td rowspan="2" colSpan="2">
                  {worklist?.deputyRegistNum}
                </td>
                <td colspan="4">
                  {moment(worklist?.deputyGetDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.deputyGetDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ height: "30px" }}>
                <td colspan="4">
                  {moment(worklist?.deputyRenewDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.deputyRenewDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr>
                <td colspan="9" style={{ height: "30px" }}>
                  재　직　사　항
                </td>
              </tr>
              <tr style={{ height: "30px" }}>
                <td colspan="4" style={{ width: "300px" }}>
                  기　　　　　　　　간
                </td>
                <td colspan="2">근　무　처</td>
                <td colspan="2">직　　　위</td>
                <td>비고</td>
              </tr>
              <tr style={{ height: "30px" }}>
                <td
                  colspan="4"
                  style={{
                    letterSpacing: "0.3px",
                    fontSize: "13px",
                    textAlign: "left",
                    paddingLeft: "5px",
                  }}
                >
                  {moment(worklist?.deputyJoinDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.deputyJoinDate).format(
                        "yyyy년 MM월 DD일"
                      )}
                  {`부터 ${moment(Date()).format("yyyy년 MM월")} 현재까지`}
                </td>
                <td colSpan="2">{worklist?.deputyWorkPlace}</td>
                <td colSpan="2">{worklist?.deputyPosition}</td>
                <td>　</td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "40px",
                  borderRight: "hidden",
                  borderLeft: "hidden",
                }}
              >
                <td colspan="9">　</td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  borderRight: "hidden",
                  borderLeft: "hidden",
                }}
              >
                <td
                  colspan="9"
                  style={{ fontSize: "20px", textAlign: "center" }}
                >
                  위와 같이 현재 당사에 재직하고 있음을 증명합니다.
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "40px",
                  borderRight: "hidden",
                  borderLeft: "hidden",
                }}
              >
                <td colspan="9">　</td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  borderRight: "hidden",
                  borderLeft: "hidden",
                }}
              >
                <td
                  colspan="9"
                  style={{ fontSize: "15px", textAlign: "center" }}
                >
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "40px",
                  borderRight: "hidden",
                  borderLeft: "hidden",
                }}
              >
                <td colspan="9">　</td>
              </tr>
            </table>
            <table style={{ tableLayout: "fixed" }}>
              <tr style={{ height: "30px", border: "hidden" }}>
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">전화</span>:　
                  <span>{worklist?.phoneNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">팩스</span>:　
                  <span>{worklist?.faxNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  borderRight: "hidden",
                  borderLeft: "hidden",
                  height: "40px",
                }}
              >
                <td colspan="4">　</td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  borderRight: "hidden",
                  borderLeft: "hidden",
                }}
              >
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc1[i] === "안전관리비 항목별 사용계획") {
      docArr.push(
        <div>
          <div className={styles.beginSafetyContainer}>
            <div className="page-doc">
              <div className={styles.title}>항목별 사용계획</div>
              <div
                style={{
                  height: "20px",
                  lineHeight: "20px",
                  textAlign: "right",
                  fontSize: "15px",
                }}
              >
                {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? "　"
                  : moment(worklist?.beginDate).format("yyyy년　MM월")}
              </div>
              <div className={styles.table_header}>
                <div className={styles.item_name}>항목</div>
                <div className={styles.usage_date}>사용일자</div>
                <div className={styles.usage_plan_items}>사용계획</div>
                <div className={styles.usage_item_price}>금액</div>
              </div>
              <div>
                {worklist?.safetyManagePlan.usagePlanItems.map(
                  (item, itemIndex) => {
                    return (
                      <div
                        className={styles.table_header}
                        style={{ borderTop: "hidden" }}
                      >
                        <div className={styles.item_name}>
                          <div className={styles.item_num_name}>
                            {itemIndex + 1}.{" "}
                            <span
                              style={{ display: "inline-flex", width: "120px" }}
                            >
                              {item.itemName}
                            </span>
                          </div>
                        </div>
                        <div className={styles.usage_date}>
                          <div style={{ borderBottom: "1px solid" }}>소계</div>
                          {Array.from(
                            { length: Number(item.usageItems?.length) },
                            (_, usageIndex) => {
                              return (
                                <div className={styles.input_box}>
                                  <input
                                    className={styles.date_input}
                                    defaultValue={
                                      item?.usageItems?.[
                                        usageIndex
                                      ].usageDate.toString() === "2021-01-01"
                                        ? ""
                                        : item?.usageItems?.[
                                            usageIndex
                                          ].usageDate.toString()
                                    }
                                    placeholder="2021-01-01"
                                    name="usageDate"
                                    //defaultValue={item1}
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className={styles.usage_plan_items}>
                          <div style={{ borderBottom: "1px solid" }}>　</div>
                          {Array.from(
                            { length: Number(item.usageItems?.length) },
                            (_, usageIndex) => {
                              return (
                                <div style={{ display: "flex" }}>
                                  <div
                                    className={styles.input_box}
                                    style={{ width: "40%" }}
                                  >
                                    <input
                                      className={styles.plan_item_name_input}
                                      defaultValue={
                                        item?.usageItems?.[usageIndex]
                                          .productName
                                      }
                                      placeholder="품명"
                                      name="productName"
                                    />
                                  </div>
                                  <div style={{ width: "10%" }}></div>
                                  <div
                                    className={styles.input_box}
                                    style={{ width: "15%" }}
                                  >
                                    <input
                                      className={styles.plan_item_num_input}
                                      defaultValue={
                                        item?.usageItems?.[usageIndex]
                                          .productNum === 0
                                          ? ""
                                          : item?.usageItems?.[usageIndex]
                                              .productNum
                                      }
                                      placeholder="수량"
                                      name="productNum"
                                    />
                                  </div>
                                  <div style={{ width: "5%" }}>
                                    {item?.usageItems?.[usageIndex]
                                      .productNum === 0 ||
                                    item?.usageItems?.[
                                      usageIndex
                                    ].productNum.toString() === ""
                                      ? ""
                                      : "x"}
                                  </div>
                                  <div
                                    className={styles.input_box}
                                    style={{ width: "30%" }}
                                  >
                                    <input
                                      className={styles.plan_item_price_input}
                                      //defaultValue={item?.usageItems?.[usageIndex].productPrice === 0 ? '' : Number(item?.usageItems?.[usageIndex].productPrice).toLocaleString()}
                                      value={
                                        item?.usageItems?.[usageIndex]
                                          .commaProductPrice === "0"
                                          ? ""
                                          : item?.usageItems?.[usageIndex]
                                              .commaProductPrice
                                      }
                                      placeholder="단가"
                                      name="productPrice"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className={styles.usage_item_price}>
                          <div style={{ borderBottom: "1px solid" }}>
                            {item.totalPrice.toLocaleString() === "0"
                              ? "　"
                              : item.totalPrice.toLocaleString()}
                          </div>
                          {Array.from(
                            { length: Number(item.usageItems?.length) },
                            (_, usageIndex) => {
                              return (
                                <div style={{ height: "20px" }}>
                                  {Number(
                                    item?.usageItems?.[usageIndex].calPrice
                                  ) === 0 ||
                                  item?.usageItems?.[
                                    usageIndex
                                  ].calPrice.toString() === ""
                                    ? ""
                                    : Number(
                                        item?.usageItems?.[usageIndex].calPrice
                                      ).toLocaleString()}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className={styles.comment}>
                ※ 주 : 사용계획은 항목별 사용일자가 빠른 순서로 작성
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectDoc1[i] === "산업안전보건관리비 사용계획서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <div
              style={{
                fontSize: "40px",
                textAlign: "center",
                borderBottom: "hidden",
                fontWeight: "600",
              }}
            >
              산업안전보건관리비 사용계획서
            </div>
            <div
              style={{
                height: "50px",
                lineHeight: "50px",
                textAlign: "right",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.beginDate).format("yyyy년　MM월")}
            </div>
            <table style={{ textAlign: "center" }}>
              <tr style={{ height: "40px" }}>
                <td style={{ width: "100px", letterSpacing: "2px" }}>
                  건설업체명
                </td>
                <td style={{ width: "220px" }}>{worklist?.companyName}</td>
                <td
                  style={{
                    width: "100px",
                    letterSpacing: "16px",
                    paddingLeft: "12px",
                  }}
                >
                  공사명
                </td>
                <td style={{ width: "220px", wordBreak: "keep-all" }}>
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  소재지
                </td>
                <td style={{ wordBreak: "keep-all" }}>
                  {worklist?.businessAddress}
                </td>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  대표자
                </td>
                <td style={{ letterSpacing: "5px" }}>
                  {worklist?.representativeName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사금액
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  ￦ {props.commaContractPrice}　원
                </td>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사기간
                </td>
                <td>
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                    "Invalid date" &&
                  moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : `${moment(worklist?.beginDate).format(
                        "yyyy.MM.DD"
                      )} ~ ${moment(worklist?.completionDate).format(
                        "yyyy.MM.DD"
                      )}`}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  발주처
                </td>
                <td>{worklist?.orderOrganization}</td>
                <td style={{ letterSpacing: "2px" }}>누계공정율</td>
                <td>100%</td>
              </tr>
              <tr style={{ height: "40px", borderBottom: "hidden" }}>
                <td>
                  <div style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                    계상된
                  </div>
                  <div style={{ letterSpacing: "2px" }}>안전관리비</div>
                </td>
                <td colSpan="3">{`${Number(
                  worklist.expensedSafetyPlanCost
                ).toLocaleString()} 원`}</td>
              </tr>
            </table>
            <table style={{ tableLayout: "fixed" }}>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td colSpan="4" style={{ letterSpacing: "30px" }}>
                  사용금액
                </td>
              </tr>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td
                  colSpan="2"
                  style={{ letterSpacing: "30px", paddingLeft: "30px" }}
                >
                  항목
                </td>
                <td>
                  (
                  {moment(worklist?.beginDate).format("yyyy년 MM월 DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.beginDate).format("M")}
                  ) 월 사용금액
                </td>
                <td>누계사용금액</td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ textAlign: "center" }}>
                  계
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyPlanTotalPrice === 0
                    ? "-"
                    : safetyPlanTotalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyPlanTotalPrice === 0
                    ? "-"
                    : safetyPlanTotalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  1. 안전관리자 등 인건비 및 각종 업무수당 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[0].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[0].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[0].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[0].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  2. 안전시설비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[1].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[1].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[1].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[1].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  3. 개인보호구 및 안전장구 구입비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[2].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[2].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[2].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[2].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  4. 안전진단비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[3].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[3].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[3].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[3].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  5. 안전보건교육비 및 행사비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[4].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[4].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[4].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[4].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  6. 근로자 건강관리비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[5].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[5].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[5].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[5].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  7. 건설재해예방 기술지도비
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[6].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[6].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[6].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[6].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  8. 본사사용비
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[7].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[7].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManagePlan[7].totalPrice === 0
                    ? "-"
                    : safetyManagePlan[7].totalPrice.toLocaleString()}
                </td>
              </tr>
            </table>
            <div
              style={{
                height: "60px",
                wordBreak: "keep-all",
                marginTop: "20px",
                marginLeft: "45px",
              }}
            >
              「 건설업 산업안전보건관리비 계상 및 사용기준 」 제 10조 제 1항에
              따라 위와 같이 사용계획서를 작성하였습니다.
            </div>
            <div
              style={{
                textAlign: "center",
                height: "50px",
                lineHeight: "50px",
                marginBottom: "10px",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
            </div>
            <table style={{ border: "hidden" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    width: "200px",
                    borderRight: "hidden",
                    height: "40px",
                  }}
                >
                  <span style={{ letterSpacing: "15px" }}>작성자 : </span>직책
                </td>
                <td style={{ width: "150px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "50px", borderRight: "hidden" }}>성명</td>
                <td style={{ width: "142px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "100px" }}>(서명 또는 인)</td>
              </tr>
              <tr>
                <td style={{ borderRight: "hidden" }}>
                  <span style={{ letterSpacing: "15px" }}>확인자 : </span>직책
                </td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td style={{ borderRight: "hidden" }}>성명</td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td>(서명 또는 인)</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc1[i] === "환경관리비 항목별 사용계획") {
      docArr.push(
        <div>
          <div className={styles.beginSafetyContainer}>
            <div className={styles.page}>
              <div className={styles.title}>항목별 사용계획</div>
              <div
                style={{
                  height: "15px",
                  lineHeight: "15px",
                  textAlign: "right",
                  fontSize: "13px",
                }}
              >
                {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? "　"
                  : moment(worklist?.beginDate).format("yyyy년　MM월")}
              </div>
              <div className={styles.container_body}>
                <div className={styles.table_header}>
                  <div className={styles.item_name}>항목</div>
                  <div className={styles.envi_name}>품명</div>
                  <div className={styles.envi_price}>금액</div>
                  <div className={styles.envi_note}>비고</div>
                </div>

                {worklist?.enviManagePlan.EnviPlanItems?.map(
                  (item, itemIndex) => {
                    return (
                      <div className={styles.envi_plan_items}>
                        <div className={styles.item_name}>
                          <div className={styles.item_num}>
                            {itemIndex + 1}.
                          </div>
                          <div className={styles.item_num_name}>
                            {item.itemName}
                          </div>
                        </div>

                        <div className={styles.envi_items_container}>
                          {Array.from(
                            { length: Number(item.EnviItems?.length) },
                            (_, enviIndex) => {
                              return (
                                <div className={styles.envi_items}>
                                  <div className={styles.envi_sub_item}>
                                    {item.EnviItems?.[enviIndex].EnviSubItem}
                                  </div>

                                  <div className={styles.product_name}>
                                    <input
                                      className={styles.product_name_input}
                                      defaultValue={
                                        item.EnviItems?.[enviIndex].productName
                                      }
                                      name="productName"
                                    />
                                  </div>

                                  <div className={styles.product_price}>
                                    <input
                                      className={styles.product_price_input}
                                      //defaultValue={item.EnviItems?.[enviIndex].productPrice === 0 ? '' : Number(item.EnviItems?.[enviIndex].productPrice).toLocaleString()}
                                      value={
                                        item?.EnviItems?.[enviIndex]
                                          .commaProductPrice === "0"
                                          ? ""
                                          : item?.EnviItems?.[enviIndex]
                                              .commaProductPrice
                                      }
                                      name="productPrice"
                                    />
                                  </div>

                                  <div className={styles.note}>
                                    <input
                                      className={styles.note_input}
                                      defaultValue={
                                        item.EnviItems?.[enviIndex].note
                                      }
                                      name="note"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <div className={styles.envi_items}>
                            <div
                              className={styles.envi_sub_item}
                              style={{
                                fontWeight: 600,
                                textAlign: "center",
                                paddingRight: "5px",
                              }}
                            >
                              소계
                            </div>

                            <div className={styles.product_name}>　</div>

                            <div className={styles.total_price}>
                              {item.totalPrice === 0
                                ? ""
                                : item.totalPrice.toLocaleString()}
                            </div>

                            <div className={styles.note}>　</div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectDoc1[i] === "환경관리비 사용계획서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <div
              style={{
                fontSize: "40px",
                textAlign: "center",
                borderBottom: "hidden",
                fontWeight: 600,
              }}
            >
              환경관리비 사용계획서
            </div>
            <div
              style={{
                height: "50px",
                lineHeight: "50px",
                textAlign: "right",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.beginDate).format("yyyy년　MM월")}
            </div>
            <table style={{ textAlign: "center" }}>
              <tr style={{ height: "40px" }}>
                <td style={{ width: "120px", letterSpacing: "2px" }}>
                  건설업체명
                </td>
                <td style={{ width: "201px" }}>{worklist?.companyName}</td>
                <td
                  style={{
                    width: "120px",
                    letterSpacing: "16px",
                    paddingLeft: "12px",
                  }}
                >
                  공사명
                </td>
                <td style={{ width: "201px", wordBreak: "keep-all" }}>
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  소재지
                </td>
                <td style={{ wordBreak: "keep-all" }}>
                  {worklist?.businessAddress}
                </td>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  대표자
                </td>
                <td style={{ letterSpacing: "5px" }}>
                  {worklist?.representativeName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사금액
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  ￦ {props.commaContractPrice}　원
                </td>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사기간
                </td>
                <td>
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                    "Invalid date" &&
                  moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : `${moment(worklist?.beginDate).format(
                        "yyyy.MM.DD"
                      )} ~ ${moment(worklist?.completionDate).format(
                        "yyyy.MM.DD"
                      )}`}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  발주처
                </td>
                <td>{worklist?.orderOrganization}</td>
                <td style={{ letterSpacing: "2px" }}>누계공정율</td>
                <td>100%</td>
              </tr>
              <tr style={{ height: "40px", borderBottom: "hidden" }}>
                <td>
                  <div style={{ letterSpacing: "16px", paddingLeft: "15px" }}>
                    계상된
                  </div>
                  <div style={{ letterSpacing: "2px" }}>환경보전비</div>
                </td>
                <td colSpan={3}>{`${Number(
                  worklist.expensedEnviPlanCost
                ).toLocaleString()} 원`}</td>
              </tr>
            </table>
            <table style={{ tableLayout: "fixed" }}>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td colSpan={4} style={{ letterSpacing: "30px" }}>
                  사용금액
                </td>
              </tr>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td
                  colSpan={2}
                  style={{ letterSpacing: "30px", paddingLeft: "30px" }}
                >
                  항목
                </td>
                <td>
                  (
                  {moment(worklist?.beginDate).format("yyyy년 MM월 DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.beginDate).format("M")}
                  ) 월 사용금액
                </td>
                <td>누계사용금액</td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  계
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviPlanTotalPrice === 0
                    ? "-"
                    : enviPlanTotalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviPlanTotalPrice === 0
                    ? "-"
                    : enviPlanTotalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  1. 비산먼지 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[0].totalPrice === 0
                    ? "-"
                    : enviManagePlan[0].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[0].totalPrice === 0
                    ? "-"
                    : enviManagePlan[0].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  2. 소음, 진동 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[1].totalPrice === 0
                    ? "-"
                    : enviManagePlan[1].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[1].totalPrice === 0
                    ? "-"
                    : enviManagePlan[1].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  3. 폐기물 처리시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[2].totalPrice === 0
                    ? "-"
                    : enviManagePlan[2].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[2].totalPrice === 0
                    ? "-"
                    : enviManagePlan[2].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  4. 수질오염 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[3].totalPrice === 0
                    ? "-"
                    : enviManagePlan[3].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[3].totalPrice === 0
                    ? "-"
                    : enviManagePlan[3].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  5. 기타 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[4].totalPrice === 0
                    ? "-"
                    : enviManagePlan[4].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManagePlan[4].totalPrice === 0
                    ? "-"
                    : enviManagePlan[4].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
              </tr>
            </table>
            <div
              style={{
                height: "60px",
                wordBreak: "keep-all",
                marginTop: "20px",
                marginLeft: "45px",
              }}
            >
              「 건설업 산업안전보건관리비 계상 및 사용기준 」 제 10조 제 1항에
              따라 위와 같이 사용계획서를 작성하였습니다.
            </div>
            <div
              style={{
                textAlign: "center",
                height: "50px",
                lineHeight: "50px",
                marginBottom: "10px",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
            </div>
            <table style={{ border: "hidden" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    width: "200px",
                    borderRight: "hidden",
                    height: "40px",
                  }}
                >
                  <span style={{ letterSpacing: "15px" }}>작성자 : </span>직책
                </td>
                <td style={{ width: "150px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "50px", borderRight: "hidden" }}>성명</td>
                <td style={{ width: "142px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "100px" }}>(서명 또는 인)</td>
              </tr>
              <tr>
                <td style={{ borderRight: "hidden" }}>
                  <span style={{ letterSpacing: "15px" }}>확인자 : </span>직책
                </td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td style={{ borderRight: "hidden" }}>성명</td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td>(서명 또는 인)</td>
              </tr>
            </table>
          </div>
        </div>
      );
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  for (var i = 0; i < Object.keys(selectDoc2).length; i++) {
    //준공서류
    if (selectDoc2[i] === "준공계") {
      docArr.push(
        <div className="page-doc">
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr style={{ borderBottom: "hidden" }}>
              <td
                colspan="4"
                style={{
                  fontSize: "50px",
                  textAlign: "center",
                  height: "150px",
                  fontWeight: "600",
                }}
              >
                준　　공　　계
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td style={{ borderRight: "hidden" }}></td>
              <td style={{ borderRight: "hidden" }}></td>
              <td style={{ borderRight: "hidden" }}>공사감독자경유 : </td>
              <td style={{ textAlign: "right" }}>(인)</td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr
              style={{
                borderBottom: "hidden",
                height: "40px",
                fontSize: "15px",
              }}
            >
              <td style={{ borderRight: "hidden" }}>
                1.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>공</span>
                  <span>사</span>
                  <span>명</span>
                </span>
                　:
              </td>
              <td style={{ wordBreak: "keep-all" }} colspan="3">
                {worklist?.constructionName}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "hidden",
                height: "40px",
                fontSize: "15px",
              }}
            >
              <td style={{ borderRight: "hidden" }}>
                2.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>약</span>
                  <span>금</span>
                  <span>액</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "hidden",
                height: "40px",
                fontSize: "15px",
              }}
            >
              <td style={{ borderRight: "hidden" }}>
                3.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>준</span>
                  <span>공</span>
                  <span>정</span>
                  <span>산</span>
                  <span>금</span>
                  <span>액</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {`일금 ${props.krCompletionCalPrice}정 (￦${props.commaCompletionCalPrice})`}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "hidden",
                height: "40px",
                fontSize: "15px",
              }}
            >
              <td style={{ borderRight: "hidden" }}>
                4.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>약</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.contractDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? "　"
                  : moment(worklist?.contractDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "hidden",
                height: "40px",
                fontSize: "15px",
              }}
            >
              <td style={{ borderRight: "hidden" }}>
                5.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>착</span>
                  <span>공</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? "　"
                  : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "hidden",
                height: "40px",
                fontSize: "15px",
              }}
            >
              <td style={{ borderRight: "hidden" }}>
                6.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>준</span>
                  <span>공</span>
                  <span>예</span>
                  <span>정</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.completionExpectDate).format(
                  "yyyy년　MM월　DD일"
                ) === "Invalid date"
                  ? "　"
                  : moment(worklist?.completionExpectDate).format(
                      "yyyy년　MM월　DD일"
                    )}
              </td>
            </tr>
            <tr
              style={{
                borderBottom: "hidden",
                height: "40px",
                fontSize: "15px",
              }}
            >
              <td style={{ borderRight: "hidden" }}>
                7.　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>준</span>
                  <span>공</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.completionDate).format(
                  "yyyy년　MM월　DD일"
                ) === "Invalid date"
                  ? "　"
                  : moment(worklist?.completionDate).format(
                      "yyyy년　MM월　DD일"
                    )}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td
                colspan="4"
                style={{
                  fontSize: "20px",
                  lineHeight: "30px",
                  textAlign: "center",
                  height: "50px",
                }}
              >
                위와 같이 준공되었기 준공계를 제출합니다.
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "15px", textAlign: "center" }}>
                {moment(worklist?.completionDate).format(
                  "yyyy년　MM월　DD일"
                ) === "Invalid date"
                  ? "　"
                  : moment(worklist?.completionDate).format(
                      "yyyy년　MM월　DD일"
                    )}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info">
                <span className="three-string">상호명</span>:　
                <span>{worklist?.companyName}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">주소</span>:　
                <span
                  style={{
                    display: "inline-flex",
                    width: "190px",
                    wordBreak: "keep-all",
                  }}
                >
                  {worklist?.businessAddress}
                </span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">전화</span>:　
                <span>{worklist?.phoneNum}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">팩스</span>:　
                <span>{worklist?.faxNum}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="three-string">대표자</span>:　
                <span style={{ letterSpacing: "10px" }}>
                  {worklist?.representativeName}
                </span>
                (인)
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td
                style={{ fontSize: "20px", textAlign: "left" }}
                colSpan="4"
              >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      );
    } else if (selectDoc2[i] === "준공검사원") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table style={{ tableLayout: "fixed", border: "hidden" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    height: "150px",
                    fontWeight: "600",
                  }}
                >
                  준 공 검 사 원
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td style={{ borderRight: "hidden" }}></td>
                <td style={{ borderRight: "hidden" }}></td>
                <td style={{ borderRight: "hidden" }}>공사감독자경유 : </td>
                <td style={{ textAlign: "right" }}>(인)</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  1.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>사</span>
                    <span>명</span>
                  </span>
                  　:
                </td>
                <td style={{ wordBreak: "keep-all" }} colspan="3">
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  2.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  3.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>준</span>
                    <span>공</span>
                    <span>정</span>
                    <span>산</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {`일금 ${props.krCompletionCalPrice}정 (￦${props.commaCompletionCalPrice})`}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  4.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>약</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.contractDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.contractDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  5.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>착</span>
                    <span>공</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  6.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>준</span>
                    <span>공</span>
                    <span>예</span>
                    <span>정</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.completionExpectDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.completionExpectDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  7.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>준</span>
                    <span>공</span>
                    <span>연</span>
                    <span>월</span>
                    <span>일</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.completionDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr
                style={{
                  borderBottom: "hidden",
                  height: "30px",
                  fontSize: "15px",
                }}
              >
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  8.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>붙</span>
                    <span>임</span>
                    <span>서</span>
                    <span>류</span>
                  </span>
                  　:
                </td>
                <td colspan="3">준공사진</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ lineHeight: "30px", textIndent: "5px" }}
                >
                  위 공사의 도급시행에 있어서 공사전반에 걸쳐 공사설계도서,
                  제시방서, 품질관리기준 및 기타 약정대로 어김없이 준공하였음을
                  확약하오며 만약 하자책임기반내에 공사의 시공, 감독 및 검사에
                  관하여 하자가 발견시는 즉시 변상 또는 재시공할 것을 서약하고
                  이에 준공검사원을 제출합니다.
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ fontSize: "15px", textAlign: "center" }}
                >
                  {moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : moment(worklist?.completionDate).format(
                        "yyyy년　MM월　DD일"
                      )}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">전화</span>:　
                  <span>{worklist?.phoneNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">팩스</span>:　
                  <span>{worklist?.faxNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "40px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc2[i] === "안전관리비 항목별 사용내역") {
      docArr.push(
        <div>
          <div className={styles.completionSafetyContainer}>
            <div className="page-doc">
              <div className={styles.title}>항목별 사용내역</div>
              <div
                style={{
                  height: "20px",
                  lineHeight: "20px",
                  textAlign: "right",
                  fontSize: "15px",
                }}
              >
                {moment(worklist?.completionDate).format(
                  "yyyy년　MM월　DD일"
                ) === "Invalid date"
                  ? "　"
                  : moment(worklist?.completionDate).format("yyyy년　MM월")}
              </div>
              <div className={styles.table_header}>
                <div className={styles.item_name}>항목</div>
                <div className={styles.usage_date}>사용일자</div>
                <div className={styles.usage_plan_items}>사용내역</div>
                <div className={styles.usage_item_price}>금액</div>
              </div>
              <div>
                {worklist?.safetyManageState.usageStateItems.map(
                  (item, itemIndex) => {
                    return (
                      <div
                        className={styles.table_header}
                        style={{ borderTop: "hidden" }}
                      >
                        <div className={styles.item_name}>
                          {/* <div className={styles.item_num}>{itemIndex + 1}</div> */}
                          <div className={styles.item_num_name}>
                            {itemIndex + 1}.{" "}
                            <span
                              style={{ display: "inline-flex", width: "120px" }}
                            >
                              {item.itemName}
                            </span>
                          </div>
                        </div>
                        <div className={styles.usage_date}>
                          <div style={{ borderBottom: "1px solid" }}>소계</div>
                          {Array.from(
                            { length: Number(item.usageItems?.length) },
                            (_, usageIndex) => {
                              return (
                                <div className={styles.input_box}>
                                  <input
                                    className={styles.date_input}
                                    defaultValue={
                                      item?.usageItems?.[
                                        usageIndex
                                      ].usageDate.toString() === "2021-01-01"
                                        ? ""
                                        : item?.usageItems?.[
                                            usageIndex
                                          ].usageDate.toString()
                                    }
                                    placeholder="2021-01-01"
                                    name="usageDate"
                                    //defaultValue={item1}
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className={styles.usage_plan_items}>
                          <div style={{ borderBottom: "1px solid" }}>　</div>
                          {Array.from(
                            { length: Number(item.usageItems?.length) },
                            (_, usageIndex) => {
                              return (
                                <div style={{ display: "flex" }}>
                                  <div
                                    className={styles.input_box}
                                    style={{ width: "40%" }}
                                  >
                                    <input
                                      className={styles.plan_item_name_input}
                                      defaultValue={
                                        item?.usageItems?.[usageIndex]
                                          .productName
                                      }
                                      placeholder="품명"
                                      name="productName"
                                    />
                                  </div>
                                  <div style={{ width: "10%" }}></div>
                                  <div
                                    className={styles.input_box}
                                    style={{ width: "15%" }}
                                  >
                                    <input
                                      className={styles.plan_item_num_input}
                                      defaultValue={
                                        item?.usageItems?.[usageIndex]
                                          .productNum === 0
                                          ? ""
                                          : item?.usageItems?.[usageIndex]
                                              .productNum
                                      }
                                      placeholder="수량"
                                      name="productNum"
                                    />
                                  </div>
                                  <div style={{ width: "5%" }}>
                                    {item?.usageItems?.[usageIndex]
                                      .productNum === 0 ||
                                    item?.usageItems?.[
                                      usageIndex
                                    ].productNum.toString() === ""
                                      ? ""
                                      : "x"}
                                  </div>
                                  <div
                                    className={styles.input_box}
                                    style={{ width: "30%" }}
                                  >
                                    <input
                                      className={styles.plan_item_price_input}
                                      //defaultValue={item?.usageItems?.[usageIndex].productPrice === 0 ? '' : Number(item?.usageItems?.[usageIndex].productPrice).toLocaleString()}
                                      value={
                                        item?.usageItems?.[usageIndex]
                                          .commaProductPrice === "0"
                                          ? ""
                                          : item?.usageItems?.[usageIndex]
                                              .commaProductPrice
                                      }
                                      placeholder="단가"
                                      name="productPrice"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className={styles.usage_item_price}>
                          <div style={{ borderBottom: "1px solid" }}>
                            {item.totalPrice.toLocaleString() === "0"
                              ? "　"
                              : item.totalPrice.toLocaleString()}
                          </div>
                          {Array.from(
                            { length: Number(item.usageItems?.length) },
                            (_, usageIndex) => {
                              return (
                                <div style={{ height: "20px" }}>
                                  {Number(
                                    item?.usageItems?.[usageIndex].calPrice
                                  ) === 0 ||
                                  item?.usageItems?.[
                                    usageIndex
                                  ].calPrice.toString() === ""
                                    ? ""
                                    : item?.usageItems?.[
                                        usageIndex
                                      ].calPrice.toLocaleString()}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className={styles.comment}>
                ※ 주 : 사용계획은 항목별 사용일자가 빠른 순서로 작성
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectDoc2[i] === "산업안전보건관리비 사용내역서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <div
              style={{
                fontSize: "40px",
                textAlign: "center",
                borderBottom: "hidden",
                fontWeight: "600",
              }}
            >
              산업안전보건관리비 사용내역서
            </div>
            <div
              style={{
                height: "50px",
                lineHeight: "50px",
                textAlign: "right",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.completionDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.completionDate).format("yyyy년　MM월")}
            </div>
            <table style={{ textAlign: "center" }}>
              <tr style={{ height: "40px" }}>
                <td style={{ width: "100px", letterSpacing: "2px" }}>
                  건설업체명
                </td>
                <td style={{ width: "220px" }}>{worklist?.companyName}</td>
                <td
                  style={{
                    width: "100px",
                    letterSpacing: "16px",
                    paddingLeft: "12px",
                  }}
                >
                  공사명
                </td>
                <td style={{ width: "220px", wordBreak: "keep-all" }}>
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  소재지
                </td>
                <td style={{ wordBreak: "keep-all" }}>
                  {worklist?.businessAddress}
                </td>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  대표자
                </td>
                <td style={{ letterSpacing: "5px" }}>
                  {worklist?.representativeName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사금액
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  ￦ {props.commaContractPrice}　원
                </td>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사기간
                </td>
                <td>
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                    "Invalid date" &&
                  moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : `${moment(worklist?.beginDate).format(
                        "yyyy.MM.DD"
                      )} ~ ${moment(worklist?.completionDate).format(
                        "yyyy.MM.DD"
                      )}`}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  발주처
                </td>
                <td>{worklist?.orderOrganization}</td>
                <td style={{ letterSpacing: "2px" }}>누계공정율</td>
                <td>100%</td>
              </tr>
              <tr style={{ height: "40px", borderBottom: "hidden" }}>
                <td>
                  <div style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                    계상된
                  </div>
                  <div style={{ letterSpacing: "2px" }}>안전관리비</div>
                </td>
                <td colSpan="3">{`${Number(
                  worklist?.expensedSafetyStateCost
                ).toLocaleString()} 원`}</td>
              </tr>
            </table>
            <table style={{ tableLayout: "fixed" }}>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td colSpan="4" style={{ letterSpacing: "30px" }}>
                  사용금액
                </td>
              </tr>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td
                  colSpan="2"
                  style={{ letterSpacing: "30px", paddingLeft: "30px" }}
                >
                  항목
                </td>
                <td>
                  (
                  {moment(worklist?.beginDate).format("yyyy년 MM월 DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.beginDate).format("M")}
                  ) 월 사용금액
                </td>
                <td>누계사용금액</td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ textAlign: "center" }}>
                  계
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyStateTotalPrice === 0
                    ? "-"
                    : safetyStateTotalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyStateTotalPrice === 0
                    ? "-"
                    : safetyStateTotalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  1. 안전관리자 등 인건비 및 각종 업무수당 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[0].totalPrice === 0
                    ? "-"
                    : safetyManageState[0].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[0].totalPrice === 0
                    ? "-"
                    : safetyManageState[0].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  2. 안전시설비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[1].totalPrice === 0
                    ? "-"
                    : safetyManageState[1].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[1].totalPrice === 0
                    ? "-"
                    : safetyManageState[1].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  3. 개인보호구 및 안전장구 구입비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[2].totalPrice === 0
                    ? "-"
                    : safetyManageState[2].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[2].totalPrice === 0
                    ? "-"
                    : safetyManageState[2].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  4. 안전진단비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[3].totalPrice === 0
                    ? "-"
                    : safetyManageState[3].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[3].totalPrice === 0
                    ? "-"
                    : safetyManageState[3].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  5. 안전보건교육비 및 행사비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[4].totalPrice === 0
                    ? "-"
                    : safetyManageState[4].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[4].totalPrice === 0
                    ? "-"
                    : safetyManageState[4].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  6. 근로자 건강관리비 등
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[5].totalPrice === 0
                    ? "-"
                    : safetyManageState[5].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[5].totalPrice === 0
                    ? "-"
                    : safetyManageState[5].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  7. 건설재해예방 기술지도비
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[6].totalPrice === 0
                    ? "-"
                    : safetyManageState[6].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[6].totalPrice === 0
                    ? "-"
                    : safetyManageState[6].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan="2" style={{ paddingLeft: "5px" }}>
                  8. 본사사용비
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[7].totalPrice === 0
                    ? "-"
                    : safetyManageState[7].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {safetyManageState[7].totalPrice === 0
                    ? "-"
                    : safetyManageState[7].totalPrice.toLocaleString()}
                </td>
              </tr>
            </table>
            <div
              style={{
                height: "60px",
                wordBreak: "keep-all",
                marginTop: "20px",
                marginLeft: "45px",
              }}
            >
              「 건설업 산업안전보건관리비 계상 및 사용기준 」 제 10조 제 1항에
              따라 위와 같이 사용계획서를 작성하였습니다.
            </div>
            <div
              style={{
                textAlign: "center",
                height: "50px",
                lineHeight: "50px",
                marginBottom: "10px",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.completionDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.completionDate).format("yyyy년　MM월　DD일")}
            </div>
            <table style={{ border: "hidden" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    width: "200px",
                    borderRight: "hidden",
                    height: "40px",
                  }}
                >
                  <span style={{ letterSpacing: "15px" }}>작성자 : </span>직책
                </td>
                <td style={{ width: "150px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "50px", borderRight: "hidden" }}>성명</td>
                <td style={{ width: "142px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "100px" }}>(서명 또는 인)</td>
              </tr>
              <tr>
                <td style={{ borderRight: "hidden" }}>
                  <span style={{ letterSpacing: "15px" }}>확인자 : </span>직책
                </td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td style={{ borderRight: "hidden" }}>성명</td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td>(서명 또는 인)</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc2[i] === "환경관리비 항목별 사용내역") {
      docArr.push(
        <div>
          <div className={styles.beginSafetyContainer}>
            <div className={styles.page}>
              <div className={styles.title}>항목별 사용내역</div>
              <div
                style={{
                  height: "15px",
                  lineHeight: "15px",
                  textAlign: "right",
                  fontSize: "13px",
                }}
              >
                {moment(worklist?.completionDate).format(
                  "yyyy년　MM월　DD일"
                ) === "Invalid date"
                  ? "　"
                  : moment(worklist?.completionDate).format("yyyy년　MM월")}
              </div>
              <div className={styles.container_body}>
                <div className={styles.table_header}>
                  <div className={styles.item_name}>항목</div>
                  <div className={styles.envi_name}>품명</div>
                  <div className={styles.envi_price}>금액</div>
                  <div className={styles.envi_note}>비고</div>
                </div>

                {worklist?.enviManageState.EnviStateItems?.map(
                  (item, itemIndex) => {
                    return (
                      <div className={styles.envi_plan_items}>
                        <div className={styles.item_name}>
                          <div className={styles.item_num}>
                            {itemIndex + 1}.
                          </div>
                          <div className={styles.item_num_name}>
                            {item.itemName}
                          </div>
                        </div>

                        <div className={styles.envi_items_container}>
                          {Array.from(
                            { length: Number(item.EnviItems?.length) },
                            (_, enviIndex) => {
                              return (
                                <div className={styles.envi_items}>
                                  <div className={styles.envi_sub_item}>
                                    {item.EnviItems?.[enviIndex].EnviSubItem}
                                  </div>

                                  <div className={styles.product_name}>
                                    <input
                                      className={styles.product_name_input}
                                      defaultValue={
                                        item.EnviItems?.[enviIndex].productName
                                      }
                                      name="productName"
                                    />
                                  </div>

                                  <div className={styles.product_price}>
                                    <input
                                      className={styles.product_price_input}
                                      //defaultValue={item.EnviItems?.[enviIndex].productPrice === 0 ? '' : Number(item.EnviItems?.[enviIndex].productPrice).toLocaleString()}
                                      value={
                                        item?.EnviItems?.[enviIndex]
                                          .commaProductPrice === "0"
                                          ? ""
                                          : item?.EnviItems?.[enviIndex]
                                              .commaProductPrice
                                      }
                                      name="productPrice"
                                    />
                                  </div>

                                  <div className={styles.note}>
                                    <input
                                      className={styles.note_input}
                                      defaultValue={
                                        item.EnviItems?.[enviIndex].note
                                      }
                                      name="note"
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                          <div className={styles.envi_items}>
                            <div
                              className={styles.envi_sub_item}
                              style={{
                                fontWeight: 600,
                                textAlign: "center",
                                paddingRight: "5px",
                              }}
                            >
                              소계
                            </div>

                            <div className={styles.product_name}>　</div>

                            <div className={styles.total_price}>
                              {item.totalPrice === 0
                                ? ""
                                : item.totalPrice.toLocaleString()}
                            </div>

                            <div className={styles.note}>　</div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (selectDoc2[i] === "환경관리비 사용내역서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <div
              style={{
                fontSize: "40px",
                textAlign: "center",
                borderBottom: "hidden",
                fontWeight: 600,
              }}
            >
              환경관리비 사용내역서
            </div>
            <div
              style={{
                height: "50px",
                lineHeight: "50px",
                textAlign: "right",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.completionDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.completionDate).format("yyyy년　MM월")}
            </div>
            <table style={{ textAlign: "center" }}>
              <tr style={{ height: "40px" }}>
                <td style={{ width: "120px", letterSpacing: "2px" }}>
                  건설업체명
                </td>
                <td style={{ width: "201px" }}>{worklist?.companyName}</td>
                <td
                  style={{
                    width: "120px",
                    letterSpacing: "16px",
                    paddingLeft: "12px",
                  }}
                >
                  공사명
                </td>
                <td style={{ width: "201px", wordBreak: "keep-all" }}>
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  소재지
                </td>
                <td style={{ wordBreak: "keep-all" }}>
                  {worklist?.businessAddress}
                </td>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  대표자
                </td>
                <td style={{ letterSpacing: "5px" }}>
                  {worklist?.representativeName}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사금액
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  ￦ {props.commaContractPrice}　원
                </td>
                <td style={{ letterSpacing: "7px", paddingLeft: "4px" }}>
                  공사기간
                </td>
                <td>
                  {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                    "Invalid date" &&
                  moment(worklist?.completionDate).format(
                    "yyyy년　MM월　DD일"
                  ) === "Invalid date"
                    ? "　"
                    : `${moment(worklist?.beginDate).format(
                        "yyyy.MM.DD"
                      )} ~ ${moment(worklist?.completionDate).format(
                        "yyyy.MM.DD"
                      )}`}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td style={{ letterSpacing: "16px", paddingLeft: "12px" }}>
                  발주처
                </td>
                <td>{worklist?.orderOrganization}</td>
                <td style={{ letterSpacing: "2px" }}>누계공정율</td>
                <td>100%</td>
              </tr>
              <tr style={{ height: "40px", borderBottom: "hidden" }}>
                <td>
                  <div style={{ letterSpacing: "16px", paddingLeft: "15px" }}>
                    계상된
                  </div>
                  <div style={{ letterSpacing: "2px" }}>환경보전비</div>
                </td>
                <td colSpan={3}>{`${Number(
                  worklist?.expensedEnviStateCost
                ).toLocaleString()} 원`}</td>
              </tr>
            </table>
            <table style={{ tableLayout: "fixed" }}>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td colSpan={4} style={{ letterSpacing: "30px" }}>
                  사용금액
                </td>
              </tr>
              <tr style={{ height: "40px", textAlign: "center" }}>
                <td
                  colSpan={2}
                  style={{ letterSpacing: "30px", paddingLeft: "30px" }}
                >
                  항목
                </td>
                <td>
                  (
                  {moment(worklist?.beginDate).format("yyyy년 MM월 DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.beginDate).format("M")}
                  ) 월 사용금액
                </td>
                <td>누계사용금액</td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  계
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviStateTotalPrice === 0
                    ? "-"
                    : enviStateTotalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviStateTotalPrice === 0
                    ? "-"
                    : enviStateTotalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  1. 비산먼지 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[0].totalPrice === 0
                    ? "-"
                    : enviManageState[0].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[0].totalPrice === 0
                    ? "-"
                    : enviManageState[0].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  2. 소음, 진동 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[1].totalPrice === 0
                    ? "-"
                    : enviManageState[1].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[1].totalPrice === 0
                    ? "-"
                    : enviManageState[1].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  3. 폐기물 처리시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[2].totalPrice === 0
                    ? "-"
                    : enviManageState[2].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[2].totalPrice === 0
                    ? "-"
                    : enviManageState[2].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  4. 수질오염 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[3].totalPrice === 0
                    ? "-"
                    : enviManageState[3].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[3].totalPrice === 0
                    ? "-"
                    : enviManageState[3].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}>
                  5. 기타 방지시설
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[4].totalPrice === 0
                    ? "-"
                    : enviManageState[4].totalPrice.toLocaleString()}
                </td>
                <td style={{ textAlign: "right", paddingRight: "5px" }}>
                  {enviManageState[4].totalPrice === 0
                    ? "-"
                    : enviManageState[4].totalPrice.toLocaleString()}
                </td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
              </tr>
              <tr style={{ height: "40px" }}>
                <td colSpan={2} style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
                <td style={{ paddingLeft: "5px" }}></td>
              </tr>
            </table>
            <div
              style={{
                height: "60px",
                wordBreak: "keep-all",
                marginTop: "20px",
                marginLeft: "45px",
              }}
            >
              「 건설업 산업안전보건관리비 계상 및 사용기준 」 제 10조 제 1항에
              따라 위와 같이 사용계획서를 작성하였습니다.
            </div>
            <div
              style={{
                textAlign: "center",
                height: "50px",
                lineHeight: "50px",
                marginBottom: "10px",
                fontSize: "15px",
              }}
            >
              {moment(worklist?.completionDate).format("yyyy년　MM월　DD일") ===
              "Invalid date"
                ? "　"
                : moment(worklist?.completionDate).format("yyyy년　MM월　DD일")}
            </div>
            <table style={{ border: "hidden" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  style={{
                    width: "200px",
                    borderRight: "hidden",
                    height: "40px",
                  }}
                >
                  <span style={{ letterSpacing: "15px" }}>작성자 : </span>직책
                </td>
                <td style={{ width: "150px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "50px", borderRight: "hidden" }}>성명</td>
                <td style={{ width: "142px", borderRight: "hidden" }}>　</td>
                <td style={{ width: "100px" }}>(서명 또는 인)</td>
              </tr>
              <tr>
                <td style={{ borderRight: "hidden" }}>
                  <span style={{ letterSpacing: "15px" }}>확인자 : </span>직책
                </td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td style={{ borderRight: "hidden" }}>성명</td>
                <td style={{ borderRight: "hidden" }}>　</td>
                <td>(서명 또는 인)</td>
              </tr>
            </table>
          </div>
        </div>
      );
    } else if (selectDoc2[i] === "사진대장 (2칸)") {
      for (let pageNum = 1; pageNum < 11; pageNum++) {
        //pageNum 1~10까지 반복하며 사진이 있는 페이지만 array에 push
        if (
          twoImg?.filter((image) => image.image_number === pageNum).length > 0
        ) {
          let image1 = twoImg?.filter(
            (image) =>
              image.image_number === pageNum && image.image_sub_number === 1
          )[0];
          let image2 = twoImg?.filter(
            (image) =>
              image.image_number === pageNum && image.image_sub_number === 2
          )[0];

          docArr.push(
            <div>
              <div className={styles.photo_page}>
                <div>
                  <table style={{ textAlign: "center", tableLayout: "fixed" }}>
                    <tr>
                      <td
                        style={{ lineHeight: "100px", paddingBottom: "30px" }}
                        className="title"
                        colSpan={4}
                      >
                        사 진 대 장
                      </td>
                    </tr>
                    <tr style={{ height: "30px" }}>
                      <td>공사명</td>
                      <td style={{ wordBreak: "keep-all" }} colSpan={3}>
                        {worklist?.constructionName}
                      </td>
                    </tr>
                    <tr style={{ height: "30px" }}>
                      <td>내용</td>
                      <td colSpan={3}>
                        <span style={{ lineHeight: "25px" }}>
                          {useContent?.[pageNum - 1].content}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "30px" }}>
                      <td
                        style={{ borderLeft: "hidden", borderRight: "hidden" }}
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr style={{ height: "400px" }}>
                      <td colSpan={4}>
                        <div
                          className={`${styles.uploadOrImage} uploadOrImage`}
                        >
                          {image1 ? (
                            <div className={styles.photolist_dropdown}>
                              <div>
                                <img
                                  className={styles.upload_two_img}
                                  src={image1.image_url}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label className={styles.input_file_btn}>
                                업로드
                              </label>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ height: "400px" }}>
                      <td colSpan={4}>
                        <div
                          className={`${styles.uploadOrImage} uploadOrImage`}
                        >
                          {image2 ? (
                            <div className={styles.photolist_dropdown}>
                              <div>
                                <img
                                  className={styles.upload_two_img}
                                  src={image2.image_url}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label className={styles.input_file_btn}>
                                업로드
                              </label>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          );
        }
      }
    } else if (selectDoc2[i] === "사진대장 (4칸)") {
      for (let pageNum = 1; pageNum < 11; pageNum++) {
        //pageNum 1~10까지 반복하며 사진이 있는 페이지만 array에 push
        if (
          fourImg?.filter((image) => image.image_number === pageNum + 10)
            .length > 0
        ) {
          let image1 = fourImg?.filter(
            (image) =>
              image.image_number === pageNum + 10 &&
              image.image_sub_number === 1
          )[0];
          let image2 = fourImg?.filter(
            (image) =>
              image.image_number === pageNum + 10 &&
              image.image_sub_number === 2
          )[0];
          let image3 = fourImg?.filter(
            (image) =>
              image.image_number === pageNum + 10 &&
              image.image_sub_number === 3
          )[0];
          let image4 = fourImg?.filter(
            (image) =>
              image.image_number === pageNum + 10 &&
              image.image_sub_number === 4
          )[0];
          docArr.push(
            <div>
              <div className={styles.photo_page}>
                <div>
                  <table style={{ textAlign: "center", tableLayout: "fixed" }}>
                    <tr>
                      <td
                        style={{ lineHeight: "100px", paddingBottom: "30px" }}
                        className="title"
                        colSpan={4}
                      >
                        사 진 대 장
                      </td>
                    </tr>
                    <tr style={{ height: "30px" }}>
                      <td>공사명</td>
                      <td style={{ wordBreak: "keep-all" }} colSpan={3}>
                        {worklist?.constructionName}
                      </td>
                    </tr>
                    <tr style={{ height: "30px" }}>
                      <td>내용</td>
                      <td colSpan={3}>
                        <span style={{ lineHeight: "25px" }}>
                          {useContent?.[pageNum + 10 - 1].content}
                        </span>
                      </td>
                    </tr>
                    <tr style={{ height: "30px" }}>
                      <td
                        style={{ borderLeft: "hidden", borderRight: "hidden" }}
                        colSpan={4}
                      ></td>
                    </tr>
                    <tr style={{ height: "400px" }}>
                      <td colSpan={2}>
                        <div
                          className={`${styles.uploadOrImage2} uploadOrImage2`}
                        >
                          {image1 ? (
                            <div className={styles.photolist_dropdown}>
                              <div>
                                <img
                                  className={styles.upload_four_img}
                                  src={image1.image_url}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label className={styles.input_file_btn}>
                                업로드
                              </label>
                            </div>
                          )}
                        </div>
                      </td>
                      <td style={{ width: "50%" }} colSpan={2}>
                        <div
                          className={`${styles.uploadOrImage2} uploadOrImage2`}
                        >
                          {image2 ? (
                            <div className={styles.photolist_dropdown}>
                              <div>
                                <img
                                  className={styles.upload_four_img}
                                  src={image2.image_url}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label className={styles.input_file_btn}>
                                업로드
                              </label>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ height: "400px" }}>
                      <td style={{ width: "359px" }} colSpan={2}>
                        <div
                          className={`${styles.uploadOrImage2} uploadOrImage2`}
                        >
                          {image3 ? (
                            <div className={styles.photolist_dropdown}>
                              <div>
                                <img
                                  className={styles.upload_four_img}
                                  src={image3.image_url}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label className={styles.input_file_btn}>
                                업로드
                              </label>
                            </div>
                          )}
                        </div>
                      </td>
                      <td style={{ width: "400px" }} colSpan={2}>
                        <div
                          className={`${styles.uploadOrImage2} uploadOrImage2`}
                        >
                          {image4 ? (
                            <div className={styles.photolist_dropdown}>
                              <div>
                                <img
                                  className={styles.upload_four_img}
                                  src={image4.image_url}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <label className={styles.input_file_btn}>
                                업로드
                              </label>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          );
        }
      }
    }
  }

  ///////////////////////////////////////////////////////////////////////////////
  for (var i = 0; i < Object.keys(selectDoc3).length; i++) {
    //청구서류
    if (selectDoc3[i] === "청구서") {
      docArr.push(
        <div className="page-doc">
          <table style={{ tableLayout: "fixed", border: "hidden" }}>
            <tr>
              <td
                colspan="4"
                style={{
                  fontSize: "50px",
                  textAlign: "center",
                  height: "150px",
                  borderBottom: "hidden",
                  fontWeight: "600",
                }}
              >
                청　　구　　서
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colSpan="4" height="20px">
                　
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                ○　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>약</span>
                  <span>건</span>
                  <span>명</span>
                </span>
                　:
              </td>
              <td style={{ wordBreak: "keep-all" }} colspan="3">
                {worklist?.constructionName}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                ○　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>약</span>
                  <span>금</span>
                  <span>액</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {`일금 ${props.krContractPrice}정 (￦${props.commaContractPrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                ○　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>청</span>
                  <span>구</span>
                  <span>금</span>
                  <span>액</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {`일금 ${props.krClaimPrice}정 (￦${props.commaClaimPrice})`}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                ○　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>약</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.contractDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? "　"
                  : moment(worklist?.contractDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                ○　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>착</span>
                  <span>공</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.beginDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? "　"
                  : moment(worklist?.beginDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                ○　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>준</span>
                  <span>공</span>
                  <span>연</span>
                  <span>월</span>
                  <span>일</span>
                </span>
                　:
              </td>
              <td colspan="3">
                {moment(worklist?.completionDate).format(
                  "yyyy년　MM월　DD일"
                ) === "Invalid date"
                  ? "　"
                  : moment(worklist?.completionDate).format(
                      "yyyy년　MM월　DD일"
                    )}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "24px", textAlign: "center" }}>
                위와 같이 청구하오니 아래 계좌에 입금하여 주시기 바랍니다.
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td style={{ borderRight: "hidden", height: "30px" }}>
                ○　
                <span
                  style={{
                    width: "100px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>좌</span>
                  <span>입</span>
                  <span>금</span>
                  <span>정</span>
                  <span>보</span>
                </span>
                　:
              </td>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td
                style={{
                  borderRight: "hidden",
                  borderBottom: "hidden",
                  height: "30px",
                }}
              ></td>
              <td style={{ borderRight: "hidden" }} colspan="2">
                　
              </td>
              <td style={{ borderBottom: "hidden" }}></td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}></td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span
                  style={{
                    width: "80px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>예</span>
                  <span>금</span>
                  <span>주</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.accountHolder}</td>
              <td style={{ borderBottom: "hidden" }}></td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}></td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span
                  style={{
                    width: "80px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>은</span>
                  <span>행</span>
                  <span>명</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.bankName}</td>
              <td style={{ borderBottom: "hidden" }}></td>
            </tr>
            <tr>
              <td style={{ borderBottom: "hidden" }}></td>
              <td style={{ textAlign: "center", height: "30px" }}>
                <span
                  style={{
                    width: "80px",
                    display: "inline-flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>계</span>
                  <span>좌</span>
                  <span>번</span>
                  <span>호</span>
                </span>
              </td>
              <td style={{ textAlign: "center" }}>{worklist?.accountNum}</td>
              <td style={{ borderBottom: "hidden" }}></td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td colspan="4" style={{ fontSize: "15px", textAlign: "center" }}>
                {moment(worklist?.claimDate).format("yyyy년　MM월　DD일") ===
                "Invalid date"
                  ? "　"
                  : moment(worklist?.claimDate).format("yyyy년　MM월　DD일")}
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "30px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colSpan="2" className="bottom-info">
                <span className="three-string">상호명</span>:　
                <span>{worklist?.companyName}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">주소</span>:　
                <span
                  style={{
                    display: "inline-flex",
                    width: "190px",
                    wordBreak: "keep-all",
                  }}
                >
                  {worklist?.businessAddress}
                </span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">전화</span>:　
                <span>{worklist?.phoneNum}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="two-string">팩스</span>:　
                <span>{worklist?.faxNum}</span>
              </td>
            </tr>
            <tr
              style={{
                height: "30px",
                borderBottom: "hidden",
                borderLeft: "hidden",
                borderRight: "hidden",
              }}
            >
              <td colSpan="2" style={{ borderRight: "hidden" }}></td>
              <td colspan="2" className="bottom-info">
                <span className="three-string">대표자</span>:　
                <span style={{ letterSpacing: "10px" }}>
                  {worklist?.representativeName}
                </span>
                (인)
              </td>
            </tr>
            <tr style={{ borderBottom: "hidden", height: "40px" }}>
              <td colSpan="4">　</td>
            </tr>
            <tr style={{ borderBottom: "hidden" }}>
              <td
                style={{ fontSize: "20px", textAlign: "left" }}
                colSpan="4"
              >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
            </tr>
          </table>
        </div>
      );
    } else if (selectDoc3[i] === "입금계좌지정서") {
      docArr.push(
        <div>
          <div className="page-doc">
            <table style={{ tableLayout: "fixed", border: "hidden" }}>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{
                    fontSize: "50px",
                    textAlign: "center",
                    height: "150px",
                    fontWeight: "600",
                  }}
                >
                  입 금 계 좌 지 정 서
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  1.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>공</span>
                    <span>사</span>
                    <span>명</span>
                  </span>
                  　:
                </td>
                <td style={{ wordBreak: "keep-all" }} colspan="3">
                  {worklist?.constructionName}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", fontSize: "15px" }}>
                <td style={{ borderRight: "hidden", height: "30px" }}>
                  2.　
                  <span
                    style={{
                      width: "100px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>청</span>
                    <span>구</span>
                    <span>금</span>
                    <span>액</span>
                  </span>
                  　:
                </td>
                <td colspan="3">
                  {`일금 ${props.krClaimPrice}정 (￦${props.commaClaimPrice})`}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{ fontSize: "19px", textAlign: "center" }}
                >
                  위 공사에 대하여 본사의 입금계좌를 아래와 같이 지정하였기에
                  통보합니다.
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    borderBottom: "hidden",
                    borderRight: "hidden",
                    height: "50px",
                  }}
                ></td>
                <td style={{ borderRight: "hidden" }} colspan="2">
                  　
                </td>
                <td style={{ borderBottom: "hidden" }}></td>
              </tr>
              <tr>
                <td style={{ borderBottom: "hidden" }}>　</td>
                <td style={{ textAlign: "center", height: "30px" }}>
                  <span
                    style={{
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>은</span>
                    <span>행</span>
                    <span>명</span>
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>{worklist?.bankName}</td>
                <td style={{ borderBottom: "hidden" }}>　</td>
              </tr>
              <tr>
                <td style={{ borderBottom: "hidden" }}>　</td>
                <td style={{ textAlign: "center", height: "30px" }}>
                  <span
                    style={{
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>점</span>
                    <span>포</span>
                    <span>명</span>
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>{worklist?.storeName}</td>
                <td style={{ borderBottom: "hidden" }}>　</td>
              </tr>
              <tr>
                <td style={{ borderBottom: "hidden" }}>　</td>
                <td style={{ textAlign: "center", height: "30px" }}>
                  <span
                    style={{
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>예</span>
                    <span>금</span>
                    <span>종</span>
                    <span>류</span>
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>
                  {worklist?.typeOfDeposit}
                </td>
                <td style={{ borderBottom: "hidden" }}>　</td>
              </tr>
              <tr>
                <td style={{ borderBottom: "hidden" }}>　</td>
                <td style={{ textAlign: "center", height: "30px" }}>
                  <span
                    style={{
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>계</span>
                    <span>좌</span>
                    <span>번</span>
                    <span>호</span>
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>{worklist?.accountNum}</td>
                <td style={{ borderBottom: "hidden" }}>　</td>
              </tr>
              <tr>
                <td style={{ borderBottom: "hidden" }}>　</td>
                <td style={{ textAlign: "center", height: "30px" }}>
                  <span
                    style={{
                      width: "80px",
                      display: "inline-flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <span>예</span>
                    <span>금</span>
                    <span>주</span>
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>
                  {worklist?.accountHolder}
                </td>
                <td style={{ borderBottom: "hidden" }}>　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden" }}>
                <td
                  colspan="4"
                  style={{
                    fontSize: "15px",
                    textAlign: "center",
                    height: "50px",
                  }}
                >
                  {moment(worklist?.claimDate).format("yyyy년　MM월　DD일") ===
                  "Invalid date"
                    ? "　"
                    : moment(worklist?.claimDate).format("yyyy년　MM월　DD일")}
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colSpan="2" className="bottom-info">
                  <span className="three-string">상호명</span>:　
                  <span>{worklist?.companyName}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">주소</span>:　
                  <span
                    style={{
                      display: "inline-flex",
                      width: "190px",
                      wordBreak: "keep-all",
                    }}
                  >
                    {worklist?.businessAddress}
                  </span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">전화</span>:　
                  <span>{worklist?.phoneNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="two-string">팩스</span>:　
                  <span>{worklist?.faxNum}</span>
                </td>
              </tr>
              <tr
                style={{
                  height: "30px",
                  borderBottom: "hidden",
                  borderLeft: "hidden",
                  borderRight: "hidden",
                }}
              >
                <td colSpan="2" style={{ borderRight: "hidden" }}></td>
                <td colspan="2" className="bottom-info">
                  <span className="three-string">대표자</span>:　
                  <span style={{ letterSpacing: "10px" }}>
                    {worklist?.representativeName}
                  </span>
                  (인)
                </td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "50px" }}>
                <td colSpan="4">　</td>
              </tr>
              <tr style={{ borderBottom: "hidden", height: "30px" }}>
                <td
                  style={{ fontSize: "20px", textAlign: "left" }}
                  colSpan="4"
                >{`${!worklist?.dear ? "" : worklist?.dear} 귀하`}</td>
              </tr>
            </table>
          </div>
        </div>
      );
    }
  }
  return (
    <div>
      <div id="printArea">
        {docArr.map((docIndex) => (
          <div>{docIndex}</div>
        ))}
      </div>
    </div>
  );
}

export default Print;
