/* eslint-disable import/no-anonymous-default-export */
import React, { useContext, useEffect, useState } from "react";
import MainPresenter from "./MainPresenter";
import Banner1 from "../../assets/img/main/slide-banner1.png";
import Banner2 from "../../assets/img/main/slide-banner2.png";
import Banner3 from "../../assets/img/main/slide-banner3.png";

import { useHistory } from "react-router-dom";
import { api } from "../../config/api";
import { util } from "../../config/util";
import { Cookies } from "../../constants/Cookies";
import CardNewsModel from "../../models/cardNews/CardNewsModel";
import AlertContext from "../../contexts/AlertContext";

import bannerOneByOne from "../../assets/img/main/modal/banner_one_by_one.png";
import { Checkbox } from "antd";
import GlobalEnvironments from "../../modules/globalEnvironment/GlobalEnvironment";
import { Close } from "@material-ui/icons";
import { videoApi } from "../../config/videoApi";
import { MainCourseCardModel } from "../video/models/VideoDataModel";
import { pages, videoPages } from "../../constants/PagePaths";
import { useGA4React } from "ga-4-react";
import Slick from "./components/Slick";
import Category from "./components/Category";
import RecommendLeture from "./components/RecommendLeture";
import Review from "./components/Review";
import Request from "./components/Request";
import MiddleBanner from "./components/MiddleBanner";
import BottomBanner from "./components/BottomBanner";

interface Props {
  clearUserInfo: () => void;
}

const slides: any[] = [
  {
    img: Banner1,
  },
  {
    img: Banner2,
  },
  {
    img: Banner3,
  },
];
//declare chnnelio
declare global {
  interface Window {
    ChannelIO: any;
  }
}

export default (props: Props) => {
  const history = useHistory();

  useEffect(() => {
    if (util.getCookie(Cookies.authKey)) {
      api
        .getMyInfo()
        .then((result) => {
          //로그인 되어있음
          // api.getAlarmsAsync(0, 10).then((result) => {
          //   setAlarms(result.data.alarms);
          //   setUnreadCount(result.data.unread_count);
          // });
        })
        .catch((error) => {
          //로그인 되어있지 않음
          props.clearUserInfo();
        });
    }
  }, []);

  return (
    <main id="wrap">
      <article>
        <Slick />
        <Category />
        <RecommendLeture />
        <MiddleBanner />
        {/* <Review /> */}
        <section className="bg-gray-50">
          <div className="container py-120">
            <div
              className="guide-banner row gap-3"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div
                className="col rounded-2 py-4 px-5 bg-primary-700 text-white float-on-hover pointer"
                onClick={() => {
                  history.push(pages.lectureroom + "/0");
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="SubTitle1 mb-3">사용법 가이드</div>
                  <span className="material-symbols-outlined">north_east</span>
                </div>
                <div className="body2">
                  다양한 프로그램을 통해 성공적인 DX와 비즈니스 혁신을
                  지원합니다.
                </div>
              </div>
              <div className="col rounded-2 py-4 px-5 bg-primary text-white float-on-hover pointer">
                <div className="d-flex justify-content-between">
                  <div className="SubTitle1 mb-3">프리랜서 지원</div>
                  <span className="material-symbols-outlined">north_east</span>
                </div>
                <div className="body2">준비중 입니다.</div>
              </div>
              <div className="col rounded-2 py-4 px-5 bg-white text-dark float-on-hover pointer">
                <div className="d-flex justify-content-between">
                  <div className="SubTitle1 mb-3">강의 지원</div>
                  <span className="material-symbols-outlined">north_east</span>
                </div>
                <div className="body2">준비중 입니다.</div>
              </div>
            </div>
          </div>
        </section>
        <Request />
        <BottomBanner />
      </article>
    </main>
  );
};
