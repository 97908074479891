import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import "./index.css";
import { Tree, Checkbox } from "antd";
import { api } from "../../config/api";
import { PrinterOutlined } from "@ant-design/icons";

//공사정보
import ContractInputInfo from "./constructioninfo/contractinputinfo/index.js";
import CompletionInputInfo from "./constructioninfo/completioninputinfo/index.js";
import ClaimInputInfo from "./constructioninfo/claiminputinfo/index.js";
import BeginInputInfo from "./constructioninfo/begininputinfo/index.js";
import CompanyInfo from "./constructioninfo/companyinfo/index.js";
import DeputyInfo from "./constructioninfo/deputyinfo/index.js";
//import PhotoInfo from "./constructioninfo/photoinfo/index.js";

//계약서류
import ConstructionSpecialCondition from "./contractdoc/constructionspecialcondition/index.js";
import ContractDepositMemo from "./contractdoc/contractdepositmemo/index.js";
import ContractDepositPayment from "./contractdoc/contractdepositpayment/index.js";
import ContractDocSkip from "./contractdoc/contractdocskip/index.js";
import EstimateSheet from "./contractdoc/estimatesheet/index.js";
import FreelyContract from "./contractdoc/freelycontract/index.js";
import IntegrityAgreement from "./contractdoc/integrityagreement/index.js";
import StandardContract from "./contractdoc/standardcontract/index.js";
import UsePresentationSeal from "./contractdoc/usepresentationseal/index.js";

//준공서류
import CompletionInspector from "./completiondoc/completioninspector/index.js";
import CompletionTotal from "./completiondoc/completiontotal/index.js";

//착공서류
import BeginTotal from "./begindoc/begintotal/index.js";
import DeputyTotal from "./begindoc/deputytotal/index.js";
import EmploymentProof from "./begindoc/employmentproof/index.js";
import ProcessSchedule from "./begindoc/processschedule/index.js";
import SafetyHealthPlan from "./begindoc/safetyhealthplan/index.js";

//청구서류
import Bill from "./claimdoc/bill/index.js";
import DepositAccount from "./claimdoc/depositaccount/index.js";

//사진대장
import PhotoList2TableContainer from "./photolist/photolist1/PhotoList2TableContainer";
import PhotoList4TableContainer from "./photolist/photolist2/PhotoList4TableContainer";

import Print from "./print.js";
import DocPrintModal from "./docprintmodal";

import AlertContext from "../../contexts/AlertContext";
import SafetyHealthStatement from "./completiondoc/safetyhealthstatement";
import CompletionSafetyHealthInfoContainer from "./completiondoc/completionsafetyhealthinfo/CompletionSafetyHealthInfoContainer";
import BeginSafetyHealthInfoContainer from "./begindoc/beginSafetyHealthInfoPage/BeginSafetyHealthInfoContainer";
import BeginEnviManageInfoPage from "./begindoc/beginenvimanageinfo/BeginEnviManageInfoPage";
import EnviManagePlanPage from "./begindoc/envimanageplan/EnviManagePlanPage";
import CompletionEnviManageInfo from "./completiondoc/completionenvimanageinfo/CompletionEnviManageInfo";
import EnviManageStatement from "./completiondoc/envimanagestatement/EnviManageStatement";
import { LoadingContext } from "../../contexts/LoadingContext";
import { pages } from "../../constants/PagePaths";
import DupLoginContext from "../../contexts/DupLoginContext";
import _ from "lodash";

function Documents() {
  const location = useLocation();
  const linkLocation = location.pathname;
  const strArray = linkLocation.split("/");
  const id = Number(strArray[2]);
  const [worklist, setWorklist] = React.useState();
  const [images, setImages] = React.useState();
  const loading = useContext(LoadingContext);
  const dupLogin = useContext(DupLoginContext);

  /**
   * 금액 한글 변환 함수
   */
  function viewKorean(num) {
    var hanA = new Array(
      "",
      "일",
      "이",
      "삼",
      "사",
      "오",
      "육",
      "칠",
      "팔",
      "구",
      "십"
    );
    var danA = new Array(
      "",
      "십",
      "백",
      "천",
      "",
      "십",
      "백",
      "천",
      "",
      "십",
      "백",
      "천",
      "",
      "십",
      "백",
      "천"
    );
    var result = "";
    if (!num) {
      return "";
    }
    for (var i = 0; i < num.length; i++) {
      let str = "";
      let han = hanA[num.charAt(num.length - (i + 1))];
      if (han !== "") str += han + danA[i];
      if (i === 4) str += "만";
      if (i === 8) str += "억";
      if (i === 12) str += "조";
      result = str + result;
    }
    if (num !== 0) result = result + "원";
    return result;
  }

  const putWorkListAsync = async (data) => {
    try {
      // 자꾸 빈 값이 들어와서 비교가 제대로 이루어지지 않음.
      delete data[""];
      const isEqual = _.isEqual(data, worklist);
      if (isEqual) {
        return;
      }

      await dupLogin.checkDupLogin(() => api.putWorklist(data, id));
      await refreshDataAsync();
    } catch (error) {
      if (error?.response?.status === 401) {
        alert.showAlert(
          "다른 브라우저에서 로그인 혹은 로그아웃하여\n현재 브라우저에서 로그아웃 합니다.",
          () => {
            history.push(pages.index);
          }
        );
      }
      console.error("에러발생 : ", error);
    }
  };

  const postWorklistImage = async (result, num, sub_num) => {
    try {
      loading.showLoading();
      await dupLogin.checkDupLogin(() =>
        api.uploadWorklistImage({
          imageFile: result,
          worklist_id: id,
          image_number: num,
          image_sub_number: sub_num,
        })
      );
      await refreshDataAsync();
    } catch (error) {
      console.error("에러발생 : ", error);
    } finally {
      loading.closeLoading();
    }
  };

  const deleteWorklistImage = async (boardImageId) => {
    try {
      loading.showLoading();
      await dupLogin.checkDupLogin(() => api.deleteWorklistImage(boardImageId));
      await refreshDataAsync();
    } catch (error) {
      if (error?.response?.status === 401) {
        alert.showAlert(
          "다른 브라우저에서 로그인 혹은 로그아웃하여\n현재 브라우저에서 로그아웃 합니다.",
          () => {
            history.push(pages.index);
          }
        );
      }
      console.error("에러발생 : ", error);
    } finally {
      loading.closeLoading();
    }
  };

  const [commaContractPrice, setCommaContractPrice] = useState();
  const [krContractPrice, setKrContractPrice] = useState();
  const [
    commaContractGuaranteePrice,
    setCommaContractGuaranteePrice,
  ] = useState();
  const [krContractGuaranteePrice, setKrContractGuaranteePrice] = useState();
  const [commaClaimPrice, setCommaClaimPrice] = useState();
  const [krClaimPrice, setKrClaimPrice] = useState();
  const [commaCompletionCalPrice, setCommaCompletionCalPrice] = useState();
  const [krCompletionCalPrice, setKrCompletionCalPrice] = useState();

  const [commaRepairPrice, setCommaRepairPrice] = useState();

  const refreshDataAsync = async () => {
    try {
      loading.showLoading();
      const result = await dupLogin.checkDupLogin(() =>
        api.getWorklist({ id: id })
      );
      const worklist = {
        ...result?.data?.worklist,
        safetyManagePlan: JSON.parse(result?.data?.worklist?.safetyManagePlan),
        safetyManageState: JSON.parse(
          result?.data?.worklist?.safetyManageState
        ),
        enviManagePlan: JSON.parse(result?.data?.worklist?.enviManagePlan),
        enviManageState: JSON.parse(result?.data?.worklist?.enviManageState),
        photoListContent: JSON.parse(result?.data?.worklist?.photoListContent),
      };
      setWorklist(worklist);

      //금액 콤마 변환
      setCommaContractPrice(Number(worklist?.contractPrice).toLocaleString());
      setCommaContractGuaranteePrice(
        Number(worklist?.contractGuaranteePrice).toLocaleString()
      );
      setCommaClaimPrice(Number(worklist?.claimPrice).toLocaleString());
      setCommaCompletionCalPrice(
        Number(worklist?.completionCalPrice).toLocaleString()
      );

      //금액 한글 변환
      setKrContractPrice(viewKorean(worklist?.contractPrice));
      setKrContractGuaranteePrice(viewKorean(worklist?.contractGuaranteePrice));
      setKrClaimPrice(viewKorean(worklist?.claimPrice));
      setKrCompletionCalPrice(viewKorean(worklist?.completionCalPrice));

      //하자보증금
      setCommaRepairPrice(
        (
          Number(worklist?.contractPrice) *
          Number(worklist?.contractRepairRate) *
          0.01
        ).toLocaleString()
      );

      const resultImage = await dupLogin.checkDupLogin(() =>
        api.getWorklistImages({ worklist_id: id })
      );
      setImages(resultImage?.data?.images);
    } catch (error) {
      if (error?.response?.status === 401) {
        alert.showAlert(
          "다른 브라우저에서 로그인 혹은 로그아웃하여\n현재 브라우저에서 로그아웃 합니다.",
          () => {
            history.push(pages.index);
          }
        );
      }
    } finally {
      loading.closeLoading();
    }
  };

  useEffect(() => {
    refreshDataAsync();
  }, []);

  //깜빡임 효과
  const [beginSafetyInfoClick, setBeginSafetyInfoClick] = useState(true);
  const [beginEnviInfoClick, setBeginEnviInfoClick] = useState(true);
  const [stateSafetyInfoClick, setStateSafetyInfoClick] = useState(true);
  const [stateEnviInfoClick, setStateEnviInfoClick] = useState(true);

  // tree 구조
  const treeData = [
    {
      title: <span style={{ fontWeight: "600" }}>회사정보</span>,
      key: "coInfo",
      children: [
        {
          title: "업체 정보",
          key: "companyinfo",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "현장대리인 정보",
          key: "deputyinfo",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
      ],
    },
    {
      title: <span style={{ fontWeight: "600" }}>공사정보</span>,
      key: "consturctionInfo",
      children: [
        {
          title: "계약 정보 입력",
          key: "contractinputinfo",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "착공 정보 입력",
          key: "begininputinfo",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "준공 정보 입력",
          key: "completioninputinfo",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "청구 정보 입력",
          key: "claiminputinfo",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
      ],
    },
    {
      title: <span style={{ fontWeight: "600" }}>계약서류</span>,
      key: "contractDoc",
      children: [
        {
          title: "견적서",
          key: "estimatesheet",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "수의계약서",
          key: "freelycontract",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "표준계약서",
          key: "standardcontract",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "사용인감계",
          key: "usepresentationseal",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "계약보증금지급각서",
          key: "contractdepositmemo",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "계약보증금납부서",
          key: "contractdepositpayment",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "청렴계약이행",
          key: "integrityagreement",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "공사계약특수조건",
          key: "constructionspecialcondition",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "계약붙임서류생략서",
          key: "contractdocskip",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
      ],
    },
    {
      title: <span style={{ fontWeight: "600" }}>착공서류</span>,
      key: "beginDoc",
      children: [
        {
          title: "착공계",
          key: "begintotal",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "예정공정표",
          key: "processschedule",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "현장대리인계",
          key: "deputytotal",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "재직증명원",
          key: "employmentproof",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },

        {
          title: "산업안전보건관리비 사용계획서",
          key: "safetyhealthplan",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "산업안전보건관리비 항목별 사용계획",
          key: "beginsafetyhealthinfo",
          className: `fs12 tree-child ${beginSafetyInfoClick ? "tree_blink" : ""}`,
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },

        {
          title: "환경관리비 사용계획서",
          key: "envimanageplan",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "환경관리비 항목별 사용계획",
          key: "beginenvimanageinfo",
          className: `tree-child ${beginEnviInfoClick ? "tree_blink" : ""}`,
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        }
      ],
    },
    {
      title: <span style={{ fontWeight: "600" }}>준공서류</span>,
      key: "completionDoc",
      children: [
        {
          title: "준공계",
          key: "completiontotal",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "준공검사원",
          key: "completioninspector",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },

        {
          title: "산업안전보건관리비 사용내역서",
          key: "safetyhealthstatement",
          className: "fs12 tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "산업안전보건관리비 항목별 사용내역",
          key: "completionsafetyhealthinfo",
          className: `fs12 tree-child ${stateSafetyInfoClick ? "tree_blink" : ""}`,
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "환경관리비 사용내역서",
          key: "envimanagestatement",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "환경관리비 항목별 사용내역",
          key: "completionenvimanageinfo",
          className: `tree-child ${stateEnviInfoClick ? "tree_blink" : ""}`,
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },

        {
          title: "사진대장 (2칸)",
          key: "photoList1",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "사진대장 (4칸)",
          key: "photoList2",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",
                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
      ],
    },
    {
      title: <span style={{ fontWeight: "600" }}>청구서류</span>,
      key: "claimDoc",
      children: [
        {
          title: "청구서",
          key: "bill",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",
                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
        {
          title: "입금계좌지정서",
          key: "depositaccount",
          className: "tree-child",
          switcherIcon: (
            <span
              style={{
                display: "inline-block",

                fontSize: "17px",
                color: "#a1a1a1",
              }}
            >
              &#x0231E;
            </span>
          ),
        },
      ],
    },
  ];

  //트리구조 기본값 펼쳐지게 보이기
  const [expandedKeys, setExpandedKeys] = useState([
    "coInfo",
    "consturctionInfo",
  ]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  //화살표 누를 시 트리구조 열리기 위한 함수
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  //
  const { DirectoryTree } = Tree;

  ////////
  const history = useHistory();

  //알람 사용
  const alert = useContext(AlertContext);

  //체크박스 선택 state
  const [selectDoc, setSelectDoc] = useState(); //계약서류
  const [selectDoc1, setSelectDoc1] = useState(); //착공서류
  const [selectDoc2, setSelectDoc2] = useState(); //준공서류
  const [selectDoc3, setSelectDoc3] = useState(); //청구서류

  //트리구조 선택 시 동작 함수
  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);

    if (selectedKeysValue.toString() === "companyinfo") {
      history.push(`/worklists/${id}/documents/companyinfo`);
    } else if (selectedKeysValue.toString() === "deputyinfo") {
      history.push(`/worklists/${id}/documents/deputyinfo`);
    } else if (selectedKeysValue.toString() === "contractinputinfo") {
      history.push(`/worklists/${id}/documents/contractinputinfo`);
    } else if (selectedKeysValue.toString() === "completioninputinfo") {
      history.push(`/worklists/${id}/documents/completioninputinfo`);
    } else if (selectedKeysValue.toString() === "begininputinfo") {
      history.push(`/worklists/${id}/documents/begininputinfo`);
    } else if (selectedKeysValue.toString() === "claiminputinfo") {
      history.push(`/worklists/${id}/documents/claiminputinfo`);
    } else if (selectedKeysValue.toString() === "estimatesheet") {
      history.push(`/worklists/${id}/documents/estimatesheet`);
    } else if (selectedKeysValue.toString() === "contractdepositpayment") {
      history.push(`/worklists/${id}/documents/contractdepositpayment`);
    } else if (selectedKeysValue.toString() === "contractdocskip") {
      history.push(`/worklists/${id}/documents/contractdocskip`);
    } else if (selectedKeysValue.toString() === "contractdepositmemo") {
      history.push(`/worklists/${id}/documents/contractdepositmemo`);
    } else if (
      selectedKeysValue.toString() === "constructionspecialcondition"
    ) {
      alert.showAlert(
        `※ 해당 서류는 전자 계약 시, 
      발주처 및 지자체의 첨부파일을 확인해주세요.`,
        () => {
          history.push(
            `/worklists/${id}/documents/constructionspecialcondition`
          );
        }
      );
    } else if (selectedKeysValue.toString() === "usepresentationseal") {
      history.push(`/worklists/${id}/documents/usepresentationseal`);
    } else if (selectedKeysValue.toString() === "freelycontract") {
      history.push(`/worklists/${id}/documents/freelycontract`);
    } else if (selectedKeysValue.toString() === "integrityagreement") {
      alert.showAlert(
        `※ 해당 서류는 전자 계약 시,
      발주처 및 지자체의 첨부파일을 확인해주세요.`,
        () => {
          history.push(`/worklists/${id}/documents/integrityagreement`);
        }
      );
    } else if (selectedKeysValue.toString() === "standardcontract") {
      history.push(`/worklists/${id}/documents/standardcontract`);
    } else if (selectedKeysValue.toString() === "completiontotal") {
      history.push(`/worklists/${id}/documents/completiontotal`);
    } else if (selectedKeysValue.toString() === "completioninspector") {
      history.push(`/worklists/${id}/documents/completioninspector`);
    } else if (selectedKeysValue.toString() === "deputytotal") {
      history.push(`/worklists/${id}/documents/deputytotal`);
    } else if (selectedKeysValue.toString() === "processschedule") {
      history.push(`/worklists/${id}/documents/processschedule`);
    } else if (selectedKeysValue.toString() === "begintotal") {
      history.push(`/worklists/${id}/documents/begintotal`);
    } else if (selectedKeysValue.toString() === "employmentproof") {
      history.push(`/worklists/${id}/documents/employmentproof`);
    } else if (selectedKeysValue.toString() === "bill") {
      history.push(`/worklists/${id}/documents/bill`);
    } else if (selectedKeysValue.toString() === "depositaccount") {
      history.push(`/worklists/${id}/documents/depositaccount`);
    } else if (selectedKeysValue.toString() === "photoList1") {
      history.push(`/worklists/${id}/documents/photoList1`);
    } else if (selectedKeysValue.toString() === "photoList2") {
      history.push(`/worklists/${id}/documents/photoList2`);
    } else if (selectedKeysValue.toString() === "safetyhealthplan") {
      history.push(`/worklists/${id}/documents/safetyhealthplan`);
    } else if (selectedKeysValue.toString() === "safetyhealthstatement") {
      history.push(`/worklists/${id}/documents/safetyhealthstatement`);
    } else if (selectedKeysValue.toString() === "beginsafetyhealthinfo") {
      setBeginSafetyInfoClick(false);
      history.push(`/worklists/${id}/documents/beginsafetyhealthinfo`);
    } else if (selectedKeysValue.toString() === "completionsafetyhealthinfo") {
      setStateSafetyInfoClick(false);
      history.push(`/worklists/${id}/documents/completionsafetyhealthinfo`);
    } else if (selectedKeysValue.toString() === "beginenvimanageinfo") {
      setBeginEnviInfoClick(false);
      history.push(`/worklists/${id}/documents/beginenvimanageinfo`);
    } else if (selectedKeysValue.toString() === "envimanageplan") {
      history.push(`/worklists/${id}/documents/envimanageplan`);
    } else if (selectedKeysValue.toString() === "completionenvimanageinfo") {
      setStateEnviInfoClick(false);
      history.push(`/worklists/${id}/documents/completionenvimanageinfo`);
    } else if (selectedKeysValue.toString() === "envimanagestatement") {
      history.push(`/worklists/${id}/documents/envimanagestatement`);
    }
  };

  const parentContractData = (data) => {
    setSelectDoc(data);
  };
  const parentBeginData = (data) => {
    setSelectDoc1(data);
  };
  const parentCompletionData = (data) => {
    setSelectDoc2(data);
  };
  const parentClaimData = (data) => {
    setSelectDoc3(data);
  };

  const onPrintModal = () => {
    alert.showDocPrintAlert(
      "< 일괄 출력 >", //title
      <DocPrintModal
        parentContractData={parentContractData}
        parentBeginData={parentBeginData}
        parentCompletionData={parentCompletionData}
        parentClaimData={parentClaimData}
      />, // message
      "출력",
      "취소",
      () => {
        //setSelectDoc(contractCheckedList);
        history.push(`/worklists/${id}/documents/print`);
      }
    );
  };

  return (
    <div id="container">
      <div id="left-side">
        <div className="constructionName-box">{worklist?.constructionName}</div>
        <DirectoryTree
          className="document-tree"
          onExpand={onExpand}
          onSelect={onSelect}
          expandedKeys={expandedKeys}
          autoExpandParent={autoExpandParent}
          treeData={treeData}
          selectedKeys={selectedKeys}
        />
        <button className="print-tree-btn" onClick={onPrintModal}>
          <PrinterOutlined
            className="printer-icon"
            style={{ fontSize: "25px", marginRight: "7px" }}
          />
          일괄 출력
        </button>
      </div>

      <div id="right-side">
        <Switch>
          {/* 입력정보 */}
          <Route
            exact={true}
            path={`/worklists/${id}/documents/contractinputinfo`}
          >
            <ContractInputInfo
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/CompletionInputInfo`}
          >
            <CompletionInputInfo
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/ClaimInputInfo`}
          >
            <ClaimInputInfo
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/BeginInputInfo`}
          >
            <BeginInputInfo
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route exact={true} path={`/worklists/${id}/documents/CompanyInfo`}>
            <CompanyInfo
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route exact={true} path={`/worklists/${id}/documents/DeputyInfo`}>
            <DeputyInfo
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          {/* 계약서류 */}
          <Route exact={true} path={`/worklists/${id}/documents/EstimateSheet`}>
            <EstimateSheet
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/FreelyContract`}
          >
            <FreelyContract worklist={worklist} />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/StandardContract`}
          >
            <StandardContract
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
              commaContractGuaranteePrice={commaContractGuaranteePrice}
              krContractGuaranteePrice={krContractGuaranteePrice}
              commaRepairPrice={commaRepairPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/UsePresentationSeal`}
          >
            <UsePresentationSeal worklist={worklist} />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/ContractDepositMemo`}
          >
            <ContractDepositMemo
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
              commaContractGuaranteePrice={commaContractGuaranteePrice}
              krContractGuaranteePrice={krContractGuaranteePrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/ContractDepositPayment`}
          >
            <ContractDepositPayment
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
              commaContractGuaranteePrice={commaContractGuaranteePrice}
              krContractGuaranteePrice={krContractGuaranteePrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/IntegrityAgreement`}
          >
            <IntegrityAgreement worklist={worklist} />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/ConstructionSpecialCondition`}
          >
            <ConstructionSpecialCondition worklist={worklist} />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/ContractDocSkip`}
          >
            <ContractDocSkip worklist={worklist} />
          </Route>
          {/* 착공서류 */}
          <Route exact={true} path={`/worklists/${id}/documents/BeginTotal`}>
            <BeginTotal
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/ProcessSchedule`}
          >
            <ProcessSchedule
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
            />
          </Route>
          <Route exact={true} path={`/worklists/${id}/documents/DeputyTotal`}>
            <DeputyTotal
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/EmploymentProof`}
          >
            <EmploymentProof worklist={worklist} />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/beginsafetyhealthinfo`}
          >
            <BeginSafetyHealthInfoContainer
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/safetyhealthplan`}
          >
            <SafetyHealthPlan
              worklist={worklist}
              commaContractPrice={commaContractPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/beginenvimanageinfo`}
          >
            <BeginEnviManageInfoPage
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/envimanageplan`}
          >
            <EnviManagePlanPage
              worklist={worklist}
              commaContractPrice={commaContractPrice}
            />
          </Route>
          {/* 준공서류 */}
          <Route
            exact={true}
            path={`/worklists/${id}/documents/CompletionTotal`}
          >
            <CompletionTotal
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
              commaCompletionCalPrice={commaCompletionCalPrice}
              krCompletionCalPrice={krCompletionCalPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/CompletionInspector`}
          >
            <CompletionInspector
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
              commaCompletionCalPrice={commaCompletionCalPrice}
              krCompletionCalPrice={krCompletionCalPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/completionsafetyhealthinfo`}
          >
            <CompletionSafetyHealthInfoContainer
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/safetyhealthstatement`}
          >
            <SafetyHealthStatement
              worklist={worklist}
              commaContractPrice={commaContractPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/completionenvimanageinfo`}
          >
            <CompletionEnviManageInfo
              worklist={worklist}
              putWorkListAsync={putWorkListAsync}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/envimanagestatement`}
          >
            <EnviManageStatement
              worklist={worklist}
              commaContractPrice={commaContractPrice}
            />
          </Route>
          <Route exact={true} path={`/worklists/${id}/documents/photoList1`}>
            <PhotoList2TableContainer
              worklist={worklist}
              images={images?.filter((image) => image.image_number < 11)}
              putWorkListAsync={putWorkListAsync}
              postWorklistImage={postWorklistImage}
              deleteWorklistImage={deleteWorklistImage}
            />
          </Route>
          <Route exact={true} path={`/worklists/${id}/documents/photoList2`}>
            <PhotoList4TableContainer
              worklist={worklist}
              images={images?.filter((image) => 10 < image.image_number)}
              putWorkListAsync={putWorkListAsync}
              postWorklistImage={postWorklistImage}
              deleteWorklistImage={deleteWorklistImage}
            />
          </Route>
          {/* 청구서류 */}
          <Route exact={true} path={`/worklists/${id}/documents/Bill`}>
            <Bill
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
              commaClaimPrice={commaClaimPrice}
              krClaimPrice={krClaimPrice}
            />
          </Route>
          <Route
            exact={true}
            path={`/worklists/${id}/documents/DepositAccount`}
          >
            <DepositAccount
              worklist={worklist}
              commaClaimPrice={commaClaimPrice}
              krClaimPrice={krClaimPrice}
            />
          </Route>
          {/* 일괄출력 */}
          <Route exact={true} path={`/worklists/${id}/documents/print`}>
            <Print
              selectDoc={selectDoc}
              selectDoc1={selectDoc1}
              selectDoc2={selectDoc2}
              selectDoc3={selectDoc3}
              worklist={worklist}
              commaContractPrice={commaContractPrice}
              krContractPrice={krContractPrice}
              commaContractGuaranteePrice={commaContractGuaranteePrice}
              krContractGuaranteePrice={krContractGuaranteePrice}
              commaRepairPrice={commaRepairPrice}
              commaClaimPrice={commaClaimPrice}
              krClaimPrice={krClaimPrice}
              commaCompletionCalPrice={commaCompletionCalPrice}
              krCompletionCalPrice={krCompletionCalPrice}
              images1={images?.filter((image) => image.image_number < 11)}
              images2={images?.filter((image) => 10 < image.image_number)}
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Documents;
