interface Props {}

export default (props: Props) => {
  return (
    <div
      className="modal fade"
      id={"DeleteModal"}
      tabIndex={-1}
      aria-labelledby="CouponModalLabel"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="CouponModalLabel">
              상품 삭제
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">장바구니에서 삭제 하시겠어요?</div>
          <div className="modal-footer">
            <div className="modal-footer-wrap">
              <button
                type="button"
                className="btn btn-light btn-lg flex-1"
                data-bs-dismiss="modal"
              >
                취소
              </button>
              <button type="button" className="btn btn-danger btn-lg flex-1">
                삭제
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
