export default () => {
  return (
    <div
      className="modal fade"
      id="CouponModal"
      tabIndex={-1}
      aria-labelledby="CouponModalLabel"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="CouponModalLabel">
              보유중인 쿠폰
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form className="needs-validation" >
              <div className="input-group has-validation mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="validationCustomUsername"
                  placeholder="쿠폰코드를 입력해주세요."
                  aria-describedby="inputGroupPrepend"
                  required
                />
                <button
                  className="btn btn-primary left-icon"
                  type="submit"
                  id="coupon-submit"
                >
                  <span className="material-symbols-outlined me-2">add</span>
                  쿠폰 등록
                </button>
                <div className="invalid-feedback">
                  올바른 쿠폰코드를 입력해주세요.
                </div>
              </div>
            </form>
            <div className="modal-coupon-list">
              <div
                className="coupon-list aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="coupons"
                    id="coupon-option1"
                    autoComplete="off"
                  />
                  <label className="btnbox w-100" htmlFor="coupon-option1">
                    <div className="coupon-list-item bg-white">
                      <div className="price-wrap">
                        <h4>
                          <span>30,000</span>원
                        </h4>
                        <span className="badge text-bg-secondary">D-day</span>
                      </div>
                      <div className="flex-2">
                        <div className="title-lg">쿠폰명</div>
                        <div className="caption">
                          <span>date time</span>
                          까지
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
                <div className="col-12">
                  <input
                    type="radio"
                    className="btn-check"
                    name="coupons"
                    id="coupon-option2"
                    autoComplete="off"
                    disabled
                  />
                  <label className="btnbox w-100" htmlFor="coupon-option2">
                    <div className="coupon-list-item list-disabled bg-white">
                      <div className="price-wrap">
                        <h4>
                          <span>30,000</span>원
                        </h4>
                        <span className="badge">D-day</span>
                      </div>
                      <div className="flex-2">
                        <div className="title-lg">쿠폰명</div>
                        <div className="caption">
                          <span>date time</span>까지
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="modal-footer-wrap">
              <button
                type="button"
                className="btn btn-light btn-lg flex-1"
                data-bs-dismiss="modal"
              >
                취소
              </button>
              <button type="button" className="btn btn-primary btn-lg flex-2">
                쿠폰 적용
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
