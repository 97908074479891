/* eslint-disable import/no-anonymous-default-export */
import { SafetyManagementItemData } from "../../../../constants/SafetyManagementItemData";
import { Worklist } from "../../../../models/worklist/WorklistModel";
import BeginSafetyHealthInfoPresenter from "./BeginSafetyHealthInfoPresenter";
interface Props {
  worklist: Worklist;
  putWorkListAsync: (data: any) => void;
}
export default (props: Props) => {
  const worklist = props.worklist;
  const putWorkListAsync = props.putWorkListAsync;
  if (!worklist?.safetyManagePlan) {
    return <div>로딩중...</div>;
  }
  const useData = !worklist
    ? SafetyManagementItemData
    : worklist.safetyManagePlan;
  return (
    <div>
      <BeginSafetyHealthInfoPresenter
        worklist={worklist}
        model={useData}
        putWorkListAsync={putWorkListAsync}
      />
    </div>
  );
};
