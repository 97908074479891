/* eslint-disable import/no-anonymous-default-export */
import _, { parseInt } from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { api } from "../../config/api";
import { pages } from "../../constants/PagePaths";
import { SampleStatementMainType } from "../../constants/SampleStatementPageData";
import { SampleStatementListItem } from "../../models/SampleStatementItem";
import LazyEvent from "../../modules/LazyEvent";
import SampleStatementListPresenter from "./SampleStatementListPresenter";
import queryString from "query-string";
import { util } from "../../config/util";
import AlertContext from "../../contexts/AlertContext";
import { Cookies } from "../../constants/Cookies";
import DupLoginContext from "../../contexts/DupLoginContext";
import { CategoryItem } from "../../models/CategoryItem";
import { FreeUseDay } from "../../constants/General";

export default () => {
  const [sampleStatementItems, setSampleStatementItems] = useState<
    SampleStatementListItem[]
  >([]);
  const [originSampleStatementItems, setOriginSampleStatementItems] = useState<
    SampleStatementListItem[]
  >([]);
  const [filteredSampleStatementItems, setFilteredSampleStatementItems] =
    useState<SampleStatementListItem[]>([]);
  const [searchTempSampleStatementItems, setSearchTempSampleStatementItems] =
    useState<SampleStatementListItem[]>([]);
  const history = useHistory();
  const searchValue = useRef("");
  const isSearching = useRef<boolean>(false);
  const [pageSize, setPageSize] = useState(3);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [selectedMainType, setSelectedMainType] = useState(
    SampleStatementMainType.전체
  );
  const lazyEvent = new LazyEvent();
  const location = useLocation();
  const params = useRef<any>({});
  const alert = useContext(AlertContext);
  const dupLogin = useContext(DupLoginContext);
  const mainTypes: CategoryItem[] = Object.values(SampleStatementMainType).map(
    (value, index) => {
      return {
        description: "",
        id: index.toString(),
        title: value,
      };
    }
  );

  useEffect(() => {
    const key = util.getCookie(Cookies.authKey);
    if (!key) {
      alert.showAlert(
        "로그인 후 이용해주세요.\n회원가입시 " +
          FreeUseDay +
          "일 무료기간 제공중입니다.",
        () => {
          history.replace(pages.login);
        }
      );
      return;
    }

    new Promise(async () => {
      const res = await dupLogin.checkDupLogin(() => api.getSampleStatement());
      const items = res?.data?.list?.map((item: any) => {
        return {
          ...item,
          keywords:
            (item?.keywords &&
              JSON.parse(item?.keywords?.replaceAll("'", '"'))) ||
            [],
          created: new Date(item.created),
          mainType: item.main_type,
          recommendCount: item.recommend_count,
        };
      });

      setOriginSampleStatementItems(_.cloneDeep(items));
      setFilteredSampleStatementItems(items);
      let list = items;
      const parsed = queryString.parse(location.search);
      params.current = parsed;
      if (
        parsed?.industry &&
        SampleStatementMainType[parsed.industry as SampleStatementMainType] &&
        parsed?.industry !== "전체"
      ) {
        setSelectedMainType(parsed.industry as SampleStatementMainType);
        list = list.filter((item: any) => {
          return item.mainType === parsed?.industry;
        });
        setFilteredSampleStatementItems(_.cloneDeep(list));
      }
      if (parsed?.search && typeof parsed?.search === "string") {
        setSearchTempSampleStatementItems(_.cloneDeep(list));
        isSearching.current = true;
        list = getSearchedItems(list, parsed.search.toString());
        searchValue.current = parsed.search.toString();
      }
      let minIndex = 0,
        maxIndex = pageSize;

      if (parsed?.page) {
        const page = +parsed.page;
        const totalPage =
          list?.length % pageSize === 0
            ? parseInt((list?.length / pageSize).toString())
            : parseInt((list?.length / pageSize).toString()) + 1;
        const initPage = page < 0 || page > totalPage ? 1 : page;

        setCurrentPageIndex(initPage);
        minIndex = (initPage - 1) * pageSize;
        maxIndex = initPage * pageSize;
      }
      setFilteredSampleStatementItems(_.cloneDeep(list));
      !parsed?.search && setSearchTempSampleStatementItems(_.cloneDeep(list));
      sliceItems(_.cloneDeep(list), minIndex, maxIndex);
    });
  }, []);

  useEffect(() => {
    const minIndex = (currentPageIndex - 1) * pageSize;
    const maxIndex = currentPageIndex * pageSize;
    sliceItems(filteredSampleStatementItems, minIndex, maxIndex);
  }, [pageSize]);

  const handlePageChange = (page: number) => {
    setCurrentPageIndex(page);
    const minIndex = (page - 1) * pageSize;
    const maxIndex = page * pageSize;

    sliceItems(filteredSampleStatementItems, minIndex, maxIndex);
    params.current.page = page;
    refreshUrl();
  };

  const refreshUrl = () => {
    const param = queryString.stringify(params.current);
    history.push(`${pages.statementlist}?${param}`);
  };

  const sliceItems = (
    items: SampleStatementListItem[],
    minIndex: number,
    maxIndex: number
  ) => {
    const slicedItems = items?.filter(
      (_, index) => index >= minIndex && index < maxIndex
    );
    setSampleStatementItems(slicedItems);
  };

  const handleMainTypeClick = (type: SampleStatementMainType) => {
    setSelectedMainType(type);
    setCurrentPageIndex(1);
    params.current.page = 1;
    if (type === SampleStatementMainType.전체) {
      setSearchTempSampleStatementItems(originSampleStatementItems);
      const searchedItems = getSearchedItems(
        originSampleStatementItems,
        searchValue.current
      );
      setFilteredSampleStatementItems(searchedItems);
      sliceItems(searchedItems, 0, pageSize);
    } else {
      refreshListByFilter(type);
    }
    params.current.industry = type;
    refreshUrl();
  };

  const refreshListByFilter = (type: SampleStatementMainType) => {
    const filteredItems = getTypeFilteredList(type);
    setCurrentPageIndex(1);
    params.current.page = 1;
    setFilteredSampleStatementItems(filteredItems);
    sliceItems(filteredItems, 0, pageSize);
    refreshUrl();
  };

  const getTypeFilteredList = (type: SampleStatementMainType) => {
    let filteredList = originSampleStatementItems.filter((item) => {
      return item.mainType === type;
    });
    setSearchTempSampleStatementItems(filteredList);
    if (isSearching.current) {
      filteredList = getSearchedItems(filteredList, searchValue.current);
    }
    return filteredList;
  };

  const handleShowSizeChange = (_: number, size: number): void => {
    setPageSize(size);
  };

  const handleSearchValueChange = (value: string): void => {
    searchValue.current = value;
    if (!isSearching.current) {
      isSearching.current = true;
      setSearchTempSampleStatementItems(filteredSampleStatementItems);
    }
    if (value.length === 0) {
      setSampleStatementItems(searchTempSampleStatementItems);
      setFilteredSampleStatementItems(searchTempSampleStatementItems);
      isSearching.current = false;
    }
    lazyEvent.registerAsync(async () => {
      const searchFilteredList = getSearchedItems(
        searchTempSampleStatementItems,
        value
      );
      setCurrentPageIndex(1);
      params.current.page = 1;
      setFilteredSampleStatementItems(searchFilteredList);
      sliceItems(searchFilteredList, 0, pageSize);
      params.current.search = value;
      refreshUrl();
    });
  };

  const getSearchedItems = (
    searchList: SampleStatementListItem[],
    searchValue: string
  ) => {
    return searchList?.filter((item) => {
      return (
        item.keywords?.some((key) => key?.includes(searchValue)) ||
        item.mainType?.includes(searchValue) ||
        item.title.includes(searchValue)
      );
    });
  };

  const handleItemClick = (id: number) => {
    history.push(`${pages.statementdetail}/${id}`, {
      prevPath: location.pathname + location.search,
    });
  };

  const pageList = () => {
    const totalPage: number = filteredSampleStatementItems?.length!;
    const lastPage = totalPage % 3 > 0 ? totalPage / 3 + 1 : totalPage / 3;
    let pages = [];
    for (let i = 1; i <= lastPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <main id="wrap">
      <article>
        <section>
          <div className="container page">
            <div className="section-header">샘플내역서</div>
            <div className="col-12 pt-4">
              <form>
                <div className="input-group mb-3" id="search-form">
                  <button
                    className="btn btn-md dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    전체
                  </button>
                  {/* <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#">
                        제목
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        내용
                      </a>
                    </li>
                  </ul> */}
                  <input
                    type="text"
                    className="form-control border-0"
                    placeholder="검색어를 입력해주세요"
                    aria-label="Text input with dropdown button "
                    onChange={(e) => {
                      handleSearchValueChange(e.target.value);
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="col-12 pt-5">
              <div className="category-wrap">
                {mainTypes.map((category, index) => {
                  return (
                    <>
                      <input
                        type="radio"
                        className="btn-check"
                        name="category"
                        id={"option" + (index + 1)}
                        checked={selectedMainType === category.title}
                        onClick={() => {
                          handleMainTypeClick(
                            Object.keys(SampleStatementMainType)[
                              index
                            ] as SampleStatementMainType
                          );
                        }}
                        value={category.title}
                      />
                      <label className="btn" htmlFor={"option" + (index + 1)}>
                        {category.title}
                      </label>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="col-12">
              <div className="mt-5">
                <div>
                  TOTAL
                  <span className="text-primary fw-bold ms-1">
                    {filteredSampleStatementItems.length}
                  </span>
                </div>
              </div>
              <div
                className="board-wrap aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                {sampleStatementItems.map((item, index) => {
                  return (
                    <div className="board-card">
                      <a
                        onClick={() => {
                          handleItemClick(item.id);
                        }}
                      >
                        <div className="date">
                          <span className="yy-mm">
                            {item?.created.format("yy.MM")}
                          </span>
                          <div className="dd">{item?.created.format("dd")}</div>
                        </div>
                        <div className="badge text-bg-secondary category">
                          {item?.mainType}
                        </div>
                        <div className="title">{item.title}</div>
                        <div className="tag-box">
                          {item?.keywords?.map((keyword) => (
                            <div className="tag">{"#" + keyword}</div>
                          ))}
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-5 mx-auto">
              <div className="paging">
                <a
                  id="prev"
                  onClick={() => {
                    if (currentPageIndex > 1) {
                      handlePageChange(currentPageIndex - 1);
                    }
                    // handleSearchChange(searchText.current, current - 1);
                  }}
                >
                  <span className="material-symbols-outlined">
                    chevron_left
                  </span>
                </a>
                {pageList().map((page) => {
                  const isFirstPage = page === 1;
                  const isLastPage = page === pageList()[pageList().length - 1];
                  if (
                    isFirstPage ||
                    isLastPage ||
                    (page - 3 < currentPageIndex && page + 3 > currentPageIndex)
                  ) {
                    return (
                      <a
                        className={currentPageIndex === page ? "active" : ""}
                        onClick={() => {
                          handlePageChange(page);
                          // handleSearchChange(searchText.current, page);
                        }}
                      >
                        {page}
                      </a>
                    );
                  }
                })}

                <a
                  id="next"
                  onClick={() => {
                    if (currentPageIndex < pageList().length) {
                      handlePageChange(currentPageIndex + 1);
                    }
                    // handleSearchChange(searchText.current, current + 1);
                  }}
                >
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </article>
    </main>
  );
};
